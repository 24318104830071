import { Component, Input, OnDestroy, OnInit, } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuditService } from './audit.service';

import { BaseFacet, BaseFacetService } from '@common/facet';
import { AuditFilterComponent } from './audit-filter.component';

import { AuditAnimalsTableOptions } from './audit-animals-table-options';
import { AuditSamplesTableOptions } from './audit-samples-table-options';
import { AuditJobsTableOptions } from './audit-jobs-table-options';
import { AuditJobAnimalsTableOptions } from './audit-job-animals-table-options';
import { AuditJobSamplesTableOptions } from './audit-job-samples-table-options';
import { AuditJobSampleGroupsTableOptions } from './audit-job-sample-groups-table-options';
import { AuditJobTasksTableOptions } from './audit-job-tasks-table-options';
import { AuditJobLinesTableOptions } from './audit-job-lines-table-options';
import { AuditJobLineDetailsTableOptions } from './audit-job-line-details-table-options';
import { AuditJobStandardPhrasesTableOptions } from './audit-job-standard-phrases-table-options';
import { AuditJobGroupsTableOptions } from './audit-job-groups-table-options';
import { AuditJobTestArticlesTableOptions } from './audit-job-test-articles-table-options';
import { AuditJobCharacteristicsTableOptions } from './audit-job-characteristics-table-options';
import { AuditJobInstitutionsTableOptions } from './audit-job-institutions-table-options';
import { AuditJobOrdersTableOptions } from './audit-job-orders-table-options';
import { AuditJobIndividualPlaceholdersTableOptions } from './audit-job-individual-placeholders-table-options';
import { AuditJobGroupPlaceholdersTableOptions } from './audit-job-group-placeholders-table-options';
import { AuditWorkflowOutputsTableOptions } from './audit-workflowOutputs-table-options';
import { AuditMaterialPoolsTableOptions } from './audit-materialPools-table-options';
import { AuditMaterialPoolMaterialsTableOptions } from './audit-materialPoolMaterials-table-options';
import { AuditMatingsTableOptions } from './audit-matings-table-options';
import { AuditMatingMaterialsTableOptions } from './audit-matingMaterials-table-options';
import { AuditCohortsTableOptions } from './audit-cohorts-table-options';
import { AuditCohortAnimalsTableOptions } from './audit-cohortAnimals-table-options';
import { AuditLinesTableOptions } from './audit-lines-table-options';
import { AuditStudiesTableOptions } from './audit-studies-table-options';
import { AuditBirthsTableOptions } from './audit-births-table-options';
import { AuditLocationsTableOptions } from './audit-locations-table-options';
import { AuditAnimalClinicalObservationsTableOptions } from './audit-animalClinicalObservations-table-options';
import { AuditAnimalHealthRecordsTableOptions } from './audit-animalHealthRecords-table-options';
import { AuditClinicalObservationDetailsTableOptions } from './audit-clinical-observation-details-table-options';
import { AuditWorkflowTasksTableOptions } from './audit-workflowTasks-table-options';
import { AuditWorkflowTaskDetailsTableOptions } from './audit-workflowTaskDetails-table-options';
import { AuditPermitInstitutionsTableOptions } from './audit-permit-institutions-table-options';
import { AuditProtocolsTableOptions } from './audit-protocols-table-options';
import { AuditProtocolsTasksTableOptions } from './audit-protocolsTasks-table-options';
import { AuditProtocolsSamplesGroupsTableOptions } from './audit-protocolsSamplesGroups-table-options';
import { AuditResourcesTableOptions } from './audit-resources-table-options';
import { AuditStudyAdministratorStudiesTableOptions } from './audit-studyAdministratorStudies-table-options';
import { AuditVocabulariesTaskStatusesTableOptions } from './audit-vocabulariesTaskStatuses-table-options';
import { AuditRoleFacetsTableOptions } from './audit-roleFacets-table-options';
import { AuditOrdersTableOptions } from './audit-orders-table-options';
import { AuditEnumerationClassesTableOptions } from './audit-enumerationClasses-table-options';
import { AuditVocabulariesMaterialOriginsTableOptions } from './audit-vocabulariesMaterialOrigins-table-options';
import { AuditVocabulariesBreedingStatusesTableOptions } from './audit-vocabulariesBreedingStatuses-table-options';
import { AuditUsersTableOptions } from './audit-users-table-options';
import { AuditUserDetailsTableOptions } from './audit-user-details-table-options';
import { AuditEnumerationItemsTableOptions } from './audit-enumerationItems-table-options';
import { AuditVocabulariesCompliancesTableOptions } from './audit-vocabulariesCompliances-table-options';
import { AuditVocabulariesInstitutionTypesTableOptions } from './audit-vocabulariesInstitutionTypes-table-options';
import { AuditVocabulariesJobReportsTableOptions } from './audit-vocabulariesJobReports-table-options';

import { CellFormatterService, DataResponse, TableState } from '@common/datatable';
import { WorkspaceFilterService } from '@services/workspace-filter.service';
import { TranslationService } from '@services/translation.service';
import { filterToDate } from '@common/util';
import { FeatureFlagService, LARGE_ANIMAL } from '@services/feature-flags.service';
import { AuditRolesTableOptions } from './audit-roles-table-options';
import { AuditVocabulariesTaskFlagMessagesTableOptions } from './audit-vocabulariesTaskFlagMessages-table-options';
import { AuditVocabulariesAnimalCommentStatusesTableOptions } from './audit-vocabulariesAnimalCommentStatuses-table-options';
import { AuditVocabulariesAnimalStatusesTableOptions } from './audit-vocabulariesAnimalStatuses-table-options';
import { AuditVocabulariesTestArticlesTableOptions } from './audit-vocabulariesTestArticles-table-options';
import { AuditVocabulariesJobSubtypesTableOptions } from './audit-vocabulariesJobSubtypes-table-options';
import { AuditVocabulariesTaskOutcomesTableOptions } from './audit-vocabulariesTaskOutcomes-table-options';
import { AuditCharacteristicsAnimalsTableOptions } from './audit-characteristicsAnimals-table-options';
import { AuditCharacteristicsJobsTableOptions } from './audit-characteristicsJobs-table-options';
import { AuditCharacteristicsStudiesTableOptions } from './audit-characteristicsStudies-table-options';
import { AuditVocabulariesCouriersTableOptions } from './audit-vocabulariesCouriers-table-options';
import { AuditVocabulariesLineStatusesTableOptions } from './audit-vocabulariesLineStatuses-table-options';
import { AuditVocabulariesJobStatusesTableOptions } from './audit-vocabulariesJobStatuses-table-options';
import { AuditVocabulariesAnimalUsesTableOptions } from './audit-vocabulariesAnimalUses-table-options';
import { AuditOrdersAnimalsTableOptions } from './audit-ordersAnimals-table-options';
import { AuditOrdersLocationsTableOptions } from './audit-ordersLocations-table-options';
import { AuditVocabulariesSampleAnalysesTableOptions } from './audit-vocabulariesSampleAnalyses-table-options';
import { AuditVocabulariesSampleConditionsTableOptions } from './audit-vocabulariesSampleConditions-table-options';
import { AuditCharacteristicsSamplesTableOptions } from './audit-characteristicsSamples-table-options';
import { AuditVocabulariesJobTypesTableOptions } from './audit-vocabulariesJobTypes-table-options';
import { AuditVocabulariesPhysicalMarkerTypesTableOptions } from './audit-vocabulariesPhysicalMarkerTypes-table-options';
import { AuditVocabulariesPlateStatusesTableOptions } from './audit-vocabulariesPlateStatuses-table-options';
import { AuditAnimalCommentsTableOptions } from './audit-animal-comments-table-options';
import { AuditVocabulariesBirthStatusesTableOptions } from './audit-vocabulariesBirthStatuses-table-options';
import { AuditVocabulariesBodyConditionScoresTableOptions } from './audit-vocabulariesBodyConditionScores-table-options';
import { AuditNameFormatTableOptions } from './audit-nameFormat-table-options';
import { AuditAnimalsTasksTableOptions } from './audit-animalsTasks-table-options';
import { AuditVocabulariesShipmentContainerConditionsTableOptions } from './audit-vocabulariesShipmentContainerConditions-table-options';
import { AuditVocabulariesShipmentAnimalConditionsTableOptions } from './audit-vocabulariesShipmentAnimalConditions-table-options';
import { AuditTaskAnimalHealthRecordsTableOptions } from './audit-task-animal-health-records-table-options';
import { AuditStudyLinesTableOptions } from './audit-study-lines-table-options';
import { AuditVocabulariesClinicalObservationStatusesTableOptions } from './audit-vocabulariesClinicalObservationStatuses-table-options';
import { AuditVocabulariesClinicalObservationsTableOptions } from './audit-vocabulariesClinicalObservation-table-options';
import { AuditVocabulariesDietsTableOptions } from './audit-vocabulariesDiets-table-options';
import { UserNameService } from '../user/user-name.service';
import { AuditVocabulariesGenerationsTableOptions } from './audit-vocabulariesGenerations-table-options';
import { AuditVocabulariesLocationTypesTableOptions } from './audit-vocabulariesLocationTypes-table-options';
import { AuditMaterialPoolTasksTableOptions } from './audit-materialPoolTasks-table-options';
import { AuditFilesTableOptions } from './audit-files-table-options';
import { AuditSettingsTableOptions } from './audit-settings-table-options';
import { AuditFacetSettingsTableOptions } from './audit-facet-settings-table-options';
import { AuditVocabulariesResourceGroupsTableOptions } from './audit-vocabulariesResourceGroups-table-options';
import { AuditVocabulariesResourceTypesTableOptions } from './audit-vocabulariesResourceTypes-table-options';
import { AuditVocabulariesLineTypesTableOptions } from './audit-vocabulariesLineTypes-table-options';
import { AuditVocabulariesIACUCProtocolsTableOptions } from './audit-vocabulariesIACUCProtocols-table-options';
import { AuditVocabulariesExitReasonsTableOptions } from './audit-vocabulariesExitReasons-table-options';
import { AuditVocabulariesGenotypesTableOptions } from './audit-vocabulariesGenotypes-table-options';
import { AuditVocabulariesMaterialPoolStatusesTableOptions } from './audit-vocabulariesMaterialPoolStatuses-table-options';
import { AuditVocabulariesMatingPurposesTableOptions } from './audit-vocabulariesMatingPurposes-table-options';
import { AuditMaterialLocationsTableOptions } from './audit-materialLocations-table-options';
import { AuditVocabulariesMatingStatusesTableOptions } from './audit-vocabulariesMatingStatuses-table-options';
import { AuditVocabulariesPaymentTermsTableOptions } from './audit-vocabulariesPaymentTerms-table-options';
import { AuditMaterialPoolSocialStatusesTableOptions } from './audit-materialPoolSocialStatuses-table-options';
import { AuditVocabulariesMatingTypesTableOptions } from './audit-vocabulariesMatingTypes-table-options';
import { AuditVocabulariesCompatibilityAccessLevelsTableOptions } from './audit-vocabulariesCompatibilityAccessLevels-table-options';
import { AuditObservationsDetailsTableOptions } from './audit-observationDetails-table-options';
import { AuditVocabulariesClinicalObservationBodySystemsTableOptions } from './audit-vocabulariesClinicalObservationBodySystems-table-options';
import { AuditVocabulariesModifiers1TableOptions } from './audit-vocabulariesModifiers1-table-options';
import { AuditVocabulariesModifiers2TableOptions } from './audit-vocabulariesModifiers2-table-options';
import { AuditVocabulariesModifiers3TableOptions } from './audit-vocabulariesModifiers3-table-options';
import { AuditVocabulariesModifiers4TableOptions } from './audit-vocabulariesModifiers4-table-options';
import { AuditVocabulariesSocialGroupAccessLevelsTableOptions } from './audit-vocabulariesSocialGroupAccessLevels-table-options';
import { AuditVocabulariesContainerTypesTableOptions } from './audit-vocabulariesContainerTypes-table-options';
import { AuditTaskAssignmentsTableOptions } from './audit-task-assignment-table-options';
import { AuditVocabulariesMaterialPoolTypesTableOptions } from './audit-vocabulariesMaterialPoolTypes-table-options';
import { AuditVocabulariesContactPersonTypesTableOptions } from './audit-vocabulariesContactPersonTypes-table-options';
import { AuditVocabulariesQuarantineFacilitiesTableOptions } from './audit-vocabulariesQuarantineFacilities-table-options';
import { AuditVocabulariesGenotypeAssaysTableOptions } from './audit-vocabulariesGenotypeAssays-table-options';
import { AuditVocabulariesSiteTypesTableOptions } from './audit-vocabulariesSiteTypes-table-options';
import { AuditVocabulariesUnitsTableOptions } from './audit-vocabulariesUnits-table-options';
import { AuditTaskInputsTableOptions } from './audit-taskInputs-table-options';
import { AuditTaskOutputsTableOptions } from './audit-taskOutputs-table-options';
import { AuditVocabulariesAnimalClassificationsTableOptions } from './audit-vocabulariesAnimalClassifications-table-options';
import { AuditWorkflowInputsTableOptions } from './audit-workflowInputs-table-options';
import { AuditOutputFlagsTableOptions } from './audit-outputFlags-table-options ';
import { AuditProtocolDefaultInputsTableOptions } from './audit-protocol-default-inputs-table-options';

import { AuditJobLocationsTableOptions } from './audit-jobLocation-table-options';
import { Subscription } from 'rxjs';
import { AuditVocabulariesRouteOfAdministrationsTableOptions } from './audit-vocabulariesRouteOfAdministrations-table-options';
import { AuditVocabulariesVeterinaryRequestStatusesTableOptions } from './audit-vocabulariesVeterinaryRequestStatuses-table-options';
import { AuditVocabulariesStandardPhrasesTableOptions } from './audit-vocabulariesStandardPhrases-table-options';
import { AuditGridView } from './models/audit-grid-view.enum';
import { AuditStudyCharacteristicsTableOptions } from './audit-studyCharacteristics-table-options';
import { AuditSampleCharacteristicsTableOptions } from './audit-sampleCharacteristics-table-options';
import { AuditAnimalCharacteristicsTableOptions } from './audit-animalCharacteristics-table-options';
import { AuditJobVariablePhrasesTableOptions } from './audit-job-variable-phrases-table-options';
import { arrowClockwise, magnifier } from '@icons';
import { AuditVocabulariesOrderStatusesTableOptions } from './audit-vocabulariesOrderStatuses-table-options';
import { AuditVocabulariesPermitStatusesTableOptions } from './audit-vocabulariesPermitStatuses-table-options';
import { AuditVocabulariesPermitExternalPurposesTableOptions } from './audit-vocabulariesPermitExternalPurposes-table-options';
import { AuditVocabulariesSeveritiesTableOptions } from './audit-vocabulariesSeverities-table-options';
import { AuditVocabulariesPermitCategoriesTableOptions } from './audit-vocabulariesPermitCategories-table-options';
import { AuditPermitsTableOptions } from './audit-permits-table-options';
import { AuditPermitSpeciesTableOptions } from './audit-permitSpecies-table-options';
import { AuditPermitSpeciesLinesTableOptions } from './audit-permitSpeciesLines-table-options';
import { AuditPermitSpeciesOriginsTableOptions } from './audit-permitSpeciesOrigins-table-options';
import { AuditOrdersInstitutionsTableOptions } from './audit-orders-institutions-table-options';
import { AuditSettingsTaskOutputPoolWorkflowTasksTableOptions } from './audit-settings-task-output-pool-workflow-tasks-table-options';
import { AuditSettingsTaskOutputPoolsTableOptions } from './audit-settings-task-output-pools-table-options';
import { AuditSettingsTaskOutputPoolOutputsTableOptions } from './audit-settings-task-output-pool-outputs-table-options';

const TODAY = new Date();
const SIX_MONTHS_AGO = new Date(TODAY).setMonth(TODAY.getMonth() - 6);

export interface IAuditDropdownOption {
    viewName: AuditGridView;
    viewDisplayName: string;
    isOptionVisible: boolean;
}

@Component({
    selector: 'audit-facet',
    templateUrl: './audit-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS,
    styles: [`
        .audit-chooser-wrapper {
            display: flex;
            flex-direction: column;
            margin: 16px auto;
            padding: 10px 16px;
            width: fit-content;
            border: 1px solid #ccc;
            border-radius: 3px;
        }
    `]
})
export class AuditFacetComponent extends BaseFacet
    implements OnInit, OnDestroy {
    @Input() facetId: string;
    @Input() facet: any;

    readonly icons = { arrowClockwise, magnifier };

    auditLinkSub: Subscription;

    componentName = 'audit';
    defaultOption = true;

    auditAnimalsTableOptions: AuditAnimalsTableOptions;
    auditAnimalCommentsTableOptions: AuditAnimalCommentsTableOptions;
    auditAnimalsTasksTableOptions: AuditAnimalsTasksTableOptions;
    auditSamplesTableOptions: AuditSamplesTableOptions;
    auditJobsTableOptions: AuditJobsTableOptions;
    auditJobAnimalsTableOptions: AuditJobAnimalsTableOptions;
    auditJobSamplesTableOptions: AuditJobSamplesTableOptions;
    auditJobSampleGroupsTableOptions: AuditJobSampleGroupsTableOptions;
    auditJobTasksTableOptions: AuditJobTasksTableOptions;
    auditJobLinesTableOptions: AuditJobLinesTableOptions;
    auditJobLineDetailsTableOptions: AuditJobLineDetailsTableOptions;
    auditJobStandardPhrasesTableOptions: AuditJobStandardPhrasesTableOptions;
    auditJobGroupsTableOptions: AuditJobGroupsTableOptions;
    auditJobTestArticlesTableOptions: AuditJobTestArticlesTableOptions;
    auditJobCharacteristicsTableOptions: AuditJobCharacteristicsTableOptions;
    auditJobInstitutionsTableOptions: AuditJobInstitutionsTableOptions;
    auditJobOrdersTableOptions: AuditJobOrdersTableOptions;
    auditJobIndividualPlaceholdersTableOptions: AuditJobIndividualPlaceholdersTableOptions;
    auditJobGroupPlaceholdersTableOptions: AuditJobGroupPlaceholdersTableOptions;
    auditWorkflowOutputsTableOptions: AuditWorkflowOutputsTableOptions;
    auditWorkflowInputsTableOptions: AuditWorkflowInputsTableOptions;
    auditMaterialPoolsTableOptions: AuditMaterialPoolsTableOptions;
    auditMaterialPoolMaterialsTableOptions: AuditMaterialPoolMaterialsTableOptions;
    auditMaterialPoolTasksTableOptions: AuditMaterialPoolTasksTableOptions;
    auditMatingsTableOptions: AuditMatingsTableOptions;
    auditMatingMaterialsTableOptions: AuditMatingMaterialsTableOptions;
    auditCohortsTableOptions: AuditCohortsTableOptions;
    auditCohortAnimalsTableOptions: AuditCohortAnimalsTableOptions;
    auditLinesTableOptions: AuditLinesTableOptions;
    auditStudiesTableOptions: AuditStudiesTableOptions;
    auditStudyLinesTableOptions: AuditStudyLinesTableOptions;
    auditBirthsTableOptions: AuditBirthsTableOptions;
    auditLocationsTableOptions: AuditLocationsTableOptions;
    auditAnimalClinicalObservationsTableOptions: AuditAnimalClinicalObservationsTableOptions;
    auditAnimalHealthRecordsTableOptions: AuditAnimalHealthRecordsTableOptions;
    auditClinicalObservationDetailsTableOptions: AuditClinicalObservationDetailsTableOptions;
    auditWorkflowTasksTableOptions: AuditWorkflowTasksTableOptions;
    auditWorkflowTaskDetailsTableOptions: AuditWorkflowTaskDetailsTableOptions;
    auditProtocolsTableOptions: AuditProtocolsTableOptions;
    auditProtocolDefaultInputsTableOptions: AuditProtocolDefaultInputsTableOptions;
    auditProtocolsTasksTableOptions: AuditProtocolsTasksTableOptions;
    auditProtocolsSamplesGroupsTableOptions: AuditProtocolsSamplesGroupsTableOptions;
    auditResourcesTableOptions: AuditResourcesTableOptions;
    auditStudyAdministratorStudiesTableOptions: AuditStudyAdministratorStudiesTableOptions;
    auditUsersTableOptions: AuditUsersTableOptions;
    auditUserDetailsTableOptions: AuditUserDetailsTableOptions;
    auditSettingsTableOptions: AuditSettingsTableOptions;
    auditSettingsTaskOutputPoolWorkflowTasksTableOptions: AuditSettingsTaskOutputPoolWorkflowTasksTableOptions
    auditSettingsTaskOutputPoolsTableOptions: AuditSettingsTaskOutputPoolsTableOptions
    auditSettingsTaskOutputPoolOutputsTableOptions: AuditSettingsTaskOutputPoolOutputsTableOptions
    auditRolesTableOptions: AuditRolesTableOptions;
    auditRoleFacetsTableOptions: AuditRoleFacetsTableOptions;
    auditTaskInputsTableOptions: AuditTaskInputsTableOptions;
    auditTaskOutputsTableOptions: AuditTaskOutputsTableOptions;
    auditVocabulariesTaskFlagMessagesTableOptions: AuditVocabulariesTaskFlagMessagesTableOptions;
    auditVocabulariesTaskStatusesTableOptions: AuditVocabulariesTaskStatusesTableOptions;
    auditVocabulariesAnimalClassificationsTableOptions: AuditVocabulariesAnimalClassificationsTableOptions;
    auditVocabulariesAnimalCommentStatusesTableOptions: AuditVocabulariesAnimalCommentStatusesTableOptions;
    auditVocabulariesAnimalStatusesTableOptions: AuditVocabulariesAnimalStatusesTableOptions;
    auditVocabulariesTestArticlesTableOptions: AuditVocabulariesTestArticlesTableOptions;
    auditVocabulariesJobSubtypesTableOptions: AuditVocabulariesJobSubtypesTableOptions;
    auditEnumerationItemsTableOptions: AuditEnumerationItemsTableOptions;
    auditVocabulariesTaskOutcomesTableOptions: AuditVocabulariesTaskOutcomesTableOptions;
    auditCharacteristicsAnimalsTableOptions: AuditCharacteristicsAnimalsTableOptions;
    auditCharacteristicsJobsTableOptions: AuditCharacteristicsJobsTableOptions;
    auditCharacteristicsStudiesTableOptions: AuditCharacteristicsStudiesTableOptions;
    auditVocabulariesCouriersTableOptions: AuditVocabulariesCouriersTableOptions;
    auditVocabulariesJobStatusesTableOptions: AuditVocabulariesJobStatusesTableOptions;
    auditVocabulariesAnimalUsesTableOptions: AuditVocabulariesAnimalUsesTableOptions;
    auditVocabulariesSampleAnalysesTableOptions: AuditVocabulariesSampleAnalysesTableOptions;
    auditVocabulariesSampleConditionsTableOptions: AuditVocabulariesSampleConditionsTableOptions;
    auditCharacteristicsSamplesTableOptions: AuditCharacteristicsSamplesTableOptions;
    auditVocabulariesJobTypesTableOptions: AuditVocabulariesJobTypesTableOptions;
    auditVocabulariesPhysicalMarkerTypesTableOptions: AuditVocabulariesPhysicalMarkerTypesTableOptions;
    auditVocabulariesPlateStatusesTableOptions: AuditVocabulariesPlateStatusesTableOptions;
    auditVocabulariesBirthStatusesTableOptions: AuditVocabulariesBirthStatusesTableOptions;
    auditVocabulariesBodyConditionScoresTableOptions: AuditVocabulariesBodyConditionScoresTableOptions;
    auditVocabulariesShipmentAnimalConditionsTableOptions: AuditVocabulariesShipmentAnimalConditionsTableOptions;
    auditVocabulariesLocationTypesTableOptions: AuditVocabulariesLocationTypesTableOptions;
    auditVocabulariesResourceGroupsTableOptions: AuditVocabulariesResourceGroupsTableOptions;
    auditVocabulariesResourceTypesTableOptions: AuditVocabulariesResourceTypesTableOptions;
    auditVocabulariesIACUCProtocolsTableOptions: AuditVocabulariesIACUCProtocolsTableOptions;
    auditVocabulariesExitReasonsTableOptions: AuditVocabulariesExitReasonsTableOptions;
    auditVocabulariesGenotypesTableOptions: AuditVocabulariesGenotypesTableOptions;
    auditVocabulariesMatingPurposesTableOptions: AuditVocabulariesMatingPurposesTableOptions;
    auditVocabulariesMatingStatusesTableOptions: AuditVocabulariesMatingStatusesTableOptions;
    auditVocabulariesPaymentTermsTableOptions: AuditVocabulariesPaymentTermsTableOptions;
    auditVocabulariesMatingTypesTableOptions: AuditVocabulariesMatingTypesTableOptions;
    auditVocabulariesCompatibilityAccessLevelsTableOptions: AuditVocabulariesCompatibilityAccessLevelsTableOptions;
    auditOrdersTableOptions: AuditOrdersTableOptions;
    auditVocabulariesLineStatusesTableOptions: AuditVocabulariesLineStatusesTableOptions;
    auditOrdersAnimalsTableOptions: AuditOrdersAnimalsTableOptions;
    auditOrdersInstitutionsTableOptions: AuditOrdersInstitutionsTableOptions;
    auditOrdersLocationsTableOptions: AuditOrdersLocationsTableOptions;
    auditEnumerationClassesTableOptions: AuditEnumerationClassesTableOptions;
    auditVocabulariesMaterialOriginsTableOptions: AuditVocabulariesMaterialOriginsTableOptions;
    auditVocabulariesBreedingStatusesTableOptions: AuditVocabulariesBreedingStatusesTableOptions;
    auditNameFormatTableOptions: AuditNameFormatTableOptions;
    auditTaskAnimalHealthRecordsTableOptions: AuditTaskAnimalHealthRecordsTableOptions;
    auditVocabulariesClinicalObservationStatusesTableOptions: AuditVocabulariesClinicalObservationStatusesTableOptions;
    auditVocabulariesClinicalObservationsTableOptions: AuditVocabulariesClinicalObservationsTableOptions;
    auditVocabulariesClinicalObservationBodySystemsTableOptions: AuditVocabulariesClinicalObservationBodySystemsTableOptions;
    auditVocabulariesDietsTableOptions: AuditVocabulariesDietsTableOptions;
    auditVocabulariesGenerationsTableOptions: AuditVocabulariesGenerationsTableOptions;
    auditFilesTableOptions: AuditFilesTableOptions;
    auditFacetSettingsTableOptions: AuditFacetSettingsTableOptions;
    auditVocabulariesCompliancesTableOptions: AuditVocabulariesCompliancesTableOptions;
    auditVocabulariesInstitutionTypesTableOptions: AuditVocabulariesInstitutionTypesTableOptions;
    auditVocabulariesLineTypesTableOptions: AuditVocabulariesLineTypesTableOptions;
    auditVocabulariesMaterialPoolStatusesTableOptions: AuditVocabulariesMaterialPoolStatusesTableOptions;
    auditMaterialLocationsTableOptions: AuditMaterialLocationsTableOptions;
    auditMaterialPoolSocialStatusesTableOptions: AuditMaterialPoolSocialStatusesTableOptions;
    auditObservationDetailsTableOptions: AuditObservationsDetailsTableOptions;
    auditVocabulariesModifiers1TableOptions: AuditVocabulariesModifiers1TableOptions;
    auditVocabulariesModifiers2TableOptions: AuditVocabulariesModifiers2TableOptions;
    auditVocabulariesModifiers3TableOptions: AuditVocabulariesModifiers3TableOptions;
    auditVocabulariesModifiers4TableOptions: AuditVocabulariesModifiers4TableOptions;
    auditVocabulariesOrderStatusesTableOptions: AuditVocabulariesOrderStatusesTableOptions;
    auditVocabulariesSocialGroupAccessLevelsTableOptions: AuditVocabulariesSocialGroupAccessLevelsTableOptions;
    auditVocabulariesContainerTypesTableOptions: AuditVocabulariesContainerTypesTableOptions;
    auditVocabulariesJobReportsTableOptions: AuditVocabulariesJobReportsTableOptions;
    auditTaskAssignmentsTableOptions: AuditTaskAssignmentsTableOptions;
    auditOutputFlagsTableOptions: AuditOutputFlagsTableOptions;
    auditVocabulariesMaterialPoolTypesTableOptions: AuditVocabulariesMaterialPoolTypesTableOptions;
    auditVocabulariesContactPersonTypesTableOptions: AuditVocabulariesContactPersonTypesTableOptions;
    auditVocabulariesQuarantineFacilitiesTableOptions: AuditVocabulariesQuarantineFacilitiesTableOptions;
    auditVocabulariesGenotypeAssaysTableOptions: AuditVocabulariesGenotypeAssaysTableOptions;
    auditVocabulariesSiteTypesTableOptions: AuditVocabulariesSiteTypesTableOptions;
    auditVocabulariesUnitsTableOptions: AuditVocabulariesUnitsTableOptions;
    auditVocabulariesRouteOfAdministrationsTableOptions: AuditVocabulariesRouteOfAdministrationsTableOptions;
    auditVocabulariesVeterinaryRequestStatusesTableOptions: AuditVocabulariesVeterinaryRequestStatusesTableOptions;
    auditJobLocationsTableOptions: AuditJobLocationsTableOptions;
    auditVocabulariesStandardPhrasesTableOptions: AuditVocabulariesStandardPhrasesTableOptions;
    auditStudyCharacteristicsTableOptions: AuditStudyCharacteristicsTableOptions;
    auditSampleCharacteristicsTableOptions: AuditSampleCharacteristicsTableOptions;
    auditAnimalCharacteristicsTableOptions: AuditAnimalCharacteristicsTableOptions;
    auditVocabulariesShipmentContainerConditionsTableOptions: AuditVocabulariesShipmentContainerConditionsTableOptions;
    auditJobVariablePhrasesTableOptions: AuditJobVariablePhrasesTableOptions;
    auditVocabulariesPermitStatusesTableOptions: AuditVocabulariesPermitStatusesTableOptions;
    auditVocabulariesPermitExternalPurposesTableOptions: AuditVocabulariesPermitExternalPurposesTableOptions;
    auditVocabulariesSeveritiesTableOptions: AuditVocabulariesSeveritiesTableOptions;
    auditVocabulariesPermitCategoriesTableOptions: AuditVocabulariesPermitCategoriesTableOptions;
    auditPermitsTableOptions: AuditPermitsTableOptions;
    auditPermitInstitutionsTableOptions: AuditPermitInstitutionsTableOptions;
    auditPermitSpeciesTableOptions: AuditPermitSpeciesTableOptions;
    auditPermitSpeciesLinesTableOptions: AuditPermitSpeciesLinesTableOptions;
    auditPermitSpeciesOriginsTableOptions: AuditPermitSpeciesOriginsTableOptions;

    gridView: AuditGridView;
    auditGridViews = AuditGridView;

    isCRO = false;
    isCRL = false;
    isGLP = false;
    largeAnimalEnabled: boolean;

    private filterApplied = false;
    private filterVisibility: any;


    options: IAuditDropdownOption[];

    readonly COMPONENT_LOG_TAG = 'audit-facet';

    constructor(
        private auditService: AuditService,
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
        private modalService: NgbModal,
        private baseFacetService: BaseFacetService,
        private featureFlagService: FeatureFlagService,
        private userNameService: UserNameService,
        workspaceFilterService: WorkspaceFilterService,
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );
        this.initIsCRO();
        this.initIsCRL();
        this.initIsGLP();
        this.initLargeAnimalEnabled();

        this.auditAnimalsTableOptions = new AuditAnimalsTableOptions(
            this.cellFormatterService,
            this.translationService,
            this.featureFlagService,
        );
        this.auditAnimalCommentsTableOptions = new AuditAnimalCommentsTableOptions(
            this.cellFormatterService
        );
        this.auditAnimalsTasksTableOptions = new AuditAnimalsTasksTableOptions(
            this.cellFormatterService
        );
        this.auditSamplesTableOptions = new AuditSamplesTableOptions(
            this.cellFormatterService,
            this.translationService,
        );
        this.auditJobsTableOptions = new AuditJobsTableOptions(
            this.cellFormatterService,
            this.translationService,
            this.isCRO,
            this.isCRL,
            this.isGLP
        );
        this.auditJobAnimalsTableOptions = new AuditJobAnimalsTableOptions(
            this.cellFormatterService,
            this.translationService,
            this.isGLP
        );
        this.auditJobSamplesTableOptions = new AuditJobSamplesTableOptions(
            this.cellFormatterService,
            this.translationService,
        );
        this.auditJobSampleGroupsTableOptions = new AuditJobSampleGroupsTableOptions(
            this.cellFormatterService,
            this.translationService,
        );
        this.auditJobTasksTableOptions = new AuditJobTasksTableOptions(
            this.cellFormatterService,
            this.translationService,
        );
        this.auditJobLinesTableOptions = new AuditJobLinesTableOptions(
            this.cellFormatterService,
            this.translationService,
        );
        this.auditJobLineDetailsTableOptions = new AuditJobLineDetailsTableOptions(
            this.cellFormatterService,
            this.translationService,
        );
        this.auditJobStandardPhrasesTableOptions = new AuditJobStandardPhrasesTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditJobGroupsTableOptions = new AuditJobGroupsTableOptions(
            this.cellFormatterService,
            this.translationService,
            this.isCRL,
        );
        this.auditJobTestArticlesTableOptions = new AuditJobTestArticlesTableOptions(
            this.cellFormatterService,
            this.translationService,
            this.isCRO,
        );
        this.auditVocabulariesTestArticlesTableOptions = new AuditVocabulariesTestArticlesTableOptions(
            this.cellFormatterService
        );
        this.auditJobCharacteristicsTableOptions = new AuditJobCharacteristicsTableOptions(
            this.cellFormatterService,
            this.translationService,
        );
        this.auditJobInstitutionsTableOptions = new AuditJobInstitutionsTableOptions(
            this.cellFormatterService,
            this.translationService,
            this.isCRO,
            this.isCRL,
        );
        this.auditJobOrdersTableOptions = new AuditJobOrdersTableOptions(
            this.cellFormatterService,
            this.translationService,
        );
        this.auditJobIndividualPlaceholdersTableOptions = new AuditJobIndividualPlaceholdersTableOptions(
            this.cellFormatterService,
            this.translationService,
        );
        this.auditJobGroupPlaceholdersTableOptions = new AuditJobGroupPlaceholdersTableOptions(
            this.cellFormatterService,
            this.translationService,
        );
        this.auditWorkflowInputsTableOptions = new AuditWorkflowInputsTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditWorkflowOutputsTableOptions = new AuditWorkflowOutputsTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditMaterialPoolsTableOptions = new AuditMaterialPoolsTableOptions(
            this.cellFormatterService
        );
        this.auditMaterialPoolMaterialsTableOptions = new AuditMaterialPoolMaterialsTableOptions(
            this.cellFormatterService
        );
        this.auditMaterialPoolTasksTableOptions = new AuditMaterialPoolTasksTableOptions(
            this.cellFormatterService
        );
        this.auditMatingsTableOptions = new AuditMatingsTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditMatingMaterialsTableOptions = new AuditMatingMaterialsTableOptions(
            this.cellFormatterService
        );
        this.auditCohortsTableOptions = new AuditCohortsTableOptions(
            this.cellFormatterService,
        );
        this.auditCohortAnimalsTableOptions = new AuditCohortAnimalsTableOptions(
            this.cellFormatterService
        );
        this.auditLinesTableOptions = new AuditLinesTableOptions(
            this.cellFormatterService,
            this.translationService,
        );
        this.auditStudiesTableOptions = new AuditStudiesTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditStudyLinesTableOptions = new AuditStudyLinesTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditBirthsTableOptions = new AuditBirthsTableOptions(
            this.cellFormatterService
        );
        this.auditLocationsTableOptions = new AuditLocationsTableOptions(
            this.cellFormatterService
        );
        this.auditAnimalClinicalObservationsTableOptions = new AuditAnimalClinicalObservationsTableOptions(
            this.cellFormatterService
        );
        this.auditAnimalHealthRecordsTableOptions = new AuditAnimalHealthRecordsTableOptions(
            this.cellFormatterService
        );
        this.auditClinicalObservationDetailsTableOptions = new AuditClinicalObservationDetailsTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditWorkflowTasksTableOptions = new AuditWorkflowTasksTableOptions(
            this.cellFormatterService
        );
        this.auditWorkflowTaskDetailsTableOptions = new AuditWorkflowTaskDetailsTableOptions(
            this.cellFormatterService
        );
        this.auditProtocolsTableOptions = new AuditProtocolsTableOptions(
            this.cellFormatterService
        );
        this.auditProtocolDefaultInputsTableOptions = new AuditProtocolDefaultInputsTableOptions(
            this.cellFormatterService
        );
        this.auditProtocolsTasksTableOptions = new AuditProtocolsTasksTableOptions(
            this.cellFormatterService
        );
        this.auditProtocolsSamplesGroupsTableOptions = new AuditProtocolsSamplesGroupsTableOptions(
            this.cellFormatterService
        );
        this.auditResourcesTableOptions = new AuditResourcesTableOptions(
            this.cellFormatterService
        );
        this.auditStudyAdministratorStudiesTableOptions = new AuditStudyAdministratorStudiesTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditUsersTableOptions = new AuditUsersTableOptions(
            this.cellFormatterService,
        );
        this.auditUserDetailsTableOptions = new AuditUserDetailsTableOptions(
            this.cellFormatterService,
            this.translationService,
            this.isGLP
        );
        this.auditSettingsTableOptions = new AuditSettingsTableOptions(
            this.cellFormatterService,
        );
        this.auditSettingsTaskOutputPoolWorkflowTasksTableOptions = new AuditSettingsTaskOutputPoolWorkflowTasksTableOptions(
            this.cellFormatterService,
        );

        this.auditSettingsTaskOutputPoolOutputsTableOptions = new AuditSettingsTaskOutputPoolOutputsTableOptions(
            this.cellFormatterService,
        );

        this.auditSettingsTaskOutputPoolsTableOptions = new AuditSettingsTaskOutputPoolsTableOptions(
          this.cellFormatterService,
        );

        this.auditRolesTableOptions = new AuditRolesTableOptions(
            this.cellFormatterService
        );

        this.auditVocabulariesTaskFlagMessagesTableOptions = new AuditVocabulariesTaskFlagMessagesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesAnimalClassificationsTableOptions = new AuditVocabulariesAnimalClassificationsTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesAnimalCommentStatusesTableOptions = new AuditVocabulariesAnimalCommentStatusesTableOptions(
            this.cellFormatterService
        );
        this.auditCharacteristicsAnimalsTableOptions = new AuditCharacteristicsAnimalsTableOptions(
            this.cellFormatterService
        );
        this.auditCharacteristicsJobsTableOptions = new AuditCharacteristicsJobsTableOptions(
            this.cellFormatterService,
            this.translationService,
            this.isCRO,
            this.isCRL
        );
        this.auditVocabulariesAnimalStatusesTableOptions = new AuditVocabulariesAnimalStatusesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesJobSubtypesTableOptions = new AuditVocabulariesJobSubtypesTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditCharacteristicsAnimalsTableOptions = new AuditCharacteristicsAnimalsTableOptions(
            this.cellFormatterService
        );
        this.auditCharacteristicsStudiesTableOptions = new AuditCharacteristicsStudiesTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditOrdersAnimalsTableOptions = new AuditOrdersAnimalsTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesCouriersTableOptions = new AuditVocabulariesCouriersTableOptions(
            this.cellFormatterService
        );
        this.auditOrdersTableOptions = new AuditOrdersTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditOrdersAnimalsTableOptions = new AuditOrdersAnimalsTableOptions(
            this.cellFormatterService
        );
        this.auditOrdersInstitutionsTableOptions = new AuditOrdersInstitutionsTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditOrdersLocationsTableOptions = new AuditOrdersLocationsTableOptions(
            this.cellFormatterService
        );
        this.auditFilesTableOptions = new AuditFilesTableOptions(
            this.cellFormatterService,
            this.translationService
        );

        this.auditCharacteristicsAnimalsTableOptions = new AuditCharacteristicsAnimalsTableOptions(
            this.cellFormatterService
        );

        this.auditCharacteristicsSamplesTableOptions = new AuditCharacteristicsSamplesTableOptions(
            this.cellFormatterService
        );

        this.auditVocabulariesPhysicalMarkerTypesTableOptions = new AuditVocabulariesPhysicalMarkerTypesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesAnimalUsesTableOptions = new AuditVocabulariesAnimalUsesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesSampleAnalysesTableOptions = new AuditVocabulariesSampleAnalysesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesSampleConditionsTableOptions = new AuditVocabulariesSampleConditionsTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesPlateStatusesTableOptions = new AuditVocabulariesPlateStatusesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesBirthStatusesTableOptions = new AuditVocabulariesBirthStatusesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesBodyConditionScoresTableOptions = new AuditVocabulariesBodyConditionScoresTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesShipmentAnimalConditionsTableOptions = new AuditVocabulariesShipmentAnimalConditionsTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesMaterialPoolStatusesTableOptions = new AuditVocabulariesMaterialPoolStatusesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesMatingPurposesTableOptions = new AuditVocabulariesMatingPurposesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesLocationTypesTableOptions = new AuditVocabulariesLocationTypesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesResourceGroupsTableOptions = new AuditVocabulariesResourceGroupsTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesLineStatusesTableOptions = new AuditVocabulariesLineStatusesTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditVocabulariesResourceTypesTableOptions = new AuditVocabulariesResourceTypesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesLineTypesTableOptions = new AuditVocabulariesLineTypesTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditVocabulariesInstitutionTypesTableOptions = new AuditVocabulariesInstitutionTypesTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditVocabulariesIACUCProtocolsTableOptions = new AuditVocabulariesIACUCProtocolsTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesExitReasonsTableOptions = new AuditVocabulariesExitReasonsTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesGenotypesTableOptions = new AuditVocabulariesGenotypesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesMatingStatusesTableOptions = new AuditVocabulariesMatingStatusesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesMatingTypesTableOptions = new AuditVocabulariesMatingTypesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesPaymentTermsTableOptions = new AuditVocabulariesPaymentTermsTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesCompatibilityAccessLevelsTableOptions = new AuditVocabulariesCompatibilityAccessLevelsTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesPaymentTermsTableOptions = new AuditVocabulariesPaymentTermsTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesJobTypesTableOptions = new AuditVocabulariesJobTypesTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditTaskInputsTableOptions = new AuditTaskInputsTableOptions(
            this.cellFormatterService,
            this.translationService,
            this.isCRO
        );
        this.auditTaskOutputsTableOptions = new AuditTaskOutputsTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditOrdersTableOptions = new AuditOrdersTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditVocabulariesJobStatusesTableOptions = new AuditVocabulariesJobStatusesTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditVocabulariesMaterialOriginsTableOptions = new AuditVocabulariesMaterialOriginsTableOptions(
            this.cellFormatterService
        );
        this.auditRoleFacetsTableOptions = new AuditRoleFacetsTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesContainerTypesTableOptions = new AuditVocabulariesContainerTypesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesCompliancesTableOptions = new AuditVocabulariesCompliancesTableOptions(
            this.cellFormatterService
        );
        this.auditEnumerationClassesTableOptions = new AuditEnumerationClassesTableOptions(
            this.cellFormatterService
        );
        this.auditEnumerationItemsTableOptions = new AuditEnumerationItemsTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesBreedingStatusesTableOptions = new AuditVocabulariesBreedingStatusesTableOptions(
            this.cellFormatterService
        );
        this.auditNameFormatTableOptions = new AuditNameFormatTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditTaskAnimalHealthRecordsTableOptions = new AuditTaskAnimalHealthRecordsTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesClinicalObservationStatusesTableOptions = new AuditVocabulariesClinicalObservationStatusesTableOptions(
            this.cellFormatterService,
            this.largeAnimalEnabled
        );
        this.auditVocabulariesClinicalObservationsTableOptions = new AuditVocabulariesClinicalObservationsTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditVocabulariesClinicalObservationBodySystemsTableOptions = new AuditVocabulariesClinicalObservationBodySystemsTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesDietsTableOptions = new AuditVocabulariesDietsTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesGenerationsTableOptions = new AuditVocabulariesGenerationsTableOptions(
            this.cellFormatterService
        );
        this.auditFacetSettingsTableOptions = new AuditFacetSettingsTableOptions(
            this.cellFormatterService,
        );
        this.auditMaterialLocationsTableOptions = new AuditMaterialLocationsTableOptions(
            this.cellFormatterService
        );
        this.auditMaterialPoolSocialStatusesTableOptions = new AuditMaterialPoolSocialStatusesTableOptions(
            this.cellFormatterService
        );
        this.auditObservationDetailsTableOptions = new AuditObservationsDetailsTableOptions(
            this.cellFormatterService,
            this.isGLP
        );
        this.auditVocabulariesModifiers1TableOptions = new AuditVocabulariesModifiers1TableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesModifiers2TableOptions = new AuditVocabulariesModifiers2TableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesModifiers3TableOptions = new AuditVocabulariesModifiers3TableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesModifiers4TableOptions = new AuditVocabulariesModifiers4TableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesOrderStatusesTableOptions = new AuditVocabulariesOrderStatusesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesSocialGroupAccessLevelsTableOptions = new AuditVocabulariesSocialGroupAccessLevelsTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesQuarantineFacilitiesTableOptions = new AuditVocabulariesQuarantineFacilitiesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesJobReportsTableOptions = new AuditVocabulariesJobReportsTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditTaskAssignmentsTableOptions = new AuditTaskAssignmentsTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditOutputFlagsTableOptions = new AuditOutputFlagsTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesMaterialPoolTypesTableOptions = new AuditVocabulariesMaterialPoolTypesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesContactPersonTypesTableOptions = new AuditVocabulariesContactPersonTypesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesGenotypeAssaysTableOptions = new AuditVocabulariesGenotypeAssaysTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesSiteTypesTableOptions = new AuditVocabulariesSiteTypesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesJobTypesTableOptions = new AuditVocabulariesJobTypesTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditVocabulariesTaskStatusesTableOptions = new AuditVocabulariesTaskStatusesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesTaskOutcomesTableOptions = new AuditVocabulariesTaskOutcomesTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesUnitsTableOptions = new AuditVocabulariesUnitsTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesRouteOfAdministrationsTableOptions = new AuditVocabulariesRouteOfAdministrationsTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesVeterinaryRequestStatusesTableOptions = new AuditVocabulariesVeterinaryRequestStatusesTableOptions(
            this.cellFormatterService
        );
        this.auditJobLocationsTableOptions = new AuditJobLocationsTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditVocabulariesStandardPhrasesTableOptions = new AuditVocabulariesStandardPhrasesTableOptions(
            this.cellFormatterService
        );
        this.auditStudyCharacteristicsTableOptions = new AuditStudyCharacteristicsTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditSampleCharacteristicsTableOptions = new AuditSampleCharacteristicsTableOptions(
            this.cellFormatterService
        );
        this.auditAnimalCharacteristicsTableOptions = new AuditAnimalCharacteristicsTableOptions(
            this.cellFormatterService
        );
        this.auditVocabulariesShipmentContainerConditionsTableOptions = new AuditVocabulariesShipmentContainerConditionsTableOptions(
            this.cellFormatterService
        );
        this.auditJobVariablePhrasesTableOptions = new AuditJobVariablePhrasesTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditVocabulariesPermitStatusesTableOptions = new AuditVocabulariesPermitStatusesTableOptions(
            this.cellFormatterService,
        );
        this.auditVocabulariesPermitExternalPurposesTableOptions = new AuditVocabulariesPermitExternalPurposesTableOptions(
            this.cellFormatterService,
        );
        this.auditVocabulariesSeveritiesTableOptions = new AuditVocabulariesSeveritiesTableOptions(
            this.cellFormatterService,
        );
        this.auditVocabulariesPermitCategoriesTableOptions = new AuditVocabulariesPermitCategoriesTableOptions(
            this.cellFormatterService,
        );
        this.auditPermitsTableOptions = new AuditPermitsTableOptions(
            this.cellFormatterService
        );
        this.auditPermitInstitutionsTableOptions = new AuditPermitInstitutionsTableOptions(
            this.cellFormatterService,
            this.translationService
        );
        this.auditPermitSpeciesTableOptions = new AuditPermitSpeciesTableOptions(
            this.cellFormatterService,
        );
        this.auditPermitSpeciesLinesTableOptions = new AuditPermitSpeciesLinesTableOptions(
            this.cellFormatterService,
            this.translationService,
        );
        this.auditPermitSpeciesOriginsTableOptions = new AuditPermitSpeciesOriginsTableOptions(
            this.cellFormatterService,
        );

        const glpTableOptions: any[] = [
            this.auditAnimalsTableOptions,
            this.auditAnimalsTasksTableOptions,
            this.auditSamplesTableOptions,
            this.auditJobsTableOptions,
            this.auditJobAnimalsTableOptions,
            this.auditJobSamplesTableOptions,
            this.auditJobTasksTableOptions,
            this.auditJobLinesTableOptions,
            this.auditJobLineDetailsTableOptions,
            this.auditJobStandardPhrasesTableOptions,
            this.auditJobGroupsTableOptions,
            this.auditJobTestArticlesTableOptions,
            this.auditVocabulariesTestArticlesTableOptions,
            this.auditJobCharacteristicsTableOptions,
            this.auditJobInstitutionsTableOptions,
            this.auditJobIndividualPlaceholdersTableOptions,
            this.auditJobGroupPlaceholdersTableOptions,
            this.auditWorkflowInputsTableOptions,
            this.auditWorkflowOutputsTableOptions,
            this.auditMaterialPoolsTableOptions,
            this.auditMaterialPoolMaterialsTableOptions,
            this.auditMatingsTableOptions,
            this.auditMatingMaterialsTableOptions,
            this.auditNameFormatTableOptions,
            this.auditCohortsTableOptions,
            this.auditCohortAnimalsTableOptions,
            this.auditLinesTableOptions,
            this.auditStudiesTableOptions,
            this.auditBirthsTableOptions,
            this.auditLocationsTableOptions,
            this.auditAnimalHealthRecordsTableOptions,
            this.auditWorkflowTaskDetailsTableOptions,
            this.auditProtocolsTableOptions,
            this.auditProtocolDefaultInputsTableOptions,
            this.auditProtocolsTasksTableOptions,
            this.auditProtocolsSamplesGroupsTableOptions,
            this.auditResourcesTableOptions,
            this.auditRoleFacetsTableOptions,
            this.auditStudyAdministratorStudiesTableOptions,
            this.auditRolesTableOptions,
            this.auditVocabulariesTaskFlagMessagesTableOptions,
            this.auditVocabulariesAnimalStatusesTableOptions,
            this.auditVocabulariesPhysicalMarkerTypesTableOptions,
            this.auditVocabulariesSampleConditionsTableOptions,
            this.auditVocabulariesPlateStatusesTableOptions,
            this.auditVocabulariesPaymentTermsTableOptions,
            this.auditOrdersTableOptions,
            this.auditOrdersAnimalsTableOptions,
            this.auditOrdersInstitutionsTableOptions,
            this.auditOrdersLocationsTableOptions,
            this.auditVocabulariesMaterialOriginsTableOptions,
            this.auditEnumerationClassesTableOptions,
            this.auditEnumerationItemsTableOptions,
            this.auditSettingsTableOptions,
            this.auditSettingsTaskOutputPoolWorkflowTasksTableOptions,
            this.auditSettingsTaskOutputPoolOutputsTableOptions,
            this.auditSettingsTaskOutputPoolsTableOptions,
            this.auditFacetSettingsTableOptions,
            this.auditMaterialLocationsTableOptions,
            this.auditFilesTableOptions,
            this.auditVocabulariesMatingTypesTableOptions,
            this.auditVocabulariesCompatibilityAccessLevelsTableOptions,
            this.auditObservationDetailsTableOptions,
            this.auditVocabulariesClinicalObservationBodySystemsTableOptions,
            this.auditVocabulariesModifiers1TableOptions,
            this.auditVocabulariesModifiers2TableOptions,
            this.auditVocabulariesModifiers3TableOptions,
            this.auditVocabulariesModifiers4TableOptions,
            this.auditVocabulariesOrderStatusesTableOptions,
            this.auditJobLocationsTableOptions,
            this.auditJobOrdersTableOptions,
            this.auditVocabulariesSocialGroupAccessLevelsTableOptions,
            this.auditVocabulariesContainerTypesTableOptions,
            this.auditVocabulariesJobReportsTableOptions,
            this.auditTaskAssignmentsTableOptions,
            this.auditVocabulariesMaterialPoolTypesTableOptions,
            this.auditVocabulariesContactPersonTypesTableOptions,
            this.auditMaterialPoolSocialStatusesTableOptions,
            this.auditVocabulariesJobTypesTableOptions,
            this.auditAnimalCommentsTableOptions,
            this.auditMaterialPoolTasksTableOptions,
            this.auditVocabulariesQuarantineFacilitiesTableOptions,
            this.auditVocabulariesGenotypeAssaysTableOptions,
            this.auditCharacteristicsAnimalsTableOptions,
            this.auditCharacteristicsJobsTableOptions,
            this.auditCharacteristicsSamplesTableOptions,
            this.auditCharacteristicsStudiesTableOptions,
            this.auditVocabulariesSiteTypesTableOptions,
            this.auditVocabulariesJobStatusesTableOptions,
            this.auditVocabulariesTaskStatusesTableOptions,
            this.auditVocabulariesTaskOutcomesTableOptions,
            this.auditVocabulariesUnitsTableOptions,
            this.auditVocabulariesAnimalClassificationsTableOptions,
            this.auditVocabulariesJobSubtypesTableOptions,
            this.auditVocabulariesMatingPurposesTableOptions,
            this.auditUsersTableOptions,
            this.auditUserDetailsTableOptions,
            this.auditVocabulariesAnimalCommentStatusesTableOptions,
            this.auditVocabulariesRouteOfAdministrationsTableOptions,
            this.auditVocabulariesVeterinaryRequestStatusesTableOptions,
            this.auditVocabulariesClinicalObservationsTableOptions,
            this.auditVocabulariesStandardPhrasesTableOptions,
            this.auditVocabulariesShipmentContainerConditionsTableOptions,
            this.auditVocabulariesClinicalObservationStatusesTableOptions,
            this.auditVocabulariesMaterialPoolStatusesTableOptions,
            this.auditVocabulariesShipmentAnimalConditionsTableOptions,
            this.auditVocabulariesExitReasonsTableOptions,
            this.auditStudyCharacteristicsTableOptions,
            this.auditSampleCharacteristicsTableOptions,
            this.auditAnimalCharacteristicsTableOptions
        ];

        if (this.isGLP) {
            for (const tableOption of glpTableOptions) {
                tableOption.options.columns.push(this.auditService.getReasonForChangeColumn());
            }
        }

        this.dataService = {
            run: (tableState: TableState) => {
                if (!this.filterApplied) {
                    return Promise.resolve({ results: [], inlineCount: 0 });
                }

                switch (this.gridView) {
                    case AuditGridView.Animals:
                        return this.loadAnimalAuditsList(tableState);
                    case AuditGridView.AnimalCharacteristics:
                        return this.loadAnimalCharacteristicsAuditsList(tableState);
                    case AuditGridView.AnimalComments:
                        return this.loadAnimalCommentsAuditsList(tableState);
                    case AuditGridView.AnimalTasks:
                        return this.loadAnimalsTasksAuditsList(tableState);
                    case AuditGridView.Samples:
                        return this.loadSampleAuditsList(tableState);
                    case AuditGridView.Jobs:
                        return this.loadJobAuditsList(tableState);
                    case AuditGridView.JobAnimals:
                        return this.loadJobAnimalsAuditsList(tableState);
                    case AuditGridView.JobSamples:
                        return this.loadJobSamplesAuditsList(tableState);
                    case AuditGridView.JobSampleGroups:
                        return this.loadJobSampleGroupsAuditsList(tableState);
                    case AuditGridView.JobTasks:
                        return this.loadJobTasksAuditsList(tableState);
                    case AuditGridView.JobLines:
                        return this.loadJobLinesAuditsList(tableState);
                    case AuditGridView.JobLineDetails:
                        return this.loadJobLineDetailsAuditsList(tableState);
                    case AuditGridView.JobStandardPhrases:
                        return this.loadJobStandardPhrasesAuditsList(tableState);
                    case AuditGridView.JobVariablePhrases:
                        return this.loadJobVariablePhrasesAuditsList(tableState);
                    case AuditGridView.JobGroups:
                        return this.loadJobGroupsAuditsList(tableState);
                    case AuditGridView.JobTestArticles:
                        return this.loadJobTestArticlesAuditsList(tableState);
                    case AuditGridView.JobCharacteristics:
                        return this.loadJobCharacteristicsAuditsList(tableState);
                    case AuditGridView.JobsInstitutions:
                        return this.loadJobInstitutionsAuditsList(tableState);
                    case AuditGridView.JobOrders:
                        return this.loadJobOrdersAuditsList(tableState);
                    case AuditGridView.JobIndividualPlaceholders:
                        return this.loadJobIndividualPlaceholdersAuditsList(tableState);
                    case AuditGridView.JobGroupPlaceholders:
                        return this.loadJobGroupPlaceholdersAuditsList(tableState);
                    case AuditGridView.WorkflowInputs:
                        return this.loadWorkflowInputsAuditsList(tableState);
                    case AuditGridView.WorkflowOutputs:
                        return this.loadWorkflowOutputsAuditsList(tableState);
                    case AuditGridView.Housing:
                        return this.loadMaterialPoolsAuditsList(tableState);
                    case AuditGridView.HousingAnimals:
                        return this.loadMaterialPoolMaterialsAuditsList(tableState);
                    case AuditGridView.HousingTasks:
                        return this.loadMaterialPoolTasksAuditsList(tableState);
                    case AuditGridView.HousingLocations:
                        return this.loadMaterialLocationsAuditsList(tableState);
                    case AuditGridView.HousingSocialStatutes:
                        return this.loadMaterialPoolSocialStatusesAuditsList(tableState);
                    case AuditGridView.Matings:
                        return this.loadMatingsAuditsList(tableState);
                    case AuditGridView.MatingMaterials:
                        return this.loadMatingMaterialsAuditsList(tableState);
                    case AuditGridView.Cohorts:
                        return this.loadCohortsAuditsList(tableState);
                    case AuditGridView.CohortAnimals:
                        return this.loadCohortAnimalsAuditsList(tableState);
                    case AuditGridView.Lines:
                        return this.loadLinesAuditsList(tableState);
                    case AuditGridView.Studies:
                        return this.loadStudiesAuditsList(tableState);
                    case AuditGridView.StudyLines:
                        return this.loadStudyLinesAuditsList(tableState);
                    case AuditGridView.SettingsOther:
                        return this.loadSettingsAuditsList(tableState);
                    case AuditGridView.Births:
                        return this.loadBirthsAuditsList(tableState);
                    case AuditGridView.Locations:
                        return this.loadLocationsAuditsList(tableState);
                    case AuditGridView.ClinicalObservations:
                        return this.loadAnimalClinicalObservationsAuditsList(tableState);
                    case AuditGridView.Clinical:
                        return this.loadAnimalHealthRecordsAuditsList(tableState);
                    case AuditGridView.ClinicalTasks:
                        return this.loadTaskAnimalHealthRecordsAuditsList(tableState);
                    case AuditGridView.ClinicalObservationDetails:
                        if (this.isGLP) {
                            return this.loadObservationDetailsAuditsList(tableState);
                        } else {
                            return this.loadClinicalObservationDetailsAuditsList(tableState);
                        }
                    case AuditGridView.Tasks:
                        return this.loadWorkflowTasksAuditsList(tableState);
                    case AuditGridView.TaskAssignments:
                        return this.loadTaskAssignmentsAuditsList(tableState);
                    case AuditGridView.Files:
                        return this.loadFilesAuditsList(tableState);
                    case AuditGridView.WorkflowTaskDetails:
                        return this.loadWorkflowTaskDetailsAuditsList(tableState);
                    case AuditGridView.TaskInputs:
                        return this.loadTaskInputsList(tableState);
                    case AuditGridView.TaskOutputs:
                        return this.loadTaskOutputsList(tableState);
                    case AuditGridView.TaskOutputFlags:
                        return this.loadOutputFlagsAuditsList(tableState);
                    case AuditGridView.Orders:
                        return this.loadOrdersAuditsList(tableState);
                    case AuditGridView.OrderAnimals:
                        return this.loadOrdersAnimalsAuditsList(tableState);
                    case AuditGridView.OrderInstitutions:
                        return this.loadOrdersInstitutionsAuditsList(tableState);
                    case AuditGridView.OrderLocations:
                        return this.loadOrdersLocationsAuditsList(tableState);
                    case AuditGridView.Protocols:
                        return this.loadProtocolsAuditsList(tableState);
                    case AuditGridView.ProtocolDefaultInputs:
                        return this.loadProtocolDefaultInputsAuditsList(tableState);
                    case AuditGridView.ProtocolTasks:
                        return this.loadProtocolsTasksAuditsList(tableState);
                    case AuditGridView.ProtocolSampleGroups:
                        return this.loadProtocolsSamplesGroupsAuditsList(tableState);
                    case AuditGridView.Resources:
                        return this.loadResourcesAuditList(tableState);
                    case AuditGridView.StudyAdministratorStudies:
                        return this.loadStudyAdministratorStudiesAuditsList(tableState);
                    case AuditGridView.Users:
                        return this.loadUsersAuditsList(tableState);
                    case AuditGridView.UserDetails:
                        return this.loadUserDetailsAuditsList(tableState);
                    case AuditGridView.Roles:
                        return this.loadRolesAuditsList(tableState);
                    case AuditGridView.RoleFacets:
                        return this.loadRoleFacetsAuditsList(tableState);
                    case AuditGridView.Enumerations:
                        return this.loadEnumerationClassesAuditsList(tableState);
                    case AuditGridView.EnumerationValues:
                        return this.loadEnumerationItems(tableState);
                    case AuditGridView.VocabulariesTaskFlagMessages:
                        return this.loadVocabulariesTaskFlagMessagesAuditsList(tableState);
                    case AuditGridView.VocabulariesTaskStatuses:
                        return this.loadVocabulariesTaskStatusesAuditsList(tableState);
                    case AuditGridView.VocabulariesAnimalClassifications:
                        return this.loadVocabulariesAnimalClassificationsAuditsList(tableState);
                    case AuditGridView.VocabulariesAnimalCommentStatuses:
                        return this.loadVocabulariesAnimalCommentStatusesAuditsList(tableState);
                    case AuditGridView.VocabulariesAnimalStatuses:
                        return this.loadVocabulariesAnimalStatusesAuditsList(tableState);
                    case AuditGridView.VocabulariesTestArticles:
                        return this.loadVocabulariesTestArticlesAuditsList(tableState);
                    case AuditGridView.VocabulariesJobSubtypes:
                        return this.loadVocabulariesJobSubtypesAuditsList(tableState);
                    case AuditGridView.VocabulariesTaskOutcomes:
                        return this.loadVocabulariesTaskOutcomesAuditsList(tableState);
                    case AuditGridView.CharacteristicsAnimals:
                        return this.loadCharacteristicAnimalsAuditsList(tableState);
                    case AuditGridView.CharacteristicsJobs:
                        return this.loadCharacteristicJobsAuditsList(tableState);
                    case AuditGridView.CharacteristicsStudies:
                        return this.loadCharacteristicStudiesAuditsList(tableState);
                    case AuditGridView.VocabulariesLineStatuses:
                        return this.loadVocabulariesLineStatusesAuditsList(tableState);
                    case AuditGridView.VocabulariesJobStatuses:
                        return this.loadVocabulariesJobStatusesAuditsList(tableState);
                    case AuditGridView.VocabulariesAnimalUses:
                        return this.loadVocabulariesAnimalUsesAuditsList(tableState);
                    case AuditGridView.CharacteristicsSamples:
                        return this.loadCharacteristicSamplesAuditsList(tableState);
                    case AuditGridView.VocabulariesJobTypes:
                        return this.loadVocabulariesJobTypesAuditsList(tableState);
                    case AuditGridView.VocabulariesMarkerTypes:
                        return this.loadVocabulariesPhysicalMarkerTypesAuditsList(tableState);
                    case AuditGridView.VocabulariesSampleAnalyses:
                        return this.loadVocabulariesSampleAnalysesAuditsList(tableState);
                    case AuditGridView.VocabulariesSampleConditions:
                        return this.loadVocabulariesSampleConditionsAuditsList(tableState);
                    case AuditGridView.VocabulariesPlateStatuses:
                        return this.loadVocabulariesPlateStatusesAuditsList(tableState);
                    case AuditGridView.VocabulariesAnimalSampleOrigins:
                        return this.loadVocabulariesMaterialOriginsAuditsList(tableState);
                    case AuditGridView.VocabulariesBirthStatuses:
                        return this.loadVocabulariesBirthStatusesAuditsList(tableState);
                    case AuditGridView.VocabulariesBodyConditionScores:
                        return this.loadVocabulariesBodyConditionScoresAuditsList(tableState);
                    case AuditGridView.VocabulariesBreedingStatuses:
                        return this.loadVocabulariesBreedingStatusesAuditsList(tableState);
                    case AuditGridView.SettingsNaming:
                        return this.loadNameFormatsList(tableState);
                    case AuditGridView.SettingsFacets:
                        return this.loadFacetSettingsList(tableState);
                    case AuditGridView.SettingsTaskOutputPoolWorkflowTasks:
                        return this.loadSettingsTaskOutputPoolWorkflowTasks(tableState);
                    case AuditGridView.SettingsTaskOutputPoolOutputs:
                        return this.loadSettingsTaskOutputPoolOutputs(tableState);
                    case AuditGridView.SettingsTaskOutputPools:
                        return this.loadSettingsTaskOutputPools(tableState);
                    case AuditGridView.VocabulariesShipmentAnimalConditions:
                        return this.loadVocabulariesShipmentAnimalConditionsAuditsList(tableState);
                    case AuditGridView.VocabulariesClinicalObservationStatuses:
                        return this.loadVocabulariesClinicalObservationStatusesAuditsList(tableState);
                    case AuditGridView.VocabulariesClinicalObservations:
                        return this.loadVocabulariesClinicalObservationsAuditsList(tableState);
                    case AuditGridView.VocabulariesClinicalObservationBodySystems:
                        return this.loadVocabulariesClinicalObservationBodySystemsAuditsList(tableState);
                    case AuditGridView.VocabulariesDiets:
                        return this.loadVocabulariesDietsAuditsList(tableState);
                    case AuditGridView.VocabulariesGenerations:
                        return this.loadVocabulariesGenerationsAuditsList(tableState);
                    case AuditGridView.VocabulariesLocationTypes:
                        return this.loadVocabulariesLocationTypesAuditsList(tableState);
                    case AuditGridView.VocabulariesResourceGroups:
                        return this.loadVocabulariesResourceGroupsAuditsList(tableState);
                    case AuditGridView.VocabulariesResourceTypes:
                        return this.loadVocabulariesResourceTypesAuditsList(tableState);
                    case AuditGridView.VocabulariesCompliances:
                        return this.loadVocabulariesCompliancesAuditsList(tableState);
                    case AuditGridView.VocabulariesInstitutionTypes:
                        return this.loadVocabulariesInstitutionTypesAuditsList(tableState);
                    case AuditGridView.VocabulariesLineTypes:
                        return this.loadVocabulariesLineTypesAuditsList(tableState);
                    case AuditGridView.VocabulariesIACUCProtocols:
                        return this.loadVocabulariesIACUCProtocolsAuditsList(tableState);
                    case AuditGridView.VocabulariesCouriers:
                        return this.loadVocabulariesCouriersAuditsList(tableState);
                    case AuditGridView.VocabulariesExitReasons:
                        return this.loadVocabulariesExitReasonsAuditsList(tableState);
                    case AuditGridView.VocabulariesGenotypes:
                        return this.loadVocabulariesGenotypesAuditsList(tableState);
                    case AuditGridView.VocabulariesHousingStatuses:
                        return this.loadVocabulariesMaterialPoolStatusesAuditsList(tableState);
                    case AuditGridView.VocabulariesMatingPurposes:
                        return this.loadVocabulariesMatingPurposesAuditsList(tableState);
                    case AuditGridView.VocabulariesMatingStatuses:
                        return this.loadVocabulariesMatingStatusesAuditsList(tableState);
                    case AuditGridView.VocabulariesPaymentTerms:
                        return this.loadVocabulariesPaymentTermsAuditsList(tableState);
                    case AuditGridView.VocabulariesMatingTypes:
                        return this.loadVocabulariesMatingTypesAuditsList(tableState);
                    case AuditGridView.VocabulariesCompatibilityAccessLevels:
                        return this.loadVocabulariesCompatibilityAccessLevelsAuditsList(tableState);
                    case AuditGridView.VocabulariesModifiers1:
                        return this.loadVocabulariesModifiers1AuditsList(tableState);
                    case AuditGridView.VocabulariesModifiers2:
                        return this.loadVocabulariesModifiers2AuditsList(tableState);
                    case AuditGridView.VocabulariesModifiers3:
                        return this.loadVocabulariesModifiers3AuditsList(tableState);
                    case AuditGridView.VocabulariesModifiers4:
                        return this.loadVocabulariesModifiers4AuditsList(tableState);
                    case AuditGridView.VocabulariesOrderStatuses:
                        return this.loadVocabulariesOrderStatusesAuditsList(tableState);
                    case AuditGridView.VocabulariesSocialGroupAccessLevels:
                        return this.loadVocabulariesSocialGroupAccessLevelsAuditsList(tableState);
                    case AuditGridView.VocabulariesContainerTypes:
                        return this.loadVocabulariesContainerTypesAuditsList(tableState);
                    case AuditGridView.VocabulariesJobReports:
                        return this.loadVocabulariesJobreportsAuditsList(tableState);
                    case AuditGridView.VocabulariesHousingTypes:
                        return this.loadVocabulariesMaterialPoolTypesAuditsList(tableState);
                    case AuditGridView.VocabulariesContactTypes:
                        return this.loadVocabulariesContactPersonTypesAuditsList(tableState);
                    case AuditGridView.VocabulariesQuarantineFacilities:
                        return this.loadVocabulariesQuarantineFacilitiesAuditsList(tableState);
                    case AuditGridView.VocabulariesGenotypeAssays:
                        return this.loadVocabulariesGenotypeAssaysAuditsList(tableState);
                    case AuditGridView.VocabulariesSiteTypes:
                        return this.loadVocabulariesSiteTypesAuditsList(tableState);
                    case AuditGridView.VocabulariesUnits:
                        return this.loadVocabulariesUnitsAuditsList(tableState);
                    case AuditGridView.VocabulariesRoutesOfAdministration:
                        return this.loadVocabulariesRouteOfAdministrationsAuditsList(tableState);
                    case AuditGridView.VocabulariesVeterinaryRequestStatuses:
                        return this.loadVocabulariesVeterinaryRequestStatusesAuditsList(tableState);
                    case AuditGridView.JobLocations:
                        return this.loadJobLocationsList(tableState);
                    case AuditGridView.VocabulariesStandardPhrases:
                        return this.loadVocabulariesStandardPhrasesAuditsList(tableState);
                    case AuditGridView.StudyCharacteristics:
                        return this.loadStudyCharacteristicsAuditsList(tableState);
                    case AuditGridView.SampleCharacteristics:
                        return this.loadSampleCharacteristicsAuditsList(tableState);
                    case AuditGridView.VocabulariesShipmentContainerConditions:
                        return this.loadVocabulariesShipmentContainerConditionsAuditsList(tableState);
                    case AuditGridView.VocabulariesOrderStatuses:
                        return this.loadVocabulariesOrderStatusesAuditsList(tableState);
                    case AuditGridView.VocabulariesPermitStatuses:
                        return this.loadVocabulariesPermitStatusesAuditsList(tableState);
                    case AuditGridView.VocabulariesPermitExternalPurposes:
                        return this.loadVocabulariesPermitExternalPurposesAuditsList(tableState);
                    case AuditGridView.VocabulariesSeverities:
                        return this.loadVocabulariesSeveritiesAuditsList(tableState);
                    case AuditGridView.VocabulariesPermitCategories:
                        return this.loadVocabulariesPermitCategoriesAuditsList(tableState);
                    case AuditGridView.Permits:
                        return this.loadPermitsAuditsList(tableState);
                    case AuditGridView.PermitInstitutions:
                        return this.loadPermitInstitutionsAuditsList(tableState);
                    case AuditGridView.PermitSpecies:
                        return this.loadPermitSpeciesAuditsList(tableState);
                    case AuditGridView.PermitSpeciesLines:
                        return this.loadPermitSpeciesLinesAuditsList(tableState);
                    case AuditGridView.PermitSpeciesOrigins:
                        return this.loadPermitSpeciesOriginsAuditsList(tableState);
                }
            }
        };

        this.options = [
            {viewName: AuditGridView.Animals, viewDisplayName: 'Animals', isOptionVisible: true},
            {viewName: AuditGridView.AnimalComments, viewDisplayName: 'Animal Comments', isOptionVisible: true},
            {viewName: AuditGridView.AnimalTasks, viewDisplayName: 'Animal Tasks', isOptionVisible: true},
            {viewName: AuditGridView.Samples, viewDisplayName: 'Samples', isOptionVisible: true},
            {viewName: AuditGridView.Jobs, viewDisplayName: this.translationService.translate('Jobs'), isOptionVisible: true},
            {viewName: AuditGridView.JobAnimals, viewDisplayName: `${this.translationService.translate('Job')} Animals`, isOptionVisible: true},
            {viewName: AuditGridView.JobSamples, viewDisplayName: `${this.translationService.translate('Job')} Samples`, isOptionVisible: true},
            {viewName: AuditGridView.JobSampleGroups, viewDisplayName: `${this.translationService.translate('Job')} Sample Groups`, isOptionVisible: true},
            {viewName: AuditGridView.JobTasks, viewDisplayName: `${this.translationService.translate('Job')} Tasks`, isOptionVisible: true},
            {viewName: AuditGridView.JobLines, viewDisplayName: `${this.translationService.translate('Job')} ${this.translationService.translate('Line')}s`, isOptionVisible: true},
            {viewName: AuditGridView.JobLineDetails, viewDisplayName: `${this.translationService.translate('Job')} ${this.translationService.translate('Line')} Details`, isOptionVisible: this.isCRL},
            {viewName: AuditGridView.JobStandardPhrases, viewDisplayName: `${this.translationService.translate('Job')} Standard Phrases`, isOptionVisible: this.isCRL},
            {viewName: AuditGridView.JobGroups, viewDisplayName: `${this.translationService.translate('Job')} Groups`, isOptionVisible: this.isCRO},
            {viewName: AuditGridView.JobTestArticles, viewDisplayName: `${this.translationService.translate('Job')} Test Articles`, isOptionVisible: true},
            {viewName: AuditGridView.JobCharacteristics, viewDisplayName: `${this.translationService.translate('Job')} Characteristics`, isOptionVisible: true},
            {viewName: AuditGridView.JobsInstitutions, viewDisplayName: `${this.translationService.translate('Job')} ${this.translationService.translate('Institution')}s`, isOptionVisible: true},
            {viewName: AuditGridView.JobOrders, viewDisplayName: `${this.translationService.translate('Job')} Orders`, isOptionVisible: true},
            {viewName: AuditGridView.JobLocations, viewDisplayName: `${this.translationService.translate('Job')} Locations`, isOptionVisible: true},
            {viewName: AuditGridView.JobIndividualPlaceholders, viewDisplayName: `${this.translationService.translate('Job')} Individual Placeholders`, isOptionVisible: this.isCRO},
            {viewName: AuditGridView.JobGroupPlaceholders, viewDisplayName: `${this.translationService.translate('Job')} Group Placeholders`, isOptionVisible: this.isCRO},
            {viewName: AuditGridView.TaskAssignments, viewDisplayName: 'Task Assignments', isOptionVisible: true},
            {viewName: AuditGridView.TaskInputs, viewDisplayName: 'Task Inputs', isOptionVisible: true},
            {viewName: AuditGridView.TaskOutputs, viewDisplayName: 'Task Outputs', isOptionVisible: true},
            {viewName: AuditGridView.TaskOutputFlags, viewDisplayName: 'Task Output Flags', isOptionVisible: true},
            {viewName: AuditGridView.WorkflowInputs, viewDisplayName: 'Workflow Inputs', isOptionVisible: true},
            {viewName: AuditGridView.WorkflowOutputs, viewDisplayName: 'Workflow Outputs', isOptionVisible: true},
            {viewName: AuditGridView.WorkflowTaskDetails, viewDisplayName: 'Workflow Task Details', isOptionVisible: true},
            {viewName: AuditGridView.Housing, viewDisplayName: 'Housing', isOptionVisible: true},
            {viewName: AuditGridView.HousingAnimals, viewDisplayName: 'Housing Animals', isOptionVisible: true},
            {viewName: AuditGridView.HousingTasks, viewDisplayName: 'Housing Tasks', isOptionVisible: true},
            {viewName: AuditGridView.HousingLocations, viewDisplayName: 'Housing Locations', isOptionVisible: true},
            {viewName: AuditGridView.HousingSocialStatutes, viewDisplayName: 'Housing Social Statuses', isOptionVisible: this.isGLP},
            {viewName: AuditGridView.Matings, viewDisplayName: 'Matings', isOptionVisible: true},
            {viewName: AuditGridView.MatingMaterials, viewDisplayName: 'Mating Materials', isOptionVisible: true},
            {viewName: AuditGridView.Cohorts, viewDisplayName: 'Cohorts', isOptionVisible: true},
            {viewName: AuditGridView.CohortAnimals, viewDisplayName: 'Cohort Animals', isOptionVisible: true},
            {viewName: AuditGridView.Lines, viewDisplayName: `${this.translationService.translate('Lines')}`, isOptionVisible: true},
            {viewName: AuditGridView.Studies, viewDisplayName: `${this.translationService.translate('Studies')}`, isOptionVisible: true},
            {viewName: AuditGridView.StudyLines, viewDisplayName: `${this.translationService.translate('Study')} ${this.translationService.translate('Lines')}`, isOptionVisible: true},
            {viewName: AuditGridView.Births, viewDisplayName: 'Births', isOptionVisible: true},
            {viewName: AuditGridView.Locations, viewDisplayName: 'Locations', isOptionVisible: true},
            {viewName: AuditGridView.Clinical, viewDisplayName: 'Clinical', isOptionVisible: true},
            {viewName: AuditGridView.ClinicalTasks, viewDisplayName: 'Clinical Tasks', isOptionVisible: true},
            {viewName: AuditGridView.ClinicalObservations, viewDisplayName: 'Clinical Observations', isOptionVisible: !this.isGLP},
            {viewName: AuditGridView.ClinicalObservationDetails, viewDisplayName: this.isGLP ? 'Health Observations' : 'Clinical Observation Details', isOptionVisible: true},
            {viewName: AuditGridView.Tasks, viewDisplayName: 'Tasks', isOptionVisible: true},
            {viewName: AuditGridView.Files, viewDisplayName: 'Files', isOptionVisible: true},
            {viewName: AuditGridView.Orders, viewDisplayName: 'Orders', isOptionVisible: true},
            {viewName: AuditGridView.OrderAnimals, viewDisplayName: 'Order Animals', isOptionVisible: true},
            {viewName: AuditGridView.OrderInstitutions, viewDisplayName: `Order ${this.translationService.translate('Institutions')}`, isOptionVisible: true},
            {viewName: AuditGridView.OrderLocations, viewDisplayName: 'Order Locations', isOptionVisible: true},
            {viewName: AuditGridView.Protocols, viewDisplayName: 'Protocols', isOptionVisible: true},
            {viewName: AuditGridView.ProtocolDefaultInputs, viewDisplayName: 'Protocol Default Inputs', isOptionVisible: true},
            {viewName: AuditGridView.ProtocolTasks, viewDisplayName: 'Protocol Tasks', isOptionVisible: true},
            {viewName: AuditGridView.ProtocolSampleGroups, viewDisplayName: 'Protocol Sample Groups', isOptionVisible: true},
            {viewName: AuditGridView.Resources, viewDisplayName: 'Resources', isOptionVisible: true},
            {
                viewName: AuditGridView.StudyAdministratorStudies,
                viewDisplayName: `${this.translationService.translate('Study')} Administrator ${this.translationService.translate('Studies')}`, isOptionVisible: this.isGLP
            },
            {viewName: AuditGridView.Users, viewDisplayName: 'Users', isOptionVisible: true},
            {viewName: AuditGridView.UserDetails, viewDisplayName: 'User Details', isOptionVisible: true},
            {viewName: AuditGridView.Roles, viewDisplayName: 'Roles', isOptionVisible: true},
            {viewName: AuditGridView.RoleFacets, viewDisplayName: 'Role Facets', isOptionVisible: true},
            {viewName: AuditGridView.SettingsNaming, viewDisplayName: 'Settings: Naming', isOptionVisible: true},
            {viewName: AuditGridView.SettingsFacets, viewDisplayName: 'Settings: Facets', isOptionVisible: true},
            {viewName: AuditGridView.SettingsOther, viewDisplayName: 'Settings: Other', isOptionVisible: true },
            {viewName: AuditGridView.SettingsTaskOutputPoolWorkflowTasks, viewDisplayName: 'Settings: Task Output Pool Tasks', isOptionVisible: true },
            {viewName: AuditGridView.SettingsTaskOutputPools, viewDisplayName: 'Settings: Task Output Pools', isOptionVisible: true},
            {viewName: AuditGridView.SettingsTaskOutputPoolOutputs, viewDisplayName: 'Settings: Task Output Pool Outputs', isOptionVisible: true },
            {viewName: AuditGridView.VocabulariesAnimalClassifications, viewDisplayName: 'Vocabularies: Animal Classifications', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesAnimalCommentStatuses, viewDisplayName: 'Vocabularies: Animal Comment Statuses', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesAnimalStatuses, viewDisplayName: 'Vocabularies: Animal Statuses', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesAnimalUses, viewDisplayName: 'Vocabularies: Animal Uses' , isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesAnimalSampleOrigins, viewDisplayName: 'Vocabularies: Animal/Sample Origins', isOptionVisible: true},
            {viewName: AuditGridView.Enumerations, viewDisplayName: 'Enumerations', isOptionVisible: true},
            {viewName: AuditGridView.EnumerationValues, viewDisplayName: 'Enumeration Values', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesTaskFlagMessages, viewDisplayName: 'Vocabularies: Task Flag Messages', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesTaskStatuses, viewDisplayName: 'Vocabularies: Task Statuses', isOptionVisible: true},
            {viewName: AuditGridView.CharacteristicsAnimals, viewDisplayName: 'Characteristics: Animals', isOptionVisible: true},
            {viewName: AuditGridView.CharacteristicsSamples, viewDisplayName: 'Characteristics: Samples', isOptionVisible: true},
            {viewName: AuditGridView.CharacteristicsJobs, viewDisplayName: `Characteristics: ${this.translationService.translate('Jobs')}`, isOptionVisible: true},
            {viewName: AuditGridView.CharacteristicsStudies, viewDisplayName: `Characteristics: ${this.translationService.translate('Studies')}`, isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesLineStatuses, viewDisplayName: `Vocabularies: ${this.translationService.translate('Line')} Statuses`, isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesJobStatuses, viewDisplayName: `Vocabularies: ${this.translationService.translate('Job')} Statuses`, isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesTaskOutcomes, viewDisplayName: 'Vocabularies: Task Outcomes', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesMarkerTypes, viewDisplayName: 'Vocabularies: Marker Types', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesSampleAnalyses, viewDisplayName: 'Vocabularies: Sample Analyses', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesSampleConditions, viewDisplayName: 'Vocabularies: Sample Conditions', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesPlateStatuses, viewDisplayName: 'Vocabularies: Plate Statuses', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesBirthStatuses, viewDisplayName: 'Vocabularies: Birth Statuses', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesBodyConditionScores, viewDisplayName: 'Vocabularies: Body Condition Scores', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesBreedingStatuses, viewDisplayName: 'Vocabularies: Breeding Statuses', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesShipmentAnimalConditions, viewDisplayName: 'Vocabularies: Shipment Animal Conditions', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesClinicalObservationStatuses, viewDisplayName: 'Vocabularies: Clinical Observation Statuses', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesClinicalObservations, viewDisplayName: 'Vocabularies: Clinical Observations', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesClinicalObservationBodySystems, viewDisplayName: 'Vocabularies: Clinical Observation Body Systems', isOptionVisible: this.isGLP},
            {viewName: AuditGridView.VocabulariesDiets, viewDisplayName: 'Vocabularies: Diets', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesGenerations, viewDisplayName: 'Vocabularies: Generations', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesLocationTypes, viewDisplayName: 'Vocabularies: Location Types', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesResourceGroups, viewDisplayName: 'Vocabularies: Resource Groups', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesResourceTypes, viewDisplayName: 'Vocabularies: Resource Types', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesCompliances, viewDisplayName: 'Vocabularies: Compliances', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesInstitutionTypes, viewDisplayName: `Vocabularies: ${this.translationService.translate('Institution')} Types`, isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesLineTypes, viewDisplayName: `Vocabularies: ${this.translationService.translate('Line')} Types`, isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesIACUCProtocols, viewDisplayName: 'Vocabularies: IACUC Protocols', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesCouriers, viewDisplayName: 'Vocabularies: Couriers', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesExitReasons, viewDisplayName: 'Vocabularies: Exit Reasons', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesGenotypes, viewDisplayName: 'Vocabularies: Genotypes', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesHousingStatuses, viewDisplayName: 'Vocabularies: Housing Statuses', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesMatingPurposes, viewDisplayName: 'Vocabularies: Mating Purposes', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesMatingStatuses, viewDisplayName: 'Vocabularies: Mating Statuses', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesPaymentTerms, viewDisplayName: 'Vocabularies: Payment Terms', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesHousingTypes, viewDisplayName: 'Vocabularies: Housing Types', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesMatingTypes, viewDisplayName: 'Vocabularies: Mating Types', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesCompatibilityAccessLevels, viewDisplayName: 'Vocabularies: Compatibility Access Levels', isOptionVisible: this.isGLP},
            {viewName: AuditGridView.VocabulariesModifiers1, viewDisplayName: 'Vocabularies: Modifiers 1', isOptionVisible: this.isGLP},
            {viewName: AuditGridView.VocabulariesModifiers2, viewDisplayName: 'Vocabularies: Modifiers 2', isOptionVisible: this.isGLP},
            {viewName: AuditGridView.VocabulariesModifiers3, viewDisplayName: 'Vocabularies: Modifiers 3', isOptionVisible: this.isGLP},
            {viewName: AuditGridView.VocabulariesModifiers4, viewDisplayName: 'Vocabularies: Modifiers 4', isOptionVisible: this.isGLP },
            {viewName: AuditGridView.VocabulariesOrderStatuses, viewDisplayName: 'Vocabularies: Order Statuses', isOptionVisible: true },
            {viewName: AuditGridView.VocabulariesSocialGroupAccessLevels, viewDisplayName: 'Vocabularies: Social Group Access Levels', isOptionVisible: this.isGLP},
            {viewName: AuditGridView.VocabulariesContainerTypes, viewDisplayName: 'Vocabularies: Container Types', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesJobReports, viewDisplayName: `Vocabularies: ${this.translationService.translate('Job')} Reports`, isOptionVisible: this.isCRL},
            {viewName: AuditGridView.VocabulariesContactTypes, viewDisplayName: 'Vocabularies: Contact Types', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesJobTypes, viewDisplayName: `Vocabularies: ${this.translationService.translate('Job')} Types`, isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesJobSubtypes, viewDisplayName: `Vocabularies: ${this.translationService.translate('Job')} Subtypes`, isOptionVisible: this.isCRO},
            {viewName: AuditGridView.VocabulariesQuarantineFacilities, viewDisplayName: 'Vocabularies: Quarantine Facilities', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesGenotypeAssays, viewDisplayName: 'Vocabularies: Genotype Assays', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesSiteTypes, viewDisplayName: 'Vocabularies: Site Types', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesUnits, viewDisplayName: 'Vocabularies: Units', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesTestArticles, viewDisplayName: 'Vocabularies: Test Articles', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesRoutesOfAdministration, viewDisplayName: 'Vocabularies: Routes of Administration', isOptionVisible: this.isGLP},
            {viewName: AuditGridView.VocabulariesVeterinaryRequestStatuses, viewDisplayName: 'Vocabularies: Veterinary Request Statuses', isOptionVisible: this.isGLP},
            {viewName: AuditGridView.VocabulariesStandardPhrases, viewDisplayName: 'Vocabularies: Standard Phrases', isOptionVisible: this.isCRL},
            {viewName: AuditGridView.StudyCharacteristics, viewDisplayName: `${this.translationService.translate('Study')} Characteristics`, isOptionVisible: true},
            {viewName: AuditGridView.SampleCharacteristics, viewDisplayName: 'Sample Characteristics', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesStandardPhrases, viewDisplayName: 'Vocabularies: Standard Phrases', isOptionVisible: this.isCRL},
            {viewName: AuditGridView.AnimalCharacteristics, viewDisplayName: 'Animal Characteristics', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesShipmentContainerConditions, viewDisplayName: AuditGridView.VocabulariesShipmentContainerConditions, isOptionVisible: true},
            {viewName: AuditGridView.JobVariablePhrases, viewDisplayName: `${this.translationService.translate('Job')} Variable Phrases`, isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesPermitStatuses, viewDisplayName: 'Vocabularies: Permit Statuses', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesPermitExternalPurposes, viewDisplayName: 'Vocabularies: Permit External Purposes', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesSeverities, viewDisplayName: 'Vocabularies: Severities', isOptionVisible: true},
            {viewName: AuditGridView.VocabulariesPermitCategories, viewDisplayName: 'Vocabularies: Permit Categories', isOptionVisible: true},
            {viewName: AuditGridView.Permits, viewDisplayName: 'Permits', isOptionVisible: true},
            {viewName: AuditGridView.PermitInstitutions, viewDisplayName: `Permit ${this.translationService.translate('Institutions')}`, isOptionVisible: true},
            {viewName: AuditGridView.PermitSpecies, viewDisplayName: 'Permit Species', isOptionVisible: true},
            {viewName: AuditGridView.PermitSpeciesLines, viewDisplayName: `Permit Species ${this.translationService.translate('Line')}s`, isOptionVisible: true},
            {viewName: AuditGridView.PermitSpeciesOrigins, viewDisplayName: 'Permit Species Origins', isOptionVisible: true},
        ];
    }


    // lifecycle
    ngOnInit() {
        super.ngOnInit();

        this.gridView = AuditGridView.ChooseSection;

        this.auditLinkSub = this.auditService.onAuditLinkClicked().subscribe((viewName: AuditGridView) => {
            this.changeGridView(viewName);
        });

        this.initialize();
    }

    ngOnDestroy(): void {
        this.auditLinkSub.unsubscribe();
    }

    initIsCRO() {
        this.isCRO = this.featureFlagService.isFlagOn("IsCRO");
    }

    initIsCRL() {
        this.isCRL = this.featureFlagService.isFlagOn("IsCRL");
    }

    initIsGLP() {
        this.isGLP = this.featureFlagService.isFlagOn('IsGLP');
    }

    initLargeAnimalEnabled() {
        this.largeAnimalEnabled = this.featureFlagService.isFlagOn(LARGE_ANIMAL);
    }

    initialize() {
        this.restoreFilterState();
        this.sortFilterViewNames();
    }

    refreshData() {
        this.initialize();
        this.dataTableComm.reloadTable();
    }

    changeGridView(viewName: AuditGridView) {
        this.gridView = viewName;
        this.defaultOption = false;
        this.filterApplied = false;

        this.auditService.setCurrentGridView(viewName);

        this.openFilter();
    }

    sortFilterViewNames() {
        this.options = this.options
            .filter((item: IAuditDropdownOption): boolean => item.isOptionVisible)
            .sort((a: IAuditDropdownOption, b: IAuditDropdownOption): number => a.viewDisplayName > b.viewDisplayName ? 1 : -1);
    }

    restoreFilterState() {
        const defaultUpdateTypes = this.getDefaultUpdateTypes(this.gridView);

        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (this.filter) {
                this.filter.ModifiedDateStart = filterToDate(this.filter.ModifiedDateStart);
                this.filter.ModifiedDateEnd = filterToDate(this.filter.ModifiedDateEnd);
                this.filter.CreatedDateStart = filterToDate(this.filter.CreatedDateStart);
                this.filter.CreatedDateEnd = filterToDate(this.filter.CreatedDateEnd);
                this.filter.DueDateStart = filterToDate(this.filter.DueDateStart);
                this.filter.DueDateEnd = filterToDate(this.filter.DueDateEnd);
                this.filter.CompleteDateStart = filterToDate(this.filter.CompleteDateStart);
                this.filter.CompleteDateEnd = filterToDate(this.filter.CompleteDateEnd);
                this.filter.ObservedDateStart = filterToDate(this.filter.ObservedDateStart);
                this.filter.ObservedDateEnd = filterToDate(this.filter.ObservedDateEnd);
                this.filter.DateInStart = filterToDate(this.filter.DateInStart);
                this.filter.DateInEnd = filterToDate(this.filter.DateInEnd);
                this.filter.DateOutStart = filterToDate(this.filter.DateOutStart);
                this.filter.DateOutEnd = filterToDate(this.filter.DateOutEnd);
                this.filter.DateDocumentedStart = filterToDate(this.filter.DateDocumentedStart);
                this.filter.DateDocumentedEnd = filterToDate(this.filter.DateDocumentedEnd);
            } else {
                this.filter = {
                    UpdateTypes: defaultUpdateTypes,
                    ModifiedDateStart: filterToDate(SIX_MONTHS_AGO),
                    ModifiedDateEnd: filterToDate(TODAY)
                };
            }
            // Set default ModifiedDate range to 1 year from current date to limit query time
        } else {
            this.filter = {
                UpdateTypes: defaultUpdateTypes,
                ModifiedDateStart: filterToDate(SIX_MONTHS_AGO),
                ModifiedDateEnd: filterToDate(TODAY)
            };
        }
    }

    loadAnimalAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getAnimals({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadMaterialPoolTasksAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getMaterialPoolTasks({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;

            if (this.isGLP) {
                this.data = this.auditService.filterAuditResponse(response.results);
                this.stopLoading();
            } else {
                this.stopLoading();

                this.data = response.results;
            }

            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadAnimalCommentsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getAnimalComments({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;

            if (this.isGLP) {
                this.data = this.auditService.filterAuditResponse(response.results);
                this.stopLoading();
            } else {
                this.stopLoading();

                this.data = response.results;
            }

            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadAnimalsTasksAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getAnimalsTasks({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadSampleAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getSamples({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            if (this.isGLP) {
                this.data = this.auditService.filterAuditResponse(response.results);
                this.stopLoading();
            } else {
                this.stopLoading();
                this.data = response.results;
            }
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadJobAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getJobs({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            if (this.isGLP) {
                this.data = this.auditService.filterAuditResponse(response.results);
                this.stopLoading();
            } else {
                this.stopLoading();

                this.data = response.results;
            }
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadJobAnimalsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getJobAnimals({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadJobSamplesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getJobSamples({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadJobSampleGroupsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getJobSampleGroups({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.isCRO, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadJobTasksAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getJobTasks({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.isCRO, this.filterVisibility).then((response) => {
            if (this.isGLP) {
                this.data = this.auditService.filterAuditResponse(response.results);
                this.stopLoading();
            } else {
                this.stopLoading();
                this.data = response.results;
            }
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadJobLinesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getJobLines({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadJobLineDetailsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getJobLineDetails({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadJobStandardPhrasesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getJobStandardPhrases({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadJobGroupsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getJobGroups({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadJobTestArticlesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getJobTestArticles({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadJobCharacteristicsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getJobCharacteristics({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadJobInstitutionsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getJobInstitutions({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadJobOrdersAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);
        return this.auditService.getJobOrders({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadJobIndividualPlaceholdersAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getJobAnimalPlaceholders({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadJobGroupPlaceholdersAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getJobGroupPlaceholders({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadWorkflowInputsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getWorkflowInputs({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.isCRO, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadWorkflowOutputsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getWorkflowOutputs({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.isCRO, this.filterVisibility).then((response) => {
            if (this.isGLP) {
                this.data = this.auditService.filterAuditResponse(response.results);
                this.stopLoading();
            } else {
                this.stopLoading();
                this.data = response.results;
            }
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadMaterialPoolsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getMaterialPools({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadMaterialPoolMaterialsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getMaterialPoolMaterials({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadMaterialPoolSocialStatusesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getMaterialPoolSocialStatuses({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadMaterialLocationsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getMaterialLocations({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadMatingsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getMatings({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadMatingMaterialsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getMatingMaterials({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadCohortsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getCohorts({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadCohortAnimalsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getCohortAnimals({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadLinesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getLines({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadStudiesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getStudies({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadStudyLinesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getStudyLines({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadBirthsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getBirths({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadLocationsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getLocations({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadAnimalClinicalObservationsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);
        return this.auditService.getAnimalClinicalObservations({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadAnimalHealthRecordsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getAnimalHealthRecords({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadTaskAnimalHealthRecordsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getTaskAnimalHealthRecords({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadClinicalObservationDetailsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getClinicalObservationDetails({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadObservationDetailsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getObservationDetails({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadWorkflowTasksAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getWorkflowTasks({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.isCRO, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadWorkflowTaskDetailsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);
        return this.auditService.getWorkflowTaskDetails({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadFilesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getFiles({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadTaskAssignmentsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getTaskAssignments({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.isCRO, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadOrdersAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getOrders({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadOrdersAnimalsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getOrdersAnimals({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    async loadOrdersInstitutionsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';


        try {
            this.setLoadingState(tableState.loadingMessage);

            const { results, inlineCount } = await this.auditService.getOrdersInstitutions({
                page,
                size: pageSize,
                sort,
                filter: this.getActiveFilter()
            }, this.filterVisibility);
            this.data = results;
            this.totalCount = inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } catch (err) {
            throw err;
        } finally {
            this.stopLoading();
        }
    }

    loadOrdersLocationsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getOrdersLocations({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadProtocolsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getProtocols({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadProtocolDefaultInputsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getProtocolDefaultInputs({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.isCRO, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadProtocolsTasksAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getProtocolsTasks({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.isCRO, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadProtocolsSamplesGroupsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getProtocolsSamplesGroups({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.isCRO, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadResourcesAuditList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getResources({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    async loadStudyAdministratorStudiesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        await this.userNameService.initializeForAudit();

        return this.auditService.getStudyAdministratorStudies({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadUsersAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getUsers({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadUserDetailsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        const promise = this.auditService.getWorkgroupUsers({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });

        return promise;
    }


    loadSettingsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getSettings({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response: any) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadRolesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getRoles({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesTaskFlagMessagesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyTaskFlagMessages({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesTaskStatusesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyTaskStatuses({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesTestArticlesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        const promise = this.auditService.getVocabularyTestArticles({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });

        return promise;
    }

    loadVocabulariesAnimalClassificationsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        const promise = this.auditService.getVocabularyAnimalClassifications({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });

        return promise;
    }

    loadVocabulariesJobSubtypesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyJobSubtypes({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesAnimalCommentStatusesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyAnimalCommentStatuses({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesTaskOutcomesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);
        return this.auditService.getVocabularyTaskOutcomes({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesAnimalStatusesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);
        return this.auditService.getVocabularyAnimalStatuses({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesMatingStatusesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);
        return this.auditService.getVocabularyMatingStatuses({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesLineStatusesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyLineStatuses({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadCharacteristicAnimalsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getCharacteristicAnimals({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadCharacteristicJobsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getCharacteristicJobs({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesAnimalUsesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyAnimalUses({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesJobStatusesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyJobStatuses({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadCharacteristicStudiesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getCharacteristicStudies({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesSampleAnalysesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularySampleAnalyses({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesShipmentAnimalConditionsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyShipmentAnimalConditions({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesShipmentContainerConditionsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyShipmentContainerConditions({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesClinicalObservationStatusesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyClinicalObservationStatuses({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesPlateStatusesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyPlateStatuses({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadEnumerationClassesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getEnumerationClasses({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesSampleConditionsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);
        return this.auditService.getVocabularySampleConditions({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesJobTypesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyJobTypes({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesPhysicalMarkerTypesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyPhysicalMarkerTypes({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesLocationTypesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyLocationTypes({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesMaterialOriginsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyMaterialOrigins({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesBirthStatusesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyBirthStatuses({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadNameFormatsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getNameFormats({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadFacetSettingsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getFacetSettings({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility, this.isCRO, this.isGLP, this.isCRL).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadSettingsTaskOutputPoolWorkflowTasks(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getSettingsTaskOutputPoolWorkflowTasks({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadSettingsTaskOutputPools(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getSettingsTaskOutputPools({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }  

    loadSettingsTaskOutputPoolOutputs(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getSettingsTaskOutputPoolOutputs({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesBodyConditionScoresAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyBodyConditionScores({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadTaskInputsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);
        return this.auditService.getTaskInputs({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadTaskOutputsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);
        return this.auditService.getTaskOutputs({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadOutputFlagsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getOutputFlags({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.isCRO, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadRoleFacetsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getRoleFacets({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesBreedingStatusesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyBreedingStatuses({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesClinicalObservationsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyClinicalObservations({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.totalCount = response.inlineCount;
            this.updatePageState();
            this.data = response.results;
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadCharacteristicSamplesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getCharacteristicSamples({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesClinicalObservationBodySystemsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyClinicalObservationBodySystems({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesDietsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyDiets({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesGenerationsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyGenerations({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadEnumerationItems(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getEnumerationItems({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesResourceGroupsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyResourceGroups({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesResourceTypesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);
        return this.auditService.getVocabularyResourceTypes({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesCompliancesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyCompliances({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesInstitutionTypesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyInstitutionTypes({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesLineTypesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyLineTypes({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesIACUCProtocolsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);
        return this.auditService.getVocabularyIACUCProtocols({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesCouriersAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyCouriers({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesExitReasonsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyExitReasons({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesGenotypesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyGenotypes({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesMaterialPoolStatusesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyMaterialPoolStatuses({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesMatingPurposesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);
        return this.auditService.getVocabularyMatingPurposes({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesPaymentTermsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);
        return this.auditService.getVocabularyPaymentTerms({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesMaterialPoolTypesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyMaterialPoolTypes({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesGenotypeAssaysAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyGenotypeAssays({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesContainerTypesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyContainerTypes({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesMatingTypesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);
        return this.auditService.getVocabularyMatingTypes({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesCompatibilityAccessLevelsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyCompatibilityAccessLevels({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesModifiers1AuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyModifiers1({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesModifiers2AuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);
        return this.auditService.getVocabularyModifiers2({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesModifiers3AuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);
        return this.auditService.getVocabularyModifiers3({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesModifiers4AuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);
        return this.auditService.getVocabularyModifiers4({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesOrderStatusesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);
        return this.auditService.getVocabularyOrderStatuses({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesSocialGroupAccessLevelsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);
        return this.auditService.getVocabularySocialGroupAccessLevels({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesJobreportsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyJobReports({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesContactPersonTypesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyContatPersonTypes({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesQuarantineFacilitiesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);
        return this.auditService.getVocabularyQuarantineFacilities({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesSiteTypesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        this.setLoadingState(tableState.loadingMessage);
        return this.auditService.getVocabularySiteTypes({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();
            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesUnitsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyUnits({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesRouteOfAdministrationsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyRouteOfAdministrations({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesVeterinaryRequestStatusesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getVocabularyVeterinaryRequestStatuses({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadJobLocationsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        return this.auditService.getJobLocations({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    loadVocabulariesStandardPhrasesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        const promise = this.auditService.getVocabularyStandardPhrases({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });

        return promise;
    }

    loadStudyCharacteristicsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        const promise = this.auditService.getStudyCharacteristics({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });

        return promise;
    }

    loadSampleCharacteristicsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        const promise = this.auditService.getSampleCharacteristics({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });

        return promise;
    }

    loadAnimalCharacteristicsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        this.setLoadingState(tableState.loadingMessage);

        const promise = this.auditService.getAnimalCharacteristics({
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        }, this.filterVisibility).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });

        return promise;
    }

    async loadJobVariablePhrasesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        try {
            this.setLoadingState(tableState.loadingMessage);
            const { results, inlineCount } = await this.auditService.getJobVariablePhrases({
                page,
                size: pageSize,
                sort,
                filter: this.getActiveFilter()
            }, this.filterVisibility);

            this.data = results;
            this.totalCount = inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } catch (err) {
            throw err;
        } finally {
            this.stopLoading();
        }
    }

    async loadVocabulariesPermitStatusesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        try {
            this.setLoadingState(tableState.loadingMessage);

            const { results, inlineCount } = await this.auditService.getVocabularyPermitStatuses({
                page,
                size: pageSize,
                sort,
                filter: this.getActiveFilter()
            }, this.filterVisibility);

            this.data = results;
            this.totalCount = inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } catch (err) {
            throw err;
        } finally {
            this.stopLoading();
        }
    }

    async loadVocabulariesPermitExternalPurposesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        try {
            this.setLoadingState(tableState.loadingMessage);

            const { results, inlineCount } = await this.auditService.getVocabularyPermitExternalPurposes({
                page,
                size: pageSize,
                sort,
                filter: this.getActiveFilter()
            }, this.filterVisibility);
            this.data = results;
            this.totalCount = inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } catch (err) {
            throw err;
        } finally {
            this.stopLoading();
        }
    }

    async loadVocabulariesSeveritiesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        try {
            this.setLoadingState(tableState.loadingMessage);
            const { results, inlineCount } = await this.auditService.getVocabularySeverities({
                page,
                size: pageSize,
                sort,
                filter: this.getActiveFilter()
            }, this.filterVisibility);

            this.data = results;
            this.totalCount = inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } catch (err) {
            throw err;
        } finally {
            this.stopLoading();
        }
    }

    async loadVocabulariesPermitCategoriesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        try {
            this.setLoadingState(tableState.loadingMessage);
            const { results, inlineCount } = await this.auditService.getVocabularyPermitCategories({
                page,
                size: pageSize,
                sort,
                filter: this.getActiveFilter()
            }, this.filterVisibility);

            this.data = results;
            this.totalCount = inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } catch (err) {
            throw err;
        } finally {
            this.stopLoading();
        }
    }

    async loadPermitsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        try {
            this.setLoadingState(tableState.loadingMessage);
            const { results, inlineCount } = await this.auditService.getPermits({
                page,
                size: pageSize,
                sort,
                filter: this.getActiveFilter()
            }, this.filterVisibility);

            this.data = results;
            this.totalCount = inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } catch (err) {
            throw err;
        } finally {
            this.stopLoading();
        }
    }

    async loadPermitInstitutionsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';
        try {
            this.setLoadingState(tableState.loadingMessage);
            const { results, inlineCount } = await this.auditService.getPermitInstitutions({
                page,
                size: pageSize,
                sort,
                filter: this.getActiveFilter()
            }, this.filterVisibility);
            this.data = results;
            this.totalCount = inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } catch (err) {
            throw err;
        } finally {
            this.stopLoading();
        }
    }

    async loadPermitSpeciesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        try {
            this.setLoadingState(tableState.loadingMessage);
            const { results, inlineCount } = await this.auditService.getPermitSpecies({
                page,
                size: pageSize,
                sort,
                filter: this.getActiveFilter()
            }, this.filterVisibility);

            this.data = results;
            this.totalCount = inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } catch (err) {
            throw err;
        } finally {
            this.stopLoading();
        }
    }

    async loadPermitSpeciesLinesAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        try {
            this.setLoadingState(tableState.loadingMessage);
            const { results, inlineCount } = await this.auditService.getPermitSpeciesLines({
                page,
                size: pageSize,
                sort,
                filter: this.getActiveFilter()
            }, this.filterVisibility);

            this.data = results;
            this.totalCount = inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } catch (err) {
            throw err;
        } finally {
            this.stopLoading();
        }
    }

    async loadPermitSpeciesOriginsAuditsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'ModifiedDate desc';

        try {
            this.setLoadingState(tableState.loadingMessage);
            const { results, inlineCount } = await this.auditService.getPermitSpeciesOrigins({
                page,
                size: pageSize,
                sort,
                filter: this.getActiveFilter()
            }, this.filterVisibility);

            this.data = results;
            this.totalCount = inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } catch (err) {
            throw err;
        } finally {
            this.stopLoading();
        }
    }

    openFilter() {
        const dialogRef = this.modalService.open(AuditFilterComponent);
        const component = dialogRef.componentInstance as AuditFilterComponent;

        component.filterSubsection = this.gridView;
        component.filter = this.filter;
        component.onFilter.subscribe((result: any) => {
            this.filter = result.filter;
            this.filterVisibility = result.visibility;
            this.filterApplied = true;
            this.runFilter();
        });
    }

    private getDefaultUpdateTypes(subsection: string): string[] {
        let defaultUpdateTypes = ['Created', 'Modified', 'Deleted'];
        const excludeModified = ['Job Standard Phrases', 'Study Administrator Studies'];
        const excludeDeleted = ['Resources', 'Vocabularies: Mating Types'];

        if (excludeModified.includes(subsection)) {
            defaultUpdateTypes = defaultUpdateTypes.filter((x) => x !== 'Modified');
        }

        if (excludeDeleted.includes(subsection)) {
            defaultUpdateTypes = defaultUpdateTypes.filter((x) => x !== 'Deleted');
        }

        return defaultUpdateTypes;
    }
}
