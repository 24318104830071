<div class="detail-form-wrapper">

    <div class="detail-header">
        <h3>{{'Study' | translate}}</h3>

        <pager-toolbar [itemName]="'line'"
                       [logTag]="COMPONENT_LOG_TAG"
                       [first]="pageState.isFirst"
                       [last]="pageState.isLast"
                       (previous)="previousClicked($event)"
                       (next)="nextClicked($event)"></pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="exitTableClicked($event)"
                        [addStudy]="true"
                        [promptForSave]="false"
                        (saveStudy)="onSaveStudy()">

            <div class="btn-group" role="group" aria-label="Edit actions" additional-groups>
                <button *ngIf="!isGLP" type="button"
                        class="btn btn-secondary"
                        (click)="viewAuditReport()">
                    <i class="fa fa-flag" aria-hidden="true" title="Audit"></i>
                    <span class="gridster-d-sm-none gridster-d-md-inline-block">Audit</span>
                    <span class="sr-only">Audit</span>
                </button>

                <button type="button"
                        class="btn btn-secondary"
                        (click)='exportDetail()'
                        title="Export to CSV">
                    <i class="fa fa-download" aria-hidden="true"></i>
                    <span class="gridster-d-sm-none gridster-d-md-inline-block">Export</span>
                    <span class="sr-only">Export to CSV</span>
                </button>

                <button type="button"
                        class="btn btn-secondary"
                        (click)="printDetail()">
                    <i class="fa fa-print" aria-hidden="true" title="Print"></i>
                    <span class="gridster-d-sm-none gridster-d-md-inline-block">Print</span>
                    <span class="sr-only">Print</span>
                </button>
            </div>
        </detail-toolbar>
    </div>

    <form name="studyForm" id="studyForm"
          class="detail-form"
          #studyForm="ngForm">

        <fieldset>
            <div class="form-group row">
                <div class="offset-md-2 col-md-10">
                    <p class="form-control-plaintext">
                        <lock-control [(model)]="study.IsLocked"
                                      [administrator]="studyAdministrator"
                                      [readonly]="readonly || loading"
                                      [largeText]="true"></lock-control>
                    </p>
                </div>
            </div>
        </fieldset>


        <fieldset [disabled]="readonly || study.IsLocked">

            <div class="form-group row" *ngIf="activeFields.includes('Active')">
                <label class="col-md-2 col-form-label">Active</label>
                <div class="col-md-10">
                    <input type="checkbox"
                           name="isActive"
                           class="mt-1"
                           [(ngModel)]="study.IsActive" />
                </div>
            </div>

            <div class="form-group row"
                 [ngClass]="studyName.hasError('unique') && studyName.dirty ? 'has-warning' : ''">
                <label class="col-md-2 col-form-label">Name</label>
                <div class="col-md-10">
                    <input type="text"
                           name="studyName"
                           #studyName="ngModel"
                           [(ngModel)]="study.StudyName"
                           validateUnique
                           uniqueEntity="Studies"
                           uniqueProperty="StudyName"
                           [entityPK]="study.C_Study_key"
                           entityPKName="C_Study_key"
                           required
                           maxlength="200"
                           class="form-control input-medium"
                           [disabled]="study.ExternalIdentifier" />
                    <!-- TODO Fix: displays warning if value changed than changed back to original -->
                    <small class="form-text text-muted"
                       *ngIf="studyName.errors?.unique && studyName.dirty">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        This {{'Study' | translate}} name already exists.
                    </small>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Type</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="study.C_StudyType_key"
                                         [vocabChoices]="studyTypes"
                                         [keyFormatter]="studyTypeKeyFormatter"
                                         [optionFormatter]="studyTypeFormatter"
                                         [required]="true"></active-vocab-select>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Status')">
                <label class="col-md-2 col-form-label">Status</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="study.C_StudyStatus_key"
                                         [vocabChoices]="studyStatuses"
                                         [keyFormatter]="studyStatusKeyFormatter"
                                         [optionFormatter]="studyStatusFormatter"
                                         [required]="requiredFields.includes('C_StudyStatus_key')"
                                         [nullable]="!requiredFields.includes('C_StudyStatus_key')"></active-vocab-select>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Approval Number')">
                <label class="col-md-2 col-form-label">Approval Number</label>
                <div class="col-md-10">
                    <input type="text"
                           name="approvalNumber"
                           [(ngModel)]="study.ApprovalNumber"
                           maxlength="100"
                           class="form-control input-medium"
                           [required]="requiredFields.includes('ApprovalNumber')"/>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Description')">
                <label class="col-md-2 col-form-label">Description</label>
                <div class="col-md-10">
                    <textarea [(ngModel)]="study.Description"
                              name="studyDescription"
                              rows="5"
                              class="form-control input-medium"
                              [required]="requiredFields.includes('Description')"></textarea>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Comments')">
                <label class="col-md-2 col-form-label">Comments</label>
                <div class="col-md-10">
                    <textarea [(ngModel)]="study.Comments"
                              name="studyComments"
                              rows="5"
                              class="form-control input-medium"
                              [required]="requiredFields.includes('Comments')"></textarea>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Total Animals')">
                <label class="col-md-2 col-form-label">Total Animals</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">
                        {{study.totalAnimalCount}}
                    </p>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Available Animals')">
                <label class="col-md-2 col-form-label">Available Animals</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">
                        {{study.availableAnimalCount}}
                    </p>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Created</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">
                        {{study.CreatedBy | userFullName}}
                        &middot;
                        {{study.DateCreated | formatShortDate}}
                    </p>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Lines')">
                <label class="col-md-2 col-form-label"
                       [ngClass]="{'required-text' : requiredFields.includes('StudyLine[0]') && study.StudyLine.length === 0}">
                    {{'Lines' | translate}}
                </label>
                <div class="col-md-10">
                    <table class="table table-borderless">
                        <thead>
                            <tr>
                                <th class="icon-cell"
                                    *ngIf="!readonly && !study.IsLocked">
                                    <a class="add-item-link" (click)="addLine()">
                                        <i class="fa fa-plus-circle" title="Add {{'Line' | translate}}"></i>
                                    </a>
                                </th>
                                <th>{{'Line' | translate}}</th>
                                <th>Animal Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let studyLine of study.StudyLine; let index = index; trackBy: trackLineRow">
                                <td class="icon-cell"
                                    *ngIf="!readonly && !study.IsLocked">
                                    <div class="form-control-plaintext">
                                        <a (click)="removeStudyLine(studyLine)">
                                            <i class="fa fa-remove remove-item" title="Remove {{'Line' | translate}}"></i>
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <line-select [(model)]="studyLine.C_Line_key"
                                                 [required]="true"
                                                 [disabled]="readonly || study.IsLocked"
                                                 (modelChange)="onLineChange()"></line-select>
                                </td>
                                <td>
                                    <input type="number"
                                           [(ngModel)]="studyLine.AnimalCount"
                                           name="studyLineAnimalCount_{{index}}"
                                           class="form-control input-small">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <characteristic-instance-wrapper
                                          [model]="study"
                                          [signalValue]="study.cv_StudyType"
                                          [characteristicType]="'study'"
                                          [isLoading]="loading">
            </characteristic-instance-wrapper>

        </fieldset>

        <!-- New fieldset that is never disabled so as not to affect controls in file upload -->
        <fieldset>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Files</label>
                <div class="col-md-10">
                    <div class="form-control-plaintext">
                        <facet-detail-file-upload [pkName]="'C_Study_key'"
                                                  [pkValue]="study.C_Study_key"
                                                  [facetPrivilege]="facet.Privilege"></facet-detail-file-upload>
                    </div>
                </div>
            </div>
        </fieldset>
    </form>

</div>

<div class="d-none" id="{{printPreviewId}}">
    <study-print-preview [study]="study"></study-print-preview>
</div>
