<div class="detail-form-wrapper">

    <div class="detail-header">
        <h3>{{'Line' | translate}}</h3>

        <pager-toolbar [itemName]="'line'"
                       [logTag]="COMPONENT_LOG_TAG"
                       [first]="pageState.isFirst"
                       [last]="pageState.isLast"
                       (previous)="previousClicked($event)"
                       (next)="nextClicked($event)"></pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="exitClicked($event)">

            <div class="btn-group" role="group" aria-label="Edit actions" additional-groups>

                <!-- Enclose Notes in btn-group b/c NG component tag messes up Bootstrap -->
                <div class="btn-group" view-notes-button
                    [readonly]="facet.Privilege==='ReadOnly'"
                    [pkName]="'C_Line_key'"
                    [pkValue]="line.C_Line_key"
                    [objectName]="line.LineName"
                    [objectType]="'Line'"
                    [isObjectNew]="line | isNew"
                    appInsightsClick="click-notes-from-line">
                </div>

                <button type="button"
                        class="btn btn-secondary"
                        (click)='exportDetail()'
                        title="Export to CSV">
                    <i class="fa fa-download" aria-hidden="true"></i>
                    <span class="gridster-d-sm-none gridster-d-md-inline-block">Export</span>
                    <span class="sr-only">Export to CSV</span>
                </button>

                <button type="button"
                        class="btn btn-secondary"
                        (click)="printDetail()">
                    <i class="fa fa-print" aria-hidden="true" title="Print"></i>
                    <span class="gridster-d-sm-none gridster-d-md-inline-block">Print</span>
                    <span class="sr-only">Print</span>
                </button>
            </div>
        </detail-toolbar>
    </div>

    <form *ngIf="line"
          name="lineForm" id="lineForm"
          class="detail-form"
          #lineForm="ngForm">
        <fieldset [disabled]="facet.Privilege === 'ReadOnly'">

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Active</label>
                <div class="col-md-9">
                    <input type="checkbox"
                           name="isActive"
                           class="mt-1"
                           [(ngModel)]="line.IsActive" />
                </div>
            </div>

            <div class="form-group row"
                 [ngClass]="lineName.hasError('unique') && lineName.dirty ? 'has-warning' : ''">
                <label class="col-md-3 col-form-label">Name</label>
                <div class="col-md-9">
                    <input data-automation-id="line-name-input"
                           type="text"
                           name="lineName"
                           #lineName="ngModel"
                           [(ngModel)]="line.LineName"
                           validateUnique
                           uniqueEntity="Lines"
                           uniqueProperty="LineName"
                           [entityPK]="line.C_Line_key"
                           entityPKName="C_Line_key"
                           required
                           maxlength="200"
                           class="form-control input-medium" />
                    <!-- TODO Fix: displays warning if value changed than changed back to original -->
                    <small class="form-text text-muted"
                       *ngIf="lineName.errors?.unique && lineName.dirty">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        This {{'Line' | translate}} name already exists.
                    </small>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">{{'LineShortName' | translate}}</label>
                <div class="col-md-9">
                    <input type="text"
                           name="stockNumber"
                           [(ngModel)]="line.StockNumber"
                           maxlength="62"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Stock</label>
                <div class="col-md-9">
                    <input type="text"
                           name="stockID"
                           [(ngModel)]="line.StockID"
                           maxlength="10"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Type</label>
                <div class="col-md-9">
                    <active-vocab-select [(model)]="line.C_LineType_key"
                                         [vocabChoices]="lineTypes"
                                         [keyFormatter]="lineTypeKeyFormatter"
                                         [optionFormatter]="lineTypeFormatter"
                                         [required]="true"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Status</label>
                <div class="col-md-9">
                    <active-vocab-select [(model)]="line.C_LineStatus_key"
                                         [vocabChoices]="lineStatuses"
                                         [keyFormatter]="lineStatusKeyFormatter"
                                         [optionFormatter]="lineStatusFormatter"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Species</label>
                <div class="col-md-9">
                    <select data-automation-id="taxon-dropdown"
                            name="taxon"
                            [(ngModel)]="line.C_Taxon_key"
                            required
                            class="form-control input-medium">
                        <option></option>
                        <option [attr.data-automation-id]="item.CommonName | dataAutomationId: '': '-item'"
                                *ngFor="let item of taxons"
                                [value]="item.C_Taxon_key">
                            {{item.CommonName}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Construct</label>
                <div class="col-md-9">
                    <select name="construct"
                            [(ngModel)]="line.C_Construct_key"
                            class="form-control input-medium">
                        <option></option>
                        <option *ngFor="let item of constructs"
                                [value]="item.C_Construct_key">
                            {{item.FullName}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Genotype Assays</label>
                <div class="col-md-9">
                    <line-genotype-assay-select [line]="line" [readonly]="facet.Privilege === 'ReadOnly'"></line-genotype-assay-select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3 col-form-label">Technician</label>
                <div class="col-md-9">
                    <user-select [(model)]="line.Technician" [forceValidSelection]="false"></user-select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Parent {{'Line' | translate}}</label>
                <div class="col-md-9">
                    <input type="text"
                           name="parentLine"
                           [(ngModel)]="line.ParentLine"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Background {{'Line' | translate}}</label>
                <div class="col-md-9">
                    <input type="text"
                           name="backgroundLine"
                           [(ngModel)]="line.BackgroundLine"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Breeding Strategy</label>
                <div class="col-md-9">
                    <input type="text"
                           name="breedingStrategy"
                           [(ngModel)]="line.BreedingStrategy"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Development</label>
                <div class="col-md-9">
                    <input type="text"
                           name="development"
                           [(ngModel)]="line.Development"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">External Link</label>
                <div class="col-md-9">
                    <input type="text"
                           name="externalLink"
                           [(ngModel)]="line.ExternalLink"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">References</label>
                <div class="col-md-9">
                    <input type="text"
                           name="reference"
                           [(ngModel)]="line.Reference"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Comment</label>
                <div class="col-md-9">
                    <input type="text"
                           name="comment"
                           [(ngModel)]="line.Comment"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Default Location</label>
                <div class="col-md-9">
                    <ng-container *ngIf="facet.Privilege !== 'ReadOnly'">
                        <p class="form-control-plaintext"
                           *ngIf="line.LocationPosition === null">
                            <a class="add-item-link"
                               (click)="addLocation()">
                                <i class="fa fa-plus-circle" title="Add default location"></i>
                            </a>
                        </p>
                        <a style="margin-right:.5em;"
                           *ngIf="line.LocationPosition !== null"
                           (click)="clearLocation()"><i class="fa fa-remove remove-item" title="Remove location"></i></a>
                    </ng-container>

                    <location-chooser [(location)]="line.LocationPosition"
                                      [required]="false"
                                      [readonly]="facet.Privilege === 'ReadOnly'"></location-chooser>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Files</label>
                <div class="col-md-9">
                    <div class="form-control-plaintext">
                        <facet-detail-file-upload [pkName]="'C_Line_key'"
                                                  [pkValue]="line.C_Line_key"
                                                  [facetPrivilege]="facet.Privilege"></facet-detail-file-upload>
                    </div>
                </div>
            </div>
        </fieldset>

        <!-- New fieldset that is never disabled so as not to affect controls in child tables -->
        <fieldset>
            <div class="form-group row">
                <div class="col-md-12">
                    <detail-task-table #detailTaskTable
                                       [parent]="line"
                                       [taskAssociations]="line.TaskLine"
                                       [taskType]="TaskType.Line"
                                       [readonly]="facet.Privilege === 'ReadOnly'"
                                       [facet]="facet"
                                       [tableOptions]="detailTaskTableOptions"
                                       [tableSort]="taskTableSort"
                                       [(inputsExpanded)]="line.inputsExpanded"
                                       [(tasksExpanded)]="line.tasksExpanded"
                                       [(taskAnimalsExpanded)]="line.taskAnimalsExpanded"
                                       [(taskAnimalsLinesExpanded)]="line.taskAnimalsLinesExpanded"
                                       [(taskCohortsExpanded)]="line.taskCohortsExpanded"
                                       [(taskSamplesExpanded)]="line.taskSamplesExpanded"
                                       (taskInstanceAdd)="addTaskLine($event)"></detail-task-table>
                </div>
            </div>
        </fieldset>
    </form>

    <div id="exportLineTableDiv" class="d-none">
        <table id="exportLineTable" class="printTable">
            <tr>
                <td><b>{{'Line' | translate}} Name</b></td>
                <td>{{line.LineName}}</td>
            </tr>
            <tr>
                <td><b>{{'LineShortName' | translate}}</b></td>
                <td>{{line.StockNumber}}</td>
            </tr>
            <tr>
                <td><b>Stock</b></td>
                <td>{{line.StockID}}</td>
            </tr>
            <tr>
                <td><b>{{'Line' | translate}} Type</b></td>
                <td>{{line.cv_LineType?.LineType}}</td>
            </tr>
            <tr>
                <td><b>{{'Line' | translate}} Status</b></td>
                <td>{{line.cv_LineStatus?.LineStatus}}</td>
            </tr>
            <tr>
                <td><b>Species</b></td>
                <td>{{line.cv_Taxon?.CommonName}}</td>
            </tr>
            <tr>
                <td><b>Construct</b></td>
                <td>{{line.Construct?.FullName}}</td>
            </tr>
            <tr>
                <td><b>Genotype Assays</b></td>
                <td>
                    <ng-container *ngIf="line.LineGenotypeAssay | notEmpty">
                        <span *ngFor="let lineGenotypeAssay of line.LineGenotypeAssay | orderBy:'SortOrder'; let last = last">
                            {{lineGenotypeAssay?.cv_GenotypeAssay.GenotypeAssay}}<span *ngIf="!last">,</span>
                        </span>
                    </ng-container>
                </td>
            </tr>
            <tr>
                <td><b>Technician</b></td>
                <td>{{line.Technician}}</td>
            </tr>
            <tr>
                <td><b>Parent {{'Line' | translate}}</b></td>
                <td>{{line.ParentLine}}</td>
            </tr>
            <tr>
                <td><b>Background {{'Line' | translate}}</b></td>
                <td>{{line.BackgroundLine}}</td>
            </tr>
            <tr>
                <td><b>Breeding Strategy</b></td>
                <td>{{line.BreedingStrategy}}</td>
            </tr>
            <tr>
                <td><b>Development</b></td>
                <td>{{line.Development}}</td>
            </tr>
            <tr>
                <td><b>External Link</b></td>
                <td>{{line.ExternalLink}}</td>
            </tr>
            <tr>
                <td><b>References</b></td>
                <td>{{line.Reference}}</td>
            </tr>
            <tr>
                <td><b>Comment</b></td>
                <td>{{line.Comment}}</td>
            </tr>
            <tr>
                <td><b>Default Location</b></td>
                <td>{{line.LocationPosition?.PositionName}}</td>
            </tr>
            <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
            <ng-container *ngFor="let task of line.TaskLine |
                    orderBy:taskTableSort.propertyPath:taskTableSort.reverse">
                <ng-container *ngIf="task?.TaskInstance">
                    <tr>
                        <td><b>Task Name</b></td>
                        <td>{{task.TaskInstance.TaskAlias}}</td>
                    </tr>
                    <tr>
                        <td><b>Task</b></td>
                        <td>{{task.TaskInstance.WorkflowTask?.TaskName}}</td>
                    </tr>
                    <tr>
                        <td><b>{{'Protocol' | translate}}</b></td>
                        <td> {{task.TaskInstance.ProtocolTask?.Protocol?.ProtocolName}}</td>
                    </tr>
                    <tr>
                        <td><b>Sequence</b></td>
                        <td>{{task.Sequence}}</td>
                    </tr>
                    <tr>
                        <td><b>Due Date</b></td>
                        <td>{{task.TaskInstance.DateDue | formatShortDate}}</td>
                    </tr>
                    <tr>
                        <td><b>Deviation</b></td>
                        <td>{{task.TaskInstance.Deviation}}</td>
                    </tr>
                    <tr>
                        <td><b>Task Location</b></td>
                        <td>{{task.TaskInstance.CurrentLocationPath}}</td>
                    </tr>
                    <tr>
                        <td><b>Deviation</b></td>
                        <td>{{task.TaskInstance.Deviation}}</td>
                    </tr>
                    <tr>
                        <td><b>Task Location</b></td>
                        <td>{{task.TaskInstance.CurrentLocationPath}}</td>
                    </tr>
                    <tr>
                        <td><b>Status</b></td>
                        <td>{{task.TaskInstance.cv_TaskStatus?.TaskStatus}}</td>
                    </tr>
                    <tr>
                        <td><b>Assigned To</b></td>
                        <td>{{task.TaskInstance.AssignedToResource?.ResourceName}}</td>
                    </tr>
                    <tr>
                        <td><b>Cost</b></td>
                        <td>{{task.TaskInstance.Cost}}</td>
                    </tr>
                    <tr>
                        <td><b>Duration</b></td>
                        <td>{{task.TaskInstance.Duration}}</td>
                    </tr>
                    <tr>
                        <td><b>Cohorts</b></td>
                    </tr>
                    <tr *ngFor="let cohort of task.TaskInstance.DerivedCohorts">
                        <ng-container *ngIf="cohort">
                            <td>
                                &nbsp;
                            </td>
                            <td>
                                {{cohort.CohortName}}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td><b>Animals</b></td>
                    </tr>
                    <tr *ngFor="let animal of task.TaskInstance.FilteredAnimalMaterials">
                        <ng-container *ngIf="animal.Material?.Animal">
                            <td>
                                &nbsp;
                            </td>
                            <td>
                                {{animal.Material?.Animal.AnimalName}}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td><b>Samples</b></td>
                    </tr>
                    <tr *ngFor="let sample of task.TaskInstance.FilteredSampleMaterials">
                        <ng-container *ngIf="sample.Material?.Sample">
                            <td>
                                &nbsp;
                            </td>
                            <td>
                                {{sample.Material?.Sample.SampleName}}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td><b>Inputs</b></td>
                    </tr>
                    <tr *ngFor="let taskInput of task.TaskInstance.TaskInput | orderBy:'Input.SortOrder'">
                        <td>
                            &nbsp;
                        </td>
                        <td>
                            {{taskInput.Input?.InputName}}
                        </td>
                        <td>
                            {{taskInput.InputValue}}
                        </td>
                    </tr>
                    <tr>
                        <td><b>Outputs</b></td>
                    </tr>
                    <tr *ngFor="let taskOutputSet of task.TaskInstance.TaskOutputSet">
                        <td>&nbsp;</td>
                        <td>
                            <div *ngFor="let animal of taskOutputSet.TaskOutputSetMaterial">
                                {{animal.Material?.Animal?.AnimalName}}
                            </div>
                        </td>
                        <ng-container *ngFor="let taskOutput of taskOutputSet.TaskOutput">
                            <td>
                                {{taskOutput.Output?.OutputName}}
                            </td>
                            <td>
                                {{taskOutput.OutputValue}}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                </ng-container>
            </ng-container>
            <tr *ngIf="line.TaskLine | notEmpty">
                <td colspan="2"><b>Total Tasks: {{line.TaskLine.length}}</b></td>
            </tr>
        </table>
    </div><!-- /#exportLineTableDiv -->

</div>
