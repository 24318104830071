import { Inject, Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EVENTS_TOKEN, Events } from '../mf-store';

@Injectable({
    providedIn: 'root'
})
export class ModernAppService {
    private readonly APP_NAME = 'climbWeb';
    private container: {
        init(value: unknown): Promise<void>
        get<T = unknown>(value: string): Promise<() => T>
    }

  private fetched = false;

  private initialized$$ = new BehaviorSubject<boolean>(false);

  initialized$ = this.initialized$$.asObservable();

  facets$$ = new BehaviorSubject<ModernFacet[]>([]);
  
  public facets$ = this.facets$$.asObservable();

    components$$ = new BehaviorSubject<Partial<Record<ModernComponentKeys, ModernComponent>>>({});

    public components$ = this.components$$.asObservable();

    get facets(): ModernFacet[] {
        return this.facets$$.value;
    }

    constructor(
        private ngZone: NgZone,
        @Inject(EVENTS_TOKEN)
        private events: Events,
    ) {}

  init(): void {
    this.ngZone.runOutsideAngular(() => {
      if (this.fetched) return;

      this.loadApp()
        .then(() => this.initApp())
        .then(() => this.loadModule<{ bootstrap: () => void }>('./bootstrap'))
        .then(({ bootstrap }) => bootstrap())
        .then(() => this.loadModule<{
          FACETS: Record<string, ModernFacet>;
          COMPONENTS: Partial<Record<ModernComponentKeys, ModernComponent>>;
        }>('./components'))
        .then(({ FACETS, COMPONENTS }) => {
          this.facets$$.next(Object.values(FACETS ?? {}));
          this.components$$.next(COMPONENTS);
        })
        .then(() => this.fetched = true)
        .catch(console.error)
        .finally(() => this.initialized$$.next(true));
    });
  }

    openComponent(elementId: string, componentId: string, facetId?: string,): void {
        this.ngZone.runOutsideAngular(() => {
            this.events.openComponent.emit({ elementId, componentId, facetId });
        });
    }

  private loadApp() {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      const newFacetsUrl = window['newFacetsUrl'];
      if (!newFacetsUrl) {
        reject(new Error('newFacetsUrl is not provided'));
        return;
      }
      script.src = `${newFacetsUrl}remoteEntry.js`;
      script.onload = () => {
        resolve({
          get: (request: unknown) => window[this.APP_NAME].get(request),
          init: (arg: unknown) => {
            try {
              return window[this.APP_NAME].init(arg);
            } catch (e) {
              console.log("climbWeb has already been loaded");
            }
          },
        })
      };
      script.onerror = () => {
        reject(new Error(`Error loading of ${newFacetsUrl}`));
      };

            document.body.append(script);
        });
    }

    private async initApp() {
        await __webpack_init_sharing__("default");
        this.container = window[this.APP_NAME]; // or get the container somewhere else
        // Initialize the container, it may provide shared modules
        await this.container.init(__webpack_share_scopes__.default);
    }

    private async loadModule<T = unknown>(moduleName: string) {
        const module = await this.container.get<T>(moduleName);
        return module();
    }
}
