
<ng-template #animalsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Animal Name</label>
        <div class="col-md-8">
            <animal-multiselect [(model)]="filter.Animals"></animal-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #animalIdentifiersTmpl>
    <div class="form-group row">
        <div class="col-md-4">
            <label class="col-form-label" style="display: block;">Animal ID</label>
            <div class="text-right">
                <one-many-toggle #animalIdentifierToggle></one-many-toggle>
            </div>
        </div>
        <div class="col-md-8">
            <multi-control-wrap [toggleComponent]="animalIdentifierToggle"
                                [(singleModel)]="filter.AnimalIdentifier"
                                [(manyModel)]="filter.AnimalIdentifiers">
                <multi-control name="single">
                    <input type="text" name="animalIdentifier"
                           [(ngModel)]="filter.AnimalIdentifier"
                           class="form-control input-medium"
                           data-auto-focus="animalIdentifier" />
                    <small class="form-text text-muted">
                        Find IDs <em>containing</em> this value.
                    </small>
                </multi-control>
                <multi-control name="many">
                    <multi-paste-input [(model)]="filter.AnimalIdentifiers"
                                       [limit]="MULTI_PASTE_INPUT_LIMIT"
                                       [rows]="3"></multi-paste-input>
                    <small class="form-text text-muted">
                        IDs separated by
                        <code>,</code> or <code>Enter</code>.
                        Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                    </small>
                </multi-control>
            </multi-control-wrap>
        </div>
    </div>
</ng-template>

<ng-template #microchipIdentifiersTmpl>
    <div class="form-group row">
        <div class="col-md-4">
            <label class="col-form-label" style="display: block;">Microchip ID</label>
            <div class="text-right">
                <one-many-toggle #microchipIdentifierToggle></one-many-toggle>
            </div>
        </div>
        <div class="col-md-8">
            <multi-control-wrap [toggleComponent]="microchipIdentifierToggle"
                                [(singleModel)]="filter.MicrochipIdentifier"
                                [(manyModel)]="filter.MicrochipIdentifiers">
                <multi-control name="single">
                    <input type="text" name="microchipIdentifier"
                           [(ngModel)]="filter.MicrochipIdentifier"
                           class="form-control input-medium"
                           data-auto-focus="microchipIdentifier" />
                    <small class="form-text text-muted">
                        Find Microchip IDs <em>containing</em> this value.
                    </small>
                </multi-control>
                <multi-control name="many">
                    <multi-paste-input [(model)]="filter.MicrochipIdentifiers"
                                       [limit]="MULTI_PASTE_INPUT_LIMIT"
                                       [rows]="3"></multi-paste-input>
                    <small class="form-text text-muted">
                        Microchip IDs separated by
                        <code>,</code> or <code>Enter</code>.
                        Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                    </small>
                </multi-control>
            </multi-control-wrap>
        </div>
    </div>
</ng-template>

<ng-template #animalCommentsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Comment</label>
        <div class="col-md-8">
            <input type="text" name="AnimalComments"
                   [(ngModel)]="filter.AnimalComments"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Comments <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #animalCommentStatusesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Status</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.AnimalCommentStatuses"
                                [values]="animalCommentStatuses"
                                [keyProperty]="'AnimalCommentStatus'"
                                [valueProperty]="'AnimalCommentStatus'"
                                [placeholder]="'Select status...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #animalCommentAnimalNamesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Animal Name</label>
        <div class="col-md-8">
            <animal-multiselect [(model)]="filter.animalCommentAnimalNames"></animal-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #animalCommentAnimalIDsTmpl>
    <div class="form-group row">
        <div class="col-md-4">
            <label class="col-form-label" style="display: block;">Animal ID</label>
            <div class="text-right">
                <one-many-toggle #animalIdentifierToggle></one-many-toggle>
            </div>
        </div>
        <div class="col-md-8">
            <multi-control-wrap [toggleComponent]="animalIdentifierToggle"
                                [(singleModel)]="filter.AnimalCommentID"
                                [(manyModel)]="filter.AnimalCommentIDs">
                <multi-control name="single">
                    <input type="text" name="AnimalCommentID"
                           [(ngModel)]="filter.AnimalCommentID"
                           class="form-control input-medium"
                           data-auto-focus="AnimalCommentID" />
                    <small class="form-text text-muted">
                        Find IDs <em>containing</em> this value.
                    </small>
                </multi-control>
                <multi-control name="many">
                    <multi-paste-input [(model)]="filter.AnimalCommentIDs"
                                       [limit]="MULTI_PASTE_INPUT_LIMIT"
                                       [rows]="3"></multi-paste-input>
                    <small class="form-text text-muted">
                        IDs separated by
                        <code>,</code> or <code>Enter</code>.
                        Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                    </small>
                </multi-control>
            </multi-control-wrap>
        </div>
    </div>
</ng-template>

<ng-template #samplesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Sample Name</label>
        <div class="col-md-8">
            <sample-multiselect [(model)]="filter.Samples"></sample-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #sampleIdentifiersTmpl>
    <div class="form-group row">
        <div class="col-md-4">
            <label class="col-form-label" style="display: block;">Sample ID</label>
            <div class="text-right">
                <one-many-toggle #sampleIdentifierToggle></one-many-toggle>
            </div>
        </div>
        <div class="col-md-8">
            <multi-control-wrap [toggleComponent]="sampleIdentifierToggle"
                                [(singleModel)]="filter.SampleIdentifier"
                                [(manyModel)]="filter.SampleIdentifiers">
                <multi-control name="single">
                    <input type="text" name="sampleIdentifier"
                           [(ngModel)]="filter.SampleIdentifier"
                           class="form-control input-medium"
                           data-auto-focus="sampleIdentifier" />
                    <small class="form-text text-muted">
                        Find IDs <em>containing</em> this value.
                    </small>
                </multi-control>
                <multi-control name="many">
                    <multi-paste-input [(model)]="filter.SampleIdentifiers"
                                       [limit]="MULTI_PASTE_INPUT_LIMIT"
                                       [rows]="3"></multi-paste-input>
                    <small class="form-text text-muted">
                        IDs separated by
                        <code>,</code> or <code>Enter</code>.
                        Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                    </small>
                </multi-control>
            </multi-control-wrap>
        </div>
    </div>
</ng-template>

<ng-template #sourceMaterialsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Source</label>
        <div class="col-md-8">
            <source-material-multiselect [(model)]="filter.SourceMaterials"></source-material-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #sourceIdentifiersTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Source IDs</label>
        <div class="col-md-8">
            <multi-paste-input [(model)]="filter.SourceIdentifiers"
                               [limit]="MULTI_PASTE_INPUT_LIMIT"
                               [rows]="3"></multi-paste-input>
            <small class="form-text text-muted">
                Source IDs separated by
                <code>,</code> or <code>Enter</code>.
                Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #jobsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{'Job' | translate}} Name</label>
        <div class="col-md-8">
            <job-multiselect [(model)]="filter.Jobs"></job-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #studiesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{ 'Study' | translate }} Name</label>
        <div class="col-md-8">
            <study-multiselect [(model)]="filter.Studies">
            </study-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #jobStatusesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{'Job' | translate}} Status</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.JobStatuses"
                                [values]="jobStatuses"
                                [keyProperty]="'JobStatus'"
                                [valueProperty]="'JobStatus'"
                                [placeholder]="'Select status...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #jobTypesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{'Job' | translate}} Type</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.JobTypes"
                                [values]="jobTypes"
                                [keyProperty]="'JobType'"
                                [valueProperty]="'JobType'"
                                [placeholder]="'Select type...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #jobSubtypesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{'Job' | translate}} Subtype</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.JobSubtypes"
                                [values]="jobSubtypes"
                                [keyProperty]="'JobSubtype'"
                                [valueProperty]="'JobSubtype'"
                                [placeholder]="'Select subtype...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #workflowTaskKeysTmpl let-label="label">
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{ label || 'Task'}}</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.WorkflowTaskKeys"
                                [values]="workflowTasks"
                                [keyProperty]="'C_WorkflowTask_key'"
                                [valueProperty]="'TaskName'"
                                [placeholder]="'Select Tasks...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #taskNamesTmpl let-label="label">
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{ label || 'Task Name' }}</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.TaskNames"
                                [values]="taskAliases"
                                [keyProperty]="'TaskAlias'"
                                [valueProperty]="'TaskAlias'"
                                [placeholder]="'Select Task names...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #protocolTaskNamesTmpl let-label="label">
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{ label || 'Task Name' }}</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ProtocolTaskNames"
                                [values]="protocolTaskAliases"
                                [keyProperty]="'TaskAlias'"
                                [valueProperty]="'TaskAlias'"
                                [placeholder]="'Select Task names...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #taskStatusesTmpl let-label="label">
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{ label || 'Task Status' }}</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.TaskStatuses"
                                [values]="taskStatuses"
                                [keyProperty]="'TaskStatus'"
                                [valueProperty]="'TaskStatus'"
                                [placeholder]="'Select status...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #dueDateRangeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Due Date Range</label>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Due Date Start"
                            [(ngModel)]="filter.DueDateStart"
                            placeholder="From&hellip;"></climb-ngb-date>
        </div>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Due Date End"
                            [(ngModel)]="filter.DueDateEnd"
                            placeholder="To&hellip;"></climb-ngb-date>
        </div>
    </div>
</ng-template>

<ng-template #linesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{'Line' | translate}}</label>
        <div class="col-md-8">
            <line-multiselect [(model)]="filter.Lines"></line-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #taxonsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Species</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.Taxons"
                                [values]="taxons"
                                [keyProperty]="'CommonName'"
                                [valueProperty]="'CommonName'"
                                [placeholder]="'Select Species...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #groupsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Group</label>
        <div class="col-md-8">
            <multi-paste-input [(model)]="filter.Groups"
                               [limit]="MULTI_PASTE_INPUT_LIMIT"
                               [rows]="3"></multi-paste-input>
            <small class="form-text text-muted">
                Groups separated by
                <code>,</code> or <code>Enter</code>.
                Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #testArticlesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Test Article</label>
        <div class="col-md-8">
            <test-article-multiselect [(model)]="filter.TestArticles"></test-article-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #jobCharacteristicsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Characteristic</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.JobCharacteristics"
                                [values]="jobCharacteristics"
                                [keyProperty]="'CharacteristicName'"
                                [valueProperty]="'CharacteristicName'"
                                [placeholder]="'Select Characteristic...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #institutionsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{'Institution' | translate}}</label>
        <div class="col-md-8">
            <institution-multiselect [(model)]="filter.Institutions"></institution-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #animalPlaceholdersTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Individual Placeholder</label>
        <div class="col-md-8">
            <animal-placeholder-multiselect [(model)]="filter.AnimalPlaceholders"></animal-placeholder-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #groupPlaceholdersTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Group Placeholder</label>
        <div class="col-md-8">
            <group-placeholder-multiselect [(model)]="filter.GroupPlaceholders"></group-placeholder-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #cohortsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Cohort Name</label>
        <div class="col-md-8">
            <cohort-multiselect [(model)]="filter.Cohorts"></cohort-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #housingsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Housing ID</label>
        <div class="col-md-8">
            <housing-multiselect [(model)]="filter.Housings"></housing-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #materialPoolTypesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Housing Type</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.MaterialPoolTypes"
                                [values]="materialPoolTypes"
                                [keyProperty]="'MaterialPoolType'"
                                [valueProperty]="'MaterialPoolType'"
                                [placeholder]="'Select type...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #materialPoolStatusesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Housing Status</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.MaterialPoolStatuses"
                                [values]="materialPoolStatuses"
                                [keyProperty]="'MaterialPoolStatus'"
                                [valueProperty]="'MaterialPoolStatus'"
                                [placeholder]="'Select status...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #animalStatusesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Animal Status</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.AnimalStatuses"
                                [values]="animalStatuses"
                                [keyProperty]="'AnimalStatus'"
                                [valueProperty]="'AnimalStatus'"
                                [placeholder]="'Select Status...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #housingDateRangeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Housing Date Range</label>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Housing Date Start"
                            [(ngModel)]="filter.HousingDateStart"
                            placeholder="From&hellip;"></climb-ngb-date>
        </div>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Housing Date End"
                            [(ngModel)]="filter.HousingDateEnd"
                            placeholder="To&hellip;"></climb-ngb-date>
        </div>
    </div>
</ng-template>

<ng-template #matingsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Mating ID</label>
        <div class="col-md-8">
            <mating-multiselect [(model)]="filter.Matings"></mating-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #matingStatusesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Mating Status</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.MatingStatuses"
                                [values]="matingStatuses"
                                [keyProperty]="'MatingStatus'"
                                [valueProperty]="'MatingStatus'"
                                [placeholder]="'Select status...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #animalMatingStatusesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Animal Mating Status</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.AnimalMatingStatuses"
                                [values]="animalMatingStatuses"
                                [keyProperty]="'AnimalMatingStatus'"
                                [valueProperty]="'AnimalMatingStatus'"
                                [placeholder]="'Select Status...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #matingTypesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Mating Type</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.MatingTypes"
                                [values]="matingTypes"
                                [keyProperty]="'MatingType'"
                                [valueProperty]="'MatingType'"
                                [placeholder]="'Select type...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #matingDateRangeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Mating Date Range</label>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Mating Date Start"
                            [(ngModel)]="filter.MatingDateStart"
                            placeholder="From&hellip;"></climb-ngb-date>
        </div>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Mating Date End"
                            [(ngModel)]="filter.MatingDateEnd"
                            placeholder="To&hellip;"></climb-ngb-date>
        </div>
    </div>
</ng-template>

<ng-template #lineShortNamesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{'LineShortName' | translate}}</label>
        <div class="col-md-8">
            <stock-number-multiselect [(model)]="filter.LineShortNames"></stock-number-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #studyTypesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{ 'Study' | translate }} Type</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.StudyTypes"
                                [values]="studyTypes"
                                [keyProperty]="'StudyType'"
                                [valueProperty]="'StudyType'"
                                [placeholder]="'Select type...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #studyStatusesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{ 'Study' | translate }} Status</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.StudyStatuses"
                                [values]="studyStatuses"
                                [keyProperty]="'StudyStatus'"
                                [valueProperty]="'StudyStatus'"
                                [placeholder]="'Select status...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #birthsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Birth ID</label>
        <div class="col-md-8">
            <birth-multiselect [(model)]="filter.Births"></birth-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #birthStatusesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Birth Status</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.BirthStatuses"
                                [values]="birthStatuses"
                                [keyProperty]="'BirthStatus'"
                                [valueProperty]="'BirthStatus'"
                                [placeholder]="'Select status...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #locationsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Location Name</label>
        <div class="col-md-8">
            <location-multiselect [(model)]="filter.Locations"></location-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #locationTypesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Location Type</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.LocationTypes"
                                [values]="locationTypes"
                                [keyProperty]="'LocationType'"
                                [valueProperty]="'LocationType'"
                                [placeholder]="'Select Types...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #parentLocationsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Parent Location</label>
        <div class="col-md-8">
            <location-multiselect [(model)]="filter.ParentLocations"></location-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #taskTypesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Task Type</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.TaskTypes"
                                [values]="taskTypes"
                                [keyProperty]="'TaskType'"
                                [valueProperty]="'TaskType'"
                                [placeholder]="'Select Types...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #protocolsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Protocol Name</label>
        <div class="col-md-8">
            <protocol-multiselect [(model)]="filter.Protocols"></protocol-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #resourceNameTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Resource Name</label>
        <div class="col-md-8">
            <input type="text" name="ResourceName"
                   [(ngModel)]="filter.ResourceName"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Names <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeResourceTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveResources"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #isEndStateTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">End State</label>
        <div class="col-md-8">
            <input type="checkbox"
                   name="isEndState"
                   [(ngModel)]="filter.IsEndState" />
        </div>
    </div>
</ng-template>


<ng-template #isDefaultTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <input type="checkbox"
                   name="isDefault"
                   [(ngModel)]="filter.IsDefault" />
        </div>
    </div>
</ng-template>

<ng-template #isDefaultClinicalStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default Clinical Status</label>
        <div class="col-md-8">
            <input type="checkbox"
                   name="isDefaultClinicalStatus"
                   [(ngModel)]="filter.IsDefaultClinicalStatus" />
        </div>
    </div>
</ng-template>

<ng-template #emailAddressTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">E-mail Address</label>
        <div class="col-md-8">
            <input type="text" name="emailAddress"
                   [(ngModel)]="filter.EmailAddress"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find e-mail addresses <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #resourceTypesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Resource Type</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ResourceTypes"
                                [values]="resourceTypes"
                                [keyProperty]="'ResourceType'"
                                [valueProperty]="'ResourceType'"
                                [placeholder]="'Select Types...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #usernamesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Climb ID</label>
        <div class="col-md-8">
            <user-multiselect [(model)]="filter.Usernames" placeholder="Climb ID..."></user-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #resourceGroupsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Resource Group</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ResourceGroups"
                                [values]="resourceGroups"
                                [keyProperty]="'ResourceGroup'"
                                [valueProperty]="'ResourceGroup'"
                                [placeholder]="'Select Groups...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #usersTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">User</label>
        <div class="col-md-8">
            <user-multiselect [(model)]="filter.Users" placeholder="User..."></user-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #userNameTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">User Name</label>
        <div class="col-md-8">
            <input type="text" name="UserName"
                   [(ngModel)]="filter.UserName"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find User names <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #firstNameTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">First Name</label>
        <div class="col-md-8">
            <input type="text" name="FirstName"
                   [(ngModel)]="filter.FirstName"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find First names <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #lastNameTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Last Name</label>
        <div class="col-md-8">
            <input type="text" name="LastName"
                   [(ngModel)]="filter.LastName"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Last names <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #isStudyAdministratorTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Is {{(isGLP ? 'Job' : 'Study') | translate}} Administrator</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.IsStudyAdministrator"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #isOrderAdministratorTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Is Order Administrator</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.IsOrderAdministrator"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #roleNameTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Role Name</label>
        <div class="col-md-8">
            <input type="text" name="RoleName"
                   [(ngModel)]="filter.RoleName"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Roles <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #hasReadAccessTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Has Read Access</label>
        <div class="col-md-8">
            <input type="checkbox"
                   name="hasReadAccess"
                   [(ngModel)]="filter.HasReadAccess" />
        </div>
    </div>
</ng-template>

<ng-template #hasWriteAccessTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Has Write Access</label>
        <div class="col-md-8">
            <input type="checkbox"
                   name="hasWriteAccess"
                   [(ngModel)]="filter.HasWriteAccess" />
        </div>
    </div>
</ng-template>

<ng-template #taskFlagMessageTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Task Flag Message</label>
        <div class="col-md-8">
            <input type="text" name="TaskFlagMessage"
                   [(ngModel)]="filter.TaskFlagMessage"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Task Flag Messages <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #taskStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Task Status</label>
        <div class="col-md-8">
            <input type="text" name="TaskStatus"
                   [(ngModel)]="filter.TaskStatus"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Task Statuses <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #facetNameTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Facet Name</label>
        <div class="col-md-8">
            <input type="text" name="FacetName"
                   [(ngModel)]="filter.FacetName"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Facets <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeTaskStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveTaskStatus"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #activeTaskFlagMessageTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveTaskFlagMessages"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #endStateTaskStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">End State</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.EndStateTaskStatus"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultTaskStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultTaskStatus"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #standardPhrasesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Standard Phrase</label>
        <div class="col-md-8">
            <active-vocab-typeahead-multi-select [(model)]="filter.StandardPhrases"
                                                 [vocabTableName]="'cv_StandardPhrase'"
                                                 [includeInactive]="true"></active-vocab-typeahead-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #variablePhraseTypesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Variable Phrase Type</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.VariablePhraseTypes"
                                [values]="variablePhraseTypes"
                                [keyProperty]="'VariablePhraseType'"
                                [valueProperty]="'VariablePhraseType'"
                                [placeholder]="'Select Variable Phrase Type...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #standardPhraseTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Standard Phrase</label>
        <div class="col-md-8">
            <input type="text" name="StandardPhrase"
                   [(ngModel)]="filter.StandardPhrase"
                   class="form-control input-medium" />
            <span class="help-block">
                Find Standard Phrases <em>containing</em> this value.
            </span>
        </div>
    </div>
</ng-template>

<ng-template #activeStandardPhraseTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveStandardPhrases"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #standardPhraseCategoriesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Category</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.StandardPhraseCategories"
                                [values]="standardPhraseCategories"
                                [keyProperty]="'StandardPhraseCategory'"
                                [valueProperty]="'StandardPhraseCategory'"
                                [placeholder]="'Select category...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #standardPhraseLinkTypesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Standard Phrase Type</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.StandardPhraseLinkTypes"
                                [values]="standardPhraseLinkTypes"
                                [keyProperty]="'StandardPhraseLinkType'"
                                [valueProperty]="'StandardPhraseLinkType'"
                                [placeholder]="'Select standard phrase type...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #animalClassificationTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Animal Classification</label>
        <div class="col-md-8">
            <input type="text" name="AnimalClassification"
                   [(ngModel)]="filter.AnimalClassification"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Animal Classifications <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeAnimalClassificationTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveAnimalClassifications"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultAnimalClassificationTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultAnimalClassifications"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #animalCommentStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Animal Comment Status</label>
        <div class="col-md-8">
            <input type="text" name="AnimalCommentStatus"
                   [(ngModel)]="filter.AnimalCommentStatus"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Animal Comment Statuses <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeAnimalCommentStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveAnimalCommentStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #endStateAnimalCommentStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">End State</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.EndStateAnimalCommentStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultAnimalCommentStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultAnimalCommentStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultEndStateTaskStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default End State</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultEndStateTaskStatus"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #permanentAnimalCommentStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Permanent</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.PermanentAnimalCommentStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #jobReportsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Report</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.JobReports"
                                [values]="jobReports"
                                [keyProperty]="'JobReport'"
                                [valueProperty]="'JobReport'"
                                [placeholder]="'Select report...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #animalStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Animal Status</label>
        <div class="col-md-8">
            <input type="text" name="AnimalStatus"
                   [(ngModel)]="filter.AnimalStatus"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Animal Statuses <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeAnimalStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveAnimalStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #endStateAnimalStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">End State</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.EndStateAnimalStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultAnimalStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultAnimalStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultClinicalStatusAnimalStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default Clinical Status</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultClinicalStatusAnimalStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #jobSubtypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{'Job' | translate}} Subtype</label>
        <div class="col-md-8">
            <input type="text" name="JobSubtype"
                   [(ngModel)]="filter.JobSubtype"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find {{'Job' | translate}} Subtypes <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #taskOutcomeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Task Outcome</label>
        <div class="col-md-8">
            <input type="text" name="TaskOutcome"
                   [(ngModel)]="filter.TaskOutcome"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Task Outcomes <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #animalUseTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Animal Use</label>
        <div class="col-md-8">
            <input type="text" name="AnimalUse"
                   [(ngModel)]="filter.AnimalUse"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Animal Uses <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeAnimalUseTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveAnimalUses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultAnimalUseTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultAnimalUses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #jobStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{'Job' | translate}} Status</label>
        <div class="col-md-8">
            <input type="text" name="JobStatus"
                   [(ngModel)]="filter.JobStatus"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find {{'Job' | translate}} Statuses <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeJobStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveJobStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #endStateJobStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">End State</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.EndStateJobStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultJobStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultJobStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #physicalMarkerTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Marker Type</label>
        <div class="col-md-8">
            <input type="text" name="PhysicalMarkerType"
                   [(ngModel)]="filter.PhysicalMarkerType"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Marker Types <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeTaskOutcomeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveTaskOutcomes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #activePhysicalMarkerTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActivePhysicalMarkerTypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultTaskOutcomeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultTaskOutcomes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultPhysicalMarkerTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultPhysicalMarkerTypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #plateStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Plate Status</label>
        <div class="col-md-8">
            <input type="text" name="PlateStatus"
                   [(ngModel)]="filter.PlateStatus"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Plate Statuses <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activePlateStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActivePlateStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultPlateStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultPlateStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #birthStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Birth Status</label>
        <div class="col-md-8">
            <input type="text" name="BirthStatus"
                   [(ngModel)]="filter.BirthStatus"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Birth Statuses <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeBirthStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveBirthStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultBirthStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultBirthStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #bodyConditionScoreTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Body Condition Score</label>
        <div class="col-md-8">
            <input type="text" name="BodyConditionScore"
                   [(ngModel)]="filter.BodyConditionScore"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Body Condition Scores <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeJobSubtypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveJobSubtypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #activeBodyConditionScoreTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveBodyConditionScores"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultJobSubtypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultJobSubtypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultBodyConditionScoreTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultBodyConditionScores"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #clinicalObservationStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Clinical Observation Status</label>
        <div class="col-md-8">
            <input type="text" name="ClinicalObservationStatus"
                   [(ngModel)]="filter.ClinicalObservationStatus"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Clinical Observation Statuses <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeClinicalObservationStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveClinicalObservationStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #endStateClinicalObservationStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">End State</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.EndStateClinicalObservationStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultClinicalObservationStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultClinicalObservationStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #clinicalObservationTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Clinical Observation</label>
        <div class="col-md-8">
            <input type="text" name="ClinicalObservation"
                   [(ngModel)]="filter.ClinicalObservation"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Clinical Observations <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>


<ng-template #observationSearchTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Observation</label>
        <div class="col-md-8">
            <input type="text" name="ObservationSearch"
                   [(ngModel)]="filter.ObservationSearch"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Observations <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>


<ng-template #observationTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Observation Type</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ObservationType"
                                [values]="observationTypes"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #activeClinicalObservationTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveClinicalObservations"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #experimentalClinicalObservationTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{'Experimental' | translate}}</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ExperimentalClinicalObservations"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultClinicalObservationTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultClinicalObservations"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #resourceGroupTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Resource Group</label>
        <div class="col-md-8">
            <input type="text" name="ResourceGroup"
                   [(ngModel)]="filter.ResourceGroup"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Resource Groups <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>
<ng-template #activeResourceGroupTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveResourceGroups"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>
<ng-template #defaultResourceGroupTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultResourceGroups"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #resourceTypeTmpl>

    <div class="form-group row">
        <label class="col-md-4 col-form-label">Resource Type</label>
        <div class="col-md-8">
            <input type="text" name="ResourceType"
                   [(ngModel)]="filter.ResourceType"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Resource Types <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>
<ng-template #activeResourceTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveResourceTypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>
<ng-template #defaultResourceTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultResourceTypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #iacucProtocolTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">IACUC Protocol</label>
        <div class="col-md-8">
            <input type="text" name="IACUCProtocol"
                   [(ngModel)]="filter.IACUCProtocol"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find IACUC Protocols <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>
<ng-template #activeIACUCProtocolTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveIACUCProtocols"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>
<ng-template #defaultIACUCProtocolTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultIACUCProtocols"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #matingPurposeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Mating Purpose</label>
        <div class="col-md-8">
            <input type="text" name="MatingPurpose"
                   [(ngModel)]="filter.MatingPurpose"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Mating Purposes <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>
<ng-template #activeMatingPurposeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveMatingPurposes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>
<ng-template #defaultMatingPurposeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultMatingPurposes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #matingStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Mating Status</label>
        <div class="col-md-8">
            <input type="text" name="MatingStatus"
                   [(ngModel)]="filter.MatingStatus"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Mating Statuses <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeMatingStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveMatingStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #endStateMatingStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">End State</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.EndStateMatingStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultMatingStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultMatingStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #paymentTermTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Payment Term</label>
        <div class="col-md-8">
            <input type="text" name="PaymentTerm"
                   [(ngModel)]="filter.PaymentTerm"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Payment Terms <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activePaymentTermTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActivePaymentTerms"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultPaymentTermTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultPaymentTerms"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #matingTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Mating Type</label>
        <div class="col-md-8">
            <input type="text" name="MatingType"
                   [(ngModel)]="filter.MatingType"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Mating Types <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeMatingTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveMatingTypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultMatingTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultMatingTypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #approvalNumbersTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Approval Number</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ApprovalNumbers"
                                [values]="approvalNumbers"
                                [keyProperty]="'ApprovalNumber'"
                                [valueProperty]="'ApprovalNumber'"
                                [placeholder]="'Select Approval numbers...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #testArticleTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Test Article</label>
        <div class="col-md-8">
            <input type="text" name="TestArticle"
                   [(ngModel)]="filter.TestArticle"
                   class="form-control input-medium" />
            <span class="help-block">
                Find Test Articles <em>containing</em> this value.
            </span>
        </div>
    </div>
</ng-template>
<ng-template #activeTestArticleTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveTestArticles"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultTestArticleTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultTestArticles"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #complianceTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Compliance</label>
        <div class="col-md-8">
            <input type="text" name="Compliance"
                   [(ngModel)]="filter.Compliance"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Compliances <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeComplianceTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveCompliance"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultComplianceTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultCompliance"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #animalCharacteristicNameTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Animal Characteristic Name</label>
        <div class="col-md-8">
            <input type="text" name="AnimalCharacteristicName"
                   [(ngModel)]="filter.AnimalCharacteristicName"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Animal Characteristic Names <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #courierTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Courier</label>
        <div class="col-md-8">
            <input type="text" name="Courier"
                   [(ngModel)]="filter.Courier"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Couriers <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #exitReasonTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Exit Reason</label>
        <div class="col-md-8">
            <input type="text" name="ExitReason"
                   [(ngModel)]="filter.ExitReason"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Exit Reasons <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeExitReasonTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveExitReasons"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultExitReasonTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultExitReasons"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>
<ng-template #genotypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Genotype</label>
        <div class="col-md-8">
            <input type="text" name="Genotype"
                   [(ngModel)]="filter.Genotype"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Genotypes <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeGenotypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveGenotypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultGenotypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultGenotypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #materialPoolStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Housing Status</label>
        <div class="col-md-8">
            <input type="text" name="MaterialPoolStatus"
                   [(ngModel)]="filter.MaterialPoolStatus"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Housing Statuses <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeMaterialPoolStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveMaterialPoolStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #endStateMaterialPoolStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">End State</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.EndStateMaterialPoolStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultMaterialPoolStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultMaterialPoolStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultEndStateMaterialPoolStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default End State</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultEndStateMaterialPoolStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultAutoEndStateTaskStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default Automatic End State</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultAutoEndStateTaskStatus"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #containerTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Container Type</label>
        <div class="col-md-8">
            <input type="text" name="ContainerType"
                   [(ngModel)]="filter.ContainerType"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Container Types <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeContainerTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveContainerType"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #animalContainerTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Animal</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.AnimalContainerType"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultContainerTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultContainerType"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #siteTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Site Type</label>
        <div class="col-md-8">
            <input type="text" name="SiteType"
                   [(ngModel)]="filter.SiteType"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Site Types <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>
<ng-template #activeSiteTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveSiteTypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>
<ng-template #defaultSiteTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultSiteTypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #unitTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Unit</label>
        <div class="col-md-8">
            <input type="text" name="Unit"
                   [(ngModel)]="filter.Unit"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Units <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeUnitTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveUnits"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultUnitTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultUnits"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #orderIdTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Order ID</label>
        <div class="col-md-8">
            <input type="text" name="OrderId"
                   [(ngModel)]="filter.OrderId"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find IDs <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeAnimalCharacteristicTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveAnimalCharacteristics"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #speciesAnimalCharacteristicTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Species</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.SpeciesAnimalCharacteristics"
                                [values]="taxons"
                                [keyProperty]="'CommonName'"
                                [valueProperty]="'CommonName'"
                                [placeholder]="'Select Species...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #sampleCharacteristicNameTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Sample Characteristic Name</label>
        <div class="col-md-8">
            <input type="text" name="SampleCharacteristicName"
                   [(ngModel)]="filter.SampleCharacteristicName"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Sample Characteristic Names <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeCourierTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveCouriers"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultCourierTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultCouriers"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #activeSampleCharacteristicTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveSampleCharacteristics"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #typeSampleCharacteristicTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Sample Type</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.TypeSampleCharacteristics"
                                [values]="sampleTypes"
                                [keyProperty]="'SampleType'"
                                [valueProperty]="'SampleType'"
                                [placeholder]="'Select Types...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #lastReviewedSampleCharacteristicTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Last Reviewed Date Range</label>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Last Reviewed Date Start"
                            [(ngModel)]="filter.LastReviewedSampleCharacteristicDateStart"
                            placeholder="From&hellip;"></climb-ngb-date>
        </div>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Last Reviewed Date End"
                            [(ngModel)]="filter.LastReviewedSampleCharacteristicDateEnd"
                            placeholder="To&hellip;"></climb-ngb-date>
        </div>
    </div>
</ng-template>

<ng-template #orderTypesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Order Type</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.OrderTypes"
                                [values]="materialTypes"
                                [keyProperty]="'MaterialType'"
                                [valueProperty]="'MaterialType'"
                                [placeholder]="'Select type...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #lineStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{'Line' | translate}} Status</label>
        <div class="col-md-8">
            <input type="text" name="LineStatus"
                   [(ngModel)]="filter.LineStatus"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find {{'Line' | translate}} Statuses <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #observedByTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Observed By</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ObservedBy"
                                [values]="resourceNames"
                                [keyProperty]="'ResourceName'"
                                [valueProperty]="'ResourceName'"
                                [placeholder]="'Select name...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #observedByUsernameTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Observed By</label>
        <div class="col-md-8">
            <user-select [(model)]="filter.ObservedByUsername"
                         [useLatestKnownUsers]="true"
                         [forceValidSelection]="false"></user-select>
        </div>
    </div>
</ng-template>

<ng-template #orderIdOrdersAnimalsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Order ID</label>
        <div class="col-md-8">
            <input type="text" name="OrderIdOrdersAnimals"
                   [(ngModel)]="filter.OrderIdOrdersAnimals"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find IDs <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeLineStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveLineStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #orderIdOrdersLocationsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Order ID</label>
        <div class="col-md-8">
            <input type="text" name="OrderIdOrdersLocations"
                   [(ngModel)]="filter.OrderIdOrdersLocations"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find IDs <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #sampleAnalysisTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Sample Analysis</label>
        <div class="col-md-8">
            <input type="text" name="SampleAnalysis"
                   [(ngModel)]="filter.SampleAnalysis"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Sample Analysis <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #defaultHealthTechnicianTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default Health Technicians</label>
        <div class="col-md-8">
            <input type="text" name="DefaultHealthTechnician"
                   [(ngModel)]="filter.DefaultHealthTechnician"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Default Health Technicians <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #inactivityTimeoutTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Inactivity Timeout</label>
        <div class="col-md-8">
            <input type="number" name="InactivityTimeout"
                   [(ngModel)]="filter.InactivityTimeout"
                   class="form-control input-medium" />
        </div>
    </div>
</ng-template>

<ng-template #sampleConditionTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Sample Condition</label>
        <div class="col-md-8">
            <input type="text" name="SampleCondition"
                   [(ngModel)]="filter.SampleCondition"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Sample Condition <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeSampleAnalysisTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveSampleAnalyses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'">
            </climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultLineStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultLineStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #observationStatusesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Observation Status</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ObservationStatuses"
                                [values]="clinicalObservationStatuses"
                                [keyProperty]="'ClinicalObservationStatus'"
                                [valueProperty]="'ClinicalObservationStatus'"
                                [placeholder]="'Select status...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #studyCharacteristicNameTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{'Study' | translate}} Characteristic Name</label>
        <div class="col-md-8">
            <input type="text" name="StudyCharacteristicName"
                   [(ngModel)]="filter.StudyCharacteristicName"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find {{'Study' | translate}} Characteristic Names <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeStudyCharacteristicTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveStudyCharacteristics"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #activeSampleConditionTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveSampleConditions"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #typeStudyCharacteristicTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{'Study' | translate}} Type</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.TypeStudyCharacteristics"
                                [values]="studyTypes"
                                [keyProperty]="'StudyType'"
                                [valueProperty]="'StudyType'"
                                [placeholder]="'Select Types...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #lastReviewedAnimalCharacteristicTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Last Reviewed Date Range</label>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Last Reviewed Animal Characteristic Date Start"
                            [(ngModel)]="filter.LastReviewedAnimalCharacteristicDateStart"
                            placeholder="From&hellip;"></climb-ngb-date>
        </div>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Last Reviewed Animal Characteristic Date End"
                            [(ngModel)]="filter.LastReviewedAnimalCharacteristicDateEnd"
                            placeholder="To&hellip;"></climb-ngb-date>
        </div>
    </div>
</ng-template>

<ng-template #defaultSampleAnalysisTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultSampleAnalyses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #lastReviewedStudyCharacteristicTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Last Reviewed Date Range</label>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Last Reviewed Study Characteristic Date Start"
                            [(ngModel)]="filter.LastReviewedStudyCharacteristicDateStart"
                            placeholder="From&hellip;"></climb-ngb-date>
        </div>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Last Reviewed Study Characteristic Date End"
                            [(ngModel)]="filter.LastReviewedStudyCharacteristicDateEnd"
                            placeholder="To&hellip;"></climb-ngb-date>
        </div>
    </div>
</ng-template>

<ng-template #defaultSampleConditionTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultSampleConditions"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #materialOriginTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Animal/Sample Origin</label>
        <div class="col-md-8">
            <input type="text" name="MaterialOrigin"
                   [(ngModel)]="filter.MaterialOrigin"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Animal/Sample Origins <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeMaterialOriginTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveMaterialOrigins"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultMaterialOriginTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultMaterialOrigins"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #breedingStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Breeding Status</label>
        <div class="col-md-8">
            <input type="text" name="BreedingStatus"
                   [(ngModel)]="filter.BreedingStatus"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Breeding Statuses <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeBreedingStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveBreedingStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultBreedingStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultBreedingStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #activeTaskTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveTasks"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #nameFormatTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Name Setting Type</label>
        <div class="col-md-8">
            <input type="text" name="NameFormatType"
                   [(ngModel)]="filter.NameFormatType"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Name Settings <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #shipmentAnimalConditionTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Shipment Animal Condition</label>
        <div class="col-md-8">
            <input type="text" name="ShipmentAnimalCondition"
                   [(ngModel)]="filter.ShipmentAnimalCondition"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Shipment Animal Conditions <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeShipmentAnimalConditionTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveShipmentAnimalConditions"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultShipmentAnimalConditionTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultShipmentAnimalConditions"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #shipmentContainerConditionTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Shipment Container Condition</label>
        <div class="col-md-8">
            <input type="text" name="ShipmentContainerCondition"
                   [(ngModel)]="filter.ShipmentContainerCondition"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Shipment Container Conditions <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeShipmentContainerConditionTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveShipmentContainerConditions"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultShipmentContainerConditionTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultShipmentContainerConditions"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #assignedToTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Assigned To</label>
        <div class="col-md-8">
            <input type="text" name="AssignedTo"
                   [(ngModel)]="filter.AssignedTo"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Resources <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #completedTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Completed</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.Completed"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #completedByTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Completed By</label>
        <div class="col-md-8">
            <input type="text" name="CompletedBy"
                   [(ngModel)]="filter.CompletedBy"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Resources <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #collectedTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Collected</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.Collected"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #reviewedTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Reviewed</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.Reviewed"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #animalNameTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Animal Name</label>
        <div class="col-md-8">
            <input type="text" name="AnimalName"
                   [(ngModel)]="filter.AnimalName"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Animal Names <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #animalIdentifierTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Animal ID</label>
        <div class="col-md-8">
            <input type="text" name="AnimalIdentifier"
                   [(ngModel)]="filter.AnimalIdentifier"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Animal IDs <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #dietTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Diet</label>
        <div class="col-md-8">
            <input type="text" name="Diet"
                   [(ngModel)]="filter.Diet"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Diets <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeDietTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveDiets"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultDietTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultDiets"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #generationTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Generation</label>
        <div class="col-md-8">
            <input type="text" name="Generation"
                   [(ngModel)]="filter.Generation"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Generations <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeGenerationTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveGenerations"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultGenerationTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultGenerations"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #locationTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Location Type</label>
        <div class="col-md-8">
            <input type="text" name="LocationType"
                   [(ngModel)]="filter.LocationType"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Location Types <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeLocationTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveLocationTypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultLocationTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultLocationTypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #filesNameTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">File Name</label>
        <div class="col-md-8">
            <input type="text" name="FileName"
                   [(ngModel)]="filter.FileName"
                   class="form-control input-medium" />
        </div>
    </div>
</ng-template>

<ng-template #constructIdentifierTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Construct ID</label>
        <div class="col-md-8">
            <input type="text" name="ConstructIdentifier"
                   [(ngModel)]="filter.ConstructIdentifier"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Construct IDs <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #facetTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Facet Type</label>
        <div class="col-md-8">
            <input type="text" name="FacetType"
                   [(ngModel)]="filter.FacetType"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Facet Types <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #facetSettingFieldTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Field</label>
        <div class="col-md-8">
            <input type="text" name="FacetSettingField"
                   [(ngModel)]="filter.FacetSettingField"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Fields <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeFacetSettingTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveFacetSettings"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #requiredFacetSettingTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Required</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.RequiredFacetSettings"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #urgentClinicalObservationTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Urgent</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.UrgentClinicalObservations"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #observedDateRangeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Observed Date Range</label>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Observed Date Start"
                            [(ngModel)]="filter.ObservedDateStart"
                            placeholder="From&hellip;"></climb-ngb-date>
        </div>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Observed Date End"
                            [(ngModel)]="filter.ObservedDateEnd"
                            placeholder="To&hellip;"></climb-ngb-date>
        </div>
    </div>
</ng-template>

<ng-template #observationsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Clinical Observation</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.Observations"
                                [values]="clinicalObservations"
                                [keyProperty]="'ClinicalObservation'"
                                [valueProperty]="'ClinicalObservation'"
                                [placeholder]="'Select observation...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #institutionTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{'Institution' | translate}} Type</label>
        <div class="col-md-8">
            <input type="text" name="InstitutionType"
                   [(ngModel)]="filter.InstitutionType"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find {{'Institution' | translate}} Types <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #lineTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{'Line' | translate}} Type</label>
        <div class="col-md-8">
            <input type="text" name="LineType"
                   [(ngModel)]="filter.LineType"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find {{'Line' | translate}} Types <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeLineTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveLineTypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultLineTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultLineTypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #activeInstitutionTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveInstitutionTypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultInstitutionTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultInstitutionTypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #dateInRangeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Date In Range</label>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Date In Start"
                            [(ngModel)]="filter.DateInStart"
                            placeholder="From&hellip;"></climb-ngb-date>
        </div>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Date In End"
                            [(ngModel)]="filter.DateInEnd"
                            placeholder="To&hellip;"></climb-ngb-date>
        </div>
    </div>
</ng-template>

<ng-template #dateOutRangeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Date Out Range</label>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Date Out Start"
                            [(ngModel)]="filter.DateOutStart"
                            placeholder="From&hellip;"></climb-ngb-date>
        </div>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Date Out End"
                            [(ngModel)]="filter.DateOutEnd"
                            placeholder="To&hellip;"></climb-ngb-date>
        </div>
    </div>
</ng-template>

<ng-template #facetTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Facet Name</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.facets"
                                [values]="facetValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #compatibilityAccessLevelTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Compatibility Access Level</label>
        <div class="col-md-8">
            <input type="text" name="CompatibilityAccessLevel"
                   [(ngModel)]="filter.CompatibilityAccessLevel"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Compatibility Access Levels <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #sexesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Sex</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.Sexes"
                                [values]="sexes"
                                [keyProperty]="'Sex'"
                                [valueProperty]="'Sex'"
                                [placeholder]="'Select Sexes...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #bodySystemTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Body System</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.BodySystems"
                                [values]="bodySystems"
                                [keyProperty]="'BodySystem'"
                                [valueProperty]="'BodySystem'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #accessLevelsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Access Level</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.AccessLevels"
                                [values]="accessLevels"
                                [keyProperty]="'SocialGroupAccessLevel'"
                                [valueProperty]="'SocialGroupAccessLevel'"
                                [placeholder]="'Select access level...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #accessLevelTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Access Level</label>
        <div class="col-md-8">
            <input type="text" name="AccessLevel"
                   [(ngModel)]="filter.AccessLevel"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Acess Levels <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #modifier1Tmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Value</label>
        <div class="col-md-8">
            <input type="text" name="Modifier1"
                   [(ngModel)]="filter.Modifier1"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Modifiers <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #dateDocumentedRangeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Date Documented Range</label>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Date Documented Start"
                            [(ngModel)]="filter.DateDocumentedStart"
                            placeholder="From&hellip;"></climb-ngb-date>
        </div>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Date Documented End"
                            [(ngModel)]="filter.DateDocumentedEnd"
                            placeholder="To&hellip;"></climb-ngb-date>
        </div>
    </div>
</ng-template>

<ng-template #activeCompatibilityAccessLevelTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveCompatibilityAccessLevels"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #activeModifier1Tmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveModifiers1"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultCompatibilityAccessLevelTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultCompatibilityAccessLevels"
                                values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #modifier2Tmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Value</label>
        <div class="col-md-8">
            <input type="text" name="Modifier2"
                   [(ngModel)]="filter.Modifier2"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Modifiers <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeModifier2Tmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveModifiers2"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #modifier3Tmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Value</label>
        <div class="col-md-8">
            <input type="text" name="Modifier3"
                   [(ngModel)]="filter.Modifier3"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Modifiers <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeModifier3Tmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveModifiers3"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #modifier4Tmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Value</label>
        <div class="col-md-8">
            <input type="text" name="Modifier4"
                   [(ngModel)]="filter.Modifier4"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Modifiers <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeModifier4Tmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveModifiers4"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>


<ng-template #orderStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Order Status</label>
        <div class="col-md-8">
            <input type="text" name="OrderStatus"
                   [(ngModel)]="filter.OrderStatus"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Order Statuses <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeOrderStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveOrderStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultOrderStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultOrderStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #jobCharacteristicNameTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{'Job' | translate}} Characteristic Name</label>
        <div class="col-md-8">
            <input type="text" name="JobCharacteristicName"
                   [(ngModel)]="filter.JobCharacteristicName"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find {{'Job' | translate}} Characteristic Names <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeJobCharacteristicTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveJobCharacteristics"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #linkTypeJobCharacteristicTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Link Type</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.LinkTypeJobCharacteristics"
                                [values]="jobCharacteristicLinkTypes"
                                [keyProperty]="'JobCharacteristicLinkTypeKey'"
                                [valueProperty]="'JobCharacteristicLinkTypeName'"
                                [placeholder]="'Select Link Types...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #lastReviewedJobCharacteristicTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Last Reviewed Date Range</label>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Last Reviewed Job Characteristic Date Start"
                            [(ngModel)]="filter.LastReviewedJobCharacteristicDateStart"
                            placeholder="From&hellip;"></climb-ngb-date>
        </div>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Last Reviewed Job Characteristic Date End"
                            [(ngModel)]="filter.LastReviewedJobCharacteristicDateEnd"
                            placeholder="To&hellip;"></climb-ngb-date>
        </div>
    </div>
</ng-template>

<ng-template #socialGroupAccessLevelTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Social Group Access Level</label>
        <div class="col-md-8">
            <input type="text" name="SocialGroupAccessLevel"
                   [(ngModel)]="filter.SocialGroupAccessLevel"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Social Group Access Levels <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeSocialGroupAccessLevelTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveSocialGroupAccessLevels"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultSocialGroupAccessLevelTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultSocialGroupAccessLevels"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #jobReportTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{ 'Job' | translate }} Report</label>
        <div class="col-md-8">
            <input type="text" name="JobReport"
                   [(ngModel)]="filter.JobReport"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find {{ 'Job' | translate }} Reports <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeJobReportTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveJobReports"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #housingTypesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Housing Type</label>
        <div class="col-md-8">
            <input type="text" name="MaterialPoolType"
                   [(ngModel)]="filter.MaterialPoolType"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Housing Types <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeHousingTypesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveMaterialPoolTypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultMatingHousingTypesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Mating Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultMatingMaterialPoolTypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultHousingTypesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultMaterialPoolTypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #contactPersonTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Contact Type</label>
        <div class="col-md-8">
            <input type="text" name="ContactPersonType"
                   [(ngModel)]="filter.ContactPersonType"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Contact Types <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeContactPersonTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveContactPersonType"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultContactPersonTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultContactPersonType"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #jobTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{'Job' | translate}} Type</label>
        <div class="col-md-8">
            <input type="text" name="JobType"
                   [(ngModel)]="filter.JobType"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find {{'Job' | translate}} Types <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>
<ng-template #activeJobTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveJobTypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>
<ng-template #defaultJobTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultJobTypes"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #quarantineFacilityTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Quarantine Facility</label>
        <div class="col-md-8">
            <input type="text" name="QuarantineFacility"
                   [(ngModel)]="filter.QuarantineFacility"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Quarantine Facilities <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeQuarantineFacilityTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveQuarantineFacilities"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultQuarantineFacilityTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultQuarantineFacilities"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #genotypeAssayTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Genotype Assay</label>
        <div class="col-md-8">
            <input type="text" name="GenotypeAssay"
                   [(ngModel)]="filter.GenotypeAssay"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Genotype Assays <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeGenotypeAssayTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveGenotypeAssay"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultGenotypeAssayTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultGenotypeAssay"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #classNameTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Enumeration Name</label>
        <div class="col-md-8">
            <input type="text" name="ClassName"
                   [(ngModel)]="filter.ClassName"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find names <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #itemNameTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Enumeration Value</label>
        <div class="col-md-8">
            <input type="text" name="ItemName"
                   [(ngModel)]="filter.ItemName"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find values <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #outputNamesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Output Name</label>
        <div class="col-md-8">
            <input type="text" name="OutputNames"
                   [(ngModel)]="filter.OutputNames"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Names <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #inputNameTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Input Name</label>
        <div class="col-md-8">
            <input type="text" name="InputNames"
                   [(ngModel)]="filter.InputName"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Input Names <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #defaultValueTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <input type="text" name="DefaultValue"
                   [(ngModel)]="filter.DefaultValue"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find defaults <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeInputTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveInputs"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #requiredInputTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Required</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.RequiredInputs"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #outputNameTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Output Name</label>
        <div class="col-md-8">
            <input type="text" name="Output"
                   [(ngModel)]="filter.OutputName"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Output Names <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #flagMessagesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Flag Message</label>
        <div class="col-md-8">
            <input type="text" name="FlagMessages"
                   [(ngModel)]="filter.FlagMessages"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Flag Messages <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeOutputTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveOutputs"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #dataTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Data Type</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DataTypes"
                                [values]="dataTypes"
                                [keyProperty]="'DataType'"
                                [valueProperty]="'DataType'"
                                [placeholder]="'Select type...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #routeOfAdministrationTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Route of Administration</label>
        <div class="col-md-8">
            <input type="text" name="RouteOfAdministration"
                   [(ngModel)]="filter.RouteOfAdministration"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Routes of Administration <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeRouteOfAdministrationTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveRouteOfAdministrations"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultRouteOfAdministrationTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultRouteOfAdministrations"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #veterinaryRequestStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Veterinary Request Status</label>
        <div class="col-md-8">
            <input type="text" name="VeterinaryRequestStatus"
                   [(ngModel)]="filter.VeterinaryRequestStatus"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Veterinary Request Statuses <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activeVeterinaryRequestStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveVeterinaryRequestStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #endStateVeterinaryRequestStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">End State</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.EndStateVeterinaryRequestStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultVeterinaryRequestStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultVeterinaryRequestStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #studyNameTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{'Study' | translate}} Name</label>
        <div class="col-md-8">
            <input type="text" name="StudyName"
                   [(ngModel)]="filter.StudyName"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find {{'Study' | translate}} Names <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #studyTypeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">{{'Study' | translate}} Type</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.StudyType"
                                [values]="studyTypes"
                                [keyProperty]="'StudyType'"
                                [valueProperty]="'StudyType'"
                                [placeholder]="'Select Types...'"></climb-multi-select>            
        </div>
    </div>
</ng-template>

<ng-template #characteristicNameTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Characteristic Name</label>
        <div class="col-md-8">
            <input type="text" name="CharacteristicName"
                   [(ngModel)]="filter.CharacteristicName"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Characteristic Names <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #characteristicValueTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Characteristic Value</label>
        <div class="col-md-8">
            <input type="text" name="CharacteristicValue"
                   [(ngModel)]="filter.CharacteristicValue"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Characteristic Values <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activePermitExternalPurposeStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActivePermitExternalPurposeStatuses"
            [values]="booleanValues"
            [keyProperty]="'Value'"
            [valueProperty]="'Value'"
            [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultPermitExternalPurposeStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultPermitExternalPurposeStatuses"
            [values]="booleanValues"
            [keyProperty]="'Value'"
            [valueProperty]="'Value'"
            [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #permitExternalPurposeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Permit External Purpose</label>
        <div class="col-md-8">
            <input type="text" name="PermitExternalPurposes"
                   [(ngModel)]="filter.PermitExternalPurposes"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Permit External Purposes <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activePermitSeverityStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActiveSeverityStatuses"
            [values]="booleanValues"
            [keyProperty]="'Value'"
            [valueProperty]="'Value'"
            [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultPermitSeverityStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultSeverityStatuses"
            [values]="booleanValues"
            [keyProperty]="'Value'"
            [valueProperty]="'Value'"
            [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #permitSeveritiesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Severity</label>
        <div class="col-md-8">
            <input type="text" name="Severity"
                   [(ngModel)]="filter.Severity"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Permit Severities <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activePermitCategoryStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActivePermitCategoryStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #defaultPermitCategoryStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultPermitCategoryStatuses"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #permitCategoryTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Permit Category</label>
        <div class="col-md-8">
            <input type="text" name="PermitCategory"
                   [(ngModel)]="filter.PermitCategory"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Permit Categories <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #activePermitStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Active</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ActivePermitStatuses"
                [values]="booleanValues"
                [keyProperty]="'Value'"
                [valueProperty]="'Value'"
                [placeholder]="'Select...'"></climb-multi-select>
            </div>
    </div>
</ng-template>

<ng-template #defaultPermitStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Default</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.DefaultPermitStatuses"
                [values]="booleanValues"
                [keyProperty]="'Value'"
                [valueProperty]="'Value'"
                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #permitStatusTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Permit Status</label>
        <div class="col-md-8">
            <input type="text" name="PermitStatus"
                   [(ngModel)]="filter.PermitStatus"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Permit Statuses <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #permitNumberTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Permit Number</label>
        <div class="col-md-8">
            <input type="text" name="PermitNumber"
                   [(ngModel)]="filter.PermitNumber"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Numbers <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #permitTitleTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Permit Title</label>
        <div class="col-md-8">
            <input type="text" name="PermitTitle"
                   [(ngModel)]="filter.PermitTitle"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Titles <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #permitGMOAnimalsAllowedVisibleTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">GMO Animals Allowed</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.GMOAnimalsAllowed"
                                [values]="booleanValues"
                                [keyProperty]="'Value'"
                                [valueProperty]="'Value'"
                                [placeholder]="'Select...'">
            </climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #permitOtherPurposesVisibleTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Permit Other Purpose</label>
        <div class="col-md-8">
            <input type="text" name="PermitOtherPurpose"
                   [(ngModel)]="filter.PermitOtherPurpose"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Other Purposes <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #permitSummaryVisibleTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Permit Summary</label>
        <div class="col-md-8">
            <input type="text" name="PermitSummary"
                   [(ngModel)]="filter.PermitSummary"
                   class="form-control input-medium" />
            <small class="form-text text-muted">
                Find Summaries <em>containing</em> this value.
            </small>
        </div>
    </div>
</ng-template>

<ng-template #permitStartDateRangeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Start Date Range</label>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Start Date Start"
                            [(ngModel)]="filter.StartDateStart"
                            placeholder="From&hellip;"></climb-ngb-date>
        </div>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Start Date End"
                            [(ngModel)]="filter.StartDateEnd"
                            placeholder="To&hellip;"></climb-ngb-date>
        </div>
    </div>
</ng-template>

<ng-template #permitExpiryDateRangeTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Expiry Date Range</label>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Expiry Date Start"
                            [(ngModel)]="filter.ExpiryDateStart"
                            placeholder="From&hellip;"></climb-ngb-date>
        </div>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Expiry Date End"
                            [(ngModel)]="filter.ExpiryDateEnd"
                            placeholder="To&hellip;"></climb-ngb-date>
        </div>
    </div>
</ng-template>


<ng-template #permitStatusesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Permit Status</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.PermitStatuses"
                                [values]="permitStatuses"
                                [keyProperty]="'PermitStatus'"
                                [valueProperty]="'PermitStatus'"
                                [placeholder]="'Select Status...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #permitCategoriesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Permit Category</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.PermitCategories"
                                [values]="permitCategories"
                                [keyProperty]="'PermitCategory'"
                                [valueProperty]="'PermitCategory'"
                                [placeholder]="'Select Category...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #permitExternalPurposesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Permit External Purpose</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.PermitExternalPurposes"
                                [values]="permitExternalPurposes"
                                [keyProperty]="'PermitExternalPurpose'"
                                [valueProperty]="'PermitExternalPurpose'"
                                [placeholder]="'Select External Purpose...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #permitOwnersTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Permit Owner</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.PermitOwners"
                                [values]="resourceNames"
                                [keyProperty]="'ResourceName'"
                                [valueProperty]="'ResourceName'"
                                [placeholder]="'Select Owner...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #permitInstitutionsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Permit {{'Institution' | translate}}</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.PermitInstitutions"
                                [values]="permitInstitutions"
                                [keyProperty]="'Name'"
                                [valueProperty]="'Name'"
                                [placeholder]="'Select...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #permitSpeciesTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Species</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.Taxons"
                                [values]="taxons"
                                [keyProperty]="'CommonName'"
                                [valueProperty]="'CommonName'"
                                [placeholder]="'Select Species...'"></climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #permitNumbersTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Permit Number</label>
        <div class="col-md-8">
            <permit-multiselect [(model)]="filter.PermitNumbers"></permit-multiselect>
        </div>
    </div>
</ng-template>

<ng-template #highestSeverityAllowedTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Highest Severity Allowed</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.HighestSeverityAllowed"
                                [values]="permitSeverities"
                                [keyProperty]="'Severity'"
                                [valueProperty]="'Severity'"
                                [placeholder]="'Select Severity...'">
            </climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #animalsOnPermitTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Animals On Permit</label>
        <div class="col-md-8">
            <input type="number" name="AnimalsOnPermit"
                   min="0"
                   [(ngModel)]="filter.AnimalsOnPermit"
                   class="form-control input-medium" />
        </div>
    </div>
</ng-template>

<ng-template #noRecoveryAllowedTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Non-Recovery Allowed</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.NoRecoveryAllowed"
                [values]="booleanValues"
                [keyProperty]="'Value'"
                [valueProperty]="'Value'"
                [placeholder]="'Select...'">
            </climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #reuseAllowedTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Reuse Allowed</label>
        <div class="col-md-8">
            <climb-multi-select [(model)]="filter.ReuseAllowed"
                [values]="booleanValues"
                [keyProperty]="'Value'"
                [valueProperty]="'Value'"
                [placeholder]="'Select...'">
            </climb-multi-select>
        </div>
    </div>
</ng-template>

<ng-template #permitSpeciesOriginsTmpl>
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Origin</label>
        <div class="col-md-8">
            <active-vocab-typeahead-multi-select
                [(model)]="filter.PermitSpeciesOrigins"
                [vocabTableName]="'cv_MaterialOrigin'"
                [includeInactive]="true"
            ></active-vocab-typeahead-multi-select>
        </div>
    </div>
</ng-template>
