// the entities listed here will not have a reason for change modal show up for them in any circumstance
export const NO_EDIT_SHOWS_MODAL: string[] = [
    'WorkflowTask',
    'Output',
    'Input',
    'OutputFlag',
    'OutputFlagMessage',
    'CalculatedOutputExpression',
];

// the entities listed will not show a reason for change if the change is from null -> non-null.
export const NULL_TO_NON_NULL_DISABLED: string[] = [
    'Order'
];

// the entities listed here are part of the "Studies" facet and will be subject to the specific validation for Job reason for change modals.
export const JOB_RELATED_ENTITIES: string[] = [
    'Job',
    'JobLine',
    'JobInstitution',
    'JobLocation',
    'JobCharacteristicInstance',
    'JobGroup',
    'JobStandardPhrase',
    'JobTestArticle',
    'TaskMaterial',
    'TaskInput',
    'TaskInstance',
    'TaskJob',
    'SampleGroup',
    'SampleGroupSourceMaterial',
    'JobMaterial',
    'JobCohort',
    'TaskPlaceholder',
    'TaskPlaceholderInput',
];
