import { Directive, HostListener, Input, ElementRef, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[climbNgControlNumberInput]'
})
export class ClimbNgControlNumberInputDirective {

    @Input() min: number = Number.MIN_SAFE_INTEGER;
    @Input() max: number = Number.MAX_SAFE_INTEGER;

    constructor(private el: ElementRef, private control: NgControl, private renderer: Renderer2) {}

    @HostListener('input', ['$event'])
    onInput(event: Event): void {
        const input = this.el.nativeElement as HTMLInputElement;
        const value = input.value;
        const numericValue = this.cleanAndConvertToInt(value);

        this.renderer.setProperty(input, 'value', numericValue);
        this.control.control.setValue(numericValue);
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', 'Control', 'v', 'a'];
        const isNumberKey = event.key >= '0' && event.key <= '9';
        const isMinusKey = event.key === '-' && this.el.nativeElement.selectionStart === 0;

        if (isNumberKey || isMinusKey || allowedKeys.includes(event.key)) {
            return;
        } else {
            event.preventDefault();
        }
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent): void {
        event.preventDefault();
        const clipboardData = event.clipboardData.getData('text');
        const numericValue = this.cleanAndConvertToInt(clipboardData);

        this.renderer.setProperty(this.el.nativeElement, 'value', numericValue);
        this.control.control.setValue(numericValue);
    }

    private cleanAndConvertToInt(text: string): number {
        let sanitizedText = text.replace(/[^0-9-]/g, '');
        if (sanitizedText.includes('-') && sanitizedText.indexOf('-', 1) !== -1) {
            sanitizedText = sanitizedText.replace('-', '');
        }
    
        let numericValue = parseInt(sanitizedText, 10);
        if (!isNaN(numericValue)) {
            numericValue = this.applyBounds(numericValue);
        } else {
            numericValue = null;
        }

        return numericValue
    }

    private applyBounds(value: number): number {
        if (value < this.min) {
            return this.min;
        }

        if (value > this.max) {
            return this.max;
        }

        return value;
    }
}
