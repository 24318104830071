import type { Animal } from './animal.interface';
import type { AnimalOrder } from './animal-order.interface';
import type { Job } from './job.interface';
import type { JobOrder } from './job-order.interface';
import type { OrderLocation } from './order-location.interface';
import type { SampleOrder } from './sample-order.interface';
import type { Site } from './site.interface';
import type { StoredFileMap } from './stored-file-map.interface';
import type { cv_MaterialOrigin } from './cv-material-origin.interface';
import type { cv_MaterialType } from './cv-material-type.interface';
import type { cv_QuarantineFacility } from './cv-quarantine-facility.interface';
import type { cv_Taxon } from './cv-taxon.interface';
import { OrderInstitution } from './order-institution.interface';
import { Resource } from './resource.interface';
import { Permit } from './permit.interface';

export interface Order {
    Animal: Animal[];
    AnimalCount: number | null;
    AnimalHousing: string | null;
    AnimalOrder: AnimalOrder[];
    C_Job_key: number | null;
    C_MaterialOrigin_key: number | null;
    C_MaterialType_key: number | null;
    C_Order_key: number;
    C_QuarantineFacility_key: number | null;
    C_Site_key: number | null;
    C_Taxon_key: number | null;
    C_Line_key: number | null;
    C_Sex_key: number | null;
    C_Workgroup_key: number;
    C_OrderStatus_key: number | null;
    C_OrderedBy_key: number | null;
    C_Diet_key: number | null;
    C_Permit_key: number | null;
    Cites: string | null;
    Comments: string | null;
    CreatedBy: string;
    CurrentLocationPath: string;
    DateCreated: Date;
    DateModified: Date;
    DateOrdered: Date | null;
    DateProjectedReceipt: Date | null;
    DateReceived: Date | null;
    DesiredReceiptDate: Date | null;
    ExpectedJobEndDate: Date | null;
    HousingRequirements: string | null;
    InvoiceNumber: string | null;
    IsAllShipmentContainersEmpty: boolean;
    IsBeddingAvailable: boolean | null;
    IsFoodAvailable: boolean | null;
    IsFoodEnrichmentAllowed: boolean;
    IsWaterAvailable: boolean | null;
    Job: Job;
    JobOrder: JobOrder[];
    JobOrderJobSortable: string | null;
    SampleOrderSampleConditionSortable: string | null;
    ModifiedBy: string;
    OrderID: string;
    OrderLocation: OrderLocation[];
    OrderInstitution: OrderInstitution[];
    Permit: Permit;
    ProcurementInstructions: string | null;
    ReasonForChange: string | null;
    RequestedAgeRange: string | null;
    RequestedWeightRange: string | null;
    SampleOrder: SampleOrder[];
    Site: Site;
    StoredFileMap: StoredFileMap[];
    VendorOrderNumber: string | null;
    VendorRoomId: string | null;
    Version: number;
    cv_MaterialOrigin: cv_MaterialOrigin;
    cv_MaterialType: cv_MaterialType;
    cv_QuarantineFacility: cv_QuarantineFacility;
    cv_Taxon: cv_Taxon;
    OrderedBy: Resource;
    InstitutionNamesSortable: string | null;
    IsLocked: boolean;
}
