import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnDestroy,
    ViewChildren,
} from '@angular/core';

import { JobVocabService } from './job-vocab.service';

import { BaseFilter } from '../common/facet';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    focusElementByQuery,
} from '../common/util/';
import { Subscription } from 'rxjs';
import { NgModel } from '@angular/forms';
import { LoggingService } from '@services/logging.service';
import { dateControlValidator } from '@common/util/date-control.validator';
import { Permit, Resource } from '@common/types';


@Component({
    selector: 'job-filter',
    templateUrl: './job-filter.component.html'
})
export class JobFilterComponent extends BaseFilter implements OnInit, OnDestroy {
    @ViewChildren('dateControl') dateControls: NgModel[];

    @Input() filter: any = {};
    @Input() isCRO = false;
    @Input() isGLP = false;
    @Output() onFilter = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();

    // CVs
    taxons: any[] = [];
    jobTypes: any[] = [];
    jobStatuses: any[] = [];
    iacucProtocols: any[] = [];
    compliances: any[] = [];
    public resources: Resource[] = [];
    public permits: Permit[] = [];

    _subs: Subscription[] = [];

    constructor(
        private jobVocabService: JobVocabService,
        private activeModal: NgbActiveModal,
        private loggingService: LoggingService,
    ) {
        super(activeModal);
    }

    ngOnInit() {
        this.cloneFilter();

        this.getCVs();

        focusElementByQuery('[data-auto-focus="jobName"] input');
    }

    private cloneFilter() {
        const projectImplantDateStart = this.filter.ProjectImplantDateStart;
        const projectImplantDateEnd = this.filter.ProjectImplantDateEnd;
        const implantedDateStart = this.filter.ImplantedDateStart;
        const implantedDateEnd = this.filter.ImplantedDateEnd;
        const dateCreatedStart = this.filter.DateCreatedStart;
        const dateCreatedEnd = this.filter.DateCreatedEnd;
        const projectedStartDateStart = this.filter.ProjectedStartDateStart;
        const projectedStartDateEnd = this.filter.ProjectedStartDateEnd;
        const dateStartedStart = this.filter.DateStartedStart;
        const dateStartedEnd = this.filter.DateStartedEnd;
        const dateEndedStart = this.filter.DateEndedStart;
        const dateEndedEnd = this.filter.DateEndedEnd;

        this.filter = this.copyFilter(this.filter);

        this.filter.ProjectImplantDateStart = projectImplantDateStart;
        this.filter.ProjectImplantDateEnd = projectImplantDateEnd;
        this.filter.ImplantedDateStart = implantedDateStart;
        this.filter.ImplantedDateEnd = implantedDateEnd;
        this.filter.DateCreatedStart = dateCreatedStart;
        this.filter.DateCreatedEnd = dateCreatedEnd;
        this.filter.ProjectedStartDateStart = projectedStartDateStart;
        this.filter.ProjectedStartDateEnd = projectedStartDateEnd;
        this.filter.DateStartedStart = dateStartedStart;
        this.filter.DateStartedEnd = dateStartedEnd;
        this.filter.DateEndedStart = dateEndedStart;
        this.filter.DateEndedEnd = dateEndedEnd;
    }

    ngOnDestroy() {
        for (const s of this._subs) {
            s.unsubscribe();
        }
    }

    private getCVs() {
        this.jobVocabService.jobTypes$
            .subscribe((jobTypes: any[]) => {
                this.jobTypes = jobTypes;
            });

        this.jobVocabService.jobStatuses$
            .subscribe((jobStatuses: any[]) => {
                this.jobStatuses = jobStatuses;
            });

        this.jobVocabService.iacucProtocols$
            .subscribe((iacucProtocols: any[]) => {
                this.iacucProtocols = iacucProtocols;
            });
        
        this.jobVocabService.compliances$
            .subscribe((compliances: any[]) => {
                this.compliances = compliances;
            });
        
        this.jobVocabService.taxons$
            .subscribe((taxons) => {
                this.taxons = taxons;
            });
        this.jobVocabService.resources$
            .subscribe((data) => {
                this.resources = data.filter((resource) => !resource.IsGroup);
            });
        this.jobVocabService.permits$
            .subscribe((data) => {
                this.permits = data;
            });
    }

    filterClicked(event: Event) {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }

        event.preventDefault();
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    closeClicked() {
        this.close.emit();
        this.activeModal.dismiss();
    }

    clearClicked() {
        this.filter = {};
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }
}
