import { Component } from '@angular/core';
import { OrderDetailService } from '../../services/order-detail.service';

@Component({
    selector: 'order-detail-general-form',
    templateUrl: './order-detail-general-form.component.html',
    styleUrls: ['./order-detail-general-form.component.scss']
})
export class OrderDetailGeneralFormComponent {
    constructor(
        public orderDetailService: OrderDetailService,
    ) {}
}
