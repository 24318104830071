<form role="form" class="form-label-right">

    <div class="modal-header justify-content-center">
        <h4 class="modal-title">{{'Jobs' | translate}} Filter</h4>
    </div>

    <div class="modal-body">
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Name</label>
            <div class="col-md-9">
                <input type="text"
                       name="jobName"
                       [(ngModel)]="filter.JobID"
                       class="form-control input-medium"
                       data-auto-focus="jobName" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Code</label>
            <div class="col-md-9">
                <input type="text"
                       name="jobCode"
                       [(ngModel)]="filter.JobCode"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Type</label>
            <div class="col-md-9">
                <select name="jobType"
                        [(ngModel)]="filter.C_JobType_key"
                        class="form-control input-medium">
                    <option></option>
                    <option *ngFor="let item of jobTypes"
                            [value]="item.C_JobType_key">
                        {{item.JobType}}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group row" *ngIf="isCRO">
            <label class="col-md-3 col-form-label">Subtype</label>
            <div class="col-md-9">
                <active-vocab-typeahead-multi-select [(model)]="filter.JobSubtypes"
                                                     [vocabTableName]="'cv_JobSubtype'"
                                                     [includeInactive]="true">

                </active-vocab-typeahead-multi-select>
            </div>
        </div>

        <div class="form-group row" *ngIf="!isCRO">
            <label class="col-md-3 col-form-label">Lead Scientist</label>
            <div class="col-md-9">
                <user-select [(model)]="filter.LeadScientist"
                             [forceValidSelection]="false"></user-select>
            </div>
        </div>
        <div class="form-group row" *ngIf="!isCRO">
            <label class="col-md-3 col-form-label">Study Monitor</label>
            <div class="col-md-9">
                <user-select [(model)]="filter.StudyMonitor"
                             [forceValidSelection]="false"></user-select>
            </div>
        </div>

        <div class="form-group row" *ngIf="isCRO">
            <label class="col-md-3 col-form-label">Research Director</label>
            <div class="col-md-9">
                <user-select [(model)]="filter.ResearchDirector"
                             [forceValidSelection]="false"></user-select>
            </div>
        </div>
        <div class="form-group row" *ngIf="isCRO">
            <label class="col-md-3 col-form-label">{{!isGLP ? 'Client Manager' : 'Alternate Contact'}}</label>
            <div class="col-md-9">
                <user-select [(model)]="filter.ClientManager"
                             [forceValidSelection]="false"></user-select>
            </div>
        </div>
        <div class="form-group row" *ngIf="isCRO">
            <label class="col-md-3 col-form-label">Study Director</label>
            <div class="col-md-9">
                <user-select [(model)]="filter.StudyDirector"
                             [forceValidSelection]="false"></user-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Permit</label>
            <div class="col-md-9">
                <climb-multi-select
                    [(model)]="filter.C_Permit_keys"
                    [values]="permits"
                    [keyProperty]="'C_Permit_key'"
                    [valueProperty]="'PermitNumber'"
                    [placeholder]="'Select permits...'">
                </climb-multi-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Permit Owner</label>
            <div class="col-md-9">
                <climb-multi-select
                    [(model)]="filter.permitOwnerKeys"
                    [values]="resources"
                    [keyProperty]="'C_Resource_key'"
                    [valueProperty]="'ResourceName'"
                    [placeholder]="'Select permit owners...'">
                </climb-multi-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">IACUC Protocol</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_IACUCProtocol_keys"
                                    [values]="iacucProtocols"
                                    [keyProperty]="'C_IACUCProtocol_key'"
                                    [valueProperty]="'IACUCProtocol'"
                                    [placeholder]="'Select IACUC Protocols...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Compliance</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_Compliance_keys"
                                    [values]="compliances"
                                    [keyProperty]="'C_Compliance_key'"
                                    [valueProperty]="'Compliance'"
                                    [placeholder]="'Select Compliances...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Locations</label>
            <div class="col-md-9">
                <input type="text"
                       name="location"
                       [(ngModel)]="filter.Location"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Order ID</label>
            <div class="col-md-9">
                <order-multiselect [(model)]="filter.orders"></order-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">{{'Line' | translate}}</label>
            <div class="col-md-9">
                <line-multiselect [(model)]="filter.lines"></line-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Species</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_Taxon_keys"
                                    [values]="taxons"
                                    [keyProperty]="'C_Taxon_key'"
                                    [valueProperty]="'CommonName'"
                                    [placeholder]="'Select species...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Test Article</label>
            <div class="col-md-9">
                <test-article-multiselect [(model)]="filter.testArticles"></test-article-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Batch</label>
            <div class="col-md-9">
                <input type="text"
                       name="batch"
                       [(ngModel)]="filter.Batch"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Status</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_JobStatus_keys"
                                    [values]="jobStatuses"
                                    [keyProperty]="'C_JobStatus_key'"
                                    [valueProperty]="'JobStatus'"
                                    [placeholder]="'Select statuses...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">{{'Study' | translate}} Name</label>
            <div class="col-md-9">
                <input type="text"
                       name="studyName"
                       [(ngModel)]="filter.StudyName"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Input Value</label>
            <div class="col-md-9">
                <input type="text"
                       name="inputValue"
                       [(ngModel)]="filter.InputValue"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row" *ngIf="isCRO">
            <label class="col-md-3 col-form-label">Projected Implant Date Range</label>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                [(ngModel)]="filter.ProjectImplantDateStart"
                                name="Projected Implant Date Start"
                                placeholder="From&hellip;"></climb-ngb-date>
            </div>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                [(ngModel)]="filter.ProjectImplantDateEnd"
                                name="Projected Implant Date End"
                                placeholder="To&hellip;"></climb-ngb-date>
            </div>
        </div>

        <div class="form-group row" *ngIf="isCRO">
            <label class="col-md-3 col-form-label">Implanted Date Range</label>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                [(ngModel)]="filter.ImplantedDateStart"
                                name="Implanted Date Start"
                                placeholder="From&hellip;"></climb-ngb-date>
            </div>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                [(ngModel)]="filter.ImplantedDateEnd"
                                name="Implanted Date End"
                                placeholder="To&hellip;"></climb-ngb-date>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Projected Start Date Range</label>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                [(ngModel)]="filter.ProjectedStartDateStart"
                                name="Projected Start Date Start"
                                placeholder="From&hellip;"></climb-ngb-date>
            </div>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                [(ngModel)]="filter.ProjectedStartDateEnd"
                                name="Projected Start Date End"
                                placeholder="To&hellip;"></climb-ngb-date>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Start Date Range</label>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                [(ngModel)]="filter.DateStartedStart"
                                name="Start Date Start"
                                placeholder="From&hellip;"></climb-ngb-date>
            </div>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                [(ngModel)]="filter.DateStartedEnd"
                                name="Start Date End"
                                placeholder="To&hellip;"></climb-ngb-date>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">End Date Range</label>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                [(ngModel)]="filter.DateEndedStart"
                                name="End Date Start"
                                placeholder="From&hellip;"></climb-ngb-date>
            </div>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                [(ngModel)]="filter.DateEndedEnd"
                                name="End Date End"
                                placeholder="To&hellip;"></climb-ngb-date>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Created Date Range</label>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                [(ngModel)]="filter.DateCreatedStart"
                                name="Created Date Start"
                                placeholder="From&hellip;"></climb-ngb-date>
            </div>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                [(ngModel)]="filter.DateCreatedEnd"
                                name="Created Date End"
                                placeholder="To&hellip;"></climb-ngb-date>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Created By</label>
            <div class="col-md-9">
                <user-multiselect [(model)]="filter.CreatedBys"></user-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Animals</label>
            <div class="col-md-9">
                <animal-multiselect [(model)]="filter.Animals"></animal-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Locked Tasks</label>
            <div class="col-md-4">
                <lock-status-select [(model)]="filter.LockedTasks"
                                    [trueOptionText]="'Contains Locked Tasks'"
                                    [falseOptionText]="'Contains Unlocked Tasks'"></lock-status-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">{{'Institution' | translate}}</label>
            <div class="col-md-9">
                <institution-multiselect [(model)]="filter.institutions"></institution-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Site</label>
            <div class="col-md-9">
                <site-multiselect [(model)]="filter.sites"></site-multiselect>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <span class="btn-set">
            <button type="submit"
                    class="btn btn-fw-wide btn-lg btn-primary"
                    (click)="filterClicked($event)">
                Filter
            </button>
            <button data-automation-id="clear-filter-button"
                    type="button"
                    class="btn btn-fw-wide btn-lg btn-secondary"
                    (click)="clearClicked()">
                Clear Filter
            </button>
        </span>
        <span class="btn-set">
            <button type="button"
                    class="btn btn-lg btn-secondary"
                    (click)="closeClicked()">
                Close
            </button>
        </span>
    </div>
</form>
