import { ColumnSelectLabel } from "@common/facet";
import { BaseTableColumnsHelper } from "../../../../common/util/table-columns/base-table-columns-helper";
import { Injectable } from "@angular/core";

@Injectable()
export class SampleGroupTableColumnsHelper extends BaseTableColumnsHelper {
    static visibleColumns = {
        protocol: true,
        taskAlias: true,
        numSources: true,
        numSamples: true,
        numTotal: true,
        type: true,
        status: true,
        preservation: true,
        containerType: true,
        harvestDate: true,
        expirationDate: true,
        timePoint: true,
        subtype: true,
        processing: true,
        sendTo: true,
        analysis: true,
        specialInstructions: true
    };
    constructor() {
        super(Object.assign({}, SampleGroupTableColumnsHelper.visibleColumns));
    }

    protected setupColumnSelectLabels(activeFields: Set<string>): void {
        this.columnSelectLabels = [
            new ColumnSelectLabel('protocol', 'Protocol'),
            new ColumnSelectLabel('taskAlias', 'Task'),
            new ColumnSelectLabel('numSources', 'Sources'),
            new ColumnSelectLabel('numSamples', 'Samples per Source'),
            new ColumnSelectLabel('numTotal', 'Samples'),
            new ColumnSelectLabel('type', 'Type'),
            new ColumnSelectLabel('status', 'Status'),
            activeFields.has("C_PreservationMethod_key") ? new ColumnSelectLabel('preservation', 'Preservation') : null,
            activeFields.has("Material.C_ContainerType_key") ? new ColumnSelectLabel('containerType', 'Container') : null,
            activeFields.has("DateHarvest") ? new ColumnSelectLabel('harvestDate', 'Harvest Date') : null,
            activeFields.has("DateExpiration") ? new ColumnSelectLabel('expirationDate', 'Expiration Date') : null,
            activeFields.has("TimePoint") ? new ColumnSelectLabel('timePoint', 'Time Point') : null,
            activeFields.has("C_SampleSubtype_key") ? new ColumnSelectLabel('subtype', 'Subtype') : null,
            activeFields.has("C_SampleProcessingMethod_key") ? new ColumnSelectLabel('processing', 'Processing') : null,
            activeFields.has("SendTo") ? new ColumnSelectLabel('sendTo', 'Send To') : null,
            activeFields.has("C_SampleAnalysisMethod_key") ? new ColumnSelectLabel('analysis', 'Analysis') : null,
            activeFields.has("SpecialInstructions") ? new ColumnSelectLabel('specialInstructions', 'Special Instructions') : null
        ];
    }
}