import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';

import { VocabularyService } from '../vocabularies/vocabulary.service';
import { take } from 'rxjs/operators';
import { ResourceService } from '../resources';
import { cv_Diet, cv_OrderStatus, cv_Sex, cv_Taxon, Entity, Resource } from '@common/types';

@Injectable()
export class OrderVocabService {

    // vocab Observables
    public get materialTypes$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_MaterialTypes')).pipe(take(1));
    }
    public get taxons$(): Observable<Entity<cv_Taxon>[]> {
        return defer(() => this.vocabularyService.getCV('cv_Taxons')).pipe(take(1));
    }
    public get sampleConditions$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_SampleConditions')).pipe(take(1));
    }
    public get sampleTypes$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_SampleTypes')).pipe(take(1));
    }
    public get materialOrigins$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_MaterialOrigins')).pipe(take(1));
    }
    public get quarantineFacilities$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_QuarantineFacilities')).pipe(take(1));
    }
    public get sexes$(): Observable<Entity<cv_Sex>[]> {
        return defer(() => this.vocabularyService.getCV('cv_Sexes')).pipe(take(1));
    }
    public get shipmentAnimals$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_ShipmentAnimalConditions')).pipe(take(1));
    }
    public get shipmentContainers$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_ShipmentContainerConditions')).pipe(take(1));
    }
    public get orderStatuses$(): Observable<Entity<cv_OrderStatus>[]> {
        return defer(() => this.vocabularyService.getCV('cv_OrderStatus')).pipe(take(1));
    }
    public get orderResources$(): Observable<Entity<Resource>[]> {
        return defer(() => this.resourceService.getAllResources()).pipe(take(1));
    }
    public get diets$(): Observable<Entity<cv_Diet>[]> {
        return defer(() => this.vocabularyService.getCV('cv_Diets')).pipe(take(1));
    }

    constructor(
        private vocabularyService: VocabularyService,
        private resourceService: ResourceService
    ) {}


}
