<loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>
<div class="detail-form-wrapper">
    <loading-overlay *ngIf="loading"></loading-overlay>
    <div *ngIf="pageState" class="detail-header">
        <h3>Task</h3>

        <pager-toolbar [itemName]="'task'"
                       [logTag]="COMPONENT_LOG_TAG"
                       [first]="pageState.isFirst"
                       [last]="pageState.isLast"
                       (previous)="previousClicked($event)"
                       (next)="nextClicked($event)">
        </pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet?.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="exitClicked($event)">

            <ng-container additional-groups>
                <div class="btn-group"
                     role="group"
                     aria-label="Edit actions">
                    <button type="button"
                            class="btn btn-secondary gridster-d-md-inline-block"
                            (click)="copyTask()"
                            [disabled]="loading"
                            [appInsightsClick]="'click-copy-task'"
                            *ngIf="readwrite">
                        <i class="fa fa-copy" aria-hidden="true"></i>
                        Copy
                    </button>
                </div>
            </ng-container>
        </detail-toolbar>
    </div>

    <div *ngIf="task | isDeleted"
         class="alert alert-warning"
         role="alert">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        This task has been deleted.
    </div>

    <form *ngIf="!(task | isDeleted)"
          name="taskForm"
          id="taskForm"
          class="detail-form"
          #taskForm="ngForm">
        <fieldset [disabled]="facet?.Privilege === 'ReadOnly' || !isTaskEditable(task)">
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Name</label>
                <div class="col-md-10">
                    <input data-automation-id="task-name-input"
                           type="text" name="taskName"
                           class="form-control input-medium task-detail-name"
                           #taskName="ngModel"
                           [(ngModel)]="task.TaskName"
                           [required]="true"
                           [disabled]="isReadOnly || !canEditInputsAndOutputsForUsedTasks"
                           validateUnique
                           uniqueEntity="WorkflowTasks"
                           uniqueProperty="TaskName"
                           [entityPK]="task.C_WorkflowTask_key"
                           entityPKName="C_WorkflowTask_key" />
                    <small class="form-text text-muted"
                           *ngIf="taskName.errors?.unique">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        This task name already exists.
                    </small>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Description')">
                <label class="col-md-2 col-form-label">Description</label>
                <div class="col-md-10">
                    <input type="text" name="taskDescription"
                           class="form-control input-medium task-detail-description"
                           [(ngModel)]="task.Description"
                           [required]="requiredFields.includes('Description')" />
                </div>
            </div>

            <div class="form-group row" *ngIf="!isReadOnly">
                <label class="col-md-2 col-form-label">Type</label>
                <div class="col-md-10">
                    <active-vocab-select data-automation-id="task-type-dropdown"
                                         [(model)]="task.C_TaskType_key"
                                         (modelChange)="typeChanged(task)"
                                         [vocabChoices]="taskTypes"
                                         [keyFormatter]="taskTypeKeyFormatter"
                                         [optionFormatter]="taskTypeFormatter"
                                         #taskTypeSelect
                                         [required]="true"
                                         [nullable]="false"
                                         [readonly]="hasTaskInstance">
                    </active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="!isReadOnly && activeFields.includes('Active')">
                <label class="col-md-2 col-form-label">Active</label>
                <div class="col-md-10">
                    <input type="checkbox" name="taskIsActive"
                           class="mt-1"
                           [(ngModel)]="task.IsActive" />
                </div>
            </div>

            <!-- Cost -->
            <div class="form-group row" *ngIf="!isReadOnly && activeFields.includes('Cost')">
                <label class="col-md-2 col-form-label">Cost</label>
                <div class="col-md-5">
                    <input type="number" name="taskCost"
                           class="form-control input-medium"
                           min="0.00"
                           step="0.01"
                           moneyFormatter
                           [(ngModel)]="task.Cost"
                           [required]="requiredFields.includes('Cost')" />
                    <div>
                        <div class="form-check form-check-inline">
                            <label class="form-check-label">
                                <input type="radio" name="costBasis"
                                       class="form-check-input"
                                       [(ngModel)]="task.IsCostPerMaterial"
                                       [value]="false">
                                Per Task
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <label class="form-check-label">
                                <input type="radio" name="costBasis"
                                       class="form-check-input"
                                       [(ngModel)]="task.IsCostPerMaterial"
                                       [value]="true">
                                Per Material
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Duration -->
            <div class="form-group row" *ngIf="!isReadOnly && activeFields.includes('Duration (minutes)')">
                <label class="col-md-2 col-form-label">Duration (minutes)</label>
                <div class="col-md-5">
                    <input type="number" name="taskDuration"
                           class="form-control input-medium mb-1"
                           min="0"
                           step="any"
                           decimalFormatter
                           [decimalPlaces]="2"
                           [(ngModel)]="task.Duration"
                           [required]="requiredFields.includes('Duration')" />
                    <div>
                        <div class="form-check form-check-inline">
                            <label class="form-check-label">
                                <input type="radio" name="durationBasis"
                                       class="form-check-input"
                                       [(ngModel)]="task.IsDurationPerMaterial"
                                       [value]="false">
                                Per Task
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <label class="form-check-label">
                                <input type="radio" name="durationBasis"
                                       class="form-check-input"
                                       [(ngModel)]="task.IsDurationPerMaterial"
                                       [value]="true">
                                Per Material
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Effort -->
            <div class="form-group row" *ngIf="!isReadOnly && activeFields.includes('Effort')">
                <label class="col-md-2 col-form-label">Effort</label>
                <div class="col-md-5">
                    <input type="number" name="taskEffort"
                           class="form-control input-medium mb-1"
                           min="0"
                           step="any"
                           decimalFormatter
                           [decimalPlaces]="2"
                           [(ngModel)]="task.Effort"
                           [required]="requiredFields.includes('Effort')" />
                    <div>
                        <div class="form-check form-check-inline">
                            <label class="form-check-label">
                                <input type="radio" name="effortBasis"
                                       class="form-check-input"
                                       [(ngModel)]="task.IsEffortPerMaterial"
                                       [value]="false">
                                Per Task
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <label class="form-check-label">
                                <input type="radio" name="effortBasis"
                                       class="form-check-input"
                                       [(ngModel)]="task.IsEffortPerMaterial"
                                       [value]="true">
                                Per Material
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row" *ngIf="!isReadOnly && activeFields.includes('No Materials')">
                <label class="col-md-2 col-form-label">No Materials</label>
                <div class="col-md-10 d-flex align-items-center">
                    <input type="checkbox"
                           name="noMaterials"
                           [(ngModel)]="task.NoMaterials"
                           (ngModelChange)="noMaterialsChanged(task)"
                           [id]="'noMaterialsCheckbox'"
                           [disabled]="(!canEditInputsAndOutputsForUsedTasks) || (jobTypeKey!==task.C_TaskType_key)"/>
                </div>
            </div>

            <div class="form-group row" *ngIf="!isReadOnly && hasAutomaticallyEndTask && activeFields.includes('Automatically End Task')">
                <label class="col-md-2 col-form-label">
                    Automatically End Task
                    <info-tooltip [text]="messageDisabledAutomaticallyEndTask">
                    </info-tooltip>
                </label>
                <div class="col-md-10 d-flex align-items-center">
                    <input type="checkbox" name="automaticallyEndTask"
                           [(ngModel)]="task.AutomaticallyEndTask"
                           [disabled]="(task.NoMaterials) || (jobTypeKey !== task.C_TaskType_key)" />
                </div>
            </div>

            <!-- Inputs -->
            <div class="form-group row" *ngIf="activeFields.includes('Inputs')">
                <label class="col-md-2 col-form-label"
                       [ngClass]="{'required-text' : requiredFields.includes('Input[0]') && task.Input.length === 0}">Inputs</label>
                <div class="col-md-10">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="col-size-fit"></th>
                                <th class="col-size-fit"></th>
                                <th class="icon-cell col-size-fit">
                                    <a class="add-item-link task-detail-add-input"
                                       (click)="addInput(task)"
                                       *ngIf="facet?.Privilege !== 'ReadOnly' && canEditInputsAndOutputsForUsedTasks">
                                        <i class="fa fa-plus-circle" title="Add input"></i>
                                    </a>
                                </th>
                                <th>Name</th>
                                <th>Data Type</th>
                                <th class="text-center col-size-fit">
                                    Active
                                    <!--<info-tooltip [text]="'When selected, this input will be included in the .'"></info-tooltip>-->
                                </th>
                                <th class="text-center col-size-fit">
                                    Value Required
                                    <info-tooltip [text]="'When selected, this option provides a visual indication that the input must be entered before task assignment.'" [placement]="'auto'">
                                    </info-tooltip>
                                </th>
                                <!-- Will remain hidden until further requirements are gathered
                                <th class="text-center col-size-fit">
                                    Validation Required
                                    <info-tooltip [text]="'When selected, this option requires the user to validate the input before they can collect output data.'"></info-tooltip>
                                </th>
                                -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let taskInput of task.Input | orderBy:'SortOrder'; let index = index" class="task-detail-input">
                                <td class="icon-cell">
                                    <a *ngIf="taskInput.SortOrder !== 1 && canEditInputsAndOutputsForUsedTasks"
                                       class="move-up-link"
                                       (click)="moveInputUp(taskInput)">
                                        <i class="fa fa-sort-up" title="Move up"></i>
                                    </a>
                                </td>
                                <td class="icon-cell">
                                    <a *ngIf="taskInput.SortOrder !== task.Input.length && canEditInputsAndOutputsForUsedTasks"
                                       class="move-down-link"
                                       (click)="moveInputDown(taskInput)">
                                        <i class="fa fa-sort-down" title="Move down"></i>
                                    </a>
                                </td>
                                <td class="icon-cell">
                                    <div class="form-control-plaintext" *ngIf="!isReadOnly && facet?.Privilege !== 'ReadOnly' && canEditInputsAndOutputsForUsedTasks">
                                        <a (click)="removeInput(taskInput)">
                                            <i class="fa fa-remove remove-item" title="Remove input"></i>
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <input type="text" [name]="'taskInputName_' | randomId"
                                           class="form-control input-medium"
                                           required
                                           [(ngModel)]="taskInput.InputName"
                                           [disabled]="!canEditInputsAndOutputsForUsedTasks" />
                                </td>
                                <td>
                                    <select [name]="'taskInputType_' | randomId"
                                            class="form-control input-medium"
                                            required
                                            [(ngModel)]="taskInput.C_DataType_key"
                                            [disabled]="!canEditInputsAndOutputsForUsedTasks">
                                        <option></option>
                                        <option *ngFor="let item of getInputDataTypes()"
                                                [value]="item.C_DataType_key">
                                            {{item.DataType === DataType.JOB_CHARACTERISTIC ? ('Job' | translate) + ' Characteristic' : item.DataType}}
                                        </option>
                                    </select>
                                    <select [name]="'taskInputEnumerationClass_' | randomId"
                                            class="form-control input-medium mt-1"
                                            required
                                            *ngIf="taskInput.cv_DataType?.DataType === DataType.ENUMERATION ||
                                            taskInput.cv_DataType?.DataType === DataType.LONG_ENUMERATION"
                                            [(ngModel)]="taskInput.C_EnumerationClass_key"
                                            [disabled]="!canEditInputsAndOutputsForUsedTasks">
                                        <option></option>
                                        <option *ngFor="let item of enumerationClasses"
                                                [value]="item.C_EnumerationClass_key">
                                            {{item.ClassName}}
                                        </option>
                                    </select>
                                    <select [name]="'taskInputDosingTable_' | randomId"
                                            class="form-control input-medium mt-1"
                                            required
                                            *ngIf="taskInput.cv_DataType?.DataType === DataType.DOSING_TABLE"
                                            [(ngModel)]="taskInput.C_DosingTable_key"
                                            [disabled]="!canEditInputsAndOutputsForUsedTasks">
                                        <option></option>
                                        <option *ngFor="let item of dosingTableFields"
                                                [value]="item.C_DosingTable_key">
                                            {{item.DosingTable}}
                                        </option>
                                    </select>
                                    <select [name]="'taskInputJobCharacteristic_' | randomId"
                                            class="form-control input-medium mt-1"
                                            required
                                            *ngIf="taskInput.cv_DataType?.DataType === DataType.JOB_CHARACTERISTIC"
                                            [(ngModel)]="taskInput.C_JobCharacteristicType_key"
                                            [disabled]="!canEditInputsAndOutputsForUsedTasks">
                                        <option></option>
                                        <option *ngFor="let item of jobCharacteristicTypeFields"
                                                [value]="item.C_JobCharacteristicType_key">
                                            {{item.JobCharacteristicType}}
                                        </option>
                                    </select>
                                    <select [name]="'taskInputVocabularyClass_' | randomId"
                                            class="form-control input-medium mt-1"
                                            required
                                            *ngIf="taskInput.cv_DataType?.DataType === DataType.VOCABULARY"
                                            [(ngModel)]="taskInput.C_VocabularyClass_key"
                                            [disabled]="!canEditInputsAndOutputsForUsedTasks">
                                        <option></option>
                                        <option *ngFor="let item of vocabularyClasses"
                                                [value]="item.C_VocabularyClass_key">
                                            {{item.ClassName}}
                                        </option>
                                    </select>
                                    <div class="form-group no-gutters" *ngIf="taskInput.cv_DataType?.DataType === DataType.LONG_TEXT">
                                        <div class="col-md-12">
                                            <table class="table table-borderless">
                                                <tr>
                                                    <th class="text-nowrap pb-0 pl-0">
                                                        <label class="col-form-label">Number of Rows</label>
                                                    </th>
                                                    <td class="full-width">
                                                        <input type="number" [name]="'taskInputTextLineCount_' | randomId"
                                                               class="form-control input-small"
                                                               min="1"
                                                               [(ngModel)]="taskInput.TextLineCount"
                                                               [disabled]="!canEditInputsAndOutputsForUsedTasks" />
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="form-group no-gutters" *ngIf="taskInput.cv_DataType?.DataType === DataType.NUMBER">
                                        <div class="col-md-12">
                                            <table class="table table-borderless">
                                                <tr>
                                                    <th class="text-nowrap">
                                                        <label class="col-form-label">Minimum Value</label>
                                                    <td class="full-width pb-0 px-0">
                                                        <input type="number" [name]="'inputNumberValueMin_' | randomId"
                                                               class="form-control input-small"
                                                               step="any"
                                                               [(ngModel)]="taskInput.ValidationMin"
                                                               [disabled]="!canEditInputsAndOutputsForUsedTasks" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th class="text-nowrap pb-0 pl-0">
                                                        <label class="col-form-label">Maximum Value</label>
                                                    </th>
                                                    <td class="full-width">
                                                        <input type="number" [name]="'inputNumberValueMax_' | randomId"
                                                               class="form-control input-small"
                                                               step="any"
                                                               [(ngModel)]="taskInput.ValidationMax"
                                                               [disabled]="!canEditInputsAndOutputsForUsedTasks" />
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center col-size-fit">
                                    <p class="form-control-static">
                                        <input type="checkbox" [name]="'taskInputIsActive_' | randomId"
                                               [(ngModel)]="taskInput.IsActive"
                                               [disabled]="!canEditInputsAndOutputsForUsedTasks" />
                                    </p>
                                </td>
                                <td class="text-center col-size-fit">
                                    <p class="form-control-static">
                                        <input type="checkbox" [name]="'taskInputIsRequired_' | randomId"
                                               [(ngModel)]="taskInput.IsRequired"
                                               [disabled]="taskInput.RequiresValidation || !canEditInputsAndOutputsForUsedTasks" />
                                    </p>
                                </td>
                                <!-- Will remain hidden until further requirements are gathered
                                <td class="text-center col-size-fit">
                                    <p class="form-control-plaintext">
                                        <input type="checkbox"
                                               [(ngModel)]="taskInput.RequiresValidation"
                                               (change)="requiresValidationChange(taskInput)"
                                               [name]="'taskInputRequiresValidation__' | randomId" />
                                    </p>
                                </td>
                                -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <!-- Outputs -->
            <div class="form-group row" *ngIf="activeFields.includes('Outputs')">
                <label class="col-md-2 col-form-label"
                       [ngClass]="{'required-text' : requiredFields.includes('Output[0]') && task.Output.length === 0}">Outputs</label>
                <div class="col-md-10">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="col-size-fit"></th>
                                <th class="col-size-fit"></th>
                                <th class="icon-cell col-size-fit">
                                    <a class="add-item-link task-detail-add-ouput"
                                       (click)="addOutput(task)"
                                       *ngIf="facet?.Privilege !== 'ReadOnly' && canEditInputsAndOutputsForUsedTasks">
                                        <i class="fa fa-plus-circle" title="Add output"></i>
                                    </a>
                                </th>
                                <th>Name</th>
                                <th>Data Type</th>
                                <th class="text-center col-size-fit">
                                    Active
                                    <!--<info-tooltip [text]="'When selected, this input will be included in the .'"></info-tooltip>-->
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let taskOutput of task.Output | orderBy:'SortOrder'; let index = index;" class="task-detail-output">
                                <td class="icon-cell">
                                    <a *ngIf="taskOutput.SortOrder !== 1 && canEditInputsAndOutputsForUsedTasks"
                                       class="move-up-link"
                                       (click)="moveOutputUp(taskOutput)">
                                        <i class="fa fa-sort-up" title="Move up"></i>
                                    </a>
                                </td>
                                <td class="icon-cell">
                                    <a *ngIf="taskOutput.SortOrder !== task.Output.length && canEditInputsAndOutputsForUsedTasks"
                                       class="move-down-link"
                                       (click)="moveOutputDown(taskOutput)">
                                        <i class="fa fa-sort-down" title="Move down"></i>
                                    </a>
                                </td>
                                <td class="icon-cell">
                                    <div class="form-control-plaintext" *ngIf="!isReadOnly && facet?.Privilege !== 'ReadOnly' && canEditInputsAndOutputsForUsedTasks">
                                        <a (click)="removeOutput(taskOutput)">
                                            <i class="fa fa-remove remove-item" title="Remove output"></i>
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <input type="text" [name]="'taskOutputName_' + index"
                                           class="form-control input-medium"
                                           required
                                           [(ngModel)]="taskOutput.OutputName"
                                           [disabled]="!canEditInputsAndOutputsForUsedTasks" />
                                </td>
                                <td>
                                    <select [name]="'taskOutputType_' + index"
                                            class="form-control input-medium"
                                            required
                                            [(ngModel)]="taskOutput.C_DataType_key"
                                            (ngModelChange)="outputDataTypeChange(taskOutput)"
                                            [disabled]="!canEditInputsAndOutputsForUsedTasks">
                                        <option></option>
                                        <option *ngFor="let item of getOutputDataTypes()"
                                                [value]="item.C_DataType_key">
                                            {{item.DataType}}
                                        </option>
                                    </select>
                                    <select [name]="'taskInputIsRequired_' + index"
                                            class="form-control input-medium mt-1"
                                            required
                                            *ngIf="taskOutput.cv_DataType?.DataType === DataType.ENUMERATION ||
                                                taskOutput.cv_DataType?.DataType === DataType.LONG_ENUMERATION"
                                            [(ngModel)]="taskOutput.C_EnumerationClass_key"
                                            [disabled]="!canEditInputsAndOutputsForUsedTasks">
                                        <option></option>
                                        <option *ngFor="let item of enumerationClasses"
                                                [value]="item.C_EnumerationClass_key">
                                            {{item.ClassName}}
                                        </option>
                                    </select>
                                    <select *ngIf="taskOutput.cv_DataType?.DataType === DataType.VOCABULARY"
                                            [name]="'taskOutputVocabularyClass_' + index"
                                            class="form-control input-medium mt-1"
                                            required
                                            [(ngModel)]="taskOutput.C_VocabularyClass_key"
                                            [disabled]="!canEditInputsAndOutputsForUsedTasks">
                                        <option></option>
                                        <option *ngFor="let item of vocabularyClasses"
                                                [value]="item.C_VocabularyClass_key">
                                            {{item.ClassName}}
                                        </option>
                                    </select>
                                    <div class="form-group row" *ngIf="taskOutput.cv_DataType?.DataType === DataType.LONG_TEXT">
                                        <div class="col-md-12">
                                            <table class="table table-borderless">
                                                <tbody>
                                                    <tr>
                                                        <th class="text-nowrap pb-0 pl-0">
                                                            <label class="col-form-label">Number of Rows</label>
                                                        </th>
                                                        <td class="full-width">
                                                            <input type="number" [name]="'taskOutputTextLineCount_' + index"
                                                                   class="form-control input-small"
                                                                   min="1"
                                                                   [(ngModel)]="taskOutput.TextLineCount"
                                                                   [disabled]="!canEditInputsAndOutputsForUsedTasks" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="form-group row no-gutters" *ngIf="taskOutput.cv_DataType?.DataType === DataType.NUMBER">
                                        <div class="col-md-12">
                                            <table class="table table-borderless">
                                                <tbody>
                                                    <tr>
                                                        <th class="text-nowrap pb-0 pl-0">
                                                            <label class="col-form-label">Validation Min</label>
                                                        </th>
                                                        <td class="full-width">
                                                            <input type="number" [name]="'outputNumberValueMin_' + index"
                                                                   class="form-control input-small"
                                                                   step="any"
                                                                   [(ngModel)]="taskOutput.ValidationMin"
                                                                   [disabled]="!canEditInputsAndOutputsForUsedTasks" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th class="text-nowrap pb-0 pl-0">
                                                            <label class="col-form-label">Validation Max</label>
                                                        </th>
                                                        <td class="full-width">
                                                            <input type="number" [name]="'outputNumberValueMax_' + index"
                                                                   class="form-control input-small"
                                                                   step="any"
                                                                   [(ngModel)]="taskOutput.ValidationMax"
                                                                   [disabled]="!canEditInputsAndOutputsForUsedTasks" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-md-12" style="display: inline-block">
                                            <output-flag [taskOutput]="taskOutput" [facet]="facet" [index]="index"></output-flag>
                                        </div>
                                        <div class="col-md-12 cohort-stats" style="display: inline-block">
                                            <cohort-stats-flag [disabled]="!canEditInputsAndOutputsForUsedTasks"
                                                               [flagIsChecked]="taskOutput.HasCohortStatsFlag"
                                                               [avgFlagMinimum]="taskOutput.AverageFlagMinimum"
                                                               [avgFlagMaximum]="taskOutput.AverageFlagMaximum"
                                                               [mdnFlagMinimum]="taskOutput.MedianFlagMinimum"
                                                               [mdnFlagMaximum]="taskOutput.MedianFlagMaximum"
                                                               [stDevFlagMinimum]="taskOutput.StdDevFlagMinimum"
                                                               [stDevFlagMaximum]="taskOutput.StdDevFlagMaximum"
                                                               (flagIsCheckedChange)="cohortStatsFlagIsCheckedChange(taskOutput, $event)"
                                                               (avgFlagMaximumChange)="avgFlagMaximumChange(taskOutput, $event)"
                                                               (avgFlagMinimumChange)="avgFlagMinimumChange(taskOutput, $event)"
                                                               (mdnFlagMaximumChange)="mdnFlagMaximumChange(taskOutput, $event)"
                                                               (mdnFlagMinimumChange)="mdnFlagMinimumChange(taskOutput, $event)"
                                                               (stDevFlagMaximumChange)="stDevFlagMaximumChange(taskOutput, $event)"
                                                               (stDevFlagMinimumChange)="stDevFlagMinimumChange(taskOutput, $event)">
                                            </cohort-stats-flag>
                                        </div>
                                        <div class="col-sm-12" *ngIf="!hideDecimalPlaces">
                                            <label class="col-form-label">Decimal Places</label>
                                            <input type="number" [name]="'decimalPlaces_' + index"
                                                   min="0"
                                                   max="10"
                                                   step="1"
                                                   style="width: 75px;"
                                                   [(ngModel)]="taskOutput.DecimalPlaces"
                                                   [disabled]="!canEditInputsAndOutputsForUsedTasks" />
                                            <info-tooltip [text]="'Select the number of decimal places to which the system will round values once inputted, calculated, or inherited in the Workflow facet. Input &quot;0&quot; to only see whole numbers. Note that standard rounding conventions apply. Only the rounded value will be stored in the database if this feature is enabled.'">
                                            </info-tooltip>
                                        </div>
                                    </div>
                                    <div class="form-group row" *ngIf="taskOutput.cv_DataType?.DataType === DataType.CALCULATED">
                                        <div class="col-md-12">
                                            <calculated-output [outputMappings]="getOutputMappings(taskOutput)"
                                                               [inputMappings]="getInputMappings(taskOutput)"
                                                               [expressionJson]="getExpressionJson(taskOutput)"
                                                               (expressionChange)="expressionChanged(taskOutput, $event)"
                                                               [disabled]="!canEditInputsAndOutputsForUsedTasks">
                                            </calculated-output>
                                        </div>
                                        <div class="col-md-12" style="display: inline-block">
                                            <output-flag [taskOutput]="taskOutput" [facet]="facet" [index]="index"></output-flag>
                                        </div>
                                        <div class="col-md-12 cohort-stats" style="display: inline-block">
                                            <cohort-stats-flag [disabled]="!canEditInputsAndOutputsForUsedTasks"
                                                               [flagIsChecked]="taskOutput.HasCohortStatsFlag"
                                                               [avgFlagMinimum]="taskOutput.AverageFlagMinimum"
                                                               [avgFlagMaximum]="taskOutput.AverageFlagMaximum"
                                                               [mdnFlagMinimum]="taskOutput.MedianFlagMinimum"
                                                               [mdnFlagMaximum]="taskOutput.MedianFlagMaximum"
                                                               [stDevFlagMinimum]="taskOutput.StdDevFlagMinimum"
                                                               [stDevFlagMaximum]="taskOutput.StdDevFlagMaximum"
                                                               (flagIsCheckedChange)="cohortStatsFlagIsCheckedChange(taskOutput, $event)"
                                                               (avgFlagMaximumChange)="avgFlagMaximumChange(taskOutput, $event)"
                                                               (avgFlagMinimumChange)="avgFlagMinimumChange(taskOutput, $event)"
                                                               (mdnFlagMaximumChange)="mdnFlagMaximumChange(taskOutput, $event)"
                                                               (mdnFlagMinimumChange)="mdnFlagMinimumChange(taskOutput, $event)"
                                                               (stDevFlagMaximumChange)="stDevFlagMaximumChange(taskOutput, $event)"
                                                               (stDevFlagMinimumChange)="stDevFlagMinimumChange(taskOutput, $event)">
                                            </cohort-stats-flag>
                                        </div>
                                        <div class="col-sm-12" *ngIf="!hideDecimalPlaces">
                                            <label class="col-form-label">Decimal Places</label>
                                            <input type="number" [name]="'decimalPlaces_' + index"
                                                   min="0"
                                                   max="10"
                                                   step="1"
                                                   style="width: 75px;"
                                                   [(ngModel)]="taskOutput.DecimalPlaces"
                                                   [disabled]="!canEditInputsAndOutputsForUsedTasks" />
                                            <info-tooltip [text]="'Select the number of decimal places to which the system will round values once inputted, calculated, or inherited in the Workflow facet. Input &quot;0&quot; to only see whole numbers. Note that standard rounding conventions apply. Only the rounded value will be stored in the database if this feature is enabled.'">
                                            </info-tooltip>
                                        </div>
                                    </div>
                                    <div class="form-group row"
                                         *ngIf="taskOutput.cv_DataType?.DataType === DataType.INHERITED_MOST_RECENT ||
                                            taskOutput.cv_DataType?.DataType === DataType.INHERITED_FIRST_OCCURRENCE ||
                                            taskOutput.cv_DataType?.DataType === DataType.INHERITED_SECOND_MOST_RECENT ||
                                            taskOutput.cv_DataType?.DataType === DataType.INHERITED_THIRD_MOST_RECENT">
                                        <div class="col-md-12 task-select-container mt-1">
                                            <task-output-select [(model)]="taskOutput.C_InheritedFromOutput_key"
                                                                [currentOutput]="taskOutput"
                                                                [defaultWorkflowTaskKey]="task.C_WorkflowTask_key"
                                                                (outputSelectionChange)="onSelectedOutputChange(taskOutput, $event)"
                                                                [disabled]="!canEditInputsAndOutputsForUsedTasks">
                                                [allowOnlyNumeric]="true">
                                            </task-output-select>
                                        </div>
                                        <div class="col-md-12"
                                             style="display: inline-block"
                                             *ngIf="taskOutput.InheritedFromOutput?.cv_DataType?.DataType === DataType.NUMBER ||
                                                taskOutput.InheritedFromOutput?.cv_DataType?.DataType === DataType.CALCULATED">
                                            <output-flag [taskOutput]="taskOutput"
                                                         [facet]="facet"
                                                         [index]="index">
                                            </output-flag>
                                        </div>
                                        <div class="col-md-12 cohort-stats"
                                             style="display: inline-block"
                                             *ngIf="taskOutput.InheritedFromOutput?.cv_DataType?.DataType === DataType.NUMBER ||
                                                taskOutput.InheritedFromOutput?.cv_DataType?.DataType === DataType.CALCULATED">
                                            <cohort-stats-flag [disabled]="!canEditInputsAndOutputsForUsedTasks"
                                                               [flagIsChecked]="taskOutput.HasCohortStatsFlag"
                                                               [avgFlagMinimum]="taskOutput.AverageFlagMinimum"
                                                               [avgFlagMaximum]="taskOutput.AverageFlagMaximum"
                                                               [mdnFlagMinimum]="taskOutput.MedianFlagMinimum"
                                                               [mdnFlagMaximum]="taskOutput.MedianFlagMaximum"
                                                               [stDevFlagMinimum]="taskOutput.StdDevFlagMinimum"
                                                               [stDevFlagMaximum]="taskOutput.StdDevFlagMaximum"
                                                               (flagIsCheckedChange)="cohortStatsFlagIsCheckedChange(taskOutput, $event)"
                                                               (avgFlagMaximumChange)="avgFlagMaximumChange(taskOutput, $event)"
                                                               (avgFlagMinimumChange)="avgFlagMinimumChange(taskOutput, $event)"
                                                               (mdnFlagMaximumChange)="mdnFlagMaximumChange(taskOutput, $event)"
                                                               (mdnFlagMinimumChange)="mdnFlagMinimumChange(taskOutput, $event)"
                                                               (stDevFlagMaximumChange)="stDevFlagMaximumChange(taskOutput, $event)"
                                                               (stDevFlagMinimumChange)="stDevFlagMinimumChange(taskOutput, $event)">
                                            </cohort-stats-flag>
                                        </div>
                                        <div class="col-sm-12" *ngIf="!hideDecimalPlaces">
                                            <label class="col-form-label">Decimal Places</label>
                                            <input type="number" [name]="'decimalPlaces_' + index"
                                                   min="0"
                                                   max="10"
                                                   step="1"
                                                   style="width: 75px;"
                                                   [(ngModel)]="taskOutput.DecimalPlaces"
                                                   [disabled]="!canEditInputsAndOutputsForUsedTasks" />
                                            <info-tooltip [text]="'Select the number of decimal places to which the system will round values once inputted, calculated, or inherited in the Workflow facet. Input &quot;0&quot; to only see whole numbers. Note that standard rounding conventions apply. Only the rounded value will be stored in the database if this feature is enabled.'">
                                            </info-tooltip>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center col-size-fit">
                                    <p class="form-control-static">
                                        <input type="checkbox" [name]="'taskOutputIsActive_' + index"
                                               [(ngModel)]="taskOutput.IsActive"
                                               [disabled]="!canEditInputsAndOutputsForUsedTasks" />
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- Output Options -->
            <div *ngIf="!isReadOnly && useJobsClassic"
                 class="form-group row">
                <label class="col-md-2 col-form-label">Output Options</label>
                <div class="col-md-10">
                    <div class="form-check">
                        <label class="form-check-label">
                            <input type="checkbox" name="taskSingleOutputPerAnimal"
                                   class="form-check-input"
                                   [(ngModel)]="task.SingleOutputPerAnimal" />
                            Restrict Duplicate Materials
                            <info-tooltip [text]="'Limit each material to one output row. Check this option to prevent duplicate output collections for an animal or sample in a task.'"></info-tooltip>
                        </label>
                    </div>
                    <div class="form-check">
                        <label class="form-check-label">
                            <input type="checkbox" name="taskSingleAnimal"
                                   class="form-check-input"
                                   [(ngModel)]="task.SingleAnimal" />
                            Single Material Per Row
                            <info-tooltip [text]="'Limit each output row to one animal or sample. Additional animal properties may be displayed in the output row.'">
                            </info-tooltip>
                        </label>
                    </div>

                    <!-- Single-animal TaskOutputProperties -->
                    <div class="output-options-group"
                         [ngClass]="{'text-muted': !task.SingleAnimal}">
                        <h5>Animal Properties To Display</h5>
                        <div class="form-check" *ngFor="let taskOutputProperty of taskOutputProperties">
                            <label class="form-check-label">
                                <input type="checkbox" name="{{taskOutputProperty.fieldName}}"
                                       class="form-check-input"
                                       [disabled]="!task.SingleAnimal"
                                       [(ngModel)]="task[taskOutputProperty.fieldName]"
                                       [disabled]="!task.SingleAnimal" />
                                {{taskOutputProperty.name}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    </form>
</div>
