import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChildren,
} from '@angular/core';

import { OrderService } from '../order.service';
import { OrderVocabService } from '../order-vocab.service';

import { BaseFilter } from '@common/facet';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    focusElementByQuery
} from '@common/util';
import { CurrentWorkgroupService } from '@services/current-workgroup.service';
import { TranslationService } from '@services/translation.service';
import { FeatureFlagService } from '@services/feature-flags.service';
import { NgModel } from '@angular/forms';
import { LoggingService } from '@services/logging.service';
import { dateControlValidator } from '@common/util/date-control.validator';
import { cv_OrderStatus, Entity } from '@common/types';


@Component({
    selector: 'order-filter',
    templateUrl: './order-filter.component.html'
})
export class OrderFilterComponent extends BaseFilter implements OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() filter: any = {};
    @Output() onFilter = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();

    // CVs
    materialTypes: any[] = [];
    taxons: any[] = [];
    orderStatuses: Entity<cv_OrderStatus>[] = []
    materialOrigins: any[] = [];
    quarantineFacilities: any[] = [];
    sexes: any[] = [];
    shipmentAnimals: any[] = [];
    shipmentContainers: any[] = [];

    institutions: any[] = [];
    currentWorkgroupKey: number;

    institutionTranslation: string;

    isGLP = false;

    constructor(
        private orderVocabService: OrderVocabService,
        private activeModal: NgbActiveModal,
        private currentWorkgroupService: CurrentWorkgroupService,
        private orderService: OrderService,
        private translationService: TranslationService,
        private featureFlagService: FeatureFlagService,
        private loggingService: LoggingService,
    ) {
        super(activeModal);
    }

    ngOnInit() {
        this.initIsGLP();
        this.cloneFilter();

        this.currentWorkgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();
        this.getInstitutions();
        this.getMaterialOrigins();
        this.getCVs();
        this.institutionTranslation = this.translationService.translate("Institution");

        focusElementByQuery('[data-auto-focus="Name"]');
    }

    initIsGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    private cloneFilter() {
        const dateReceivedStart = this.filter.DateReceivedStart;
        const dateReceivedEnd = this.filter.DateReceivedEnd;
        const dateProjectedReceiptStart = this.filter.DateProjectedReceiptStart;
        const dateProjectedReceiptEnd = this.filter.DateProjectedReceiptEnd;

        this.filter = this.copyFilter(this.filter);

        this.filter.DateReceivedStart = dateReceivedStart;
        this.filter.DateReceivedEnd = dateReceivedEnd;
        this.filter.DateProjectedReceiptStart = dateProjectedReceiptStart;
        this.filter.DateProjectedReceiptEnd = dateProjectedReceiptEnd;
    }

    private getCVs() {
        this.orderVocabService.materialTypes$
            .subscribe((data) => {
                this.materialTypes = data;
            });

        this.orderVocabService.taxons$
            .subscribe((data) => {
                this.taxons = data;
            });

        this.orderVocabService.orderStatuses$
            .subscribe((data) => {
                this.orderStatuses = data;
            });

        this.orderVocabService.quarantineFacilities$
            .subscribe((data) => {
                this.quarantineFacilities = data;
            });

        this.orderVocabService.sexes$
            .subscribe((data) => {
                this.sexes = data;
            });

        this.orderVocabService.shipmentAnimals$
            .subscribe((data) => {
                this.shipmentAnimals = data;
            });

        this.orderVocabService.shipmentContainers$
            .subscribe((data) => {
                this.shipmentContainers = data;
            });
    }

    filterClicked(event: Event) {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }
        event.preventDefault();
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    closeClicked() {
        this.close.emit();
        this.activeModal.dismiss();
    }

    clearClicked() {
        this.filter = {};
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    // Formatters for <select> input

    getInstitutions() {
        this.orderService.getInstitutions(false, false).then((data) => {
            this.institutions = data;
        });
    }

    getMaterialOrigins() {
        this.orderVocabService.materialOrigins$.subscribe((data) => {
            this.materialOrigins = data;
        });
    }

    materialOriginKeyFormatter = (value: any) => {
        return value.C_MaterialOrigin_key;
    }
    materialOriginFormatter = (value: any) => {
        return value.MaterialOrigin;
    }
}
