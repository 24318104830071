import { Component, OnDestroy, OnInit } from '@angular/core';
import { cv_Diet, cv_Sex } from '@common/types';
import { OrderDetailService } from '../../services/order-detail.service';
import { ORDER_DETAIL_FIELD, OrderActiveField } from '../../order-setting-fields';
import { Subscription } from 'rxjs';

const PERMIT_AND_JOB_INFORMATION_FIELDS = Object.values(ORDER_DETAIL_FIELD.permitAndJobInformation).map(field => field.name)
const ANIMAL_INFORMATION_FIELDS = Object.values(ORDER_DETAIL_FIELD.animalInformation).map(field => field.name)
const HOUSING_FIELDS = Object.values(ORDER_DETAIL_FIELD.housingAndLocationInformation).map(field => field.name)
const RECEIPT_INSTRUCTION_FIELDS = Object.values(ORDER_DETAIL_FIELD.receiptInstructions).map(field => field.name)
const PROCUREMENT_INFORMATION_FIELDS = Object.values(ORDER_DETAIL_FIELD.procurementInformation).map(field => field.name)

@Component({
    selector: 'order-detail-animal-form',
    templateUrl: './order-detail-animal-form.component.html',
    styleUrls: ['./order-detail-animal-form.component.scss']
})
export class OrderDetailAnimalFormComponent implements OnInit, OnDestroy {
    private subscription: Subscription;

    isShowPermitAndJobInformation = false;
    isShowAnimalInformation = false;
    isShowHousingAndLocationInformation = false;
    isShowReceiptInstruction = false;
    isShowProcurementInformation = false;

    permitAndJobInformationField = ORDER_DETAIL_FIELD.permitAndJobInformation
    animalInformationField = ORDER_DETAIL_FIELD.animalInformation
    housingAndLocationInformationField = ORDER_DETAIL_FIELD.housingAndLocationInformation
    receiptInstructionsField = ORDER_DETAIL_FIELD.receiptInstructions
    procurementInformationField = ORDER_DETAIL_FIELD.procurementInformation

    constructor(
        public orderDetailService: OrderDetailService,
    ) { }

    ngOnInit(): void {
        // If initialize method was already called (when we change Order Type)
        if (this.orderDetailService.activeFields.size > 0) {
            this.calculateSectionsVisibility()
        }

        // When initialize method re-called
        this.subscription = this.orderDetailService.activeFieldsChanged$.subscribe(() => {
            this.calculateSectionsVisibility()
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    calculateSectionsVisibility(): void {
        this.isShowPermitAndJobInformation = this.isSectionVisible(PERMIT_AND_JOB_INFORMATION_FIELDS);
        this.isShowAnimalInformation = this.isSectionVisible(ANIMAL_INFORMATION_FIELDS);
        this.isShowHousingAndLocationInformation = this.isSectionVisible(HOUSING_FIELDS);
        this.isShowReceiptInstruction = this.isSectionVisible(RECEIPT_INSTRUCTION_FIELDS);
        this.isShowProcurementInformation = this.isSectionVisible(PROCUREMENT_INFORMATION_FIELDS);
    }

    sexKeyFormatter = (value: cv_Sex): number => {
        return value.C_Sex_key;
    }
    sexFormatter = (value: cv_Sex): string => {
        return value.Sex;
    }

    dietKeyFormatter = (value: cv_Diet): number => {
        return value.C_Diet_key;
    }
    dietFormatter = (value: cv_Diet): string => {
        return value.Diet;
    }

    private isSectionVisible(sectionFields: OrderActiveField[]): boolean {
        return sectionFields.some(fieldName => this.orderDetailService.activeFields.has(fieldName));
    }
}
