/**
     * Helper to return a string or a default if the string is not set.
     *
     * Slightly different than notEmpty() in that '0' is a valid value.
     *
     * @param value Possibly missing value
     * @param defaultValue Value to use if the actual value is missing
     */
export const defaultString = (value: string, defaultValue: string) => {
    if ((value === null) || (value === undefined)) {
        return defaultValue;
    }

    return value;
}