import { ORDER_FACET_COMPONENT } from '../dynamic/lazy-components.token';
import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { SearchSharedModule } from './../search/search-shared.module';

import { EnumerationsSharedModule } from './../enumerations/enumerations-shared.module';
import { CharacteristicsSharedModule } from './../characteristics/characteristics-shared.module';
import { OrderFacetComponent } from './order-facet.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { OrdersSharedModule } from './orders-shared.module';

import { AuditSharedModule } from '../audit/audit-shared.module';
import { LinesSharedModule } from '../lines/lines-shared.module';
import { UsersSharedModule } from '../user/users-shared.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';
import { JobsSharedModule } from '../jobs/jobs-shared.module';
import { LotsTableComponent } from './orders-detail/components/order-detail-general-form/components/sample-order-detail/components/lots-table/lots-table.component';

import { NgxMaskModule } from 'ngx-mask';
import { OrderAnimalTableComponent } from './orders-detail/components/order-detail-animal-form/components/order-animal-table/order-animal-table.component';
import { LocationsSharedModule } from '../locations/locations-shared.module';

import { InstitutionsSharedModule } from '../institution/institutions-shared.module';
import { OrderDetailGeneralFormComponent } from './orders-detail/components/order-detail-general-form/order-detail-general-form.component';
import { OrderDetailAnimalFormComponent } from './orders-detail/components/order-detail-animal-form/order-detail-animal-form.component';
import { OrderDetailSummaryComponent } from './orders-detail/components/order-detail-summary/order-detail-summary.component';
import { SampleOrderDetailComponent } from './orders-detail/components/order-detail-general-form/components/sample-order-detail/sample-order-detail.component';
import { OrderInstitutionTableComponent } from './orders-detail/components/order-institution-table/order-institution-table.component';
import { OrderLocationTableComponent } from './orders-detail/components/order-location-table/order-location-table.component';
import { OrderDetailComponent } from './orders-detail/order-detail.component';
import { OrderAnimalReceiptCheckTableComponent } from './orders-detail/components/order-detail-animal-form/components/order-animal-receipt-check-table/order-animal-receipt-check-table.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        CharacteristicsSharedModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        OrdersSharedModule,
        AuditSharedModule,
        EnumerationsSharedModule,
        LinesSharedModule,
        SearchSharedModule,
        UsersSharedModule,
        VocabulariesSharedModule,
        WorkspacesSharedModule,
        NgxMaskModule.forChild(),
        JobsSharedModule,
        LocationsSharedModule,
        InstitutionsSharedModule,
    ],
    declarations: [
        OrderFacetComponent,
        OrderDetailComponent,
        OrderDetailSummaryComponent,
        OrderDetailGeneralFormComponent,
        OrderDetailAnimalFormComponent,
        SampleOrderDetailComponent,
        LotsTableComponent,
        OrderAnimalTableComponent,
        OrderAnimalReceiptCheckTableComponent,
        OrderLocationTableComponent,
        OrderInstitutionTableComponent
    ],
    providers: [
        { provide: ORDER_FACET_COMPONENT, useValue: OrderFacetComponent },
    ]
})
export class OrdersModule { }

