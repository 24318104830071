import { DataContextService } from '@services/data-context.service';
import { DataManagerService } from '@services/data-manager.service';
import { LoggingService } from '@services/logging.service';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';

import { AnimalService } from '../../../animals/services/animal.service';
import { CohortService } from '../../services/cohort.service';
import { CopyBufferService } from '@common/services/copy-buffer.service';
import { MaterialService } from '@services/material.service';
import {
    ViewTaskOutputSelectComponentService
} from '../../../tasks/outputs/view-task-output-select-component.service';

import { DroppableEvent } from '@common/droppable-event';
import { TableSort } from '@common/models';
import {
    notEmpty, uniqueArrayFromPropertyPath, animalAgeDays, animalAgeMonths, animalAgeWeeks, formatDecimal, setSafeProp, sortObjectArrayByAccessor, currentMaterialHousingID, priorMaterialHousingID
} from '@common/util';

import {
    CohortOutputStatsRowsComponent
} from '../cohort-output-stats-rows/cohort-output-stats-rows.component';
import { TranslationService } from '@services/translation.service';
import { ColumnSelectLabel, ColumnSelect } from '@common/facet';
import { BooleanMap } from '../../../workflow/models/workflow-bulk-data';
import { WorkspaceService } from '../../../workspaces/workspace.service';
import { ColumnSelectConfig } from '../../../jobs/pharma/services/job-pharma-detail.service';
import { countDecimalPlaces } from '@common/util/count-decimal';
import { FeatureFlagService } from '@services/feature-flags.service';
import { Animal, Cohort, CohortMaterial, Entity } from "@common/types";
import { ExtendedCohortMaterial } from '../..';
import { getGenotypesString } from '../../../common/util/genotypes';

@Component({
    selector: 'cohort-animal-table',
    templateUrl: './cohort-animal-table.component.html',
    styles: [`
        .output-value-header {
            vertical-align: top;
        }

        .disabled {
            cursor: not-allowed;
        }

        .table::ng-deep th {
            border-top: 1px solid #ddd;
        }
    `]
})
export class CohortAnimalTableComponent implements OnChanges, OnDestroy, OnInit {
    @Input() facet: any;
    @Input() cohort: Entity<Cohort>;
    @Input() cohortMaterials: ExtendedCohortMaterial[];
    // The maximum number of outputs that can be selected
    @Input() maxOutputs: number;
    @Input() readonly: boolean;
    @Input() tableSort: TableSort;

    @Output() cohortMaterialsChange: EventEmitter<void> = new EventEmitter<void>();
    @Output() cohortMaterialsSelectionChange: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Output() selectedOutputsChange: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('cohortOutputStatsRows')
    cohortOutputStatsRows: CohortOutputStatsRowsComponent;

    // Checkbox for selecting all animals
    allAnimalsSelected = false;

    // Column Select state
    columnSelect: ColumnSelect = {model: [], labels: []};

    // Table column visibility flags
    visible: BooleanMap = {};

    // Is the cohort used in a study
    isCohortUsed = false;

    // Show animal selection modal
    showOutputRangeModal = false;

    // Output Range modal values
    outputRangeModal: any = {
        output1IsSelected: false,
        output1Minimum: null,
        output1Maximum: null,
        output2IsSelected: false,
        output2Minimum: null,
        output2Maximum: null,
        output3IsSelected: false,
        output3Minimum: null,
        output3Maximum: null
    };

    // Default table sort property path
    readonly TABLE_SORT_PROPERTY_PATH_DEFAULT = 'Material.Animal.AnimalNameSortable';
    readonly TABLE_SORT_GENOTYPES_PROPERTY_PATH = 'Genotypes';
    readonly TABLE_SORT_HOUSING_ID_PROPERTY_PATH = 'HousingID';
    readonly TABLE_SORT_PRIOR_HOUSING_ID_PROPERTY_PATH = 'PriorHousingID';
    readonly TABLE_SORT_ANIMAL_AGE_DAYS_PROPERTY_PATH = 'AgeDays';
    readonly TABLE_SORT_ANIMAL_AGE_WEEKS_PROPERTY_PATH = 'AgeWeeks';
    readonly TABLE_SORT_ANIMAL_AGE_MONTHS_PROPERTY_PATH = 'AgeMonths';
    readonly OUTPUT_RANGE_DROPDOWN_DEFAULT_WIDTH = 600;
    readonly OUTPUT_RANGE_DROPDOWN_COLUMN_WIDTH = 225;
    // this is for testing purpose of standalone function sortObjectArrayByAccessor
    sortObjectArrayByAccessor = sortObjectArrayByAccessor;

    animalField: any = {};

    isCRO = false;
    isGLP = false;

    private countSelectedCohortMaterials = 0;
    public countCohortMaterialLabel = '0 Animals Selected';

    constructor(
        private animalService: AnimalService,
        private cohortService: CohortService,
        private copyBufferService: CopyBufferService,
        private dataContext: DataContextService,
        private dataManager: DataManagerService,
        private loggingService: LoggingService,
        private materialService: MaterialService,
        private translationService: TranslationService,
        private viewTaskOutputSelectComponentService: ViewTaskOutputSelectComponentService,
        private workspaceService: WorkspaceService,
        private featureFlagService: FeatureFlagService,
    ) {
        this.initIsCRO();
        this.initIsGLP();
    }

    ngOnInit() {
        this.isCohortUsed = this.cohort.JobCohort ? this.cohort.JobCohort.length > 0 : false;
        this.initUpdateObject();
        this.initialize();
    }

    ngOnDestroy() {
        if (notEmpty(this.cohortMaterials)) {
            // Clear selections
            for (const cohortMaterial of this.cohortMaterials) {
                cohortMaterial.isSelected = false;
            }
        }
    }

    ngOnChanges(changes: any) {
        if (changes.cohort && !changes.cohort.firstChange) {
            this.initialize();
            this.cohortOutputStatsRows?.calculate(changes.cohort.currentValue);
        }
        this.setSelectedCohortMaterialCount();
    }

    initialize() {
        this.initColumnSelect();
        this.allAnimalsSelected = false;
        this._setDefaultTableSort();
        this.initOutputRangeModalValues();
        if (!this.cohortMaterials) {
            return;
        }
    }

    initOutputRangeModalValues() {
        this.outputRangeModal = {
            output1IsSelected: false,
            output1Minimum: null,
            output1Maximum: null,
            output2IsSelected: false,
            output2Minimum: null,
            output2Maximum: null,
            output3IsSelected: false,
            output3Minimum: null,
            output3Maximum: null
        };
    }

    initIsCRO() {
        const flag = this.featureFlagService.getFlag("IsCRO");
        this.isCRO = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    initIsGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    private _setDefaultTableSort() {
        if (!this.tableSort) {
            this.tableSort = new TableSort();
        }
        if (!this.tableSort.propertyPath) {
            this.tableSort.propertyPath = this.TABLE_SORT_PROPERTY_PATH_DEFAULT;
        }
    }

    private showAnimalEnrolledToast() {
        this.loggingService.logWarning("Animals cannot be added or removed from cohorts actively enrolled in studies.", null, "Cohorts Animal Table", true);
    }

    onDropMaterialToCohort(event: DroppableEvent) {
        if (this.isCohortUsed) {
            this.showAnimalEnrolledToast();
            return;
        }
        const newAnimals = this.animalService.draggedAnimals;
        this._processAddedAnimals(newAnimals);

        this.animalService.draggedAnimals = [];
        this.cohortMaterialsChange.emit();
    }

    pasteAnimalsIntoCohort() {
        if (this.isCohortUsed) {
            return;
        }
        if (this.copyBufferService.hasAnimals()) {
            const animals: any[] = this.copyBufferService.paste();
            this._processAddedAnimals(animals);
        }
    }

    clickSelectOutputRange() {
        jQuery('#select-output-range-dropdown').trigger('click');

        for (const cohortMaterial of this.cohortMaterials) {
            if (cohortMaterial.Material.Animal) {
                cohortMaterial.isSelected = this._cohortMaterialOutputInRange(0, cohortMaterial)
                    && this._cohortMaterialOutputInRange(1, cohortMaterial)
                    && this._cohortMaterialOutputInRange(2, cohortMaterial);
            }
        }

        this.cohortMaterialsSelectionChange.emit();
        this.showOutputRangeModal = false;
        this.initOutputRangeModalValues();
        this.setSelectedCohortMaterialCount();
    }

    get outputRangeDropdownMaxWidth(): string {
        const visibleColumnCount = [this.cohort.Output1, this.cohort.Output2, this.cohort.Output3].filter(Boolean).length;
        const maxWidth = visibleColumnCount === 0
            ? this.OUTPUT_RANGE_DROPDOWN_DEFAULT_WIDTH
            : this.OUTPUT_RANGE_DROPDOWN_COLUMN_WIDTH * visibleColumnCount;
        return maxWidth + 'px';
    }

    private _cohortMaterialOutputInRange(outputIndex: number, cohortMaterial: any): boolean {
        // return false if neither output is selected
        if (!this.outputRangeModal.output1IsSelected && !this.outputRangeModal.output2IsSelected && !this.outputRangeModal.output3IsSelected) {
            return false;
        }

        // Check if output1 is valid
        if (outputIndex === 0) {
            if (this.outputRangeModal.output1IsSelected) {
                if (cohortMaterial.OutputValue1 === null) {
                    return false;
                }
                // check if outputValue is within range
                const output1 = Number(cohortMaterial.OutputValue1);
                if (((this.outputRangeModal.output1Minimum !== null) && (output1 < this.outputRangeModal.output1Minimum))
                    || ((this.outputRangeModal.output1Maximum !== null) && (output1 > this.outputRangeModal.output1Maximum))) {
                    return false;
                }
            }

            // output is valid if previous steps haven't returned false
            return true;

        } else if (outputIndex === 1) { // Check if output2 is valid
            if (this.outputRangeModal.output2IsSelected) {
                if (cohortMaterial.OutputValue2 === null) {
                    return false;
                }

                // check if outputValue is within range
                const output2 = Number(cohortMaterial.OutputValue2);
                if (((this.outputRangeModal.output2Minimum !== null) && output2 < this.outputRangeModal.output2Minimum)
                    || ((this.outputRangeModal.output2Maximum !== null) && output2 > this.outputRangeModal.output2Maximum)) {
                    return false;
                }
            }

            // output is valid if previous steps haven't returned false
            return true;
        } else {// Check if output3 is valid (outputIndex === 2)
            if (this.outputRangeModal.output3IsSelected) {
                if (cohortMaterial.OutputValue3 === null) {
                    return false;
                }

                // check if outputValue is within range
                const output3 = Number(cohortMaterial.OutputValue3);
                if (((this.outputRangeModal.output3Minimum !== null) && output3 < this.outputRangeModal.output3Minimum)
                    || ((this.outputRangeModal.output3Maximum !== null) && output3 > this.outputRangeModal.output3Maximum)) {
                    return false;
                }
            }

            // output is valid if previous steps haven't returned false
            return true;
        }
    }

    /**
     * Converts dragged or pasted animals into CohortMaterials.
     *
     * @param animals
     */
    private _processAddedAnimals(animals: any[]): Promise<any> {
        const cohortKey = this.cohort.C_Cohort_key;
        const newCohortMaterials: any[] = [];

        for (const animal of animals) {
            const initialValues = {
                C_Cohort_key: cohortKey,
                C_Material_key: animal.C_Material_key
            };
            const newCohortMaterial = this.cohortService.createCohortMaterial(initialValues);

            if (newCohortMaterial) {
                newCohortMaterial.isSelected = false;
                newCohortMaterials.push(newCohortMaterial);
            }
        }

        this.cohortMaterialsSelectionChange.emit();

        if (this.cohort.C_Cohort_key <= 0) {
            // Cohort has not been saved yet
            return Promise.resolve(null);
        }

        const materialKeys = uniqueArrayFromPropertyPath(
            newCohortMaterials, 'C_Material_key'
        );

        // Get output values if Cohort has saved outputs
        let p1 = null;
        let p2 = null;
        let p3 = null;
        if (this.cohort.Output1) {
            p1 = this.refreshOutputValues(null, 0, materialKeys);
        }
        if (this.cohort.Output2) {
            p2 = this.refreshOutputValues(null, 1, materialKeys);
        }
        if (this.cohort.Output3) {
            p3 = this.refreshOutputValues(null, 2, materialKeys);
        }

        return Promise.all([p1, p2, p3]).then(() => {
            return this.dataContext.saveSingleRecordBatch(newCohortMaterials)
                .then(() => {
                    // refresh any created JobMaterials
                    return this.dataManager.refreshEntityCollection(
                        "Material", "JobMaterial", materialKeys
                    ).then(() => {
                        return this.cohortService.refreshCohortJobs(this.cohort.C_Cohort_key);
                    });
                });
        });
    }

    copyAnimals() {
        const animalsToCopy = [];

        for (const cohortMaterial of this.cohortMaterials) {
            if (cohortMaterial.isSelected && cohortMaterial.Material.Animal) {
                animalsToCopy.push(cohortMaterial.Material.Animal);
            }
        }

        this.copyBufferService.copy(animalsToCopy);
    }

    selectAllAnimals(selectAll: boolean) {
        jQuery('#select-output-range-dropdown').trigger('click');

        for (const cohortMaterial of this.cohortMaterials) {
            if (cohortMaterial.Material.Animal) {
                cohortMaterial.isSelected = selectAll;
            }
        }
        this.cohortMaterialsSelectionChange.emit();
        this.setSelectedCohortMaterialCount();
    }

    cohortMaterialSelected(cohortMaterial: any) {
        this.cohortMaterialsSelectionChange.emit();
        this.setSelectedCohortMaterialCount();
    }

    setSelectedCohortMaterialCount() {
        let count = 0;
        for (const cohortMaterial of this.cohortMaterials) {
            if (cohortMaterial.Material && cohortMaterial.Material.Animal && cohortMaterial.isSelected) {
                count++;
            }
        }
        this.countSelectedCohortMaterials = count;
        this.setCohotMaterialCountLabel();
    }

    private setCohotMaterialCountLabel() {
        this.countCohortMaterialLabel = `${this.countSelectedCohortMaterials} Animal${this.countSelectedCohortMaterials === 1 ? '' : 's'} Selected`;
    }

    dragStart() {
        const animalsToCopy = [];
        for (const cohortMaterial of this.cohortMaterials) {
            if (cohortMaterial.isSelected && cohortMaterial.Material.Animal) {
                animalsToCopy.push(cohortMaterial.Material.Animal);
            }
        }
        this.animalService.draggedAnimals = animalsToCopy;
    }

    dragStop() {
        setTimeout(() => {
            this.animalService.draggedAnimals = [];
        }, 500);
    }

    refreshOutputValues(event: any, outputIndex: number, newMaterialKeys: number[] = []): Promise<any> {
        const materialKeys = newMaterialKeys.length === 0 ? this._getMaterialKeys(this.cohortMaterials) : newMaterialKeys;
        let outputKey;
        let outputDecimalPlaces: any;
        if (outputIndex === 0) {
            outputKey = this.cohort.C_Output1_key;
            outputDecimalPlaces = this.cohort.Output1.DecimalPlaces;
        } else if (outputIndex === 1) {
            outputKey = this.cohort.C_Output2_key;
            outputDecimalPlaces = this.cohort.Output2.DecimalPlaces;
        } else if (outputIndex === 2) {
            outputKey = this.cohort.C_Output3_key;
            outputDecimalPlaces = this.cohort.Output3.DecimalPlaces;
        }
        return this.materialService.getLatestTaskOutputValues(
            materialKeys, outputKey
        ).then((materialOutputValues: any[]) => {
            for (const materialOutputValue of materialOutputValues) {
                const materialKey = materialOutputValue.MaterialKey;
                let outputValue = materialOutputValue.OutputValue;
                if (outputDecimalPlaces && outputValue) {
                    if (countDecimalPlaces(outputValue) > outputDecimalPlaces) {
                        outputValue = formatDecimal(outputValue, outputDecimalPlaces);
                    }
                }
                // Assign to the matching CohortMaterial
                const cohortMaterial = this._findCohortMaterialInModel(materialKey);
                if (cohortMaterial) {
                    if (outputIndex === 0) {
                        cohortMaterial.OutputValue1 = outputValue;
                    } else if (outputIndex === 1) {
                        cohortMaterial.OutputValue2 = outputValue;
                    } else if (outputIndex === 2) {
                        cohortMaterial.OutputValue3 = outputValue;
                    }
                }
            }
            this.onSelectedOutputsChange();
            return Promise.resolve();
        });
    }

    addOutputAndValues(outputIndex: number): Promise<any> {
        // Get the Output from selection modal
        return this.viewTaskOutputSelectComponentService.openComponent()
            .then((output: any) => {
                if (output) {
                    if (outputIndex === 0) {
                        this.cohort.C_Output1_key = output.C_Output_key;
                    } else if (outputIndex === 1) {
                        this.cohort.C_Output2_key = output.C_Output_key;
                    } else if (outputIndex === 2) {
                        this.cohort.C_Output3_key = output.C_Output_key;
                    }
                    this.refreshOutputValues(null, outputIndex);
                }
            });
    }

    private _getMaterialKeys(materials: any[]): number[] {
        return materials.map((item) => {
            return item.C_Material_key;
        });
    }

    private _findCohortMaterialInModel(materialKey: number): any {
        return this.cohortMaterials.find((item) => {
            return item.C_Material_key === materialKey;
        });
    }

    async removeSelectedAnimals() {
      let toastShown = false;
      jQuery('#select-output-range-dropdown').click();
      if (!toastShown && this.isCohortUsed) {
          this.showAnimalEnrolledToast();
          toastShown = true;
          return
      }
      try {
        await Promise.all(
          this.cohortMaterials
          .filter((cohortMaterial) => cohortMaterial.isSelected)
          .map(material => this.removeCohortMaterial(material))
        );

        this.cohortMaterialsSelectionChange.emit();
        this.setSelectedCohortMaterialCount();
      } catch (error) {
        this.loggingService.logError(error.message, null, null, false);
      }
  }

  async removeAnimal(cohortMaterial: any) {
    try {
      await this.removeCohortMaterial(cohortMaterial);
      this.setSelectedCohortMaterialCount();
      this.cohortMaterialsSelectionChange.emit();
      this.cohortMaterialsChange.emit();
    } catch (error) {
      this.loggingService.logError(error.message, null, null, false);
    }
  }

  private async removeCohortMaterial(cohortMaterial: any) {
    if (this.isCohortUsed || !cohortMaterial) {
      throw new Error('Cohort is used or cohort material does not exist');
    }

    this.cohortService.deleteCohortMaterial(cohortMaterial);

    if (this.cohort.C_Cohort_key <= 0) {
      return;
    }

    await this.processRemovedCohortMaterials(cohortMaterial);
  }

    private async processRemovedCohortMaterials(cohortMaterial: any) {
      await this.dataContext.saveSingleRecordBatch([cohortMaterial])
      if (this.cohort.JobCohort.length > 0) {
          await this.dataManager.refreshEntityCollection(
              "Material", "JobMaterial", [cohortMaterial.C_Material_key]
          );
      }

      const numberOfJobs = await this.cohortService.refreshCohortJobs(cohortMaterial.C_Cohort_key);
      if (numberOfJobs <= 0) {
        return;
      }

      const jobsText = this.translationService.translateMessage(numberOfJobs === 1 ? 'job' : 'jobs');
      this.loggingService.logWarning(
          `Animal removed from ${numberOfJobs} ${jobsText}`, null, null, true
      );
    }

    private _clearOutputValues(outputIndex: number) {
        for (const cohortMaterial of this.cohortMaterials) {
            if (outputIndex === 0) {
                cohortMaterial.OutputValue1 = null;
            } else if (outputIndex === 1) {
                cohortMaterial.OutputValue2 = null;
            } else if (outputIndex === 2) {
                cohortMaterial.OutputValue3 = null;
            }
        }
    }

    removeSelectedOutput(outputIndex: number) {
        this._clearOutputValues(outputIndex);

        if (outputIndex === 0) {
            this.cohort.C_Output1_key = null;
        } else if (outputIndex === 1) {
            this.cohort.C_Output2_key = null;
        } else if (outputIndex === 2) {
            this.cohort.C_Output3_key = null;
        }

        this.onSelectedOutputsChange();
    }

    onSelectedOutputsChange() {
        this.selectedOutputsChange.emit();
        this.cohortOutputStatsRows?.calculate(this.cohort);
    }

    /**
     * Initialize the column selection and visibility flags.
     */
    initColumnSelect() {
        // Assemble the list of all columns that can be selected
        const labels = [
            // Use [] to note columns that can be selected but are not
            // currently visible because the data is in the header.
            new ColumnSelectLabel('AnimalID', 'ID'),
            new ColumnSelectLabel('AnimalName', 'Name'),
            new ColumnSelectLabel('Genotype', 'Genotype'),
            new ColumnSelectLabel('Sex', 'Sex'),
            new ColumnSelectLabel('Status', 'Status'),
            new ColumnSelectLabel('MicrochipID', 'Microchip ID'),
            new ColumnSelectLabel('Marker', 'Marker'),
            new ColumnSelectLabel('ExternalID', 'External ID'),
            new ColumnSelectLabel('HousingID', 'Housing ID'),
            new ColumnSelectLabel('PriorHousingID', 'Prior Housing ID'),
            new ColumnSelectLabel('AgeDays', 'Age (days)'),
            new ColumnSelectLabel('AgeWeeks', 'Age (weeks)'),
            new ColumnSelectLabel('AgeMonths', 'Age (months)'),
            new ColumnSelectLabel('Line', this.translationService.translate('Line')),
            new ColumnSelectLabel('OtherCohorts', 'Other Cohorts')
        ];

        this.columnSelect.labels = labels;

        // Default column visibility
        this.visible = {
            AnimalID: false,
            AnimalName: true,
            Genotype: true,
            Sex: true,
            Status: false,
            MicrochipID: false,
            Marker: false,
            ExternalID: false,
            HousingID: false,
            PriorHousingID: false,
            AgeDays: false,
            AgeWeeks: true,
            AgeMonths: false,
            Line: false,
            OtherCohorts: false
        };
        // Get the selected columns from the user configuration
        const config = this.parseColumnSelectConfig();
        this.columnSelect.model = labels.filter((item) => {
            const columnConfig = config[item.key];

            if (!columnConfig) {
                // No user config yet, so rely on the default visibility
                return this.visible[item.key];
            }

            // Columns are visible unless explicitly hidden
            return (columnConfig.visible !== false);
        }).map((item) => item.key);

        // Update the column visiblility
        this.updateVisible();
    }

    /**
     * Column selections have changed
     */
    columnSelectChanged(current: string[]) {
        // Get the current selections
        this.columnSelect.model = current;

        // Update the column visibilty
        this.updateVisible();

        this.saveColumnSelectConfig();
    }

    /**
     * Update the column visibility flags.
     */
    updateVisible() {
        // Make a lookup table
        const selected = {};
        this.columnSelect.model.forEach((key) => {
            selected[key] = true;
        });

        // Update the visibilty based on the column selections
        this.columnSelect.labels.forEach((column) => {
            const key = column.key;
            this.visible[key] = (selected[key] === true);
        });
    }

    ageInDays(cohortMaterial: CohortMaterial) {
        return animalAgeDays(cohortMaterial.Material?.Animal?.DateBorn, cohortMaterial.Material?.Animal?.DateExit);
    }

    ageInWeeks(cohortMaterial: CohortMaterial) {
        return animalAgeWeeks(cohortMaterial.Material?.Animal?.DateBorn, cohortMaterial.Material?.Animal?.DateExit);
    }

    ageInMonths(cohortMaterial: CohortMaterial) {
        return animalAgeMonths(cohortMaterial.Material?.Animal?.DateBorn, cohortMaterial.Material?.Animal?.DateExit);
    }

    /**
     * Parse the TaskGridConfiguration JSON string, or provide a blank config object
     *
     * Note: Currently this is "reusing" (squatting in) the
     * TaskGridConfiguration field in WorkspaceDetails.
     */
    private parseColumnSelectConfig(): ColumnSelectConfig {
        try {
            if (this.facet && this.facet.TaskGridConfiguration) {
                return JSON.parse(this.facet.TaskGridConfiguration);
            }
        } catch (e) {
            console.error('Could not parse TaskGridConfiguration', e);
        }

        return {};
    }

    /**
     * Save the column selections for this facet.
     *
     * Note: Currently this is "reusing" (squatting in) the
     * TaskGridConfiguration field in WorkspaceDetails.
     */
    private saveColumnSelectConfig(): Promise<any> {
        if (!this.facet) {
            return Promise.resolve();
        }
        // Start with a blank config
        const config: ColumnSelectConfig = {};

        // Map the selected columns
        const selected: BooleanMap = {};
        for (const key of this.columnSelect.model) {
            selected[key] = true;
        }

        // Getthe config for each column
        for (const item of this.columnSelect.labels) {
            const key = item.key;
            config[key] = {
                visible: selected[key] || false
            };
        }

        // Rebuild the TaskGridConfiguration JSON
        this.facet.TaskGridConfiguration = JSON.stringify(config);

        // Save just the TaskGridConfiguration value in the facet
        return this.workspaceService.saveTaskGridConfiguration(this.facet);
    }

    initUpdateObject() {
        this.animalField.__updateObject = {};
        setSafeProp(this.animalField.__updateObject, 'AnimalNames', undefined);
    }

    updateClicked() {
        const item = this.animalField.__updateObject;
        if (item.useNameFormatInput) {
            this.fillDownNameFormat(item);
        } else {
            this.fillDownName(item.AnimalNames);
        }
        this.initUpdateObject();
    }

    fillDownNameFormat(settings: any) {
        const prefix = settings.prefix || "";
        let counter = settings.counter;
        const suffix = settings.suffix || "";
        if (counter === null || counter === undefined) {
            const message = "Counter must be set to assign name values";
            this.loggingService.logWarning(message, null, "Cohorts Animal Table", true);
            return;
        }

        for (const animal of this.cohortMaterials) {
            animal.Material.Animal.AnimalName = prefix + counter + suffix;
            counter += 1;
        }
    }

    fillDownName(names: string[]) {
        if (names) {
            for (let i = 0; i < names.length; i++) {
                // only update the name if it's an existing animal
                if (this.cohortMaterials[i].C_Material_key > 0) {
                    this.cohortMaterials[i].Material.Animal.AnimalName = names[i];
                }
            }
        }
    }

    getGenotypesSortable(cohortMaterial: CohortMaterial): string {
        return getGenotypesString(cohortMaterial.Material?.Animal?.Genotype);
    }

    getCurrentHoisingID(cohortMaterial: CohortMaterial): string {
        return currentMaterialHousingID(cohortMaterial.Material?.MaterialPoolMaterial);
    }

    getPriorHoisingID(cohortMaterial: CohortMaterial): string {
        return priorMaterialHousingID(cohortMaterial.Material?.MaterialPoolMaterial);
    }

    customSort(sortPath: string) {
        this.tableSort.toggleSort(sortPath);

        if (!this.tableSort.propertyPath) {
            // If no property selected, restore original sort property
            this.tableSort.toggleSort(this.TABLE_SORT_PROPERTY_PATH_DEFAULT);
            return;
        }

        let sortAccessor: (x: any) => any;
        let reverse = this.tableSort.reverse;
        let natural = this.tableSort.natural;

        switch (sortPath) {
            case this.TABLE_SORT_GENOTYPES_PROPERTY_PATH:
                sortAccessor = this.getGenotypesSortable;
                break;
            case this.TABLE_SORT_HOUSING_ID_PROPERTY_PATH:
                sortAccessor = this.getCurrentHoisingID;
                break;
            case this.TABLE_SORT_PRIOR_HOUSING_ID_PROPERTY_PATH:
                sortAccessor = this.getPriorHoisingID;
                break;
            case this.TABLE_SORT_ANIMAL_AGE_DAYS_PROPERTY_PATH:
                sortAccessor = this.ageInDays;
                natural = true;
                reverse = !reverse;
                break;
            case this.TABLE_SORT_ANIMAL_AGE_WEEKS_PROPERTY_PATH:
                sortAccessor = this.ageInWeeks;
                natural = true;
                reverse = !reverse;
                break;
            case this.TABLE_SORT_ANIMAL_AGE_MONTHS_PROPERTY_PATH:
                sortAccessor = this.ageInMonths;
                natural = true;
                reverse = !reverse;
                break;
            default:
                throw `Unknown sort path ${sortPath}`;
        }
        this.sortObjectArrayByAccessor(this.cohortMaterials, sortAccessor, reverse, natural)
    }
}
