import { Component } from '@angular/core';
import { Resource, cv_MaterialOrigin, cv_MaterialType, cv_OrderStatus} from '@common/types';
import { OrderDetailService } from '../../services/order-detail.service';

@Component({
    selector: 'order-detail-summary',
    templateUrl: './order-detail-summary.component.html',
})
export class OrderDetailSummaryComponent {
    constructor(
        public orderDetailService: OrderDetailService,
    ) { }

    materialTypeKeyFormatter = (value: cv_MaterialType): number => {
        return value.C_MaterialType_key;
    }
    materialTypeFormatter = (value: cv_MaterialType): string => {
        return value.MaterialType;
    }

    orderStatusKeyFormatter = (value: cv_OrderStatus): number => {
        return value.C_OrderStatus_key;
    }
    orderStatusFormatter = (value: cv_OrderStatus): string => {
        return value.OrderStatus;
    }

    resourceKeyFormatter = (value: Resource): number => {
        return value.C_Resource_key;
    }
    resourceNameFormatter = (value: Resource): string => {
        return value.ResourceName;
    }

    materialOriginKeyFormatter = (value: cv_MaterialOrigin): number => {
        return value.C_MaterialOrigin_key;
    }
    materialOriginFormatter = (value: cv_MaterialOrigin): string => {
        return value.MaterialOrigin;
    }
}
