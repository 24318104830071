import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

import { ISupportIdGeneration, IValidatable, OnSaveResult, SaveChangesService, UnsavedChanges } from '@services/save-changes.service';

import { BaseDetail, BaseDetailService, FacetView, IFacet, PageState } from '@common/facet';
import { Subscription } from 'rxjs';
import { Entity, Order} from '@common/types';
import { OrderDetailService } from './services/order-detail.service';
import { TranslationService } from '../../services/translation.service';

@Component({
    selector: 'order-detail',
    templateUrl: './order-detail.component.html',
})
export class OrderDetailComponent extends BaseDetail
    implements OnInit, OnChanges, OnDestroy, IValidatable, OnSaveResult, ISupportIdGeneration {
    @Input() facet: IFacet;
    @Input() facetView: FacetView;
    @Input() order: Entity<Order>;
    @Input() pageState: PageState;

    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild("OrderForm") orderForm: NgForm;
    @ViewChildren('dateControl') dateControls: NgModel[];

    private readonly subs = new Subscription();

    constructor(
        public baseDetailService: BaseDetailService,
        public orderDetailService: OrderDetailService,

        private saveChangesService: SaveChangesService,
        private translationService: TranslationService,
    ) {
        super(baseDetailService);
    }

    async generateId(): Promise<void> {
        await this.orderDetailService.generateId()
    }

    // lifecycle
    ngOnInit(): void {
        this.saveChangesService.registerIdGenerator(this);
        this.saveChangesService.registerValidator(this);
        this.subs.add(this.saveChangesService.saveResult$.subscribe(() => {
          this.onSaveResult();
        }));

        this.orderDetailService.initialize(this.order, this.facet).then(() => {
            this.loggingService.logDebug('Order detail view is re-initialized', null, this.COMPONENT_LOG_TAG);
        });
    }

    ngOnChanges(changes: any): void {
        if (changes.order) {
            if (this.order && !changes.order.firstChange) {
                if (this.orderForm) {
                    this.orderForm.form.markAsPristine();
                }
                this.orderDetailService.initialize(this.order).then(() => {
                    this.loggingService.logDebug('Order detail view is re-initialized', null, this.COMPONENT_LOG_TAG);
                });
            }
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
        this.saveChangesService.unregisterIdGenerator(this);
        this.saveChangesService.unregisterValidator(this);
        this.orderDetailService.clearValues()
    }

    async validate(): Promise<string> {   
        return this.orderDetailService.validate(this.dateControls)
    }

    onCancel(): void {
        this.orderDetailService.onCancel();
    }

    onSaveResult(): void {
        this.orderDetailService.onSaveResult()
    }

    nextClicked(changes: UnsavedChanges): void {
        super.nextClicked(changes);
        this.orderDetailService.clearValues()
    }

    previousClicked(changes: UnsavedChanges): void {
        super.previousClicked(changes);
        this.orderDetailService.clearValues()
    }
}
