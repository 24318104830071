import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PermitService } from '../services/permit.service';
import { PermitSpecies } from '@common/types/models/permit-species.interface';
import { cv_MaterialOrigin, cv_Severity, cv_Taxon, Entity } from '@common/types';
import { Permit } from '@common/types/models/permit.interface';
import { TranslationService } from '@services/translation.service';
import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { PermitSpeciesLineForm } from '../models/permit-form.interfaces';

@Component({
    selector: 'permit-species',
    templateUrl: './permit-species.component.html',
})
export class PermitSpeciesComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() permit: Entity<Permit>;
    @Input() facetPrivilege: string;
    @Input() isReadWrite: boolean;
    @Input() required: boolean;
    @Input() requiredFields: string[];
    @Input() activeFields: string[];
    
    public severities: cv_Severity[] = [];
    public species: cv_Taxon[] = [];
    public materialOrigins: Entity<cv_MaterialOrigin>[] = [];

    public loadingMessage = 'Loading';
    public loading = true;

    public readonly MAX_ANIMALS_ON_PERMIT = 100000;

    constructor (
        private permitService: PermitService,
        private fb: FormBuilder,
        public translationService: TranslationService,
        private vocabularyService: VocabularyService,
    ) {}

    get permitSpeciesControl(): FormArray {
        return this.form.get('PermitSpecies') as FormArray;
    }

    public ngOnInit(): void {
        this.initCVs();
        if (this.permit.PermitSpecies.length == 0) {
            this.addPermitSpecies();
        }
    }

    public async initCVs(): Promise<void> {
        const result = await Promise.all([
            this.vocabularyService.getCV('cv_Severities'),
            this.vocabularyService.getCV('cv_Taxons'),
            this.vocabularyService.getCV('cv_MaterialOrigins'),
        ]);
        [this.severities, this.species, this.materialOrigins] = result;
        this.loading = false;
    }

    public addPermitSpecies(): void {
        const newPermitSpecies = this.permitService.createPermitSpecies(this.permit.C_Permit_key);
        this.permitSpeciesControl.push(this.fb.group({
            PermitSpeciesKey: [newPermitSpecies.C_PermitSpecies_key],
            SpeciesKey: [null],
            AnimalsOnPermit: [null, [Validators.min(0), Validators.pattern('^[0-9]+$')]],
            PermitSpeciesLine: null,
            SeverityKey: [null],
            NoRecoveryAllowed: [false],
            ReuseAllowed: [false],
            PermitSpeciesOrigin: null,
        }));
    }

    public deletePermitSpecies(item: Entity<PermitSpecies>, index: number): void {
        this.permitService.deletePermitSpecies(item);
        this.permitSpeciesControl.removeAt(index);
    }

    public onLineChange(value: PermitSpeciesLineForm[], index: number): void {
        const control = this.permitSpeciesControl.at(index).get('SpeciesKey');
        if (value.length) {
            if (!control.value) {
                control.setValue(value[0].TaxonKey);
            }
            return;
        }
        control.setValue(null);
    }

    severityKeyFormatter = (value: cv_Severity): number => {
        return value.C_Severity_key;
    }

    severityFormatter = (value: cv_Severity): string => {
        return value.Severity;
    }

    permitSpeciesFormatter = (value: cv_Taxon): string => {
        return value.CommonName;
    }

    permitSpeciesKeyFormatter = (value: cv_Taxon): number => {
        return value.C_Taxon_key;
    }
}
