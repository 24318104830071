<form role="form" class="form-label-right">
  <div class="modal-header justify-content-center">
      <h4 class="modal-title">Journal Filter</h4>
  </div>

  <div class="modal-body">
      <climb-alert-inline
        message="In order to keep animal data visible, Climb does not save this filter with your workspace settings"
        status="info"
        class="unsaved-changes-warning"
      ></climb-alert-inline>
      <div class="form-group row">
          <label class="col-md-2 col-form-label">Date Range</label>
          <div class="col-md-5">
              <climb-ngb-date
                  #dateControl="ngModel"
                  name="Date Start"
                  [(ngModel)]="filter.start"
                  placeholder="From&hellip;"
                  ngDefaultControl
              ></climb-ngb-date>
          </div>
          <div class="col-md-5">
              <climb-ngb-date
                  #dateControl="ngModel"
                  name="Date End"
                  [(ngModel)]="filter.end"
                  placeholder="To&hellip;"
                  ngDefaultControl
              ></climb-ngb-date>
            </div>
      </div>
  </div>

  <div class="modal-footer">
      <span class="btn-set">
          <button type="submit" class="btn btn-fw-wide btn-lg btn-primary" (click)="filterClicked($event)">
              Filter
          </button>
          <button
              data-automation-id="clear-filter-button"
              class="btn btn-fw-wide btn-lg btn-secondary" 
              type="button" 
              (click)="clearClicked($event)"
          >
              Clear Filter
          </button>
      </span>
      <span class="btn-set">
          <button type="button" class="btn btn-lg btn-secondary" (click)="closeClicked()">
              Close
          </button>
      </span>
  </div>
</form>
