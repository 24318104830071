import { SaveChangesService } from './../services/save-changes.service';
import { DataManagerService } from './../services/data-manager.service';
import { TableSort } from './../common/models/table-sort';

import { ConfirmService } from '../common/confirm';
import { MaterialPoolService } from './../services/material-pool.service';
import { IoTService } from './../iot/iot.service';
import { SampleService } from './../samples/sample.service';
import { SearchService } from './../search/search.service';
import { VocabularyService } from './../vocabularies/vocabulary.service';
import {
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    BaseFacet,
    BaseFacetService,
    FacetView
} from '../common/facet';

import {
    isSearchEmpty,
    notEmpty,
    uniqueArrayFromPropertyPath
} from '../common/util';
import { LocationService } from './location.service';
import { LocationDetail } from './models';
import { QueryDef } from '../services/query-def';
import { DataContextService } from '@services/data-context.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MaterialLocation } from '../common/types/models/material-location.interface';
import { arrowClockwise, listView } from '@icons';

@Component({
    selector: 'location-facet',
    templateUrl: './location-facet.component.html',
    styleUrls: ['./location-facet.component.scss'],
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS
})
export class LocationFacetComponent extends BaseFacet implements OnInit, OnDestroy {
    @Input() facetId: string;
    @Input() facet: any;

    readonly icons = { arrowClockwise, listView };

    componentName = 'location';

    position: any;
    childrenExpanded: boolean;
    // Prevent changing the location while adding new materials.
    addingMaterials = false;

    // location detail state
    locationDetail: LocationDetail = new LocationDetail();

    // Table sorting
    animalTableSort: TableSort = new TableSort();
    locationTableSort: TableSort = new TableSort();
    sampleTableSort: TableSort = new TableSort();

    selectedLocationPositionKey: number;

    readonly COMPONENT_LOG_TAG = 'location-facet';
    readonly HOME_VIEW: FacetView = FacetView.HOME_VIEW;

    readonly MAX_MATERIALPOOLS = 200;
    readonly MAX_SAMPLES = 200;

    private notifier$ = new Subject<void>();

    constructor(
        private saveChangesService: SaveChangesService,
        private baseFacetService: BaseFacetService,
        private confirmService: ConfirmService,
        private iotService: IoTService,
        private locationService: LocationService,
        private materialPoolService: MaterialPoolService,
        private sampleService: SampleService,
        private searchService: SearchService,
        private vocabularyService: VocabularyService,
        private dataManager: DataManagerService,
        private dataContext: DataContextService,
    ) {
        super(baseFacetService);
    }

    // lifecycle
    ngOnInit(): void {
        super.ngOnInit();
        this.initialize();

        this.dataContext.onCancel$.pipe(takeUntil(this.notifier$)).subscribe(() => {
            this.refresh();
        });
    }

    async initialize(): Promise<void> {
        this.changeView(this.HOME_VIEW);

        try {
            this.setLoading(true);
            await this.getCVs();
            this.restoreState();
        } catch (error) {
            throw error;
        } finally {
            this.setLoading(false);
        }
    }

    refresh() {
        this.initialize();
    }

    async getCVs(): Promise<string[][]> {
        // load CVs into memory
        const cv1: string[] = await this.vocabularyService.getCV('cv_LocationTypes');
        const cv2: string[] = await this.vocabularyService.getCV('cv_MaterialTypes');

        return [cv1, cv2];
    }

    async restoreState(): Promise<any> {
        this.locationService.invalidateCache();
        const locationPosition = await this.locationService.getDefaultLocation();
        return this.setCurrentLocationPosition(locationPosition);
    }

    async retrieveAndSetCurrentLocationPosition(locationPositionKey: number): Promise<any> {
        const locationPosition = await this.locationService.getLocationPosition(locationPositionKey);
        return this.setCurrentLocationPosition(locationPosition);
    }

    async setCurrentLocationPosition(locationPosition: any): Promise<any> {
        this.position = locationPosition;

        await this.getRelatedDataForCurrentPosition();
        return this.resetDataTables();
    }

    async getRelatedDataForCurrentPosition(): Promise<void[]> {
        try {
            this.setLoading(true);
            const p1 = await this.getAncestors(this.position);
            // Disabled until we reinstate child card display:
            // getChildren(this.position);
            const p2 = await this.getIsChildOfRootNode(this.position);
            const p3 = await this.getMaterialStats(this.position);

            return [p1, p2, p3];
        } catch (error) {
            throw error;
        } finally {
            this.setLoading(false);
        }
    }

    async resetDataTables(): Promise<void[]> {
        this.locationDetail.filter = {};

        this.locationDetail.childLocations = [];
        const p1 = await this.populateChildLocations();

        this.locationDetail.materialPools = [];
        const p2 = await this.populateMaterialPools();

        this.locationDetail.samples = [];
        const p3 = await this.populateSamples();

        return [p1, p2, p3];
    }

    private async populateChildLocations(): Promise<any> {
        if (!this.position) {
            return Promise.resolve();
        }

        const searchFilter = {
            IncludeDetails: false,
            ParentLocationKey: this.position.C_LocationPosition_key
        };
        const searchQueryDef = {
            entity: 'Locations',
            page: 1,
            size: 2000,
            filter: searchFilter
        };
        const results = await this.searchService.getEntitiesBySearch(searchQueryDef);
        this.locationDetail.childLocations = results.data;
    }

    private async populateMaterialPools(): Promise<any> {
        if (!this.position) {
            return Promise.resolve();
        }

        const queryDef: QueryDef = {
            filter: {
                locationPositionKey: this.position.C_LocationPosition_key
            },
            inlineCount: false,
            size: this.MAX_MATERIALPOOLS,
            sort: 'MaterialPool.MaterialPoolID ASC',
        };

        await this.locationService.getHousingsInLocation(queryDef);
        this.setMaterialPools();
    }

    private setMaterialPools() {
        let materialPools = this.position.MaterialLocation.filter((ml: MaterialLocation) => !ml.DateOut);
        materialPools = uniqueArrayFromPropertyPath(materialPools, 'MaterialPool');
        this.locationDetail.materialPools = materialPools;
    }

    private async populateSamples(): Promise<any> {
        if (!this.position) {
            return Promise.resolve();
        }

        const queryDef: QueryDef = {
            filter: {
                locationPositionKey: this.position.C_LocationPosition_key
            },
            expands: [
                'Material.MaterialSourceMaterial.SourceMaterial.Animal',
                'Material.MaterialSourceMaterial.SourceMaterial.Sample'
            ],
            inlineCount: false,
            size: this.MAX_SAMPLES,
            sort: 'Material.Identifier ASC',
        };

        await this.locationService.getSamplesInLocation(queryDef);
        this.setSamples();
    }

    private setSamples() {
        const materialLocations = this.position.MaterialLocation.filter((ml: MaterialLocation) => !ml.DateOut);
        const samples = uniqueArrayFromPropertyPath(materialLocations, 'Material.Sample');
        this.locationDetail.samples = samples;
    }

    async getAncestors(locationPosition: any): Promise<any> {
        const results = await this.locationService.getAncestors(locationPosition.C_LocationPosition_key);
        locationPosition.ancestors = results.data;
    }

    async getChildren(locationPosition: any): Promise<any> {
        const results = await this.locationService.getChildPositionsWithoutChildEntities(locationPosition.C_LocationPosition_key);
        locationPosition.children = results;

        const promises: Promise<any>[] = [];
        for (const child of locationPosition.children) {
            const promise = this.getMaterialStats(child);
            promises.push(promise);
        }

        return Promise.all(promises);
    }

    async getIsChildOfRootNode(locationPosition: any): Promise<void> {
        const rootNodes = await this.locationService.getRootLocationPositions();
        const relatedRootNodes = rootNodes.filter(node => {
            return node.C_LocationPosition_key === locationPosition.C_ParentPosition_key;
        });
        locationPosition.isChildOfRootNode = relatedRootNodes.length > 0;
    }

    async getMaterialStats(locationPosition: any): Promise<any> {
        const positionKey = locationPosition.C_LocationPosition_key;
        locationPosition.materialStats = null;
        const materialStatsData = await this.locationService.getMaterialStats(positionKey);
        locationPosition.materialStats = materialStatsData.data[0];

        locationPosition.deviceStats = null;
        const deviceStatsData = await this.locationService.getDeviceStats(positionKey);
        locationPosition.deviceStats = deviceStatsData.data[0];

        return [materialStatsData, deviceStatsData];
    }

    locationTableHasFilter() {
        return !isSearchEmpty(this.locationDetail.filter);
    }

    async onDrop(): Promise<void> {
        if (this.facet.Privilege !== 'ReadWrite') {
            return;
        }

        this.setAddingMaterials(true);

        const targetLocationPositionKey = this.position.C_LocationPosition_key;
        const draggedSamples = this.sampleService.draggedSamples;
        const draggedPools = this.materialPoolService.draggedPools;

        if (draggedSamples && draggedSamples.length) {
            await this.addSamplesToCurrentLocation(draggedSamples);
        }

        if (draggedPools && draggedPools.length) {
            await this.addMaterialPoolsToCurrentLocation(draggedPools);
        }

        this.sampleService.draggedSamples = [];
        this.materialPoolService.draggedPools = [];

        // Allow dragging of devices to location
        if (notEmpty(this.iotService.draggedDevices)) {
            for (const device of this.iotService.draggedDevices) {
                device.C_MaterialPool_key = null;
                device.C_LocationPosition_key = targetLocationPositionKey;
            }

            this.iotService.draggedDevices = [];
        }

        this.setAddingMaterials(false);
    }

    async addSamplesToCurrentLocation(samplesToAdd: any[]): Promise<void> {
        if (!notEmpty(samplesToAdd)) {
            return Promise.resolve();
        }

        const targetPositionKey = this.position.C_LocationPosition_key;

        // check current samples in location to ensure we don't add duplicates
        const currentSamples = this.locationDetail.samples || [];
        let existingSamples = currentSamples;
        if (currentSamples.length >= this.MAX_SAMPLES) {
            // not all samples may be loaded, query any matching the samplesToAdd
            const queryDef = {
                filter: {
                    locationPositionKey: targetPositionKey,
                    materialKeys: uniqueArrayFromPropertyPath(samplesToAdd, 'C_Material_key')
                }
            };
            existingSamples = await this.locationService.getSamplesInLocation(queryDef);
        }

        const now = new Date();
        const promises: Promise<void>[] = [];

        // ensure newly added, but not saves items are counted
        existingSamples = currentSamples.concat(existingSamples);

        for (const sampleToAdd of samplesToAdd) {
            // Do not add if it is already here
            const existingSample = existingSamples.find((sample) => {
                return sample.C_Material_key === sampleToAdd.C_Material_key;
            });

            if (existingSample) {
                continue;
            }

            const initialValues = {
                C_LocationPosition_key: targetPositionKey,
                C_Material_key: sampleToAdd.C_Material_key,
                DateIn: now
            };

            promises.push(this.locationService.createMaterialLocation(initialValues)
                .then(newMaterialLocation => {
                    if (newMaterialLocation?.Material?.Sample) {
                        this.locationDetail.samples.push(newMaterialLocation.Material.Sample);
                    }
                })
            );
        }

        await Promise.all(promises);
    }

    async addMaterialPoolsToCurrentLocation(unitsToAdd: any[]): Promise<void> {
        if (!notEmpty(unitsToAdd)) {
            return Promise.resolve();
        }

        const targetPositionKey = this.position.C_LocationPosition_key;

        // check current pools in location to ensure we don't add duplicates
        const currentPools = this.locationDetail.materialPools || [];
        let existingUnits = currentPools;
        if (currentPools.length >= this.MAX_MATERIALPOOLS) {
            // not all housing units may be loaded, query any matching the unitsToAdd
            const queryDef = {
                filter: {
                    locationPositionKey: targetPositionKey,
                    materialPoolKeys: uniqueArrayFromPropertyPath(unitsToAdd, 'C_MaterialPool_key')
                }
            };
            existingUnits = await this.locationService.getHousingsInLocation(queryDef);
        }

        const promises: Promise<void>[] = [];
        const now = new Date();

        // ensure newly added, but not saves items are counted
        existingUnits = currentPools.concat(existingUnits);

        for (const unitToAdd of unitsToAdd) {
            // Do not add if it is already here
            const existingUnit = existingUnits.find((materialPool) => {
                return materialPool.C_MaterialPool_key === unitToAdd.C_MaterialPool_key;
            });
            if (existingUnit) {
                continue;
            }

            const initialValues: any = {
                C_LocationPosition_key: targetPositionKey,
                C_MaterialPool_key: unitToAdd.C_MaterialPool_key,
                DateIn: now
            };

            const p = this.locationService.createMaterialLocation(initialValues)
                .then(newMaterialLocation => {
                    if (newMaterialLocation.MaterialPool) {
                        this.locationDetail.materialPools.push(newMaterialLocation.MaterialPool);
                    }

                    // load in memory any animal names associated with this MaterialLocation
                    this.loadMaterialPoolMaterials(newMaterialLocation.MaterialPool);
                });

            promises.push(p);
        }

        await Promise.all(promises);
    }

    async loadMaterialPoolMaterials(materialPool: any): Promise<any> {
        if (!materialPool) {
            return Promise.resolve();
        }

        const materialPoolMaterials = materialPool.MaterialPoolMaterial;
        // empty list indicates materials may not have been loaded yet.
        if (materialPoolMaterials.length === 0) {
            await this.materialPoolService.getMaterialPoolMaterials(materialPool.C_MaterialPool_key);
            this.setMaterialPools();
            this.setSamples();
        }
        return Promise.resolve();
    }

    // Deletes at *both* the UI and the db levels (after confirmation).
    async deleteLocationPosition(locationPosition: any): Promise<void> {
        const isSafe = await this.isDeleteSafe(locationPosition);
        const { PositionName, C_LocationPosition_key } = locationPosition;
        if (isSafe) {
            const modalTitle = 'Delete Location';
            const modalMessage = `Delete "${PositionName}"? This action cannot be undone.`;
            try {
                await this.confirmService.confirmDelete(modalTitle, modalMessage);
                this.locationService.deleteLocationPosition(locationPosition);
                // Save the delete in the db!
                await this.dataManager.saveEntity('LocationPosition');
                this.removeChildIfParentIsCurrent(locationPosition);
                this.showParentIfCurrentIsDeleted(locationPosition);

                const logMessage = `${PositionName} has been deleted.`;
                this.loggingService.logSuccess(logMessage, C_LocationPosition_key, this.COMPONENT_LOG_TAG, true);
            } catch (error) {
                this.loggingService.logSuccess('Delete canceled.', C_LocationPosition_key, this.COMPONENT_LOG_TAG, true);
            }
        } else {
            const logMessage = `Cannot delete ${PositionName}: it has children or related data.`;
            this.loggingService.logWarning(logMessage, C_LocationPosition_key, this.COMPONENT_LOG_TAG, true);
        }
    }

    isDeleteSafe(locationPosition: any): Promise<boolean> {
        // Safe to delete a node only if it has neither materials nor children
        return this.locationService.isDeleteLocationPositionSafe(locationPosition);
    }

    removeChildIfParentIsCurrent(deletedLocationPosition: any) {
        const currentKey = this.position.C_LocationPosition_key;
        const deletedParentKey = deletedLocationPosition.C_ParentPosition_key;

        if (currentKey === deletedParentKey) {
            const index = this.position.children.indexOf(deletedLocationPosition);
            this.position.children.splice(index, 1);
        }
    }

    showParentIfCurrentIsDeleted(deletedLocationPosition: any) {
        const currentKey = this.position.C_LocationPosition_key;
        const deletedKey = deletedLocationPosition.C_LocationPosition_key;

        if (currentKey === deletedKey) {
            const deletedParentKey = deletedLocationPosition.C_ParentPosition_key;
            this.retrieveAndSetCurrentLocationPosition(deletedParentKey);
        }
    }

    addChildLocationPosition(parentLocationPosition: any) {
        if (this.facet.Privilege === 'ReadWrite') {
            // IsActive must be 1 b/c column datatype is int
            const initialValues = {
                C_ParentPosition_key: parentLocationPosition.C_LocationPosition_key,
                Ordinal: 1,
                XPosition: 1,
                YPosition: 1,
                ZPosition: 1,
                IsActive: true
            };
            const newLocationPosition = this.locationService.createLocationPosition(initialValues);
            this.vocabularyService.getCVDefault('cv_LocationTypes').then((value) => {
                newLocationPosition.cv_LocationType = value;
            });
            this.editBegin(newLocationPosition);
        }
    }

    editBegin(locationPosition: any) {
        if (this.facet.Privilege !== 'ReadWrite') {
            return;
        }
        this.changeView(this.DETAIL_VIEW);

        if (locationPosition.C_LocationPosition_key >= 0) {
            this.retrieveAndSetCurrentLocationPosition(locationPosition.C_LocationPosition_key);
        } else {
            this.position = locationPosition;
        }

        this.locationService.getDevices(this.position);
    }

    editEnd() {
        try {
            this.locationService.cancelLocation(this.position);
        } catch (e) {
            this.loggingService.logError('Could not discard pending changes.', e, this.COMPONENT_LOG_TAG, true);
        }

        this.changeView(this.HOME_VIEW);
        this.restoreState();
    }

    selectLocation(locationPosition: any) {
        this.retrieveAndSetCurrentLocationPosition(locationPosition.LocationKey);
    }

    selectPositionKey(positionKey: number) {
        if (!positionKey) {
            return;
        }
        this.retrieveAndSetCurrentLocationPosition(positionKey);
    }

    // <select> formatters
    locationTypeKeyFormatter = (value: any) => {
        return value.C_LocationType_key;
    }
    locationTypeFormatter = (value: any) => {
        return value.LocationType;
    }
    materialTypeKeyFormatter = (value: any) => {
        return value.C_MaterialType_key;
    }
    materialTypeFormatter = (value: any) => {
        return value.MaterialType;
    }

    expandAllChildren() {
        this.childrenExpanded = true;
    }

    collapseAllChildren() {
        this.childrenExpanded = false;
    }

    onSaveLocation() {
        this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
    }

    ngOnDestroy(): void {
        this.notifier$.next();
        this.notifier$.complete();
    }

    private setAddingMaterials(isAdding: boolean) {
        this.addingMaterials = isAdding;
        this.setLoading(isAdding);
    }

    private setLoading(isLoading: boolean) {
        if (isLoading) {
            this.setLoadingState();
        } else {
            this.stopLoading();
        }

        this.saveChangesService.isLocked = isLoading;
        this.facetLoadingState.changeLoadingState(isLoading);
    }
}
