import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseFilter } from '../common/facet';
import {
    filterToDate, focusElementByQuery
} from '../common/util/';
import { LoggingService } from '../services/logging.service';
import { AuditFilterTemplatesComponent } from './audit-filter-templates.component';
import { DateTime } from 'luxon';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';
import { getUpdateTypes } from './audit.utils';


const TODAY = new Date();
const SIX_MONTHS_AGO = new Date(TODAY).setMonth(TODAY.getMonth() - 6);

@Component({
    selector: 'audit-filter',
    templateUrl: './audit-filter.component.html'
})
export class AuditFilterComponent extends BaseFilter implements OnInit, AfterViewInit {
    @ViewChild('templates') templatesRef: AuditFilterTemplatesComponent;
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() filterSubsection: any;
    @Input() filter: any = {};
    @Output() onFilter = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();

    MULTI_PASTE_INPUT_LIMIT = 150;

    auditFilterTempltes: AuditFilterTemplatesComponent;

    constructor(
        private activeModal: NgbActiveModal,
        private loggingService: LoggingService
    ) {
        super(activeModal);
    }

    ngOnInit() {
        this.cloneFilter();

        focusElementByQuery('[data-auto-focus="animalName"]');
    }

    ngAfterViewInit() {
        this.auditFilterTempltes = this.templatesRef;
        this.auditFilterTempltes?.setUpdateTypes(this.filterSubsection);
        this.auditFilterTempltes?.setSubsectionVisible(this.filterSubsection);
    }

    private cloneFilter() {
        const modifiedDateStart = this.filter.ModifiedDateStart;
        const modifiedDateEnd = this.filter.ModifiedDateEnd;
        const createdDateStart = this.filter.CreatedDateStart;
        const createdDateEnd = this.filter.CreatedDateEnd;
        const dueDateStart = this.filter.DueDateStart;
        const dueDateEnd = this.filter.DueDateEnd;
        const completeDateStart = this.filter.CompleteDateStart;
        const completeDateEnd = this.filter.CompleteDateEnd;
        const lastReviewedAnimalCharacteristicDateStart = this.filter.LastReviewedAnimalCharacteristicDateStart;
        const lastReviewedAnimalCharacteristicDateEnd = this.filter.LastReviewedAnimalCharacteristicDateEnd;
        const lastReviewedJobCharacteristicDateStart = this.filter.LastReviewedJobCharacteristicDateStart;
        const lastReviewedJobCharacteristicDateEnd = this.filter.LastReviewedJobCharacteristicDateEnd;
        const lastReviewedStudyCharacteristicDateStart = this.filter.LastReviewedStudyCharacteristicDateStart;
        const lastReviewedStudyCharacteristicDateEnd = this.filter.LastReviewedStudyCharacteristicDateEnd;
        const housingDateStart = this.filter.HousingDateStart;
        const housingDateEnd = this.filter.HousingDateEnd;
        const matingDateStart = this.filter.MatingDateStart;
        const matingDateEnd = this.filter.MatingDateEnd;
        const observedDateStart = this.filter.ObservedDateStart;
        const observedDateEnd = this.filter.ObservedDateEnd;
        const dateInStart = this.filter.DateInStart;
        const dateInEnd = this.filter.DateInEnd;
        const dateOutStart = this.filter.DateOutStart;
        const dateOutEnd = this.filter.DateOutEnd;
        const dateDocumentedStart = this.filter.DateDocumentedStart;
        const dateDocumentedEnd = this.filter.DateDocumentedEnd;
        const lastReviewedSampleCharacteristicDateStart = this.filter.LastReviewedSampleCharacteristicDateStart;
        const lastReviewedSampleCharacteristicDateEnd = this.filter.LastReviewedSampleCharacteristicDateEnd;

        this.filter = this.copyFilter(this.filter);

        const updateTypes = getUpdateTypes(this.filterSubsection).map((item) => item.Name);
        this.filter.UpdateTypes = this.filter.UpdateTypes.filter((x: any) => updateTypes.includes(x));
        if (this.filter.UpdateTypes.length === 0) {
            this.filter.UpdateTypes = updateTypes;
        }

        this.filter.ModifiedDateStart = modifiedDateStart;
        this.filter.ModifiedDateEnd = modifiedDateEnd;
        this.filter.CreatedDateStart = createdDateStart;
        this.filter.CreatedDateEnd = createdDateEnd;
        this.filter.DueDateStart = dueDateStart;
        this.filter.DueDateEnd = dueDateEnd;
        this.filter.CompleteDateStart = completeDateStart;
        this.filter.CompleteDateEnd = completeDateEnd;
        this.filter.LastReviewedAnimalCharacteristicDateStart = lastReviewedAnimalCharacteristicDateStart;
        this.filter.LastReviewedAnimalCharacteristicDateEnd = lastReviewedAnimalCharacteristicDateEnd;
        this.filter.LastReviewedJobCharacteristicDateStart = lastReviewedJobCharacteristicDateStart;
        this.filter.LastReviewedJobCharacteristicDateEnd = lastReviewedJobCharacteristicDateEnd;
        this.filter.LastReviewedStudyCharacteristicDateStart = lastReviewedStudyCharacteristicDateStart;
        this.filter.LastReviewedStudyCharacteristicDateEnd = lastReviewedStudyCharacteristicDateEnd;
        this.filter.HousingDateStart = housingDateStart;
        this.filter.HousingDateEnd = housingDateEnd;
        this.filter.MatingDateStart = matingDateStart;
        this.filter.MatingDateEnd = matingDateEnd;
        this.filter.ObservedDateStart = observedDateStart;
        this.filter.ObservedDateEnd = observedDateEnd;
        this.filter.DateInStart = dateInStart;
        this.filter.DateInEnd = dateInEnd;
        this.filter.DateOutStart = dateOutStart;
        this.filter.DateOutEnd = dateOutEnd;
        this.filter.DateDocumentedStart = dateDocumentedStart;
        this.filter.DateDocumentedEnd = dateDocumentedEnd;
        this.filter.LastReviewedSampleCharacteristicDateStart = lastReviewedSampleCharacteristicDateStart;
        this.filter.LastReviewedSampleCharacteristicDateEnd = lastReviewedSampleCharacteristicDateEnd;
    }

    filterClicked(event: Event) {
        const errMessage = dateControlValidator(this.dateControls) || this.templatesRef.validate();
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }
        
        event.preventDefault();

        // Validate that the Modified Date range is not greater than 365 days
        const diffInDays = Math.abs(DateTime.fromJSDate(this.filter.ModifiedDateStart).diff(DateTime.fromJSDate(this.filter.ModifiedDateEnd), 'days').as("days"));

        if (diffInDays <= 365) {
            this.onFilter.emit({
                filter: this.filter,
                visibility: this.auditFilterTempltes.auditFilterVisibility
            });
            this.close.emit();
            this.activeModal.close();
        } else {
            this.loggingService.logError(
                "Modified Date range cannot be greater than 365 days. Please adjust your selection.",
                null,
                'audit-filter',
                true
            );
        }
    }

    closeClicked() {
        this.close.emit();
        this.activeModal.dismiss();
    }

    clearClicked() {
        // Set default ModifiedDate range to 1 year from current date to limit query time
        this.filter = {
            UpdateTypes: getUpdateTypes(this.filterSubsection).map((item) => item.Name),
            ModifiedDateStart: filterToDate(SIX_MONTHS_AGO),
            ModifiedDateEnd: filterToDate(TODAY)
        };
        this.onFilter.emit({
            filter: this.filter,
            visibility: this.auditFilterTempltes.auditFilterVisibility
        });
        this.close.emit();
        this.activeModal.close();
    }
}
