import type { ContactPerson } from './contact-person.interface';
import type { Job } from './job.interface';
import type { JobInstitution } from './job-institution.interface';
import type { Note } from './note.interface';
import type { Order } from './order.interface';
import type { Site } from './site.interface';
import type { cv_Country } from './cv-country.interface';
import type { cv_InstitutionType } from './cv-institution-type.interface';
import type { cv_State } from './cv-state.interface';
import { OrderInstitution } from './order-institution.interface';

export interface Institution {
    AccountNumber: string;
    Address: string;
    AddressQualifier: string;
    C_Country_key: number | null;
    C_InstitutionType_key: number | null;
    C_Institution_key: number;
    C_State_key: number | null;
    C_Workgroup_key: number;
    City: string;
    ContactPerson: ContactPerson[];
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    EmailAddress: string;
    ExternalID: string;
    Fax: string;
    Identifier: string;
    Job: Job[];
    JobInstitution: JobInstitution[];
    OrderInstitution: OrderInstitution[];
    MobilePhone: string;
    ModifiedBy: string;
    Name: string;
    Note: Note[];
    Order: Order[];
    Phone: string;
    PostalCode: string;
    Site: Site[];
    State: string;
    URL: string;
    Version: number;
    cv_Country: cv_Country;
    cv_InstitutionType: cv_InstitutionType;
    cv_State: cv_State;
}
