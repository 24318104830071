import { Injectable } from "@angular/core";
import { ColumnSelectLabel } from "@common/facet";
import { BaseTableColumnsHelper } from "@common/util/table-columns/base-table-columns-helper";
import { FeatureFlagService } from "@services/feature-flags.service";
import { TranslationService } from "@services/translation.service";

@Injectable()
export class AnimalsIndividualTableColumnsHelper extends BaseTableColumnsHelper {
    static visibleColumns = {
        name: true,
        line: true,
        genotype: true,
        sex: true,
        birthDate: true,
        status: true,
        housing: true,
        cohort: true,
        sequence: true,
        heldFor: false,
        microchipIdentifier: false,
        alternatePhysicalID: false,
        externalIdentifier: false,
        CITESNumber: false,
        classification: false,
        ageDays: false,
        ageWeeks: false,
        ageMonths: false
    }
    constructor(
        private translationService: TranslationService,
        private featureFlagSerivce: FeatureFlagService
    ) { 
        super(Object.assign({}, AnimalsIndividualTableColumnsHelper.visibleColumns));
    }

    protected setupColumnSelectLabels(): void {
        const isGLP = this.featureFlagSerivce.getIsGLP();
        if (isGLP) {
            this.columnSelectLabels = [
                new ColumnSelectLabel('name', 'Name'),
                new ColumnSelectLabel('line', this.translationService.translate('Line')),
                new ColumnSelectLabel('genotype', 'Genotype'),
                new ColumnSelectLabel('sex', 'Sex'),
                new ColumnSelectLabel('birthDate', 'Birth Date'),
                new ColumnSelectLabel('status', 'Status'),
                new ColumnSelectLabel('heldFor', 'Held For'),
                new ColumnSelectLabel('housing', 'Housing ID'),
                new ColumnSelectLabel('microchipIdentifier', 'Microchip ID'),
                new ColumnSelectLabel('alternatePhysicalID', 'Alternate Physical ID'),
                new ColumnSelectLabel('externalIdentifier', 'External ID'),
                new ColumnSelectLabel('CITESNumber', 'CITES Number'),
                new ColumnSelectLabel('classification', 'Classification'),
                new ColumnSelectLabel('ageDays', 'Age (Days)'),
                new ColumnSelectLabel('ageWeeks', 'Age (Weeks)'),
                new ColumnSelectLabel('ageMonths', 'Age (Months)'),
                new ColumnSelectLabel('cohort', 'Cohort'),
                new ColumnSelectLabel('sequence', 'Sequence'),
            ];
        } else {
            this.columnSelectLabels = [
                new ColumnSelectLabel('name', 'Name'),
                new ColumnSelectLabel('line', this.translationService.translate('Line')),
                new ColumnSelectLabel('genotype', 'Genotype'),
                new ColumnSelectLabel('sex', 'Sex'),
                new ColumnSelectLabel('birthDate', 'Birth Date'),
                new ColumnSelectLabel('status', 'Status'),
                new ColumnSelectLabel('housing', 'Housing ID'),
                new ColumnSelectLabel('microchipIdentifier', 'Microchip ID'),
                new ColumnSelectLabel('externalIdentifier', 'External ID'),
                new ColumnSelectLabel('ageDays', 'Age (Days)'),
                new ColumnSelectLabel('ageWeeks', 'Age (Weeks)'),
                new ColumnSelectLabel('ageMonths', 'Age (Months)'),
                new ColumnSelectLabel('cohort', 'Cohort'),
                new ColumnSelectLabel('sequence', 'Sequence'),
            ];
        }
    }
}