import { Injectable } from "@angular/core";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { Entity, EntityQuery, EntityState, Predicate } from "breeze-client";
import { AdminManagerService } from "../../services/admin-manager.service";
import { BaseEntityService } from "../../services/base-entity.service";
import { DataManagerService } from "../../services/data-manager.service";
import { DeletionService } from "../../services/deletion.service";
import { ModificationService } from "../../services/modification.service";
import { OverlayService } from "../../services/overlay-service";
import { getSafeProp, notEmpty } from "../util";
import { ReasonForChangeModalComponent } from "./reason-for-change-modal.component";

import { NO_EDIT_SHOWS_MODAL, NULL_TO_NON_NULL_DISABLED, JOB_RELATED_ENTITIES } from './reason-for-change-entity-lists';

@Injectable()
export class ReasonForChangeService extends BaseEntityService {

    // entities that are on the admin database need to be saved using the admin manager, which means their creation method is slightly different
    // define those entities here.
    adminEntities: string[] = [
        'ClimbRole',
        'User',
        'WorkgroupUser',
        'ClimbRoleFacet'
    ];

    constructor(
        private dataManager: DataManagerService,
        private adminManager: AdminManagerService,
        private _modificationService: ModificationService,
        private _deletionService: DeletionService,
        private _overlayService: OverlayService,
        private _modalService: NgbModal,
    ) {
        super();
    }

    async handleReasonForChange(): Promise<unknown[]> {
        const overlayId = this._overlayService.show();
        let batches: unknown[];

        try {
            const entities = this.gatherEntities();
            batches = await this.filterEntities(entities);
        } finally {
            this._overlayService.hide(overlayId);
        }

        return await this.createOpenModalPromises(batches);
    }

    async handleReasonForChangeForTypes(entityTypes: string[]): Promise<any> {
        const entities = this.gatherEntities(entityTypes);
        return this.handleReasonForChangeForEntities(entities);
    }

    async handleReasonForChangeForEntities(entities: Entity[]): Promise<any> {
        const batches = await this.filterEntities(entities);
        return this.createOpenModalPromises(batches);
    }

    /**
     * Gets all of the entities that are modified or deleted, along with any special-case entities that have been added.
     */
    private gatherEntities(entityTypes: string[] = []): Entity[] {
        const _dataManager = this.dataManager.getManager();
        const _adminManager = this.adminManager.getManager();

        let entities: any[] = _dataManager.getEntities(null, EntityState.Modified);
        entities = entities.concat(_adminManager.getEntities(null, EntityState.Modified));

        entities = entities.concat(_dataManager.getEntities(null, EntityState.Deleted));
        entities = entities.concat(_adminManager.getEntities(null, EntityState.Deleted));

        if (entityTypes.length > 0) {
            entities = entities.filter((entity: Entity) => {
                const entityName = this.getEntityName(entity);
                return entityTypes.indexOf(entityName) >= 0;
            });
        }
        return entities;
    }

    /**
     * Takes a list of entites and filters them based off of certain conditions.
     * @param entities
     */
    private async filterEntities(entities: Entity[]): Promise<any[]> {
        const someEntities = [];
        // need to track the primary keys and entity types that are being modfied in order to enforce one modal per change.
        const modifiedPKs = {};

        for (const entity of entities) {

            // skip entities which only have NULL -> NON-NULL modifications.
            if (await this.shouldEntityBeSkipped(entity)) {
                // even if the entity is skipped, a deletion entity should still be made if the entity state is "Deleted"
                if (this.getEntityState(entity) === EntityState.Deleted) {
                    this._deletionService.createDeletionRecord(entity);
                }
                continue;
            }

            const entityName = this.getEntityName(entity);
            if (!modifiedPKs[entityName] || !modifiedPKs[entityName].includes(this.getEntityKeyValue(entity))) {
                someEntities.push(entity);
            }
            if (!modifiedPKs[entityName]) {
                modifiedPKs[entityName] = [];
            }
            modifiedPKs[entityName].push(this.getEntityKeyValue(entity));
        }
        return someEntities;
    }

    /**
     * If the modification should be filtered out in the entity filter process, return true.
     * @param entity
     */
    private shouldEntityBeSkipped(entity: Entity): Promise<boolean> {
        // births, matings, housing, workflow data, locations, enumerations, vocabularies, and clinical facets, should have a RFC modal for all changes
        // roles facet should only change if a role is changed
        // resource does not require a reason for change
        const entityName = this.getEntityName(entity);
        if (NO_EDIT_SHOWS_MODAL.includes(entityName)) {
            return Promise.resolve(true);
        } else if (JOB_RELATED_ENTITIES.includes(entityName)) {
            return this.isOnlyChangeSequence(entity) // for JobMaterial
                ? Promise.resolve(true)
                : this.isEntityJobInDraft(entity, entityName);
        } else if (NULL_TO_NON_NULL_DISABLED.includes(entityName)) {
            return Promise.resolve(this.onlyNullEntityChanges(entity));
        } else {
            return Promise.resolve(this.isOnlyChangeSequence(entity));
        }
    }

    /**
     * Returns true if all of the changes for an entity is from null values to non-null values.
     * @param entity
     */
    private onlyNullEntityChanges(entity: Entity): boolean {
        const keys = Object.keys(entity.entityAspect.originalValues);
        // if there are no changes at all to the entity, it will be due to a manual modification marking, in which case it should pass.
        if (keys.length === 0) {
            return false;
        }
        for (const k of keys) {
            // some GLP entities are "removed" by modifying the date out.
            // Since this is a removal it should be exempt from the null - check, since it would normally fail it.
            if (k === 'DateOut' || k === 'Sequence') {
                continue;
            }
            if (entity.entityAspect.originalValues[k] != null) {
                return false;
            }
        }
        return true;
    }

    /**
     * Returns true if the only changes are for the Sequence or SortOrder properties.
     * @param entity
     */
    private isOnlyChangeSequence(entity: Entity): boolean {
        const keys = Object.keys(entity.entityAspect.originalValues);
        if (keys.length !== 1) {
            return false;
        } else if (keys[0] === "Sequence" || keys[0] === "SortOrder") {
            return true;
        }
    }

    /**
     * Returns true if the Job has a "Draft" status.
     * @param entity
     * @param entityName
     */
    private isEntityJobInDraft(entity: Entity, entityName: string): Promise<boolean> {
        return this.extractJob(entity, entityName)
            .then((jobExtracted: any) => this.isJobInDraft(jobExtracted));
    }

    private isJobInDraft(job: any){
        if (!job) {
            return false;
        }
        
        const jobStatus = job.cv_JobStatus;
        if (!jobStatus) {
            return false;
        }
        
        return jobStatus.IsDraft;
    }

    /**
     * Gets the Job entity attached to the supplied entity.
     * If the Job Entity does not exist, it will attempt to fetch the job from the API depending on the entity type.
     * @param entity
     * @param entityName
     */
    private extractJob(entity: any, entityName: string): Promise<any> {
        if (entityName === "Job") {
            return Promise.resolve(entity);
        } else {
            // if the entity has a direct relation to Job, then get the job
            if (entity.Job) {
                return Promise.resolve(entity.Job);
            } else {
                // otherwise, check each special-case entity and manually extract the job
                if (entity.C_Job_key) {
                    return this.getJobFromCache(entity.C_Job_key);
                }
                if (entityName === "TaskInstance"
                    || entityName === "TaskMaterial"
                    || entityName === "TaskInput"
                    || entityName === 'SampleGroup'
                    || entityName === 'TaskPlaceholder') {
                    return this.getTaskJobFromCache(entity.C_TaskInstance_key);
                }

                if (entityName === "SampleGroupSourceMaterial") {
                    return this.getJobFromSampleGroupKey(entity.C_SampleGroup_key);
                }

                if (entityName === 'TaskPlaceholderInput') {
                    return this.getJobFromTaskPlaceholderKey(entity.C_TaskPlaceholder_key);
                }
            }
        }
    }

    /**
     * Calls the API to get the job based off of the job key.
     * @param jobKey
     */
    private getJobFromCache(jobKey: number): Promise<any> {
        let query = EntityQuery.from('Jobs')
            .where('C_Job_key', '==', jobKey);
        const expands = ["cv_JobStatus"];
        if (notEmpty(expands)) {
            query = query.expand(expands.join(','));
        }

        return this.dataManager.returnSingleQueryResultCached(query, 1000);
    }

    /**
     * Gets the Job based off of the TaskInstance key
     * @param taskInstanceKey
     */
    private getTaskJobFromCache(taskInstanceKey: number): Promise<any> {
        const predicate = Predicate.create('TaskJob', 'any', 'C_TaskInstance_key', 'eq', taskInstanceKey);
        let query = EntityQuery.from('Jobs')
            .where(predicate);
        const expands = ["TaskJob", "cv_JobStatus"];
        if (notEmpty(expands)) {
            query = query.expand(expands.join(','));
        }

        return this.dataManager.returnSingleQueryResultCached(query, 1000);
    }

    /**
     * Gets the SampleGroup based off of the SampleGroup key, then uses the TaskInstance key of that SampleGroup to get the Job.
     * @param sampleGroupKey
     */
    private getJobFromSampleGroupKey(sampleGroupKey: number): Promise<any> {
        // need to get a job from the sample group key. I could go samplegroup key -> task instance -> task Job -> job
        const predicate = Predicate.create('C_SampleGroup_key', 'eq', sampleGroupKey);
        const query = EntityQuery.from('SampleGroups')
            .where(predicate);

        return this.dataManager.returnSingleQueryResultCached(query, 1000).then((sampleGroup: any) => {
            if (!sampleGroup) {
                return Promise.resolve(null);
            } else {
                return this.getTaskJobFromCache(sampleGroup.C_TaskInstance_key);
            }
        });
    }

    /**
     * Gets the TaskPlaceholder based off of the TaskPlacehodler key, then uses the TaskInstance key of that TaskPlaceholder to get the Job.
     * @param taskPlaceholderKey
     */
    private getJobFromTaskPlaceholderKey(taskPlaceholderKey: number): Promise<any> {
        const predicate = Predicate.create("C_TaskPlaceholder_key", "eq", taskPlaceholderKey);
        const query = EntityQuery.from('TaskPlaceholders')
            .where(predicate);
        return this.dataManager.returnSingleQueryResultCached(query, 1000).then((taskPlaceholder: any) => {
            if (!taskPlaceholder) {
                return Promise.resolve(null);
            } else {
                return this.getTaskJobFromCache(taskPlaceholder.C_TaskInstance_key);
            }
        });
    }

    /**
     * Creates one ReasonForChange modal per batch, and returns a list of result promises which create Modification entities for all entities in that batch.
     * @param batches
     */
    private createOpenModalPromises(batches: any[]): Promise<any> {
        return this.openReasonForChangeModal(batches, true);
    }

    /**
     * Opens the ReasonForChangeModal.
     * If the entities sent in the entityBatch parameter are modifications, Modification entities will be created.
     * If they are deletions, Deletion entities will be created.
     * @param entityBatch
     * @param showBackdrop
     */
    openReasonForChangeModal(entityBatch: any[], showBackdrop = true): Promise<any> {
        if (entityBatch.length === 0) {
            return Promise.resolve([]);
        }
        const modal = this._modalService.open(ReasonForChangeModalComponent, this.getModalSettings(showBackdrop));

        modal.componentInstance.entitiesTable = this.createEntityTable(entityBatch);

        return modal.result.then((reasonForChange: string) => {
            return this.createChangeEntities(entityBatch, reasonForChange);
        });
    }


    /**
     * Creates the object used in the "entitiesTable" property of the modal.
     */
    private createEntityTable(entities: any[]) {
        return entities.map((e: any) => {
            return {
                entityName: this.getEntityName(e),
                actionType: this.getEntityState(e).getName()
            };
        });
    }

    /**
     * Creates Modification/Deletion entities from the specified entity batch, set with the specified reason for change.
     * @param entityBatch
     * @param reasonForChange
     */
    private createChangeEntities(entityBatch: any[], reasonForChange: string): any[] {
        const reasonForChangeEntities = [];
        for (const entity of entityBatch) {
            if (this.getEntityState(entity) === EntityState.Modified) {
                if (this.adminEntities.includes(this.getEntityName(entity))) {
                    reasonForChangeEntities.push(this._modificationService.createModificationRecord(entity, reasonForChange, true));
                } else {
                    reasonForChangeEntities.push(this._modificationService.createModificationRecord(entity, reasonForChange));
                }
            } else if (this.getEntityState(entity) === EntityState.Deleted) {
                reasonForChangeEntities.push(this._deletionService.createDeletionRecord(entity, reasonForChange));
            }
        }
        return reasonForChangeEntities;
    }

    /**
     * Returns the settings for the ReasonForChangeModal
     * @param showBackdrop
     */
    private getModalSettings(showBackdrop: boolean): NgbModalOptions {
        return {
            backdrop: showBackdrop ? 'static' : false,
            size: 'md',
            windowClass: 'reason-modal',
            keyboard: false
        };
    }

    /**
     * Opens a ReasonForChangeModal that does not create additional entities. It only returns the "Reason For Change" string.
     * For use in API calls that need a Reason for change sent.
     * @param entities
     */
    openDirectModal(entities: any[]): Promise<string> {
        const modal = this._modalService.open(ReasonForChangeModalComponent, this.getModalSettings(true));
        modal.componentInstance.entitiesTable = this.createEntityTable(entities);
        return modal.result;
    }
    
    async getRFCForAddProtocolToStudyAction(protocolTasks: Entity[], jobKey: number): Promise<string> {
        const job = await this.getJobFromCache(jobKey);
        if (this.isJobInDraft(job)){
            return null;
        }
        
        return await this.openDirectModal(protocolTasks);
    }

    /**
     * Manually marks the list of entities for modification
     * @param entities
     */
    markModification(entities: Entity[]): void {
        for (const entity of entities) {
            entity.entityAspect.setModified();
        }
    }

    /**
     * Returns the entity's short name.
     * @param entity
     */
    private getEntityName(entity: Entity) {
        return entity.entityAspect.getKey().entityType.shortName;
    }

    /**
     * Returns the entity's EntityState symbol
     * @param entity
     */
    private getEntityState(entity: Entity) {
        return entity.entityAspect.entityState;
    }

    /**
     * Gets the value of the supplied entity's primary key
     * @param entity
     */
    private getEntityKeyValue(entity: Entity) {
        return entity.entityAspect.getKey().values[0];
    }

}
