import { Entity } from "../breeze.interface";
import {
    Animal,
    AnimalComment,
    Genotype,
    Material,
    TaxonCharacteristic,
    TaxonCharacteristicInstance,
    cv_AnimalMatingStatus,
    cv_AnimalStatus,
    cv_Taxon
} from "../models";

export interface BulkCreateAnimal {
    AgeWeeks: number[];
    AlternatePhysicalID: string | null;
    AnimalComment: AnimalComment[];
    AnimalNames: string[];
    CITESNumber: number;
    C_AnimalClassification_key: number | null;
    C_AnimalStatus_key: number | null;
    C_AnimalUse_key: number | null;
    C_BreedingStatus_key: number | null;
    C_Diet_key: number | null;
    C_ExitReason_key: number | null;
    C_Generation_key: number | null;
    C_IACUCProtocol_key: number | null;
    C_Material_key: number;
    C_MaterialType_key: number;
    C_Order_key: number | null;
    C_Permit_key: number | null;
    C_PhysicalMarkerType_key: number | null;
    C_Sex_key: number | null;
    characteristics: TaxonCharacteristic[];
    characteristicsDefaults: {[key: number]: string};
    Comments: string | null;
    DateBorn: Date | null;
    DateCreated: Date | null;
    DateExit: Date | null;
    DateModified: Date | null;
    DateOrigin: Date | null;
    Genotype: Genotype[];
    HeldFor: string;
    Material: Material;
    Owner: string;
    PhysicalMarker: string | null;
    ShipmentID: string | null;
    TaxonCharacteristicInstance: TaxonCharacteristicInstance[];
    VendorID: string | null;
    cv_AnimalMatingStatus: cv_AnimalMatingStatus | null;
    cv_AnimalStatus: cv_AnimalStatus | null;
    cv_Taxon?: cv_Taxon;
    JobKey?: number;
}

export type BulkCreateAnimalRequestBody = Entity<Animal> & BulkCreateAnimal;
