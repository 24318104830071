<div class="detail-form-wrapper">

    <div class="detail-header">
        <h3>Cohort</h3>

        <pager-toolbar [itemName]="'cohort'"
                       [logTag]="COMPONENT_LOG_TAG"
                       [first]="pageState.isFirst"
                       [last]="pageState.isLast"
                       (previous)="previousClicked($event)"
                       (next)="nextClicked($event)"></pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="exitClicked($event)">

            <div class="btn-group"
                 role="toolbar"
                 aria-label="Edit actions"
                 additional-groups
                 *ngIf="facet.Privilege === 'ReadWrite'">
                <button type="button"
                        class="btn btn-secondary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                    <i class="fa fa-copy" aria-hidden="true" title="Copy Cohort"></i>
                    <span class="gridster-d-sm-none gridster-d-md-inline-block">Copy</span>
                    <span class="sr-only">Copy Cohort</span>
                </button>
                <div class="dropdown-menu">
                    <a
                        class="dropdown-item"
                        [ngClass]="{'disabled': selectedAnimalsCount() === 0}"
                        (click)="copyCohortWithSelectedMaterials()"
                    >
                        With Selected Animals
                    </a>
                </div>
            </div>

        </detail-toolbar>
    </div>

    <div *ngIf="cohort | isDeleted"
         class="alert alert-warning"
         role="alert">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        This cohort has been deleted.
    </div>

    <form *ngIf="!(cohort | isDeleted)"
          name="cohortForm" id="cohortForm"
          class="detail-form"
          (scroll)="onScroll($event)"
          #cohortForm="ngForm">
        <fieldset [disabled]="facet.Privilege === 'ReadOnly'">
            <div class="form-group row"
                 [ngClass]="cohortName.hasError('unique') && cohortName.dirty ? 'has-error' : ''">
                <label class="col-md-2 col-form-label">Name</label>
                <div class="col-md-10">
                    <input data-automation-id="cohort-name-input"
                           type="text"
                           name="cohortName"
                           #cohortName="ngModel"
                           [(ngModel)]="cohort.CohortName"
                           [required]="true"
                           validateUnique
                           uniqueEntity="Cohorts"
                           uniqueProperty="CohortName"
                           [entityPK]="cohort.C_Cohort_key"
                           entityPKName="C_Cohort_key"
                           maxlength="75"
                           class="form-control input-medium"/>
                    <!-- TODO Fix: displays warning if value changed than changed back to original -->
                    <small class="form-text text-muted"
                           *ngIf="cohortName.errors?.unique && cohortName.dirty">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        This cohort name already exists.
                    </small>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Description</label>
                <div class="col-md-10">
                    <textarea [(ngModel)]="cohort.Description"
                              name="cohortDescription"
                              rows="5"
                              maxlength="1000"
                              class="form-control input-medium"></textarea>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Background Color</label>
                <div class="col-md-10">
                    <climb-colorpicker 
                        [(model)]="cohort.BackgroundColor"
                        [popupScrollEnabled]="[scrollTypes.WorkspaceViewComponent, scrollTypes.CohortDetailComponent]"
                        [containerSelector]="colorpickerContainerSelector"
                        [disabled]="facet.Privilege === 'ReadOnly'">
                    </climb-colorpicker>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Foreground Color</label>
                <div class="col-md-10">
                    <climb-colorpicker 
                        [(model)]="cohort.ForegroundColor"
                        [popupScrollEnabled]="[scrollTypes.WorkspaceViewComponent, scrollTypes.CohortDetailComponent]"
                        [containerSelector]="colorpickerContainerSelector"
                        [disabled]="facet.Privilege === 'ReadOnly'">
                    </climb-colorpicker>
                </div>
            </div>

        </fieldset>

        <!-- New fieldset, not disabled for readonly -->
        <fieldset>
            <loading-overlay *ngIf="loading"></loading-overlay>
            <!-- Animals -->
            <div class="form-group" style="margin-top: 1em;">
                <div class="col-md-12">
                    <cohort-animal-table #cohortAnimalTable
                                         [facet]="facet"
                                         [cohort]="cohort"
                                         [cohortMaterials]="cohort.CohortMaterial"
                                         [tableSort]="animalTableSort"
                                         [maxOutputs]="MAX_OUTPUTS"
                                         [readonly]="facet.Privilege==='ReadOnly'"
                                         (cohortMaterialsChange)="onCohortMaterialsChange()"
                                         (cohortMaterialsSelectionChange)="onCohortMaterialsSelectionChange()"
                                         (selectedOutputsChange)="onSelectedOutputsChange()"></cohort-animal-table>
                </div>
            </div>

            <!-- Tabs: histogram and splitter -->
            <nav ngbNav [destroyOnHide]="false" class="nav-tabs p-0" #nav="ngbNav">
                <ng-container ngbNavItem>
                    <a ngbNavLink>Task Output Frequencies</a>
                    <ng-template ngbNavContent>
                        <cohort-task-outputs-chart #cohortTaskOutputsChart
                                                   [taskOutputsData]="taskOutputsData"></cohort-task-outputs-chart>
                    </ng-template>
                </ng-container>

                <ng-container ngbNavItem
                              *ngIf="facet.Privilege === 'ReadWrite' && (cohort.CohortMaterial | notEmpty)">
                    <a data-automation-id="split-cohort-tab" ngbNavLink>Split Cohort</a>
                    <ng-template ngbNavContent>
                        <cohort-splitter #cohortSplitter
                            [facet]="facet"
                            [cohort]="cohort"
                            (cohortsFromDraftCreated)="cohortsFromDraftCreated()"
                            (cohortsDraftCreated)="cohortsDraftCreated($event)"
                            [draftCohortsData]="draftCohortsData"
                            [selectedCohortMaterials]="selectedCohortMaterials"
                            [outputSelectionOptions]="outputSelectionOptions"
                            [maxOutputs]="MAX_OUTPUTS"></cohort-splitter>
                    </ng-template>
                </ng-container>
            </nav>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>

        </fieldset>

    </form>
</div>
