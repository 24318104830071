export const entityMap = {
    '<': '&lt;',
    '>': '&gt;',
    '&': '&amp;',
};

/**
* Escape HTML entities in text.
*/

export default function escapeHtml(value: string): string {
    return String(value).replace(/[<>&]/g, (s) => {
        return entityMap[s];
    });
}
