import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslationService } from '@services/translation.service';
import { padlockToggleClosed, padlockToggleOpened } from '@icons';

@Component({
  selector: 'lock-toggle',
  templateUrl: './lock-toggle.component.html',
  styleUrls: ['./lock-toggle.component.scss']
})
export class LockToggleComponent {
    @Input() model: boolean;
    @Input() administrator = false;
    @Input() readonly = false;
    @Input() title: string = 'Only ' + this.translationService.translate('Study') + ' Administrators can lock or unlock tasks';

    @Output() modelChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    checked = false;

    readonly icons = { padlockToggleClosed, padlockToggleOpened };

    constructor(
        private translationService: TranslationService
    ) { }

    get disabled(): boolean {
        return !this.administrator || this.readonly
    }

    toggle(): void {
        if (this.disabled) {
            return
        }

        this.model = !this.model;
        this.modelChange.emit(this.model);
    }
}
