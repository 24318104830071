import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { SearchService } from '../../search/search.service';
import { SearchQueryDef } from '../../search/search-query-def';
import { Permit } from '@common/types';
import { ApiResponse } from '@services/models/api-response';

const MAX_SEARCH_RESULTS = 50;

@Component({
    selector: 'permit-multiselect',
    template: `
        <!-- Selected permits -->
        <div *ngIf="model && model.length > 0">
            <table class="table table-sm table-borderless table-nonfluid">
                <tbody>
                    <tr *ngFor="let item of model; let i = index;">
                        <td class="icon-cell">
                            <a (click)="removePermit(i)" title="Remove Permit">
                                <i class="fa fa-remove remove-item"></i>
                            </a>
                        </td>
                        <td>
                            {{item.PermitNumber}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Permit typeahead -->
        <climb-indirect-typeahead
                [search]="searchPermits"
                [resultFormatter]="permitNumberFormatter"
                placeholder="Enter Number&hellip;"
                (selectItem)="selectPermit($event)"></climb-indirect-typeahead>
    `,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class PermitMultiselectComponent {
    @Input() model: Permit[];
    @Output() modelChange: EventEmitter<Permit[]> = new EventEmitter<Permit[]>();

    constructor(
        private searchService: SearchService
    ) {}

    removePermit(index: number): void {
        if (this.model && index >= 0 && index < this.model.length) {
            this.model.splice(index, 1);
        }
        this.modelChange.emit(this.model);
    }

    searchPermits = async (term: string): Promise<ApiResponse<Permit>[]> => {

        let count = null;
        if (term.length < 3) {
            count = MAX_SEARCH_RESULTS;
        }

        const permitFilter = {
            PermitNumber: term
        };

        const searchQueryDef: SearchQueryDef = {
            entity: 'Permits',
            page: 1,
            size: count,
            sortColumn: 'PermitNumber',
            sortDirection: 'asc',
            filter: permitFilter,
        };

        const results = await this.searchService.getEntitiesBySearch(searchQueryDef);
        return results.data;
    }

    permitNumberFormatter = (value: Permit): string => {
        return value.PermitNumber;
    }

    selectPermit(permit: Permit): void {
        if (this.model) {
            this.model.push(permit);
        } else {
            this.model = [permit];
        }

        this.modelChange.emit(this.model);
    }
}
