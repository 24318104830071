import type { Entity as BreezeEntity, ComplexObject } from 'breeze-client';

export type Entity<T> = BreezeEntity & T;

type StructuralObject = BreezeEntity | ComplexObject;

declare module 'breeze-client' {
    export interface EntityManager {
        helper: {
            // @see https://breeze.github.io/doc-js/api-docs/globals.html#unwrapchangedvalues
            unwrapChangedValues(entity: BreezeEntity, metadataStore: MetadataStore, transformFn: (dp: DataProperty, val: any) => any): Record<string, unknown>;
            // @see https://breeze.github.io/doc-js/api-docs/globals.html#unwrapinstance
            unwrapInstance(structObj: StructuralObject, transformFn?: (dp: DataProperty, val: any) => any): any;
            // @see https://breeze.github.io/doc-js/api-docs/globals.html#unwraporiginalvalues
            unwrapOriginalValues(target: StructuralObject, metadataStore: MetadataStore, transformFn?: (dp: DataProperty, val: any) => any): Record<string, unknown>;
        };
    }
}
