import { DynamicComponentLoaderModule } from './../dynamic/dynamic-component-loader.module';
import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { StudiesModule } from './../studies/studies.module';
import { WorkflowsModule } from './../workflow/workflows.module';
import { TasksModule } from './../tasks/tasks.module';
import { MonitoringModule } from './../monitoring/monitoring.module';
import { MatingsModule } from './../matings/matings.module';
import { LocationsModule } from './../locations/locations.module';
import { LinesModule } from './../lines/lines.module';
import { ImportModule } from './../import/import.module';
import { InstitutionsModule } from './../institution/institutions.module';
import { HousingModule } from './../housing/housing.module';
import { GenotypesModule } from './../genotypes/genotypes.module';
import { EnumerationsModule } from './../enumerations/enumerations.module';
import { ChartsModule } from './../charts/charts.module';
import { CharacteristicsModule } from './../characteristics/characteristics.module';
import { BirthsModule } from './../birth/births.module';
import { AuditModule } from './../audit/audit.module';
import { AnimalsModule } from './../animals/animals.module';
import { WorkspacesSharedModule } from './workspaces-shared.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClimbCommonModule } from '../common/climb-common.module';
import { OrdersModule } from './../orders/orders.module';

import { ClinicalModule } from '../clinical/clinical.module';
import { ConstructsModule } from '../constructs/constructs.module';
import { PlatesModule } from '../plates/plates.module';
import { ProtocolsModule } from '../protocol/protocols.module';
import { ReportingModule } from '../reporting/reporting.module';
import { RolesModule } from '../role/roles.module';
import { SamplesModule } from '../samples/samples.module';
import { UsersModule } from '../user/users.module';
import { VocabulariesModule } from '../vocabularies/vocabularies.module';
import { IoTModule } from '../iot/iot.module';
import { SettingsModule } from '../settings/settings.module';
import { WorkspaceViewComponent } from './workspace-view.component';
import { GridsterModule } from 'angular2gridster';
import { SearchModule } from '../search/search.module';
import { CensusModule } from '../census/census.module';
import { ClimbServicesModule } from '../services/climb-services.module';
import { FacetFactoryComponent } from './components/facet-factory/facet-factory.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { OnboardingBannerComponent } from './onboarding-banner/onboarding-banner.component';
import { PermitsModule } from '../permits/permits.module';
import { ModernFacetFactoryComponent } from './components/modern-facet-factory/modern-facet-factory.component';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        ClimbCommonModule,
        CommonModule,
        CensusModule,
        FormsModule,
        ReactiveFormsModule,
        FacetSharedModule,
        AnimalsModule,
        AuditModule,
        BirthsModule,
        CharacteristicsModule,
        ChartsModule,
        ClinicalModule,
        ConstructsModule,
        EnumerationsModule,
        GenotypesModule,
        HousingModule,
        ImportModule,
        InstitutionsModule,
        IoTModule,
        LinesModule,
        LocationsModule,
        MatingsModule,
        MonitoringModule,
        OrdersModule,
        PermitsModule,
        PlatesModule,
        ProtocolsModule,
        ReportingModule,
        RolesModule,
        SamplesModule,
        SearchModule,
        SettingsModule,
        StudiesModule,
        TasksModule,
        UsersModule,
        VocabulariesModule,
        WorkflowsModule,
        WorkspacesSharedModule,
        GridsterModule,
        DynamicComponentLoaderModule,
        ClimbServicesModule
    ],
    declarations: [
        SidebarComponent,
        FacetFactoryComponent,
        ModernFacetFactoryComponent,
        WorkspaceViewComponent,
        OnboardingBannerComponent,
    ],
    exports: [
        FacetFactoryComponent,
        WorkspaceViewComponent
    ],
    providers: [
    ]
})
export class WorkspacesModule { }
