// code mostly copied from new-health-record.component.ts
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImportVocabService } from './import-vocab.service';
import { LoggingService } from '../services/logging.service';
import { WebApiService } from '../services/web-api.service';
import { ImportFileDefinitionService } from './import-file-definition.service';
import { CsvExporter } from '../common/export';
import { ImportMappingTargetService } from './import-mapping-target.service';
import { TranslationService, UNHANDLED_ERROR_MESSAGE } from '../services/translation.service';

@Component({
    selector: 'new-import',
    templateUrl: './new-import.component.html',
    styles: [`
        .disabled {
            pointer-events: none;
            opacity: 0.4;
        }
    `]
})
export class NewImportComponent implements OnInit {
    @Input() facetPrivilege: string;
    @Input() username: string;
    @Output() createdImportLog: EventEmitter<number> = new EventEmitter<number>();
    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

    // State
    selectedImportFileTypeKey: number;
    selectedImportFileDefinitionKey: number;
    fileUploaded: boolean;
    filenameInCloud: string;
    importInProgress: boolean;
    definitionModalMode: string;

    // Lists of values
    importFileTypes: any[];
    importFileFormats: any[];
    importMappingTargets: any[];

    // Global variables, with defaults    
    utcOffset: number = new Date().getTimezoneOffset() / 60;

    private csvExporter: CsvExporter;

    constructor(
        private modalService: NgbModal,
        private importVocabService: ImportVocabService,
        private loggingService: LoggingService,
        private webApiService: WebApiService,
        private importFileDefinitionService: ImportFileDefinitionService,
        private importMappingTargetService: ImportMappingTargetService,
        private translationService: TranslationService
    ) {
        this.csvExporter = new CsvExporter();
    }

    async ngOnInit() {
        await this.importVocabService.reloadCache();
        await this.setupCVs();
        this.importInProgress = false;
    }

    cancelHandler() {
        this.cancel.emit();
    }

    async importData(): Promise<void> {
        this.importInProgress = true;

        try {
          // Move file to preprocessed folder
          await this.webApiService.postApi(
            'api/file/import-mapping/move',
            JSON.stringify(this.filenameInCloud),
            'application/json'
          );

          const requestBody = {
            FileName: this.filenameInCloud,
            DefinitionKey: this.selectedImportFileDefinitionKey,
            GlobalVariables: {
              user: this.username,
              utcOffset: this.utcOffset
            }
          };
          // Run actual import
          const response = await this.webApiService.postApi('api/file/import/', requestBody, 'application/json');
          this.createdImportLog.emit(response.data);
        } catch (error) {
          const preparedError = typeof error === 'string' ? JSON.parse(error) : error;
          let message = preparedError.message ?? UNHANDLED_ERROR_MESSAGE;
          if (preparedError.Message) {
            message = preparedError.ErrorCount === 1 ? preparedError.Message : "Multiple validation errors occurred. Please see import result details.";
          }
          
          this.loggingService.logError(message, null, 'new-import', true);

          // TODO: is it correct try to get a key from message (not from preparedError) - check the requirements
          const key = message.ImportLogKey;
          if (key) {
            this.createdImportLog.emit(key);
          } else {
            this.cancel.emit();
          }
        } finally {
          this.importInProgress = false;
        }
    }

    setupCVs(): Promise<any> {
        const cv1: Promise<void> = this.importVocabService.getImportFileFormats()
            .then((data: any[]) => {
                this.importFileFormats = data;
            });
        const cv2: Promise<void> = this.importVocabService.getImportFileTypes()
            .then((data: any[]) => {
                this.importFileTypes = data.filter((item) => {
                    return item.IsSelectable;
                });
                this.importFileTypes.forEach((importFileType) => {
                    importFileType.ImportFileTypeTranslated = this.translationService.translate(importFileType.ImportFileType);
                });
            });

        return Promise.all([cv1, cv2]);
    }

    // For the future
    // editDefinition(fileDefinition: any): void {
    //     console.log({ message: "Editing definition", data: fileDefinition });

    //     // Finalize the definition when OK is hit
    // }

    // createNewDefinition(): void {
    //     console.log({ message: "Creating new definition" });
    //     // Create the definition, then present values returned to user
    //     // Finally call finalize when OK is hit
    // }

    // Make sure we aren't accepting any blank values
    isInputValid(): boolean {
        if (this.selectedImportFileTypeKey &&
            this.filenameInCloud && this.selectedImportFileDefinitionKey) {            
                return true;            
        }
        return false;
    }

    fileUpdate(filename: any) {
        // This will refresh data in lower part
        this.fileUploaded = false;
        // Disable next steps if no file uploaded in child component
        this.fileUploaded = filename ? true : false;
        this.filenameInCloud = this.fileUploaded ? filename : null;
    }

    openModal(modal: any) {
        this.modalService.open(modal);
    }

    changeFileType() {
        // Clear file definition select when there has been file type change.
        this.selectedImportFileDefinitionKey = 0;
    }

    downloadTemplate(selectedDefinitionKey: any) {
        this.importFileDefinitionService
            .getImportFileDefinition(selectedDefinitionKey)
            .then((fileDefinition: any) => {
                this.importMappingTargetService.getImportMappingTargets(
                    fileDefinition.cv_ImportFileType.ImportFileType, fileDefinition.C_ImportFileDefinition_key)
                    .then((targets) => {
                        const componentList: any[] = [];
                        for (const component of fileDefinition.ImportFileComponent) {
                            // Find the component if it already exists in the collection
                            // Components may be mapped to multiple targets
                            const listItem = componentList.find(
                                (x) => x.ComponentName === component.ComponentName
                            );
                            if (listItem == null) {
                                const target = targets.find(
                                    (item: any) => {
                                        return item.TargetName === component.TargetName;
                                    });
                                // Show only Visible targets
                                if (target && target.Visible) {
                                    let translated;
                                    if (target.DisplayName && target.DisplayName.includes("Characteristic - ")) {
                                        translated = target.DisplayName;
                                    } else {
                                        translated = component.ComponentName;
                                        const entitytranslatable = ["Job", "Study", "Line"];
                                        const entity = entitytranslatable.find((term: any) => {
                                            return component.ComponentName.includes(term);
                                        });
                                        if (entity) {
                                            translated = translated.replace(entity, this.translationService.translate(entity));
                                        }
                                    }
                                    componentList.push(translated);
                                }
                            }
                        }
                        this.csvExporter.download([componentList], `${fileDefinition.DefinitionNameTranslated.toLowerCase()}.csv`);
                    });
            })
            .catch(() => {
                this.loggingService.logError('Couldn\'t generate CSV template', null, 'new-import', true);
            });
    }
}
