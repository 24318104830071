<h4>Summary</h4>
<div class="form-group row" [ngClass]="orderID.hasError('unique') && orderID.dirty ? 'has-warning' : ''">
    <label class="col-md-2 col-form-label">ID</label>
    <div class="col-md-10">
        <input
            data-automation-id="order-id-input"
            type="text"
            name="orderID"
            class="form-control input-medium"
            maxlength="75"
            validateUnique
            uniqueEntity="Orders"
            uniqueProperty="OrderID"
            [entityPK]="orderDetailService.order.C_Order_key"
            entityPKName="C_Order_key"
            #orderID="ngModel"
            [(ngModel)]="orderDetailService.order.OrderID"
            [disabled]="(orderDetailService.order | isNew) && orderDetailService.orderNamingActive"
            [required]="!(orderDetailService.order | isNew) || !orderDetailService.orderNamingActive"
        />
        <small class="form-text text-muted" *ngIf="orderID.errors?.unique && orderID.dirty">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            This Order number already exists.
        </small>
        <small
            class="form-text text-muted"
            *ngIf="(orderDetailService.order | isNew) && orderDetailService.orderNamingActive"
        >
            The {{ 'Order' | translate }} Number will be assigned automatically.
        </small>
    </div>
</div>

<div class="form-group row" *ngIf="!orderDetailService.isGLP && orderDetailService.activeFields.has('Order Type')">
    <label class="col-md-2 col-form-label">Type</label>
    <div class="col-md-10">
        <active-vocab-select
            [(model)]="orderDetailService.order.C_MaterialType_key"
            (modelChange)="orderDetailService.orderTypeChanged()"
            [vocabChoices]="orderDetailService.materialTypes"
            [keyFormatter]="materialTypeKeyFormatter"
            [optionFormatter]="materialTypeFormatter"
            [required]="orderDetailService.requiredFields.includes('C_MaterialType_key')"
            [nullable]="!orderDetailService.requiredFields.includes('C_MaterialType_key')"
        >
        </active-vocab-select>
    </div>
</div>

<div class="form-group row" *ngIf="orderDetailService.activeFields.has('Status')">
    <label class="col-md-2 col-form-label">Status</label>
    <div class="col-md-10">
        <active-vocab-select
            [(model)]="orderDetailService.order.C_OrderStatus_key"
            [vocabChoices]="orderDetailService.orderStatuses"
            [keyFormatter]="orderStatusKeyFormatter"
            [optionFormatter]="orderStatusFormatter"
            [defaultValue]="orderDetailService.orderStatusDefaultKey"
            [loadDefaultValue]="orderDetailService.order.C_Order_key < 0"
            [required]="orderDetailService.requiredFields.includes('C_OrderStatus_key')"
            [nullable]="!orderDetailService.requiredFields.includes('C_OrderStatus_key')"
        >
        </active-vocab-select>
    </div>
</div>

<div class="form-group row">
    <label class="col-md-2 col-form-label">Ordered By</label>
    <div class="col-md-10">
        <active-vocab-select
            [(model)]="orderDetailService.order.C_OrderedBy_key"
            [vocabChoices]="orderDetailService.resources"
            [keyFormatter]="resourceKeyFormatter"
            [optionFormatter]="resourceNameFormatter"
            [required]="orderDetailService.requiredFields.includes('C_OrderedBy_key')"
            [nullable]="!orderDetailService.requiredFields.includes('C_OrderedBy_key')"
        >
        </active-vocab-select>
    </div>
</div>

<div class="form-group row" *ngIf="orderDetailService.activeFields.has('Animal/Sample Origin')">
    <label class="col-md-2 col-form-label">Animal/Sample Origin</label>
    <div class="col-md-10">
        <active-vocab-select
            [(model)]="orderDetailService.order.C_MaterialOrigin_key"
            [vocabChoices]="orderDetailService.materialOrigins"
            [keyFormatter]="materialOriginKeyFormatter"
            [optionFormatter]="materialOriginFormatter"
            [defaultValue]="orderDetailService.materialOriginDefaultKey"
            [loadDefaultValue]="orderDetailService.order.C_Order_key < 0"
            [required]="orderDetailService.requiredFields.includes('C_MaterialOrigin_key')"
            [nullable]="!orderDetailService.requiredFields.includes('C_MaterialOrigin_key')"
        >
        </active-vocab-select>
    </div>
</div>

<order-institution-table *ngIf="orderDetailService.activeFields.has('Institutions')"
    class="institutions-table"    
    [order]="orderDetailService.order"
    [readonly]="orderDetailService.readonly || orderDetailService.order.IsLocked"
    [required]="orderDetailService.requiredFields.includes('OrderInstitution[0]') && orderDetailService.order.OrderInstitution.length === 0">
</order-institution-table>
