<form name="OrderForm" id="orderForm" class="detail-form" #orderForm="ngForm">
    <fieldset [disabled]="orderDetailService.readonly || orderDetailService.order.IsLocked">
        <div class="form-group row">
            <label class="col-md-2 col-form-label lock-label">
                <h3>
                    Order {{orderDetailService.order.IsLocked ? 'Locked' : 'Unlocked'}}
                </h3>
            </label>
            <div class="col-md-10">
                <p>
                    <lock-toggle
                        [(model)]="orderDetailService.order.IsLocked"
                        [administrator]="orderDetailService.isOrderAdministrator"
                        [readonly]="orderDetailService.readonly"
                        [title]="orderDetailService.lockTitle"
                    ></lock-toggle>
                </p>
            </div>
        </div>

        <div class="row order-section">
            <div class="col-md-12">
                <order-detail-summary></order-detail-summary>
            </div>
        </div>

        <div class="form-group row" *ngIf="orderDetailService.activeFields.has('Job')">
            <label class="col-md-2 col-form-label">{{ 'Job' | translate }}</label>
            <div class="col-md-10">
                <job-select
                    [excludeLocked]="true"
                    [(model)]="orderDetailService.jobOrderKey"
                    (modelChange)="orderDetailService.onSelectJob($event)"
                    [required]="orderDetailService.requiredFields.includes('JobOrder[0].C_Job_key')"
                >
                </job-select>
            </div>
        </div>

        <order-location-table
            class="locations-table"
            *ngIf="orderDetailService.activeFields.has('Locations')"
            (firstLineChanged)="orderDetailService.updateOrderID('location')"
            [order]="orderDetailService.order"
            [readonly]="orderDetailService.readonly || orderDetailService.order.IsLocked"
            [required]="
                orderDetailService.requiredFields.includes('OrderLocation[0]') &&
                orderDetailService.order.OrderLocation.length === 0
            "
        >
        </order-location-table>

        <div class="form-group row" *ngIf="orderDetailService.activeFields.has('PO Number')">
            <label class="col-md-2 col-form-label">PO Number</label>
            <div class="col-md-10">
                <input
                    type="text"
                    name="InvoiceNumber"
                    class="form-control input-medium"
                    maxlength="75"
                    #name="ngModel"
                    [(ngModel)]="orderDetailService.order.InvoiceNumber"
                    [required]="orderDetailService.requiredFields.includes('InvoiceNumber')"
                />
            </div>
        </div>

        <div class="form-group row" *ngIf="orderDetailService.activeFields.has('Vendor Order Number')">
            <label class="col-md-2 col-form-label">Vendor Order Number</label>
            <div class="col-md-10">
                <input
                    type="text"
                    name="Vendor"
                    class="form-control input-medium"
                    maxlength="200"
                    #name="ngModel"
                    [(ngModel)]="orderDetailService.order.VendorOrderNumber"
                    [required]="orderDetailService.requiredFields.includes('VendorOrderNumber')"
                />
            </div>
        </div>

        <div class="form-group row" *ngIf="orderDetailService.activeFields.has('Number of Animals Ordered')">
            <label class="col-md-2 col-form-label">Number of Animals Ordered</label>
            <div class="col-md-10">
                <input
                    type="number"
                    name="animalCount"
                    class="form-control input-number"
                    min="1"
                    step="1"
                    [(ngModel)]="orderDetailService.order.AnimalCount"
                    [required]="orderDetailService.requiredFields.includes('AnimalCount')"
                />
                <small
                    *ngIf="
                        orderDetailService.order.AnimalCount &&
                        orderDetailService.tableAnimalCount != null &&
                        orderDetailService.order.AnimalCount !== orderDetailService.tableAnimalCount
                    "
                    class="form-text text-muted"
                >
                    <i class="fa fa-exclamation-triangle alert-warning" aria-hidden="true"></i>
                    The number of animals ordered does not match the received number.
                </small>
            </div>
        </div>

        <div class="form-group row" *ngIf="orderDetailService.activeFields.has('Quarantine Services Facility')">
            <label class="col-md-2 col-form-label">Quarantine Services Facility</label>
            <div class="col-md-10">
                <active-vocab-select
                    [(model)]="orderDetailService.order.C_QuarantineFacility_key"
                    [vocabChoices]="orderDetailService.quarantineFacilities"
                    [keyFormatter]="orderDetailService.quarantineFacilityKeyFormatter"
                    [optionFormatter]="orderDetailService.quarantineFacilityFormatter"
                    [required]="orderDetailService.requiredFields.includes('C_QuarantineFacility_key')"
                    [nullable]="!orderDetailService.requiredFields.includes('C_QuarantineFacility_key')"
                >
                </active-vocab-select>
            </div>
        </div>

        <div class="form-group row" *ngIf="orderDetailService.activeFields.has('Vendor Room ID')">
            <label class="col-md-2 col-form-label">Vendor Room ID</label>
            <div class="col-md-10">
                <input
                    type="text"
                    name="VendorRoom"
                    class="form-control input-medium"
                    maxlength="200"
                    #name="ngModel"
                    [(ngModel)]="orderDetailService.order.VendorRoomId"
                    [required]="orderDetailService.requiredFields.includes('VendorRoomId')"
                />
            </div>
        </div>

        <div class="form-group row" *ngIf="orderDetailService.activeFields.has('Date Ordered')">
            <label class="col-md-2 col-form-label">Date Ordered</label>
            <div class="col-md-10">
                <climb-ngb-date
                    #dateControl="ngModel"
                    name="Date Ordered"
                    [(ngModel)]="orderDetailService.order.DateReceived"
                    [required]="orderDetailService.requiredFields.includes('DateReceived')"
                >
                </climb-ngb-date>
            </div>
        </div>

        <div class="form-group row" *ngIf="orderDetailService.activeFields.has('Projected Receipt Date')">
            <label class="col-md-2 col-form-label">Projected Receipt Date</label>
            <div class="col-md-10">
                <climb-ngb-date
                    #dateControl="ngModel"
                    name="Projected Receipt Date"
                    [(ngModel)]="orderDetailService.order.DateProjectedReceipt"
                    [required]="orderDetailService.requiredFields.includes('DateProjectedReceipt')"
                >
                </climb-ngb-date>
            </div>
        </div>

        <div class="form-group row" *ngIf="orderDetailService.activeFields.has('Instructions')">
            <label class="col-md-2 col-form-label">Instructions</label>
            <div class="col-md-10">
                <textarea climbInput
                          climbCharacterCounter
                          [maxCharacterCount]="2000"
                          name="Comments"
                          #name="ngModel"
                          class="form-control textarea-field"
                          [(ngModel)]="orderDetailService.order.Comments"
                          [required]="orderDetailService.requiredFields.includes('Comments')">
                </textarea>
            </div>
        </div>

        <div class="form-group row" *ngIf="orderDetailService.activeFields.has('CITES')">
            <label class="col-md-2 col-form-label">CITES</label>
            <div class="col-md-10">
                <input
                    type="text"
                    name="Cites"
                    class="form-control input-medium"
                    maxlength="200"
                    #name="ngModel"
                    [(ngModel)]="orderDetailService.order.Cites"
                    [required]="orderDetailService.requiredFields.includes('Cites')"
                />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Files</label>
            <div class="col-md-10">
                <div class="form-control-plaintext">
                    <facet-detail-file-upload
                        [pkName]="'C_Order_key'"
                        [pkValue]="orderDetailService.order.C_Order_key"
                        [facetPrivilege]="orderDetailService.facetPrivilege"
                        [disabled]="orderDetailService.order.IsLocked"
                    >
                    </facet-detail-file-upload>
                </div>
            </div>
        </div>

        <ng-container
            *ngIf="orderDetailService.subOrder && orderDetailService.order.cv_MaterialType?.MaterialType === 'Sample'"
        >
            <sample-order-detail
                [order]="orderDetailService.order"
                [sampleOrder]="orderDetailService.subOrder"
                [readonly]="orderDetailService.readonly || orderDetailService.order.IsLocked"
            >
            </sample-order-detail>
        </ng-container>
    </fieldset>
</form>
