import { Injectable } from "@angular/core";
import { Animal, Entity, ExtendedJob, Job, JobMaterial, SampleGroupSourceMaterial, TaskJob } from "@common/types";
import { JobPharmaDetailService } from "../../services/job-pharma-detail.service";
import { DataManagerService } from "@services/data-manager.service";
import { WorkflowService } from "src/app/workflow/services/workflow.service";
import { JobService } from "../../../job.service";
import { ISelectable } from "@common/types/selectable.interface";
import { TableSort, TableSortConfig } from "@common/models";
import { WorkspaceService } from "src/app/workspaces/workspace.service";
import { JobPharmaCoreService } from "../../services";
import { FeatureFlagService } from "@services/feature-flags.service";
import { JobPharmaDataAccessService } from "../../services/job-pharma-data-access.service";
import { VocabularyService } from "src/app/vocabularies/vocabulary.service";
import { DragulaService } from "ng2-dragula";
import { LoggingService } from "@services/logging.service";
import { IFacet } from "@common/facet";
import { defaultString } from "@common/util/default-string";
import { SortColumnConfig, SortConfig } from "../../util/sort-config";
import { PartialJobMaterial, PartialMaterial } from "../../models/partial-material";
import { formatDataAutomationId } from "@common/util/format-data-automation-id";
import { toPartialDTO } from "../../util/to-partial-dto";
type JobMaterialExtended = JobMaterial & ISelectable;

class DragEvent {
    name: string;
    el: Element;
    target: Element;
    source: Element;
    sibling: Element;
    item: any;
    sourceModel: any[];
    targetModel: any[];
    sourceIndex: number;
    targetIndex: number;
}

@Injectable()
export class JobPharmaAnimalsIndividualTableService {
    constructor(
        private jobPharmaDetailService: JobPharmaDetailService,
        private dataManager: DataManagerService,
        private workflowService: WorkflowService,
        private jobService: JobService,
        private workspaceService: WorkspaceService,
        private featureFlagService: FeatureFlagService,
        private jobPharmaDataAccessService: JobPharmaDataAccessService,
        private vocabularyService: VocabularyService,
        private dragulaService: DragulaService,
        private jobPharmaCoreService: JobPharmaCoreService,
        private loggingService: LoggingService
    ) { }
    
    trackedUnselections: Set<number> = new Set();
    loadingMaterialDataPromise?: Promise<void>;
    applyAllSelected = false;
    trueAnimalCount: number;

    allRowsSelected: boolean;
    selectedRows: Map<number, (PartialMaterial & PartialJobMaterial)> = new Map();
    readonly COMPONENT_LOG_TAG = 'job-pharma-animals-individual-table';
    private dragId: number = null;
    private dragTimeoutId: ReturnType<typeof setTimeout>;

    async getAnimalJobMaterials(jobKey: number, pageNumber: number, orderBy?: string): Promise<JobMaterial[]> {
        let animalJobMaterials = await this.jobPharmaCoreService.getAnimalJobMaterials(jobKey, pageNumber, orderBy);
        if (this.featureFlagService.getIsGLP()) {
            animalJobMaterials = animalJobMaterials.filter((jm: JobMaterial) => {
                return !jm.DateOut;
            });
        }

        return animalJobMaterials;
    }

    async getAnimalCount(job: Job): Promise<number> {
        return this.jobPharmaCoreService.getAnimalCount(job);
    }

    loadVocabularies() {
        return Promise.all([
            this.vocabularyService.ensureCVLoaded('cv_GenotypeAssays', false),
            this.vocabularyService.ensureCVLoaded('cv_GenotypeSymbols', false)
        ]);
    }

    async removeAnimalJobMaterial(jobMaterial: JobMaterial, job: Entity<Job & ExtendedJob>, isGLP: boolean): Promise<void> {
        const animal = jobMaterial.Material.Animal as Entity<Animal>;
        const taskInstanceKeys = job.TaskJob.map((taskJob: TaskJob) => taskJob.C_TaskInstance_key);
        const samples = jobMaterial.Material.SampleGroupSourceMaterial
            .find((x: any) => x.SampleGroup && x.SampleGroup.Sample && x.SampleGroup.Sample.length > 0
                && taskInstanceKeys.includes(x.SampleGroup.C_TaskInstance_key));

        if (!isGLP && samples) {
            return this.jobPharmaDetailService.notifyAnimalsHaveSamples([animal]);
        }

        try {
            // Try to remove the Animal from the tasks as well
            this.jobPharmaDetailService.busyStart();
            const result = await this.jobPharmaDetailService.tryRemoveAnimalsFromTasks(job, [animal]);

            if (!result.allRemoved) {
                return this.jobPharmaDetailService.notifyAnimalsHaveSamples(result.withData);
            }

            const sampleGroupSourceMaterialToDelete = jobMaterial.Material.SampleGroupSourceMaterial
                .filter((x: SampleGroupSourceMaterial) => x.SampleGroup && taskInstanceKeys.includes(x.SampleGroup.C_TaskInstance_key));

            if (sampleGroupSourceMaterialToDelete) {
                sampleGroupSourceMaterialToDelete.forEach((element: SampleGroupSourceMaterial) => {
                    this.dataManager.deleteEntity(element);
                });
                this.jobPharmaDetailService.tabRefresh('samples', 'groups');
            }

            const taskInstances = job.TaskJob.map(taskJob => taskJob.TaskInstance);

            if (jobMaterial.Material.CohortMaterial?.length) {
                const tasksToDelete = taskInstances.filter(taskInstance => {
                    return taskInstance.TaskMaterial.map(item => item.C_Material_key).includes(animal.C_Material_key);
                });

                tasksToDelete.forEach(task => {
                    this.workflowService.deleteTask(task);
                });
            }

            // Remove the Animal from the Job
            this.jobService.deleteJobMaterial(jobMaterial);

            this.jobPharmaDetailService.tabRefresh('tasks', 'list');
            this.jobPharmaDetailService.tabRefresh('tasks', 'outline');

            if (isGLP) {
                jobMaterial.DateOut = new Date();
            }
            this.jobPharmaCoreService.removeAnimalJobMaterial(jobMaterial);
        } finally {
            this.jobPharmaDetailService.busyStop();
        }
    }

    async dragStart(job: Job): Promise<void> {
        if (this.dragTimeoutId) {
            clearTimeout(this.dragTimeoutId);
            this.dragTimeoutId = null;
        }

        this.dragId = this.jobPharmaDetailService.startDragAsync('Animal', async () => {
            if (this.loadingMaterialDataPromise){
                await this.loadingMaterialDataPromise;
            }

            return this.getSelectedRows(job);
        });

        let count = 0;
        if (this.loadingMaterialDataPromise && this.applyAllSelected) {
            count = this.trueAnimalCount - this.trackedUnselections.size;
        } else {
            count = this.getSelectedRows(job).length;
        }

        if (count > 1) {
            jQuery('.ui-draggable-dragging')[0].textContent = `${this.selectedRows.size} Animals Selected`;
        }
    }

    dragStop(): void {
        this.dragTimeoutId = setTimeout(() => {
            this.jobPharmaDetailService.stopDrag(this.dragId);
            this.dragTimeoutId = null;
        }, 500);
    }

    saveSortConfig(tableSort: TableSort, facet: IFacet) {
        // Start from scratch
        const config = new SortConfig();

        if (tableSort.nested) {
            tableSort.properties.forEach((path: any) => {
                const columnConfig = new SortColumnConfig();
                columnConfig.selected = true;
                columnConfig.reverse = tableSort.nestedReverse[path];
                config.columns[path] = columnConfig;
            });
        } else {
            if (tableSort.propertyPath !== '') {
                const columnConfig = new SortColumnConfig();
                columnConfig.selected = true;
                columnConfig.reverse = tableSort.reverse;
                config.columns[tableSort.propertyPath] = columnConfig;
            }
        }

        // Rebuild the BulkDataConfiguration JSON
        facet.BulkDataConfiguration = JSON.stringify(config);

        // Save just the BulkDataConfiguration value in the facet
        this.workspaceService.saveBulkDataConfiguration(facet);
    }

    allSelectedChanged(animalJobMaterials: JobMaterialExtended[], job: Job): void {
        /**
         * Clear tracked unselections when select all button is clicked.
         * This is responsible for keeping tracking of user unselecting rows
         * while get all animal request is in transit so when data is loaded in,
         * we know to unselect some of them.
         */
        this.trackedUnselections.clear();

        if (this.allRowsSelected) {
            // Select all
            this.applyAllSelected = true;
            
            // Update current page of non-deleted animal job materials for UI purposes
            animalJobMaterials.forEach((jm) => {
                if (!(<Entity<JobMaterial>>jm).entityAspect?.entityState?.isDeleted()) {
                    jm.isSelected = true;
                }
            });

            if (this.loadingMaterialDataPromise) {
                // Do not make additional calls to get all animal if request is in transit
                return;
            }

            this.loadingMaterialDataPromise = this.handleGetAllAnimalMaterialsOnJob(job, animalJobMaterials);
        } else {
            // Unselect all
            if (this.loadingMaterialDataPromise) {
                // Keeps track of select all action while isLoadingMaterialData is still not resolved
                this.applyAllSelected = false;
            }

            this.clearAnimalSelections(job, animalJobMaterials);
        }
    }

    private async handleGetAllAnimalMaterialsOnJob(job: Job, animalJobMaterials: JobMaterialExtended[]) {
        try {
            const jobMaterials = await this.jobPharmaCoreService.getAllAnimalJobMaterialsOnJob(job);
            if (!this.applyAllSelected) {
                // Do nothing if user unselects all while request is in transit
                return;
            }

            this.selectedRows.clear();
            // Add materials to selected rows only for rows that were not unselected during get all animals request
            jobMaterials.forEach(jm => !this.trackedUnselections.has(jm.C_JobMaterial_key) && this.selectedRows.set(jm.C_JobMaterial_key, jm));
        } catch(err) {
            this.loggingService.logError("Something went wrong getting all animals on job", err, this.COMPONENT_LOG_TAG, false);
            animalJobMaterials.forEach((jm) => this.selectedRows.set(jm.C_JobMaterial_key, toPartialDTO(jm)));
        } finally {
            this.loadingMaterialDataPromise = null;
            this.trackedUnselections.clear();
            this.updateSelections(animalJobMaterials);
            this.selectionChanged(job, animalJobMaterials);
        }
    }

    async clearAnimalSelections(job: Job, animalJobMaterials: JobMaterialExtended[], reload?: boolean) {
        this.selectedRows.clear();
        this.updateSelections(animalJobMaterials);
        await this.selectionChanged(job, animalJobMaterials);

        if (reload) {
            this.jobPharmaCoreService.cleanupIgnoredAnimalsOnJob(job);
        }

    }

    updateSelections(animalJobMaterials: JobMaterialExtended[]): void {
        animalJobMaterials.forEach((jm) => jm.isSelected = this.selectedRows.has(jm.C_JobMaterial_key));
    }

    handleUIUpdatesIfLoadingMaterialData(animalJobMaterials: JobMaterialExtended[]) {
        if (this.loadingMaterialDataPromise) {
            animalJobMaterials.forEach(jm => {
                if (this.applyAllSelected && !this.trackedUnselections.has(jm.C_JobMaterial_key)) {
                    jm.isSelected = true;
                }
            });
        }
    }

    isSelectedChanged(job: Job, animalJobMaterials: JobMaterialExtended[], jobMaterial: JobMaterialExtended): void {
        // Check if all the rows are selected
        if (jobMaterial.isSelected) {
            this.selectedRows.set(jobMaterial.C_JobMaterial_key, toPartialDTO(jobMaterial));

            if (this.loadingMaterialDataPromise) {
                this.trackedUnselections.delete(jobMaterial.C_JobMaterial_key);
            }
        } else {
            this.selectedRows.delete(jobMaterial.C_JobMaterial_key);

            if (this.loadingMaterialDataPromise) {
                this.trackedUnselections.add(jobMaterial.C_JobMaterial_key);
            }
        }

        this.selectionChanged(job, animalJobMaterials)
    }

    async selectionChanged(job: Job, animalJobMaterials: JobMaterialExtended[]){
        this.trueAnimalCount = await this.jobPharmaCoreService.getAnimalCountMinusPendingDeletions(job);

        if (this.loadingMaterialDataPromise) {
            await this.loadingMaterialDataPromise;
        }

        const rows = this.getSelectedRows(job);
        if (rows.length === this.trueAnimalCount && (animalJobMaterials.filter((jm: Entity<JobMaterial>) => !jm.entityAspect.entityState.isDeleted()).length > 0 || this.applyAllSelected)) {
            this.allRowsSelected = true;
        } else {
            this.allRowsSelected = false;
        }

    }

    getSelectedRows(job: Job) {
        const ignoredAnimalsOnJob = this.jobPharmaCoreService.getIgnoredAnimalJobMaterialKeysOnJob(job, true);
        return Array.from(this.selectedRows.values()).filter(jm => !ignoredAnimalsOnJob.has(jm.C_JobMaterial_key));
    }

    reorderOnDrag(event: DragEvent, animalJobMaterials: JobMaterialExtended[], dragulaBagName: string): JobMaterialExtended[] {
        const {el, targetModel, targetIndex} = event;
        const draggedKey = parseInt((el as HTMLElement).dataset.key, 10);
        const draggedLookup: {[key: number]: JobMaterialExtended} = {}
        const dragged: JobMaterialExtended[] = [];

        if (draggedLookup[targetIndex]) {
            this.dragulaService.find(dragulaBagName).drake.cancel(true);
            return;
        }

        animalJobMaterials.forEach((jm, index) => {
            if (jm.isSelected || jm.C_Material_key === draggedKey){
                draggedLookup[index] = jm;
                dragged.push(jm);
            }
        });

        if (dragged.length > 1) {
            const front = []
            const back = []

            for (let i = 0; i < animalJobMaterials.length; i++) {
                const jm = animalJobMaterials[i]
                if (draggedLookup[i] === jm) {
                    continue
                } 

                if (i <= targetIndex && targetIndex !== 0) {
                    front.push(jm)
                }

                if (i > targetIndex || targetIndex === 0) {
                    back.push(jm)
                }
            }

            animalJobMaterials = [...front, ...dragged, ...back];
            this.dragulaService.find(dragulaBagName).drake.cancel(true);
        } else {
            animalJobMaterials = targetModel;
        }
        return animalJobMaterials
    }
    

    parseSortConfig(facet: any): SortConfig {
        try {
            if (facet.BulkDataConfiguration) {
                return JSON.parse(facet.BulkDataConfiguration);
            }
        } catch (e) {
            console.error('Could not parse BulkDataConfiguration', e);
        }

        return new SortConfig();
    }

    setDefaultTableSort(tableSort: TableSort, facet: IFacet): TableSort {
        if (!tableSort) {
            tableSort = new TableSort();
        }
        const config = this.parseSortConfig(facet);
        const columns = Object.keys(config.columns);
        if (columns.length === 0) {
            return new TableSort();
        }
        tableSort.nested = columns.length > 1;
        tableSort.natural = true;
        if (tableSort.nested) {
            const properties: string[] = [];
            const reverses: {[key: string]: boolean} = {};
            const indexes: {[key: string]: number} = {};
            columns.forEach((key, index) => {
                reverses[key] = config.columns[key].reverse;
                indexes[key] = index + 1;
                properties.push(key);
            });
            tableSort.setSortSetup(properties, reverses, indexes);
        } else {
            const key = columns[0];
            tableSort.setSingleSortSetup(key, config.columns[key].reverse);
        }

        return tableSort;
    }

    checkPageSequence(animalJobMaterials: JobMaterialExtended[], page: number): boolean{
        let sequence = ((page - 1) * 50);
        const jobMaterials = animalJobMaterials.filter((jm) => jm?.Material?.Animal);
        const sequenceCheck = jobMaterials.every((jm) => {
            return jm.Sequence === ++sequence
        });
        return sequenceCheck;
    }

    fixPageSequence(animalJobMaterials: JobMaterialExtended[], page: number): JobMaterialExtended[] {
        let sequence = (page - 1) * 50;
        const jobMaterials = animalJobMaterials.filter((jm) => jm?.Material?.Animal);
        jobMaterials.forEach(jm => jm.Sequence = ++sequence);
        return jobMaterials
    }

    async updateAnimalSequence(jobKey: number, tableSort: TableSortConfig[]): Promise<void> {
        const data = {JobKey: String(jobKey), TableSorts: tableSort};
        const animalSequenceCheck = await this.jobPharmaDataAccessService.checkAnimalSequence(data);
        if (animalSequenceCheck){
            await this.jobPharmaDataAccessService.updateAnimalSequence(data);
        }
        if (animalSequenceCheck) {
            this.loggingService.logWarning(
                `Updated animal sequence. Animal sequence determines the animal order in the Workflow facet's Bulk Data Entry.`,
                null,
                this.COMPONENT_LOG_TAG,
                true);
            this.loggingService.logFacetSaveSuccess(this.COMPONENT_LOG_TAG, true)?.attr("data-automation-id", formatDataAutomationId("changes-saved", "", "-text"));
        }
    }

    async bulkNameUpdated(bulkNamePrefix: string, bulkNameSuffix: string, bulkNameCounter: number, jobKey: number): Promise<void> {
        try {
            const prefix = defaultString(bulkNamePrefix, '');
            const suffix = defaultString(bulkNameSuffix, '');

            const data = {
                JobKey: jobKey, 
                Prefix: prefix, 
                Suffix: suffix, 
                Counter: bulkNameCounter
            }

            await this.jobPharmaDataAccessService.bulkUpdateAnimalName(data);
            this.loggingService.logWarning(
                `Animal Names Updated.`,
                null,
                this.COMPONENT_LOG_TAG,
                true);
            } catch (err) {
                this.loggingService.logError("Error bulk updating animal name.", err, this.COMPONENT_LOG_TAG, true)
            }
        } 
}