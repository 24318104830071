import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';

import { CohortTaskOutputsChartComponent, CohortSplitterComponent, CohortAnimalTableComponent } from '../components';
import { CohortService, IDraftCohorts } from '../services/cohort.service';
import { IValidatable, IBeforeSaveInterceptor, SaveChangesService, UnsavedChanges } from '@services/save-changes.service';
import { VocabularyService } from '../../vocabularies/vocabulary.service';

import { TaskOutputsData } from '../models';

import {
    BaseDetail,
    BaseDetailService, 
    FacetView,
    PageState
} from '@common/facet';

import {
    TableSort
} from '@common/models';
import { FeatureFlagService } from '@services/feature-flags.service';
import { Cohort, CohortMaterial, Entity } from '@common/types';
import { ScrollEventService } from '@common/services';
import { ScrollTypes } from '@common/export';
import { SelectionOption } from '@common/checkbox/checkbox-multi-select.component';
import { ToastrService } from '@services/toastr.service';
import { LogLevel } from '@services/models';
import { DataManagerService } from '@services/data-manager.service';
import { DialogService } from '@common/dialog/deprecated';

export  interface ExtendedCohortMaterial extends CohortMaterial {
    isSelected: boolean;
}
export interface ExtendedCohort extends Cohort {
    CohortMaterial: ExtendedCohortMaterial[];
}

@Component({
    selector: 'cohort-detail',
    templateUrl: './cohort-detail.component.html',
    styles: [`
        .detail-header {
            z-index: 10000;
        }
    `],
})
export class CohortDetailComponent extends BaseDetail
    implements OnChanges, OnDestroy, OnInit, IValidatable, IBeforeSaveInterceptor {
    @Input() facet: any;
    @Input() facetView: FacetView;
    @Input() cohort: Entity<ExtendedCohort>;
    @Input() pageState: PageState;
    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();
    @Output() modelCopy: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("cohortForm") cohortForm: NgForm;
    @ViewChild("cohortSplitter") cohortSplitter?: CohortSplitterComponent;
    @ViewChild("cohortTaskOutputsChart") cohortTaskOutputsChart: CohortTaskOutputsChartComponent;
    @ViewChild('cohortAnimalTable') cohortAnimalTable: CohortAnimalTableComponent;
    readonly colorpickerContainerSelector = '.facet-panel .card-body .detail-form';

    // State
    saving = false;
    // Table sorting
    animalTableSort: TableSort = new TableSort();
    taskOutputsData: TaskOutputsData = new TaskOutputsData();
    // selected animals
    selectedCohortMaterials: any[] = [];

    isGLP = false;

    readonly COMPONENT_LOG_TAG = 'cohort-detail';
    // The maximum number of outputs that can be selected for animals
    readonly MAX_OUTPUTS = 2;
    public scrollTypes = ScrollTypes;
    public draftCohortsData: IDraftCohorts | null = null;

    outputSelectionOptions: SelectionOption[];


    constructor(
        private baseDetailService: BaseDetailService,
        private cohortService: CohortService,
        private saveChangesService: SaveChangesService,
        private vocabularyService: VocabularyService,
        private featureFlagService: FeatureFlagService,
        private scrollEventService: ScrollEventService,
        private toastrService: ToastrService,
        private dataManager: DataManagerService,
        private dialogService: DialogService,
    ) {
        super(baseDetailService);
        this.initIsGLP();
    }

    // lifecycle
    async ngOnInit() {
        this.saveChangesService.registerValidator(this);
        this.saveChangesService.registerBeforeSaveInterceptor(this);
        await this.initialize();
    }

    ngOnDestroy() {
        this.saveChangesService.unregisterValidator(this);
        this.saveChangesService.unregisterBeforeSaveInterceptor(this);
    }

    ngOnChanges(changes: any) {
        if (changes.cohort) {
            if (this.cohort && !changes.cohort.firstChange) {
                if (this.cohortForm) {
                    this.cohortForm.form.markAsPristine();
                }
                this.initialize();

                // Reset ViewChildren
                if (this.cohortSplitter) {
                    this.cohortSplitter.initialize();
                }

            }
        }
    }

    async initialize(): Promise<void> {
        this.setLoading(true);

        this.taskOutputsData = new TaskOutputsData();
        try {
            await this.getCVs();
            await this.getAnimals();
            const draftCohortsData = await this.cohortService.getDraftCohortsString(this.cohort);

            if (draftCohortsData) {
                this.draftCohortsData = JSON.parse(draftCohortsData);
                this.initializeDraftCohortsData();
            } else {
                this.selectedCohortMaterials = this.getSelectedCohortMaterials();
                this.setupOutputSelectionOptions();
            }

            this.refreshCohortTaskOutputsChart();
        } catch (error) {
            throw error;
        } finally {
            this.setLoading(false);
        }
    }

    private initializeDraftCohortsData() {
        const { newCohortsChanges, excludedCohortChanges, selectedOutputsToRandomize } = this.draftCohortsData;
        const parsedNewCohorsChanges = JSON.parse(newCohortsChanges);
        const cohortMaterialsData = parsedNewCohorsChanges.entityGroupMap['CohortMaterial:#CLIMB.Data'];
        const newCohortsMaterials: Entity<CohortMaterial>[] = (cohortMaterialsData && cohortMaterialsData.entities) || [];
        let excludedCohortMaterials: Entity<CohortMaterial>[];
        if (excludedCohortChanges) {
            const parsedExcludedCohortMaterialsChanges = JSON.parse(excludedCohortChanges);
            const cohortMaterialsData = parsedExcludedCohortMaterialsChanges.entityGroupMap['CohortMaterial:#CLIMB.Data'];
            excludedCohortMaterials = (cohortMaterialsData && cohortMaterialsData.entities) || [];
        }

        let materialKeys = newCohortsMaterials.map(item => item.C_Material_key);
        if (excludedCohortMaterials) {
            const excludedCohortMaterialKeys = excludedCohortMaterials.map(item => item.C_Material_key);
            materialKeys = [ ...materialKeys, ...excludedCohortMaterialKeys];
        }
        for (const cohortMaterial of this.cohort.CohortMaterial) {
            cohortMaterial.isSelected = materialKeys.includes(cohortMaterial.C_Material_key);
        }
        this.cohortAnimalTable.setSelectedCohortMaterialCount();

        this.selectedCohortMaterials = this.getSelectedCohortMaterials();
        this.outputSelectionOptions = selectedOutputsToRandomize.map((item: string) => {
            return {
                label: item.replace('Value', ' '),
                value: item,
                checked: true,
            };
        });
    }

    private getAnimals(): Promise<Entity<Cohort>> {
        if (this.cohort && this.cohort.C_Cohort_key > 0) {
            const expands: string[] = [
                "CohortMaterial",
                "CohortMaterial.Material.Line",
                "CohortMaterial.Material.MaterialPoolMaterial.MaterialPool",
                "CohortMaterial.Material.Animal.Genotype.cv_GenotypeSymbol",
                "CohortMaterial.Material.Animal.Genotype.cv_GenotypeAssay",
                "CohortMaterial.Material.Animal.cv_PhysicalMarkerType"
            ];

            return this.cohortService.getCohort(this.cohort.C_Cohort_key, expands);
        }

        return Promise.resolve(this.cohort);
    }

    private getCVs(): Promise<any> {
        return this.vocabularyService.ensureCVLoaded('cv_AnimalStatuses').then(() => {
            return this.vocabularyService.ensureCVLoaded('cv_Sexes');
        });
    }

    onCancel() {
        this.cohortService.cancelCohort(this.cohort);

        if (this.cohortSplitter) {
            this.cohortSplitter.onCancel();
        }
    }

    selectedAnimalsCount(): number {
        return this.cohort?.CohortMaterial?.reduce(
            (count: number, cohortMaterial: any): number => {
                if (cohortMaterial.isSelected) {
                    return count + 1;
                } else {
                    return count;
                }
            }, 0);
    }

    getSelectedCohortMaterials(): any[] {
        return this.cohort?.CohortMaterial?.filter(
            (cohortMaterial: ExtendedCohortMaterial) => {
                return cohortMaterial.isSelected;
            });
    }

    initIsGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    async onCohortMaterialsChange(): Promise<void> {
        if (this.draftCohortsData) {
            await this.cohortService.saveDraftCohortsString(this.cohort, '');
            this.draftCohortsData = null;
            this.toastrService.showToast('Editing the number of animals in the main cohort removes drafts related to the main cohort.', LogLevel.Warning);
        }
    }

    onSelectedOutputsChange() {
        this.setupOutputSelectionOptions();
        this.refreshCohortTaskOutputsChart();
    }

    setupOutputSelectionOptions() {
        this.outputSelectionOptions = [
            {
                label: this.cohort.Output1?.OutputName,
                value: 'OutputValue1',
                checked: false,
            }, 
            {
                label: this.cohort.Output2?.OutputName,
                value: 'OutputValue2',
                checked: false,
            },
            {
                label: this.cohort.Output3?.OutputName,
                value: 'OutputValue3',
                checked: false,
            },
        ].filter(o => o.label?.length);
    }

    onCohortMaterialsSelectionChange() {
        this.refreshCohortTaskOutputsChart();
        this.selectedCohortMaterials = this.getSelectedCohortMaterials();
    }

    refreshCohortTaskOutputsChart() {
        this.taskOutputsData = new TaskOutputsData();
        this.taskOutputsData.cohortMaterials = this.cohort?.CohortMaterial;
        this.taskOutputsData.selectedCohortMaterials = this.getSelectedCohortMaterials();
        this.taskOutputsData.selectedOutputs = [this.cohort?.Output1, this.cohort?.Output2, this.cohort?.Output3];
    }

    async beforeSave(): Promise<void> {
        if (this.cohortSplitter) {
            this.cohortSplitter.cancelExcludedCohorts();
        }
    }

    async copyCohortWithSelectedMaterials() {
        if (this.selectedAnimalsCount() < 1) {
            const showToast = true;
            this.loggingService.logWarning(
                'Please select at least one animal.',
                null,
                this.COMPONENT_LOG_TAG,
                showToast
            );

            return;
        }

        await this.saveChangesService.promptForUnsavedChanges(this.COMPONENT_LOG_TAG);
        const fromCohort = this.cohort;
        const toCohort = this.cohortService.create();

        this.cohortService.copyCohortWithSelectedMaterials(fromCohort, toCohort);
        this.cohort = toCohort;
        this.modelCopy.emit(this.cohort);
    }

    public onScroll(event: Event) {
        this.scrollEventService.scrollEventEmit = {
            event,
            name: ScrollTypes.CohortDetailComponent
        };
    }
     
    async validate() {
        return this.cohortSplitter?.validate() ?? '';
    }

    public cohortsFromDraftCreated(): void {
        this.draftCohortsData = null;
    }

    public cohortsDraftCreated(draftCohortsData: IDraftCohorts): void {
        this.draftCohortsData = draftCohortsData;
    }

    public async exitClicked(result: UnsavedChanges): Promise<void> {
        if (this.draftCohortsData && result === UnsavedChanges.noChanges) {
            const isSave = await this.dialogService.confirmYesNo({
                title: 'Confirm Cohorts',
                bodyText: 'You have drafted cohorts. Do you want to confirm them?',
                yesButtonTitle: 'Yes, confirm cohorts',
                noButtonTitle: 'No',
            });

            if (isSave) {
                try {
                    this.setLoading(true);
                    this.dataManager.importEntities(this.draftCohortsData.newCohortsChanges);
                    await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
                    await this.cohortService.saveDraftCohortsString(this.cohort, '');
                    this.draftCohortsData = null;
                    this.toastrService.showToast('New cohorts created.', LogLevel.Success);
                } catch (error) {
                    const changes = this.dataContext.getChanges();
                    this.cohortService.cancelConfirmCohortChanges(changes);
                    return;
                } finally {
                    this.setLoading(false);
                }
            }
        }
        this.exit.emit();
    }
}
