import { FacetLoadingStateService } from '@common/facet/facet-loading-state.service';
import { map } from 'rxjs/operators';
import {
    Component,
    Input,
    OnInit,
    OnChanges,
    Output,
    EventEmitter,
} from '@angular/core';

import { DataContextService } from '@services/data-context.service';
import { LineService } from 'src/app/lines/line.service';
import { AnimalVocabService } from 'src/app/animals/services/animal-vocab.service';
import { ReasonForChangeService } from '@common/reason-for-change/reason-for-change.service';
import { ConfirmService } from '@common/confirm';
import { OrderService } from 'src/app/orders/order.service';
import { OrderVocabService } from 'src/app/orders/order-vocab.service';
import { AnimalOrder, Order, cv_Sex, cv_ShipmentAnimalCondition, cv_ShipmentContainerCondition, cv_Taxon } from '@common/types';

@Component({
    selector: 'order-animal-table',
    templateUrl: './order-animal-table.component.html',
    styles: [`
        .animals-table {
            margin-top: 10px;
           }
    `]
})
export class OrderAnimalTableComponent implements OnInit, OnChanges {
    /* The value of the object's primary key */
    @Input() pkValue: number;
    /* The entity to assign */
    @Input() entity: Order;

    // Active and required fields set by facet settings
    @Input() activeFields: Set<string>;
    @Input() requiredFields: Set<string>;

    @Input() taxonKey: number;
    @Input() readonly: boolean;
    @Output() onAnimalCountChanged = new EventEmitter<number>();

    // CVs
    sexes: cv_Sex[] = [];
    taxons: cv_Taxon[] = [];
    shipmentAnimals: cv_ShipmentAnimalCondition[] = [];
    shipmentContainers: cv_ShipmentContainerCondition[] = [];

    // State
    readonly COMPONENT_LOG_TAG = 'animals-table';

    readwrite: boolean;
    animalsShown: boolean;

    bulk: any = null;

    fieldPrefix = 'Receipt Recording:'

    constructor(
        private facetLoadingStateService: FacetLoadingStateService,
        private orderService: OrderService,
        private orderVocabService: OrderVocabService,
        private confirmService: ConfirmService,
        private dataContext: DataContextService,
        private lineService: LineService,
        private animalVocabService: AnimalVocabService,
        private reasonForChangeService: ReasonForChangeService
    ) { }

    // lifecycle
    ngOnInit(): void {
        this.initBulkValues();
        this.initialize();
    }

    ngOnChanges(changes: any): void {
        if (changes.entity && !changes.entity.firstChange) {
            this.initialize();
        }
        if (changes.taxonKey && !changes.taxonKey.firstChange) {
            for (const animal of this.entity.AnimalOrder) {
                if (!animal.C_Taxon_key) {
                    animal.C_Taxon_key = this.taxonKey;
                }
            }
        }
    }

    animalCountChanged(): void {
        let count;
        for (const animal of this.entity.AnimalOrder) {
            if (animal.AnimalCount != null) {
                count = (count || 0) + animal.AnimalCount;
            }
        }
        this.onAnimalCountChanged.emit(count);
    }

    initBulkValues(): void {
        this.bulk = {
            sexKey: null,
            taxonKey: null,
            lineKey: null,
            shipmentAnimalKey: null,
            shipmentContainerKey: null,
            force: false,
        };
    }

    initialize(): Promise<void> {
        this.facetLoadingStateService.changeLoadingState(true);

        return this.getCVs().then(() => {
            this.getAnimalDetails();
            this.facetLoadingStateService.changeLoadingState(false);
        }).catch((error) => {
            this.facetLoadingStateService.changeLoadingState(false);
            throw error;
        });
    }

    isFieldActive(fieldName: string): boolean {
        return this.activeFields.has(`${this.fieldPrefix} ${fieldName}`)
    }

    private getCVs(): Promise<any> {
        const p1 = this.animalVocabService.sexes$.pipe(map((data) => {
            this.sexes = data;
        })).toPromise();
        const p2 = this.orderVocabService.taxons$.pipe(map((data) => {
            this.taxons = data;
        })).toPromise();
        const p3 = this.orderVocabService.shipmentAnimals$.pipe(map((data) => {
            this.shipmentAnimals = data;
        })).toPromise();
        const p4 = this.orderVocabService.shipmentContainers$.pipe(map((data) => {
            this.shipmentContainers = data;
        })).toPromise();

        return Promise.all([p1, p2, p3, p4]);
    }

    // Formatters for <select> input
    sexKeyFormatter = (value: cv_Sex): number => {
        return value.C_Sex_key;
    }
    sexFormatter = (value: cv_Sex): string => {
        return value.Sex;
    }

    taxonKeyFormatter = (value: cv_Taxon): number => {
        return value.C_Taxon_key;
    }
    taxonFormatter = (value: cv_Taxon): string => {
        return value.CommonName;
    }

    shipmentAnimalKeyFormatter = (value: cv_ShipmentAnimalCondition): number => {
        return value.C_ShipmentAnimalCondition_key;
    }
    shipmentAnimalFormatter = (value: cv_ShipmentAnimalCondition): string => {
        return value.ShipmentAnimalCondition;
    }

    shipmentContainerKeyFormatter = (value: cv_ShipmentContainerCondition): number => {
        return value.C_ShipmentContainerCondition_key;
    }
    shipmentContainerFormatter = (value: cv_ShipmentContainerCondition): string => {
        return value.ShipmentContainerCondition;
    }

    lineChanged(lineKey: number | null, animalOrder: AnimalOrder): void {
        if (lineKey != null) {
            this.lineService.getLine(lineKey).then((fullLine) => {
                animalOrder.cv_Taxon = fullLine.cv_Taxon;
            });
        }
    }

    bulkSexChanged(): void {
        for (const animal of this.entity.AnimalOrder) {
            if (this.bulk.force || !animal.C_Sex_key) {
                animal.C_Sex_key = this.bulk.sexKey;
            }
        }

        this.initBulkValues();
    }

    bulkTaxonChanged(): void {
        for (const animal of this.entity.AnimalOrder) {
            if (this.bulk.force || !animal.C_Taxon_key) {
                animal.C_Taxon_key = this.bulk.taxonKey;
            }
        }

        this.initBulkValues();
    }

    bulkLineChanged(): void {
        this.lineService.getLine(this.bulk.lineKey).then((fullLine) => {
            for (const animal of this.entity.AnimalOrder) {
                if (this.bulk.force || !animal.C_Line_key) {
                    animal.C_Line_key = this.bulk.lineKey;
                    animal.cv_Taxon = fullLine ? fullLine.cv_Taxon : null;
                }
            }

            this.initBulkValues();
        });
    }

    bulkShipmentAnimalChanged(): void {
        for (const animal of this.entity.AnimalOrder) {
            if (this.bulk.force || !animal.C_ShipmentAnimalCondition_key) {
                animal.C_ShipmentAnimalCondition_key = this.bulk.shipmentAnimalKey;
            }
        }

        this.initBulkValues();
    }

    bulkShipmentContainerChanged(): void {
        for (const animal of this.entity.AnimalOrder) {
            if (this.bulk.force || !animal.C_ShipmentContainerCondition_key) {
                animal.C_ShipmentContainerCondition_key = this.bulk.shipmentContainerKey;
            }
        }

        this.initBulkValues();
    }

    createAnimal(): void {
        this.orderService.createAnimalOrder(this.pkValue);
        this.animalsShown = true;
    }

    private getAnimalDetails() {
        if (this.pkValue > 0) {
            return this.orderService.getAnimalOrder(this.pkValue).then(
                (items) => {
                    if (items.length > 0) {
                        this.animalsShown = true;
                        this.animalCountChanged();
                    }
                });
        }
    }

    /**
     * Delete Animal on "x" click
     */
    removeAnimal(animal: AnimalOrder): Promise<void> {
        if (!this.readonly) {
            return this.confirmService.confirmDelete(
                'Delete Animal',
                'Are you sure you want to delete this animal?'
            ).then(
                // confirmed
                () => {
                    this.reasonForChangeService.markModification([animal.Order]);
                    this.orderService.deleteAnimalOrder(animal);
                    if (this.pkValue > 0 &&
                        animal.C_AnimalOrder_key > 0
                    ) {
                        this.dataContext.save().then(() => {
                            this.animalCountChanged();
                        });
                    }
                },
                // cancel
                () => { /* do nothing on cancel */ }
            );
        }
    }

    /**
     * Hide/show lots table contents
     */
    toggleAnimalsShown(): void {
        this.animalsShown = !this.animalsShown;
    }
}
