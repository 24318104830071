import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit
} from '@angular/core';

import { SearchService } from '../search/search.service';
import { SearchQueryDef } from '../search/search-query-def';
import { TranslationService } from '../services/translation.service';

const MAX_SEARCH_RESULTS = 50;

@Component({
    selector: 'institution-multiselect',
    template: `
<!-- Selected institutions -->
<div *ngIf="model && model.length > 0">
    <table class="table table-sm table-borderless table-nonfluid">
        <tbody>
            <tr *ngFor="let item of model; let i = index;">
                <td class="icon-cell">
                    <a *ngIf="!disabled" (click)="removeInstitution(i)" title="Remove">
                        <i class="fa fa-remove remove-item"></i>
                    </a>
                </td>
                <td>
                    {{item.Name}}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Institution typeahead -->
<climb-indirect-typeahead
        [search]="searchInstitutions"
        [resultFormatter]="institutionNameFormatter"
        [placeholder]="placeholder"
        [required]="required"
        (selectItem)="selectInstitution($event)"></climb-indirect-typeahead>
`,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class InstitutionMultiselectComponent implements OnInit  {
    @Input() model: any[];
    @Input() required: boolean;
    @Input() disabled = false;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    isInitiallyRequired: boolean;
    placeholder: string;

    constructor(
        private _searchService: SearchService,
        private translationService: TranslationService
    ) {
        //
    }

    ngOnInit() {
        this.placeholder = this.translationService.translate('Institution') + " name...";
        this.isInitiallyRequired = this.required;
        this.checkIfInstitutionShouldBeRequired();
    }

    removeInstitution(index: number) {
        if (this.model && index >= 0 && index < this.model.length) {
            this.model.splice(index, 1);
        }
        this.modelChange.emit(this.model);
        this.checkIfInstitutionShouldBeRequired();
    }

    searchInstitutions = (term: string): Promise<any> => {

        let count = null;
        if (term.length < 3) {
            count = MAX_SEARCH_RESULTS;
        }

        const institutionFilter = {
            Name: term
        };

        const searchQueryDef: SearchQueryDef = {
            entity: 'Institutions',
            page: 1,
            size: count,
            sortColumn: 'Name',
            sortDirection: 'asc',
            filter: institutionFilter
        };

        return this._searchService.getEntitiesBySearch(searchQueryDef)
            .then((results: any) => {
                return results.data;
            });
    }

    institutionNameFormatter = (value: any) => {
        return value.Name;
    }

    selectInstitution(institution: any) {
        if (this.model) {
            this.model.push(institution);
        } else {
            this.model = [institution];
        }

        this.modelChange.emit(this.model);
        this.checkIfInstitutionShouldBeRequired();
    }

    checkIfInstitutionShouldBeRequired() {
        if (this.model.length > 0) {
            this.required = false;
        } else if (this.isInitiallyRequired == true) {
            this.required = true;
        }
    }
}


