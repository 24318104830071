<!-- Table for Institutions -->
<div class="form-group row">
    <label class="col-sm-2 col-form-label"
           [ngClass]="{'required-text' : required && order.OrderInstitution.length === 0}">
           {{'Institution' | translate }}s
    </label>
    <div class="col-sm-10">
        <table class="table">
            <thead>
                <tr>
                    <th class="icon-cell col-size-fit">
                        <a (click)="addOrderInstitution(order)"
                           class="add-item-link"
                           *ngIf="!readonly">
                            <i class="fa fa-plus-circle" title="Add {{'Institution' | translate }}{{ siteVisible ? ' and Site' : '' }}"></i>
                        </a>
                    </th>
                    <th>{{'Institution' | translate}}</th>
                    <th *ngIf="siteVisible">Site</th>
                    <th *ngIf="siteVisible">Country of Origin</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let orderInstitution of order.OrderInstitution">
                    <td>
                        <a *ngIf="!readonly" (click)="removeInstitution(orderInstitution)">
                            <i class="fa fa-fw fa-remove remove-item"
                               title="Delete {{'Institution' | translate}}"></i>
                        </a>
                    </td>
                    <td>
                        <institution-select [(model)]="orderInstitution.C_Institution_key"
                                            (modelChange)="institutionChanged($event, orderInstitution)"
                                            [required]="required || !orderInstitution.C_Institution_key">
                        </institution-select>
                    </td>
                    <td *ngIf="siteVisible">
                        <select name="Site"
                                #name="ngModel"
                                [required]="siteRequired && (!orderInstitution.C_Site_key || orderInstitution.SelectedSites?.length === 0)"
                                [(ngModel)]="orderInstitution.C_Site_key"
                                [disabled]="!siteRequired && (!orderInstitution.C_Institution_key || orderInstitution.SelectedSites?.length === 0)"
                                class="form-control input-medium">
                            <option></option>
                            <option *ngFor="let item of orderInstitution.SelectedSites"
                                    [value]="item.C_Site_key">
                                {{item.Name}}
                            </option>
                        </select>
                    </td>
                    <td *ngIf="siteVisible">
                        <div class="mt-1">{{orderInstitution.Site?.cv_Country?.Country}}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
