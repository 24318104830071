import {
    Component,
    Input,
} from '@angular/core';

import { Entity, Order } from '@common/types';

@Component({
    selector: 'order-animal-receipt-check-table',
    templateUrl: './order-animal-receipt-check-table.component.html',
})
export class OrderAnimalReceiptCheckTableComponent {
    @Input() order: Entity<Order>;
    @Input() activeFields: Set<string>;

    get isShowTable() {
        return this.activeFields.size > 0
    }

    onAnimalSectionClick(type: Order[keyof Order], property: string): void {
        this.order[property] = this.order[property] === type ? null : type;
    }
}
