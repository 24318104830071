import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    OnInit,
    TemplateRef,
} from '@angular/core';
import { checkmarkCircleFilled, errorCircleFilled } from '@icons';

export type AlertInlineStatus = 'success' | 'error' | 'warning' | 'info';

@Component({
    selector: 'climb-alert-inline',
    templateUrl: './alert-inline.component.html',
    styleUrls: ['./alert-inline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertInlineComponent implements OnInit {
    icons = { checkmarkCircleFilled, errorCircleFilled };

    @Input() message: string;
    @Input() template?: TemplateRef<any>;

    @Input()
    get status(): AlertInlineStatus {
        return this.alertInlineStatus;
    }
    set status(alertInlineStatus: AlertInlineStatus) {
        this.alertInlineStatus = alertInlineStatus;
        this.setClasses();
    }
    private alertInlineStatus: AlertInlineStatus = 'success';

    @HostBinding('class')
    private classes = '';

    ngOnInit(): void {
        this.setClasses();
    }

    setClasses(): void {
        this.classes = `alert-inline-${this.status}`;
    }
}
