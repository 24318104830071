<loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>
<div class="detail-form-wrapper">
    <loading-overlay *ngIf="loading"></loading-overlay>

    <div *ngIf="pageState" class="detail-header">
        <h3>Permit</h3>

        <pager-toolbar [itemName]="'permit'"
            [logTag]="COMPONENT_LOG_TAG"
            [first]="pageState.isFirst"
            [last]="pageState.isLast"
            (previous)="previousClicked($event)"
            (next)="nextClicked($event)">
        </pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet?.Privilege"
            [logTag]="COMPONENT_LOG_TAG"
            [emitOnSave]="true"
            (exit)="exitClicked($event)"
            (genericSaveEmitter)="savePermit()">

            <ng-container additional-groups>
                <div class="btn-group"
                    role="group"
                    aria-label="Edit actions">
                    <button type="button"
                            class="btn btn-secondary gridster-d-md-inline-block"
                            (click)="copyPermit()"
                            [disabled]="loading"
                            [appInsightsClick]="'click-copy-permit'"
                            *ngIf="readwrite">
                        <i class="fa fa-copy" aria-hidden="true"></i>
                        Copy
                    </button>
                </div>
            </ng-container>
        </detail-toolbar>
    </div>

    <form *ngIf="form" class="detail-form" [formGroup]="form" class="detail-form">
        <fieldset [disabled]="facet.Privilege === 'ReadOnly'">
            <h3>Permit Information</h3>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Number</label>
                <div class="col-md-10">
                    <input
                        type="text"
                        name="permitNumber"
                        class="form-control input-medium"
                        formControlName="PermitNumber"
                        validateUnique
                        uniqueEntity="Permits"
                        uniqueProperty="PermitNumber"
                        [entityPK]="permit.C_Permit_key"
                        entityPKName="C_Permit_key"
                        [required]="requiredFields.includes('PermitNumber')">
                    <small class="form-text text-muted" *ngIf="permitNumberControl?.errors?.unique || permitNumberControl?.errors?.required">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        {{ permitNumberControl?.errors?.unique ? 'This permit number already exists.' : '' }}
                        {{ permitNumberControl?.errors?.required ? 'Enter Number' : '' }}
                    </small>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Owner')">
                <label class="col-md-2 col-form-label">Owner</label>
                <div class="col-md-10">
                    <active-vocab-select data-automation-id="permit-owner"
                        formControlName="Owner"
                        [vocabChoices]="resources"
                        [keyFormatter]="resourceKeyFormatter"
                        [optionFormatter]="resourceNameFormatter"
                        [displayName]="'Owner'"
                        [nullable]="!requiredFields.includes('Resource')"
                        [required]="requiredFields.includes('Resource')">
                    </active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Title')">
                <label class="col-md-2 col-form-label">Title</label>
                <div class="col-md-10">
                    <input 
                        type="text" 
                        name="permitTitle" 
                        class="form-control input-medium" 
                        formControlName="Title"
                        [required]="requiredFields.includes('Title')">
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Status')">
                <label class="col-md-2 col-form-label">Status</label>
                <div class="col-md-10">
                    <active-vocab-select data-automation-id="permit-status-dropdown"
                        formControlName="PermitStatusKey"
                        [vocabChoices]="permitStatuses"
                        [keyFormatter]="permitStatusKeyFormatter"
                        [optionFormatter]="permitStatusFormatter"
                        [displayName]="'Status'"
                        [required]="true"
                        [nullable]="!requiredFields.includes('C_PermitStatus_key')"
                        [required]="requiredFields.includes('C_PermitStatus_key')">
                    </active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Category')">
                <label class="col-md-2 col-form-label">Category</label>
                <div class="col-md-10">
                    <active-vocab-select data-automation-id="permit-category-dropdown"
                        formControlName="PermitCategoryKey"
                        [vocabChoices]="permitCategories"
                        [keyFormatter]="permitCategoryKeyFormatter"
                        [optionFormatter]="permitCategoryFormatter"
                        [displayName]="'Permit Category'"
                        [nullable]="!requiredFields.includes('C_PermitCategory_key')"
                        [required]="requiredFields.includes('C_PermitCategory_key')">
                    </active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('GMO Animals Allowed')">
                <label class="col-md-2 col-form-label">GMO Animals Allowed</label>
                <div class="col-md-10">
                    <input type="checkbox"
                        formControlName="IsGMOAnimalsAllowed"
                        name="gmo_animals_allowed"
                        id="gmo_animals_allowed"
                        [required]="requiredFields.includes('GMOAnimalsAllowed')">
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Start Date')">
                <label class="col-md-2 col-form-label">Start Date</label>
                <div class="col-md-10">
                    <climb-ngb-date name="Start Date" formControlName="StartDate" [required]="requiredFields.includes('StartDate')"></climb-ngb-date>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Expiry Date')">
                <label class="col-md-2 col-form-label">Expiry Date</label>
                <div class="col-md-10">
                    <climb-ngb-date name="Expiry Date" formControlName="ExpiryDate" [required]="requiredFields.includes('ExpiryDate')"></climb-ngb-date>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('External Purpose')">
                <label class="col-md-2 col-form-label">External Purpose</label>
                <div class="col-md-10">
                    <active-vocab-select data-automation-id="permit-external-purpose-dropdown"
                        formControlName="PermitExternalPurposeKey"
                        [vocabChoices]="permitExternalPurposes"
                        [keyFormatter]="permitExternalPurposeKeyFormatter"
                        [optionFormatter]="permitExternalPurposeFormatter"
                        [displayName]="'External Purpose'"
                        [nullable]="!requiredFields.includes('C_PermitExternalPurpose_key')"
                        [required]="requiredFields.includes('C_PermitExternalPurpose_key')">
                    </active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Other Purpose')">
                <label class="col-md-2 col-form-label">Other Purpose</label>
                <div class="col-md-10">
                    <textarea name="permitOtherPurpose"
                              climbInput
                              class="form-control input-large"
                              formControlName="OtherPurpose"
                              maxlength="MAX_OTHER_PURPOSE_SYMBOLS"
                              climbCharacterCounter
                              [maxCharacterCount]="MAX_OTHER_PURPOSE_SYMBOLS"
                              [required]="requiredFields.includes('OtherPurpose')">
                    </textarea>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Institution(s)')">
                <label class="col-md-2 col-form-label">{{translationService?.translate('Institution')}}(s)</label>
                <div class="col-md-10">
                    <institution-multiselect
                        [disabled]="facet.Privilege === 'ReadOnly'"
                        [(model)]="permitInstitutions" 
                        [required]="requiredFields.includes('PermitInstitution[0]')" 
                        (modelChange)="onInstitutionsChange($event)">
                    </institution-multiselect>
                </div>
            </div>
        </fieldset>

        <fieldset
            *ngIf="permitSpeciesControl"
            [disabled]="facet.Privilege === 'ReadOnly'">
            <permit-species 
                [form]="form" 
                [activeFields]="activeFields"
                [requiredFields]="requiredFields"
                [permit]="permit" 
                [isReadWrite]="readwrite" 
                [loading]="loading"
                [facetPrivilege]="facet.Privilege"
                [required]="true"></permit-species>
        </fieldset>

        <fieldset>
            <div class="form-group row" *ngIf="activeFields.includes('Files')">
                <label class="col-md-2 col-form-label">Files</label>
                <div class="col-md-10">
                    <div class="form-control-plaintext">
                        <facet-detail-file-upload [pkName]="'C_Permit_key'" [pkValue]="permit.C_Permit_key"
                                                  [facetPrivilege]="facet.Privilege">
                        </facet-detail-file-upload>
                    </div>
                </div>
            </div>
        </fieldset>

        <fieldset [disabled]="facet.Privilege === 'ReadOnly'">
            <div class="form-group row" *ngIf="activeFields.includes('Summary')">
                <label class="col-md-2 col-form-label">Summary</label>
                <div class="col-md-10">
                    <climb-input class="summary-textarea" #container>
                        <textarea name="permitSummary"
                            climbInput
                            class="form-control input-medium"
                            maxlength="MAX_SUMMARY_SYMBOLS"
                            climbCharacterCounter
                            [maxCharacterCount]="MAX_SUMMARY_SYMBOLS"
                            [container]="container"
                            rows="5"
                            formControlName="Summary"
                            [required]="requiredFields.includes('Summary')">
                        </textarea>
                    </climb-input>
                </div>
            </div>

            <ng-container *ngIf="!permit.entityAspect.entityState.isAdded()">
                <div class="form-group row">
                    <label class="col-md-2 col-form-label">Created</label>
                    <div class="col-md-10">
                        <p class="form-control-plaintext">
                            {{permit.DateCreated | formatShortDate}}
                        </p>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-2 col-form-label">Created By</label>
                    <div class="col-md-10">
                        <p class="form-control-plaintext">
                            {{permit.CreatedBy | userFullName}}
                        </p>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-2 col-form-label">Modified</label>
                    <div class="col-md-10">
                        <p>
                            {{permit.DateModified | formatShortDate}}
                        </p>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-2 col-form-label">Last Modified By</label>
                    <div class="col-md-10">
                        <p class="form-control-plaintext">
                            {{permit.ModifiedBy | userFullName}}
                        </p>
                    </div>
                </div>
            </ng-container>
        </fieldset>
    </form>
</div>
