import { DataContextService } from './../services/data-context.service';
import { WorkspaceManagerService } from './../services/workspace-manager.service';
import { RoutingService } from './../routing/routing.service';
import { WorkspaceService } from './workspace.service';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { RoleService } from '../services/role.service';
import { IGridsterOptions, GridsterComponent } from 'angular2gridster';
import { Subscription, from } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LoggingService } from '../services/logging.service';
import { ScrollEventService } from '@common/services';
import { ScrollTypes } from '@common/export';
import { FacetSelectorComponent } from './components/facet-selector/facet-selector.component';
import { ModernAppService } from '@common/modern-app.service';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { Facet, FacetDimensions } from '../mf-store';
import { FeatureFlagService } from '@services/feature-flags.service';

@Component({
    selector: 'workspace-view',
    templateUrl: './workspace-view.component.html',
    styleUrls: ['./workspace-view.component.scss'],
})
export class WorkspaceViewComponent implements OnInit, OnDestroy {
    @ViewChild("gridster") gridster: GridsterComponent;
    @ViewChild('sidebar') facetSelector: FacetSelectorComponent;

    gridsterOptions: IGridsterOptions;

    loading = false;
    workspace: any;
    workspaceName = '';
    userRole: any;

    private subs = new Subscription();

    facets$ = this.workspaceService.facets$.pipe(
      debounceTime(20),
      tap(() => this.reflowGridster()),
    );
    facetsCount$ = this.facets$.pipe(map(facets => facets.length));
    facetsEmpty$ = this.facetsCount$.pipe(map(count => count === 0));

    visibility = false;

    toggleVisibility() {
        this.visibility = !this.visibility;
    }

    constructor(
        private dataContext: DataContextService,
        private workspaceManager: WorkspaceManagerService,
        private roleService: RoleService,
        private routingService: RoutingService,
        public workspaceService: WorkspaceService,
        private route: ActivatedRoute,
        private loggingService: LoggingService,
        private scrollEventService: ScrollEventService,
        private modernApp: ModernAppService,
        private featureFlag: FeatureFlagService,
    ) {
        // Nothing to do
    }

    async ngOnInit(): Promise<void> {
        this.modernApp.init();
        this.setGridsterOptions();

        this.registerSubscriptions();

        await Promise.all([this.dataContext.init(), this.workspaceManager.init()]);
        await this.featureFlag.getFeatureFlags();
        const ID = Number(this.route.snapshot.paramMap.get('id'));
        if (ID) {
            try {
                let workspaces = this.workspaceService.workspaces;
                if (!workspaces) {
                    workspaces = await this.workspaceService.fetchWorkspaces();
                }
                const currentWorkspace = workspaces.find(workspace => workspace.C_Workspace_key === ID);
                if (currentWorkspace) {
                    this.workspaceService.setCurrentWorkspace(currentWorkspace);
                    return;
                }
                await this.routingService.navigateToDashboard();
            } catch (_) {
                await this.routingService.navigateToDashboard();
            }
        } else {
            await this.routingService.navigateToDashboard();
        }

    }

    ngOnDestroy() {
      this.subs.unsubscribe();
    }

    registerSubscriptions() {
        const s1 = this.workspaceService.isCustomizeWorkspaceActive$.subscribe((active: boolean) => {
            this.facetSelector?.reset();
            if (active) {
                this.facetSelector?.focus();
            }
            this._toggleResizeHandles(active);
            // waiting for the end of the animation of the facet-selector panel
            setTimeout(() => this.reflowGridster(), 480);
        });

        // subscribe to workspace changes
        const s2 = this.workspaceService.currentWorkspace$
            .pipe(
              tap(() => {
                this.loading = true;
                this.workspace = null
              }),
              switchMap(() => from(this.roleService.getUserRole())),
              switchMap(userRole => from(this.workspaceService.initializeFacets(userRole))),
            )
            .subscribe(() => {
              this.workspace = this.workspaceService.currentWorkspace;
              this.loading = false;
            });

        const s3 = this.workspaceService.workspaceConnectivity$.subscribe((animalName) => {
            // Check if have clinical facet opened or not
            for (const facet of this.workspace.WorkspaceDetail) {
                if (facet.FacetName.toLowerCase() === "clinical") {
                    return;
                }
            }
            this.loggingService.logWarning(`Please add the Clinical facet to your workspace and try again.`, null, "Workspace View", true);
        });

        this.subs.add(s1);
        this.subs.add(s2);
        this.subs.add(s3);
    }

    setGridsterOptions() {
        const showHandles = this.workspaceService.isCustomizeWorkspaceActive;

        this.gridsterOptions = {
            // how many lines (grid cells) dashboard has
            lanes: 6, 
            cellHeight: 200,
            // items floating direction: vertical/horizontal/none
            direction: 'vertical',
            // default=true - prevents items to float according to the direction (gravity) 
            floating: true, 
            // possible to change items position by drag n drop
            dragAndDrop: showHandles, 
            // possible to resize items by drag n drop by item edge/corner
            resizable: showHandles, 
            resizeHandles: {
                w: true,
                ne: true,
                se: true,
                sw: true,
                nw: true
            },
             // Uses CSS3 translate() instead of position top/left - significant performance boost.
            useCSSTransforms: true,
            responsiveDebounce: 10,
        };
    }

    disableResizeHandles() {
        this._toggleResizeHandles(false);
    }

    enableResizeHandles() {
        this._toggleResizeHandles(true);
    }

    private _toggleResizeHandles(showHandles: boolean) {
        if (!this.gridster) {
            return;
        }

        this.gridsterOptions.dragAndDrop = showHandles;
        this.gridsterOptions.resizable = showHandles;

        this.gridster.setOption('resizable', showHandles);
        this.gridster.setOption('dragAndDrop', showHandles);
    }

    reflowGridster() {
        if (this.gridster) {
          this.gridster.reload();
        }
    }

    manuallyFacetChanged(id: string) {
      this.workspaceService.manuallyFacetChanged(id);
    }

    public scrollEvent(event: Event) {
        this.scrollEventService.scrollEventEmit = {event, name: ScrollTypes.WorkspaceViewComponent};
    }

    trackById(_: number, facet: Facet) {
      return facet.id;
    }

    changeDimension(facetId: string, key: keyof FacetDimensions, value: number) {
      this.workspaceService.changeDimension(facetId, key, value);
    }
}
