import { FileDownloader } from '../common/download';
import { MonthlyScheduleRecord } from './models';
import { QueryDef } from './../services/query-def';
import { DataManagerService } from './../services/data-manager.service';
import { Injectable } from '@angular/core';
import { WebApiService } from '../services/web-api.service';
import { BaseEntityService } from '../services/base-entity.service';
import { HttpParams } from '@angular/common/http';

import { WorkflowService } from '../workflow/services/workflow.service';
import { QueryResult, Predicate } from 'breeze-client';
import { notEmpty, getSafeProp } from '../common/util';
import { LoggingService } from '../services/logging.service';
import { AuthService } from '../services/auth.service';
import { ResourceService } from '../resources';
import { convertValueToLuxon } from '@common/util/date-time-formatting/convert-value-to-luxon';

@Injectable()
export class ScheduleService extends BaseEntityService {

    private fileDownloader: FileDownloader = new FileDownloader();

    readonly COMPONENT_LOG_TAG = 'schedule-service';

    constructor(
        private dataManager: DataManagerService,
        private webApiService: WebApiService,
        private workflowService: WorkflowService,
        private loggingService: LoggingService,
        private authService: AuthService,
        private resourceService: ResourceService,
    ) {
        super();
    }

    async getScheduleNonTasks(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery('ScheduleNonTasks', queryDef);

        query = query.expand(queryDef.expands.join(','));

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    buildPredicates(filter: any): Predicate[] {
        const predicates: Predicate[] = [];

        if (!filter) {
            return predicates;
        }

        // return no results if these filters are applied
        // they relate to workflow tasks only
        if (filter.jobs ||
            filter.TaskLocation ||
            filter.MaterialLocation ||
            filter.C_WorkflowTask_keys ||
            filter.C_TaskStatus_keys ||
            filter.JobCreatedBy
        ) {
            predicates.push(
                Predicate.create('C_ScheduleNonTask_key', 'eq', null)
            );
        }

        if (filter.C_Resource_key) {
            predicates.push(
                Predicate.create('C_Resource_key', 'eq', filter.C_Resource_key)
            );
        }
        if (notEmpty(filter.C_Resource_keys)) {
            predicates.push(
                Predicate.create('C_Resource_key', 'in', filter.C_Resource_keys)
            );
        }

        if (notEmpty(filter.C_ResourceGroup_keys)) {
            predicates.push(
                Predicate.create('Resource.C_ResourceGroup_key', 'in', filter.C_ResourceGroup_keys)
            );
        }

        if (filter.DateDueStart || filter.DateDueEnd) {
            const datePredicates: Predicate[] = [];

            if (filter.DateDueEnd) {
                const dateEnd = convertValueToLuxon(filter.DateDueEnd).startOf('day')
                    .plus({"day": 1})
                    .toJSDate();

                datePredicates.push(
                    Predicate.create('DateTimeStart', '<', dateEnd)
                );
            }

            if (filter.DateDueStart) {
                datePredicates.push(
                    Predicate.create('DateTimeComplete', '>=', filter.DateDueStart)
                );
            }

            if (notEmpty(datePredicates)) {
                predicates.push(Predicate.and(datePredicates));
            }
        }

        return predicates;
    }


    async getMonthlySchedule(paramsObject: any): Promise<MonthlyScheduleRecord[]> {
        paramsObject.UtcOffset = new Date().getTimezoneOffset() / 60;
        const params: HttpParams = this.webApiService.buildURLSearchParams(paramsObject);

        const requestUrl = 'api/schedule/monthly';
        const response = await this.webApiService.callApi(requestUrl, params);
        return response.data.results as MonthlyScheduleRecord[];
    }

    async downloadICSFile(paramsObject: any, filename: string): Promise<void> {
        paramsObject.UtcOffset = new Date().getTimezoneOffset() / 60;
        const params: HttpParams = this.webApiService.buildURLSearchParams(paramsObject);

        const requestUrl = 'api/schedule/export/ics';
        const mimeType = 'text/calendar';
        const response = await this.webApiService.callTextApi(requestUrl, params);
        this.fileDownloader.triggerFileDownload(response.data, filename, mimeType);
    }

    async calculateDueDates(changedTask: any): Promise<void> {
        if (!changedTask.DateDue) {
            // Need a start date for the relative time math
            return Promise.resolve();
        }

        try {
            await this.dataManager.saveEntities(['TaskInstance']);

            const taskKeys = [changedTask.C_TaskInstance_key];
            const username = this.authService.getCurrentUserName();
            const isEndState: boolean = getSafeProp(changedTask, 'cv_TaskStatus.IsEndState');

            const resource = await this.resourceService.getCurrentUserResource();

            const currentResourceKey = resource ? resource.C_Resource_key : null;
            const taskStatusKey = changedTask.C_TaskStatus_key;
            const dateComplete = changedTask.DateComplete;

            await this.workflowService.completeTasks(
                taskKeys,
                username,
                currentResourceKey,
                taskStatusKey,
                dateComplete,
                true,
                isEndState,
                isEndState,
                true,
            );

            // Warn the user about the related date changes
            this.loggingService.logWarning('Dependent tasks have been updated.', null, this.COMPONENT_LOG_TAG, true);
        } catch (error) {
            this.loggingService.logError('Could not update dependent tasks.', null, this.COMPONENT_LOG_TAG, false);
        }
    }

    createScheduleNonTask(initialValues: any): any {
        return this.dataManager.createEntity('ScheduleNonTask', initialValues);
    }

    deleteScheduleNonTask(scheduleNonTask: any): void {
        this.dataManager.deleteEntity(scheduleNonTask);
    }

    cancelScheduleNonTask(scheduleNonTask: any): void {
        if (!scheduleNonTask) {
            return;
        }

        if (scheduleNonTask.C_ScheduleNonTask_key > 0) {
            this.cancelScheduleNonTaskEdits(scheduleNonTask);
        } else {
            this.cancelNewScheduleNonTask(scheduleNonTask);
        }
    }

    private cancelNewScheduleNonTask(scheduleNonTask: any): void {
        try {
            this.deleteScheduleNonTask(scheduleNonTask);
        } catch (error) {
            console.error('Error cancelling new ScheduleNonTask: ' + error);
        }
    }

    private cancelScheduleNonTaskEdits(scheduleNonTask: any): void {
        this.dataManager.rejectEntityAndRelatedPropertyChanges(scheduleNonTask);
    }
}
