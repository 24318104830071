import { EventCallable } from 'effector';
import { InjectionToken, NgZone, Provider } from "@angular/core";
import { partial } from "lodash";
import { mappedStore$, mappedStore, StoreWrapper } from "../helpers";
import { Store } from '../types';
import { $featureFlagStore, setData } from './feature-flag';

export type FeatureFlagStore = Store<unknown, {
  setData: EventCallable<unknown>;
}>;

const Api: FeatureFlagStore = {
  __store: $featureFlagStore,
  events: {
    setData,
  },
  getValue$: partial(mappedStore$, $featureFlagStore),
  getValue: partial(mappedStore, $featureFlagStore),
};

export const FEATURE_FLAG_STORE_TOKEN = new InjectionToken<FeatureFlagStore>('FEATURE_FLAG_STORE_TOKEN');
export const FEATURE_FLAG_STORE_PROVIDER: Provider = {
  provide: FEATURE_FLAG_STORE_TOKEN,
  useFactory: (ngZone: NgZone) => {
    const wrapper = new StoreWrapper(ngZone);
    wrapper.setApi(Api as Store);
    return wrapper;
  },
  deps: [NgZone],
};

window.mfStore ??= {};
window.mfStore.featureFlagStore = Api;