import { Component } from '@angular/core';
import { OrderDetailService } from '../../services/order-detail.service';
import { ORDER_DETAIL_FIELD } from '../../order-setting-fields';

@Component({
    selector: 'order-detail-general-form',
    templateUrl: './order-detail-general-form.component.html',
    styleUrls: ['./order-detail-general-form.component.scss']
})
export class OrderDetailGeneralFormComponent {
    orderField = ORDER_DETAIL_FIELD

    constructor(
        public orderDetailService: OrderDetailService,
    ) {}
}
