import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import { SearchService } from '../search/search.service';
import { SearchQueryDef } from '../search/search-query-def';

const MAX_SEARCH_RESULTS = 50;

export interface ILineMultiselect {
    LineKey: number;
    LineName: string;
    Taxon: string;
    TaxonKey: number;
}

@Component({
    selector: 'line-multiselect',
    template: `
<!-- Selected lines -->
<div *ngIf="hasValue">
    <table class="table table-sm table-borderless table-nonfluid">
        <tbody>
            <tr *ngFor="let item of model; let i = index;">
                <td class="icon-cell">
                    <a *ngIf="!disabled" (click)="removeLine(i)" title="Remove {{'Line' | translate}}">
                        <i [ngClass]="{ disabled: disabled }" class="fa fa-remove remove-item"></i>
                    </a>
                </td>
                <td>
                    {{item.LineName}}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Line typeahead -->
<climb-indirect-typeahead
        [search]="searchLines"
        [resultFormatter]="lineNameFormatter"
        [required]="required && !hasValue"
        placeholder="{{'Line' | translate}} name&hellip;"
        (selectItem)="selectLine($event)"></climb-indirect-typeahead>
`,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `],
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => LineMultiselectComponent),
            multi: true,
        },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LineMultiselectComponent),
            multi: true,
        }
    ]
})
export class LineMultiselectComponent implements OnInit, ControlValueAccessor, Validator {
    @Input() model: ILineMultiselect[];
    @Input() displayName = 'field';
    @Input() disabled = false;
    @Input() required = false;
    @Input() filterParams: { IsActive?: boolean, TaxonKey?: number } = {};
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    isInitiallyRequired: boolean;

    onChange = (value: ILineMultiselect[]): void => {/*empty*/};
    onTouched = (): void => {/*empty*/};

    get hasValue(): boolean {
        return this.model && this.model.length > 0;
    }

    constructor(
        private _searchService: SearchService
    ) {
        //
    }
    ngOnInit(): void {
        this.isInitiallyRequired = this.required;
    }

    removeLine(index: number) {
        if (this.model && index >= 0 && index < this.model.length) {
            this.model.splice(index, 1);
        }
        this.modelChange.emit(this.model);
        this.onChange(this.model);
        this.checkIfFieldShouldBeRequired();
    }

    searchLines = (term: string): Promise<any> => {

        let count = null;
        if (term.length < 3) {
            count = MAX_SEARCH_RESULTS;
        }

        const lineFilter: { LineName: string, IsActive?: boolean, TaxonKey?: number } = {
            LineName: term
        };

        if (this.filterParams.IsActive) {
            lineFilter.IsActive = this.filterParams.IsActive;
        }

        if (this.filterParams.TaxonKey) {
            lineFilter.TaxonKey = this.filterParams.TaxonKey;
        }

        const searchQueryDef: SearchQueryDef = {
            entity: 'Lines',
            page: 1,
            size: count,
            sortColumn: 'LineName',
            sortDirection: 'asc',
            filter: lineFilter
        };

        return this._searchService.getEntitiesBySearch(searchQueryDef)
            .then((results: any) => {
                return results.data;
            });
    }

    lineNameFormatter = (value: any) => {
        return value.LineName;
    }

    selectLine(line: ILineMultiselect): void {
        if (this.model) {
            this.model.push(line);
        } else {
            this.model = [line];
        }

        this.modelChange.emit(this.model);
        this.onChange(this.model);
        this.checkIfFieldShouldBeRequired();
    }

    registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    writeValue(value: ILineMultiselect[]): void {
        this.model = value;
    }

    checkIfFieldShouldBeRequired() {
        if (this.model.length > 0) {
            this.required = false;
        } else if (this.isInitiallyRequired == true) {
            this.required = true;
        }
    }

    validate(control: FormControl): ValidationErrors | null {
        if (this.required && !this.hasValue) {
            return { message: `The "${this.displayName}" is required. Ensure that all required fields are filled.` };
        }
        return null;
    }
}
