import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TableSortConfig } from "@common/models/table-sort";
import { JobMaterial, Sample, SampleGroup, SampleGroupSourceMaterial, TaskJob } from "@common/types";
import { DataManagerService } from "@services/data-manager.service";
import { EntityQuery, Predicate } from "breeze-client";
import { PartialJobMaterial, PartialMaterial } from "../models/partial-material";
import { MaterialType } from "../models/material-type";

export interface UpdateAnimalSequenceRequest {
    JobKey: string,
    TableSorts: TableSortConfig[]
}

export interface BulkUpdateAnimalNameRequest {
    JobKey: number,
    Prefix: string,
    Suffix: string,
    Counter: number
}


export interface SampleGroupMetadata {
    C_SampleGroup_key?: number;
    SamplesCreatedCount?: number;
    NumberOfAnimalSourcesCount?: number;
    NumberOfSampleSourcesCount?: number;
    NumberOfAnimalPlaceholderSourcesCount?: number;
    HasEndStateMemberTask?: boolean;
}

@Injectable()
export class JobPharmaDataAccessService {
    constructor(
        private dataManagerService: DataManagerService,
        private httpClient: HttpClient
    ) { }
    
    // TODO: Replace Breeze query with GraphQL query
    async getSampleJobMaterials(jobKey: number, pageNumber: number): Promise<JobMaterial[]> {
        const predicates = [
            Predicate.create("Material.Sample.C_Material_key", "!=", null),
            Predicate.create("C_Job_key", "==", jobKey)
        ];
        const expands = [
            "Material.Sample",
            "Material.cv_MaterialType",
            "Material.MaterialSourceMaterial.SourceMaterial.Animal",
            "Material.MaterialSourceMaterial.SourceMaterial.Sample"
        ];
        const query = EntityQuery.from("JobMaterials")
            .expand(expands.join(", "))
            .where(Predicate.and(predicates))
            .skip(50 * (pageNumber - 1))
            .take(50);

        const result = await this.dataManagerService.executeQuery(query);

        return result.results as unknown as JobMaterial[];
    }

    // TODO: Replace Breeze query with GraphQL query
    async getSampleCount(jobKey: number): Promise<number>{
        const predicates = [
            Predicate.create("Material.Sample.C_Material_key", "!=", null),
            Predicate.create("C_Job_key", "==", jobKey)
        ];
        const expands = [
            "Material.Sample",
            "Material.cv_MaterialType",
        ];
        const query = EntityQuery.from("JobMaterials")
            .expand(expands.join(", "))
            .where(Predicate.and(predicates));

        return this.dataManagerService.returnQueryCount(query);
    }

    // TOOD: Pagination?
    async getSampleGroups(jobKey: number) {
        const predicates = [
            Predicate.create("C_Job_key", "==", jobKey),
            Predicate.create("TaskInstance.C_GroupTaskInstance_key", "==", null)
        ];
        const expands = [
            "TaskInstance.ProtocolInstance",
            "TaskInstance.SampleGroup"
        ];
        const orders = [
            "TaskInstance.DateDue",
            "Sequence",
            "TaskInstance.C_ProtocolInstance_key",
            "TaskInstance.ProtocolTask.SortOrder",
            "TaskInstance.C_WorkflowTask_key"
        ];

        const query = EntityQuery.from("TaskJobs")
            .expand(expands.join(","))
            .where(Predicate.and(predicates))
            .orderBy(orders.join(','));

        const { results } = await this.dataManagerService.executeQuery(query);
        const taskJobs = results as unknown as TaskJob[];
        return taskJobs.flatMap(tj => tj.TaskInstance.SampleGroup);
    }

    async getSampleGroupsMetadata(sampleGroupKeys: number[]) {
        if (!sampleGroupKeys || sampleGroupKeys.length === 0) {
            throw new Error('No sample group keys provided');
        }

        const params = { sampleGroupKeys: sampleGroupKeys.join(',') }; 
        const result = await this.httpClient.get('/api/samplegroup/metadata', { params }).toPromise() as SampleGroupMetadata[];

        return result;
    }

    async getSampleGroupSourceMaterials(sampleGroupKeys: number[]) {
        const predicates = [
            Predicate.create("C_SampleGroup_key", "in", sampleGroupKeys)
        ];
        const expands = [
            "Material.Sample",
            "Material.Animal",
            "AnimalPlaceholder.Material.Animal"
        ];

        const query = EntityQuery.from("SampleGroupSourceMaterials")
            .expand(expands.join(','))
            .where(Predicate.and(predicates))
        
        const { results } = await this.dataManagerService.executeQuery(query);
        return results as unknown as SampleGroupSourceMaterial[];
    }

    async getSamplesCreatedFromSampleGroups(sampleGroupKeys: number[]) {
        const predicates = [
            Predicate.create("C_SampleGroup_key", "in", sampleGroupKeys)
        ];
        const expands = [
            "Material.MaterialSourceMaterial.SourceMaterial",
            "Material.MaterialSourceMaterial.SourceMaterial.Animal",
            "Material.MaterialSourceMaterial.SourceMaterial.Sample",
        ];

        const query = EntityQuery.from("Samples")
            .expand(expands.join(','))
            .where(Predicate.and(predicates))
        
        const { results } = await this.dataManagerService.executeQuery(query);
        return results as unknown as Sample[];
    }

    removeSampleGroupSourceMaterials(sampleGroupSourceMaterials: SampleGroupSourceMaterial[]) {
        for (const sgsm of sampleGroupSourceMaterials) {
            this.dataManagerService.deleteEntity(sgsm);
        }
    }

    removeSampleGroup(sampleGroups: SampleGroup[]) {
        for (const sg of sampleGroups) {
            // prevent breeze from updating sample group source material
            const sampleGroupSourceMaterialsToDetach = [...sg.SampleGroupSourceMaterial];
            for (const sgsm of sampleGroupSourceMaterialsToDetach) {
                this.dataManagerService.detachEntity(sgsm);
            }
            this.dataManagerService.deleteEntity(sg);
        }
    }

    async getAllMaterialsOnJob(jobKey: number, materialType: MaterialType, ignoredJobMaterialKeys?: number[]): Promise<(PartialMaterial & PartialJobMaterial)[]> {
        const url = `/api/jobdata/${jobKey}/${materialType}`;
        let params = new HttpParams();
        if (ignoredJobMaterialKeys?.length > 0) {
            params = params.set("ignoredMaterialKeys", ignoredJobMaterialKeys.join(','));
        }

        const result = await this.httpClient.get(url, { params }).toPromise() as (PartialMaterial & PartialJobMaterial)[];

        return result;
    }

    async getAnimalJobMaterials(jobKey: number, pageNumber: number, orderBy = "Sequence"): Promise<JobMaterial[]> {
        const predicates = [
            Predicate.create("Material.Animal.C_Material_key", "!=", null),
            Predicate.create("C_Job_key", "==", jobKey)
        ];
        
        const expands = [
            'Material.MaterialPoolMaterial.MaterialPool',
            'Material.CohortMaterial.Cohort',
            'Material.Animal.cv_Sex',
            'Material.Animal.cv_AnimalStatus',
            'Material.Animal.Genotype',
            'Material.Line'
        ];

        const query = EntityQuery.from("JobMaterials")
            .expand(expands.join(", "))
            .where(Predicate.and(predicates))
            .orderBy(orderBy)
            .skip(50 * (pageNumber - 1))
            .take(50);

        const result = await this.dataManagerService.executeQuery(query);
        return result.results as unknown as JobMaterial[];
    }

    getAnimalCount(jobKey: number): Promise<number> {
        const predicates = [
            Predicate.create("Material.Animal.C_Material_key", "!=", null),
            Predicate.create("C_Job_key", "==", jobKey)
        ];
        const query = EntityQuery.from("JobMaterials")
            .where(Predicate.and(predicates));

        return this.dataManagerService.returnQueryCount(query);
    }

    checkAnimalSequence(data: UpdateAnimalSequenceRequest): Promise<boolean> {
        return this.httpClient.post(
            "/api/jobdata/checkAnimalSequence",
            data,
            { headers: new HttpHeaders() }
        ).toPromise() as Promise<boolean>;
    }

    updateAnimalSequence(data: UpdateAnimalSequenceRequest): Promise<void> {
        return this.httpClient.post(
            "/api/jobdata/updateAnimalSequence", 
            data, 
            { headers: new HttpHeaders() },
        ).toPromise() as unknown as Promise<void>;
    }

    bulkUpdateAnimalName(data: BulkUpdateAnimalNameRequest): Promise<void>{
        return this.httpClient.post(
            "/api/jobdata/bulkUpdateAnimalName",
            data,
            { headers: new HttpHeaders() }
        ).toPromise() as unknown as Promise<void>;
    }
}