import {
    Component,
    Input,
    OnInit,
    OnChanges,
    EventEmitter,
    Output,
    SimpleChanges
} from '@angular/core';
import { FacetLoadingStateService } from '@common/facet';

import { Entity, Order, Site } from '@common/types';
import { OrderInstitution } from '@common/types/models/order-institution.interface';
import { FeatureFlagService } from '@services/feature-flags.service';
import { InstitutionService } from 'src/app/institution/institution.service';
import { OrderService } from 'src/app/orders/order.service';

export interface ExtendedOrderInstitution extends OrderInstitution {
    SelectedSites?: Site[];
}

/**
 * Table for adding institutions to orders
 */
@Component({
    selector: 'order-institution-table',
    templateUrl: './order-institution-table.component.html'
})
export class OrderInstitutionTableComponent implements OnInit, OnChanges {
    @Input() order: Order;
    @Input() isDotmatics = false;
    @Input() required: boolean;
    @Input() readonly: boolean;
    @Input() loading: boolean;

    @Output() institutionChange: EventEmitter<number> = new EventEmitter<number>();

    isCRL = false;
    isRemoveBillingContact: boolean;

    constructor(
        private orderService: OrderService,
        private facetLoadingStateService: FacetLoadingStateService,
        private featureFlagService: FeatureFlagService,
        private institutionService: InstitutionService
    ) {
        //
    }

    ngOnInit(): void {
        this.initialize();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        // Re-initialize table when order.C_Order_key changes (only happens when Order is copied)
        if (changes.order && changes.order.previousValue && (changes.order.currentValue.C_Order_key !== changes.order.previousValue.C_Order_key)) {
            this.initialize();
        }
        if (!changes.loading?.firstChange && !changes.loading?.currentValue) {
            await this.initialize();
        }
    }

    async initialize(): Promise<void> {
        await this.getAllInstitutionSites();
    }

    addOrderInstitution(order: Order): void {
        if (this.readonly) {
            return;
        }
        const initialValues = {
            C_Order_key: order.C_Order_key,
        };
        this.orderService.createOrderInstitution(initialValues);
    }


    removeInstitution(orderInstitution: Entity<OrderInstitution>): void {
        if (this.readonly) {
            return;
        }
        this.orderService.deleteOrderInstitution(orderInstitution);
    }

    async getAllInstitutionSites(): Promise<void> {
        this.facetLoadingStateService.changeLoadingState(true);
        try {
            for (const orderInstitution of this.order.OrderInstitution as ExtendedOrderInstitution[]) {
                orderInstitution.SelectedSites = await this.orderService.getInstitutionSites(orderInstitution.C_Institution_key);
            }
        } finally {
            this.facetLoadingStateService.changeLoadingState(false);
        }
    }

    getSites(institutionKey: number): void {
        this.orderService.getInstitutionSites(institutionKey).then((data) => {
            this.order.OrderInstitution.filter((orderInstitution: ExtendedOrderInstitution) => {
                if (orderInstitution.C_Institution_key === institutionKey) {
                    orderInstitution.SelectedSites = data;
                }
            });
        });
    }
    
    institutionChanged(institutionKey: number, orderInstitution: ExtendedOrderInstitution): void {
        orderInstitution.C_Site_key = null;
        this.getSites(institutionKey);                
    }
}
