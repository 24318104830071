<div
    [ngClass]="{ 'toggle-container': true, checked: model, disabled: disabled }"
    (click)="toggle()"
    [title]="disabled ? title : ''"
>
    <div class="toggle-circle">
        <i>
            <svg [climbIcon]="model ? icons.padlockToggleClosed : icons.padlockToggleOpened"></svg>
        </i>
    </div>
</div>
