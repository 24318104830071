import { WebApiService } from './../services/web-api.service';
import { Injectable } from '@angular/core';
import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
    QueryResult
} from 'breeze-client';

import {
    notEmpty
} from '../common/util';

import { DataManagerService } from '../services/data-manager.service';
import { QueryDef } from '../services/query-def';
import { BaseEntityService } from '../services/base-entity.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class InstitutionService extends BaseEntityService {

    constructor(
        private dataManager: DataManagerService,
        private webApiService: WebApiService
    ) {
        super();
    }

    getInstitutions(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery('Institutions', queryDef);

        this.ensureDefExpanded(queryDef, 'cv_InstitutionType');
        this.ensureDefExpanded(queryDef, 'cv_State');
        this.ensureDefExpanded(queryDef, 'cv_Country');
        this.ensureDefExpanded(queryDef, 'Note');
        query = query.expand(queryDef.expands.join(','));

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getCRLClient(customerID: string): Promise<any> {
        let params = new HttpParams();
        params = params.set('customerID', customerID);
        return this.webApiService.callApi('api/crl/getclients', params).then((customer: any) => {
            return customer;
        });
    }

    buildPredicates(filter: any): Predicate[] {
        const predicates: Predicate[] = [];
        if (!filter) {
            return predicates;
        }

        if (filter.Name) {
            predicates.push(Predicate.create('Name', FilterQueryOp.Contains, { value: filter.Name }));
        }
        if (filter.Identifier) {
            predicates.push(Predicate.create('Identifier', FilterQueryOp.Contains, { value: filter.Identifier }));
        }
        if (filter.AccountNumber) {
            predicates.push(Predicate.create('AccountNumber', FilterQueryOp.Contains, { value: filter.AccountNumber }));
        }
        if (filter.EmailAddress) {
            predicates.push(Predicate.create('EmailAddress', FilterQueryOp.Contains, { value: filter.EmailAddress }));
        }
        if (filter.City) {
            predicates.push(Predicate.create('City', FilterQueryOp.Contains, { value: filter.City }));
        }
        if (notEmpty(filter.C_State_keys)) {
            predicates.push(Predicate.create(
                'C_State_key', 'in', filter.C_State_keys
            ));
        }
        if (notEmpty(filter.C_Country_keys)) {
            predicates.push(Predicate.create(
                'C_Country_key', 'in', filter.C_Country_keys
            ));
        }
        if (filter.PostalCode) {
            predicates.push(Predicate.create('PostalCode', FilterQueryOp.Contains, { value: filter.PostalCode }));
        }
        if (notEmpty(filter.C_InstitutionType_keys)) {
            predicates.push(Predicate.create(
                'C_InstitutionType_key', 'in', filter.C_InstitutionType_keys
            ));
        }


        return predicates;
    }

    getInstitution(institutionKey: number, expands?: string[]): Promise<any> {

        const query = EntityQuery.from('Institutions')
            .where('C_Institution_key', '==', institutionKey);

        return this.dataManager.returnSingleQueryResult(query);
    }

    getSite(siteKey: number): Promise<any> {

        const query = EntityQuery.from('Sites')
            .where('C_Site_key', '==', siteKey);

        return this.dataManager.returnSingleQueryResult(query);
    }

    getInstitutionNames(): Promise<string[]> {
        const query = EntityQuery
            .from('Institutions')
            .select('Name');

        return this.dataManager.returnQueryResults(query);
    }

    getAllInstitutionSiteNames(): Promise<string[]> {
        const query = EntityQuery
            .from('Sites')
            .select('Name');
            return this.dataManager.returnQueryResults(query);
    }

    getInstitutionSites(institutionKey: number): Promise<any> {

        const query = EntityQuery.from('Sites')
            .where('C_Institution_key', '==', institutionKey)
            .orderBy('DateCreated DESC');

        return this.dataManager.returnQueryResults(query);
    }

    getInstitutionContacts(institutionKey: number): Promise<any> {

        const query = EntityQuery.from('ContactPersons')
            .where('C_Institution_key', '==', institutionKey)
            .orderBy('DateCreated DESC');

        return this.dataManager.returnQueryResults(query);
    }

    getContacts(pkName: string, pkValue: number): Promise<any> {

        const query = EntityQuery.from('ContactPersons')
            .where(pkName, '==', pkValue);

        return this.dataManager.returnQueryResults(query);
    }

    createInstitution(): any {
        const initialValues: any = {
            DateCreated: new Date()
        };
        return this.dataManager.createEntity('Institution', initialValues);
    }

    deleteInstitution(institution: any) {
        if (institution.Site) {
            while (institution.Site.length > 0) {
                this.deleteSite(institution.Site[0]);
            }
        }

        this.dataManager.deleteEntity(institution);
    }

    createSite(institutionKey: number): any {
        const initialValues: any = {
            C_Institution_key: institutionKey,
            // set DateCreated immediately for sorting purposes
            DateCreated: new Date()
        };
        return this.dataManager.createEntity('Site', initialValues);
    }

    deleteSite(site: any) {
        this.dataManager.deleteEntity(site);
    }

    createContact(pkName: string, pkValue: number): any {
        const initialValues: any = {
            [pkName]: pkValue,
            IsActive: true,
        };
        return this.dataManager.createEntity('ContactPerson', initialValues);
    }

    deleteContact(contact: any) {
        this.dataManager.deleteEntity(contact);
    }


    cancelInstitution(institution: any) {
        if (!institution) {
            return;
        }

        if (institution.C_Institution_key > 0) {
            this._cancelInstitutionEdits(institution);
        } else {
            this._cancelNewInstitution(institution);
        }
    }

    private _cancelNewInstitution(institution: any) {
        try {
            this.deleteInstitution(institution);
        } catch (error) {
            console.error('Error cancelling new institution: ' + error);
        }
    }

    private _cancelInstitutionEdits(institution: any) {
        this.dataManager.rejectEntityAndRelatedPropertyChanges(institution);
        for (const site of institution.Site) {
            this.dataManager.rejectChangesToEntityByFilter(
                'ContactPerson', (item: any) => {
                    return item.C_Site_key === site.C_Site_key;
                }
            );
        }
        this.dataManager.rejectChangesToEntityByFilter(
            'Site', (item: any) => {
                return item.C_Institution_key === institution.C_Institution_key;
            }
        );
        this.dataManager.rejectChangesToEntityByFilter(
            'ContactPerson', (contacts: any) => {
                return contacts.C_Institution_key === institution.C_Institution_key;
            }
        );
    }

    async ensureVisibleColumnsDataLoaded(institutions: any[], visibleColumns: string[]): Promise<void> {
        const expands = this.generateExpandsFromVisibleColumns(institutions[0], visibleColumns);
        return this.dataManager.ensureRelationships(institutions, expands);
    }
}
