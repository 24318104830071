import { map } from 'rxjs/operators';
import {
    Component,
    Input,
    OnInit,
    OnChanges,
} from '@angular/core';


import { SampleOrder, cv_SampleCondition, cv_SampleType, cv_Taxon } from '@common/types';
import { PrivilegeService } from '@services/privilege.service';
import { FacetLoadingStateService } from '@common/facet';
import { OrderVocabService } from 'src/app/orders/order-vocab.service';

@Component({
    selector: 'sample-order-detail',
    templateUrl: './sample-order-detail.component.html',
    styles: [`

    `]
})
export class SampleOrderDetailComponent implements OnInit, OnChanges {
    @Input() order: string;
    @Input() sampleOrder: SampleOrder;
    @Input() readonly: boolean;

    // CVs
    sampleConditions: cv_SampleCondition[] = [];
    taxons: cv_Taxon[] = [];
    sampleTypes: cv_SampleType[] = [];

    // State
    readonly COMPONENT_LOG_TAG = 'sample-order-detail';

    readwrite: boolean;
    contactsShown: boolean;

    constructor(
        private privilegeService: PrivilegeService,
        private facetLoadingStateService: FacetLoadingStateService,
        private orderVocabService: OrderVocabService,
    ) { }

    // lifecycle
    ngOnInit(): void {
        this.initialize();
    }

    ngOnChanges(changes: any): void {
        if (changes.entity && !changes.entity.firstChange) {
            this.initialize();
        }
    }

    initialize(): Promise<void> {
        this.facetLoadingStateService.changeLoadingState(true);

        return this.getCVs().then(() => {
            this.facetLoadingStateService.changeLoadingState(false);
        }).catch((error) => {
            this.facetLoadingStateService.changeLoadingState(false);
            throw error;
        });
    }

    private getCVs(): Promise<any> {
        const p1 = this.orderVocabService.sampleConditions$.pipe(map((data) => {
            this.sampleConditions = data;
        })).toPromise();

        const p2 = this.orderVocabService.taxons$.pipe(map((data) => {
            this.taxons = data;
        })).toPromise();

        const p3 = this.orderVocabService.sampleTypes$.pipe(map((data) => {
            this.sampleTypes = data;
        })).toPromise();

        return Promise.all([p1, p2, p3]);
    }

    // Formatters for <select> input
    sampleConditionKeyFormatter = (value: cv_SampleCondition): number => {
        return value.C_SampleCondition_key;
    }
    sampleConditionFormatter = (value: cv_SampleCondition): string => {
        return value.SampleCondition;
    }

    sampleTypeKeyFormatter = (value: cv_SampleType): number => {
        return value.C_SampleType_key;
    }
    sampleTypeFormatter = (value: cv_SampleType): string => {
        return value.SampleType;
    }

    taxonKeyFormatter = (value: cv_Taxon): number => {
        return value.C_Taxon_key;
    }
    taxonFormatter = (value: cv_Taxon): string => {
        return value.Taxon;
    }
}
