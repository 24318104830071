<loading-overlay *ngIf="loading"></loading-overlay>
<div class="detail-form-wrapper">
    <loading-overlay *ngIf="orderDetailService.materialTypeLoading" message="Loading..."></loading-overlay>

    <div class="detail-header">
        <h3>Order</h3>

        <pager-toolbar [itemName]="'order'"
                       [logTag]="COMPONENT_LOG_TAG"
                       [first]="pageState.isFirst"
                       [last]="pageState.isLast"
                       (previous)="previousClicked($event)"
                       (next)="nextClicked($event)">
        </pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="exitClicked($event)">
        </detail-toolbar>
    </div>

    <ng-container *ngIf="orderDetailService.isShowAnimalForm; else generalDetailForm">
        <order-detail-animal-form></order-detail-animal-form>
    </ng-container>

    <ng-template #generalDetailForm>
        <order-detail-general-form></order-detail-general-form>
    </ng-template>
</div>
