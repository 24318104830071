import { Subscription } from "rxjs";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { BaseDetail, BaseDetailService, FacetView, IFacet } from "../../../common/facet";
import { cv_DataType, cv_SampleType, SampleCharacteristic } from "../../../common/types";
import { SampleCharacteristicSampleType } from "../../../common/types/models/sample-characteristic-sample-type.interface";
import { DataManagerService } from "../../../services/data-manager.service";
import { IValidatable, SaveChangesService } from "../../../services/save-changes.service";
import { CharacteristicVocabService } from "../../characteristic-vocab.service";
import { CharacteristicService } from "../../characteristic.service";
import type { EditResult } from "../../characteristics.interface";

@Component({
    selector: "sample-characteristic-edit",
    templateUrl: "./sample-characteristic-edit.component.html"
})
export class SampleCharacteristicEditComponent extends BaseDetail implements OnInit, OnDestroy, IValidatable {
    @Input() facet: IFacet;
    @Input() facetView: FacetView;
    @Input() facetPrivilege: string;
    @Input() sampleCharacteristic: SampleCharacteristic;

    @Output() editEnd: EventEmitter<EditResult> = new EventEmitter<EditResult>();

    dataTypes: cv_DataType[];
    sampleTypes: cv_SampleType[] = [];
    reloadLocalCharactericticInstances = false;
    selectedSampleTypes: number[] = [];

    editResult: EditResult = {
        isCanceled: false,
        isSaved: false,
    };

    subscriptions: Subscription = new Subscription();

    constructor(
        baseDetailService: BaseDetailService,
        private _characteristicService: CharacteristicService,
        private _characteristicVocabService: CharacteristicVocabService,
        private _dataManagerService: DataManagerService,
        private _saveChangesService: SaveChangesService
    ) {
        super(baseDetailService);
    }

    ngOnInit() {
        this._saveChangesService.registerValidator(this);


        this._characteristicVocabService.getDataTypes().then((results: any[]) => {
            this.dataTypes = results.filter((item: cv_DataType) => item.ShowInCharacteristics && item.DataType !== 'Vocabulary');
        });

        this._characteristicVocabService.getSampleTypes().then((results: cv_SampleType[]) => this.sampleTypes = results);
        this.setSelectedSampleTypes();
        this._characteristicVocabService.getSampleTypes()
            .then((results: cv_SampleType[]) => this.sampleTypes = results.filter((cv: cv_SampleType) => cv.IsActive || this.selectedSampleTypes.includes(cv.C_SampleType_key)));

        const subscription = this._saveChangesService.saveSuccessful$.subscribe(async () => {
            if (this.reloadLocalCharactericticInstances) {
                this.reloadLocalCharactericticInstances = false;
                await this._characteristicService.refreshLocalCharacteristicInstances('Sample', this.sampleCharacteristic);
            }
            this.editResult.isSaved = true;
        });
        this.subscriptions.add(subscription);
    }


    setSelectedSampleTypes(): void {
        this.selectedSampleTypes = this.sampleCharacteristic.SampleCharacteristicSampleType.map((scst: SampleCharacteristicSampleType) => scst.cv_SampleType.C_SampleType_key);
        this.sampleCharacteristic.HasSampleType = this.selectedSampleTypes.length > 0;
    }

    onSampleTypeChange(sampleTypeKeys: number[]): void {
        const initialSampleCharacteristicSampleTypes = this.sampleCharacteristic.SampleCharacteristicSampleType;
        const initialSampleTypeKeys = initialSampleCharacteristicSampleTypes.map((scst: SampleCharacteristicSampleType) => scst.cv_SampleType.C_SampleType_key);
        const initialValues: any = [];

        for (const key of sampleTypeKeys) {
            if (!initialSampleTypeKeys.includes(key)) {
                initialValues.push({
                    C_SampleType_key: key,
                    C_SampleCharacteristic_key: this.sampleCharacteristic.C_SampleCharacteristic_key
                });
            }
        }

        const toDelete = initialSampleCharacteristicSampleTypes.filter((scst: SampleCharacteristicSampleType) => {
            return !sampleTypeKeys.includes(scst.C_SampleType_key);
        });

        for (const e of toDelete) {
            this._dataManagerService.deleteEntity(e);
        }

        const sampleCharacteristicSampleTypes = [];
        for (const value of initialValues) {
            sampleCharacteristicSampleTypes.push(this._dataManagerService.createEntity("SampleCharacteristicSampleType", value));
        }
        this.sampleCharacteristic.HasSampleType = this.selectedSampleTypes.length > 0;

        const selectedSampleTypes = this.sampleCharacteristic.SampleCharacteristicSampleType.map((scst: SampleCharacteristicSampleType) => scst.cv_SampleType.SampleType);
        this.sampleCharacteristic.SampleTypes = selectedSampleTypes.join(', ');
    }

    ngOnDestroy() {
        this._saveChangesService.unregisterValidator(this);
        this.subscriptions.unsubscribe();
    }

    onSaveCharacteristic(): void {
        this._saveChangesService.saveChanges(this.facet.FacetName, true);
    }

    async validate(): Promise<string> {
        if (this.selectedSampleTypes.length === 0) {
            return "Sample Type is required";
        }

        this.reloadLocalCharactericticInstances = this._characteristicService.isCharacteristinNameChanged(this.sampleCharacteristic);

        return this._characteristicService.validateCommonCharacteristic(this.sampleCharacteristic);
    }

    onCancel(): void {
        this.editResult.isCanceled = true;
        this.editEnd.emit(this.editResult);
    }
}
