import { cv_PermitCategory } from './cv-permit-category.interface';
import { cv_PermitExternalPurpose } from './cv-permit-external-purpose.interface';
import { cv_PermitStatus } from './cv-permit-status.interface';
import { PermitInstitution } from './permit-institution.interface';
import { PermitSpecies } from './permit-species.interface';
import { Resource } from './resource.interface';

export interface Permit {
    C_Permit_key: number;
    C_Resource_key: number;
    C_PermitStatus_key: number;
    C_PermitCategory_key: number;
    C_PermitExternalPurpose_key: number;
    PermitNumber: string;
    Title: string;
    GMOAnimalsAllowed: boolean;
    OtherPurpose: string;
    Summary: string;
    StartDate: Date;
    ExpiryDate: Date;
    CreatedBy: string;
    DateCreated: Date;
    ModifiedBy: string;
    DateModified: Date;
    Resource: Resource;
    PermitInstitution: PermitInstitution[];
    PermitSpecies: PermitSpecies[];

    EndStateAnimals: number;
    LiveAnimals: number;
    TotalAnimals: number;
    AnimalsAllowedOnPermit: number;
    AnimalsRemaining: number;

    InstitutionsSortable: string;
    SpeciesSortable: string;
    LinesSortable: string;
    HighestSeveritiesSortable: string;
    NonRecoveriesSortable: string;
    OriginsSortable: string;
    ReuseAllowedSortable: string;    

    cv_PermitStatus: cv_PermitStatus;
    cv_PermitCategory: cv_PermitCategory;
    cv_PermitExternalPurpose: cv_PermitExternalPurpose;
}
