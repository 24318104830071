import { Component, ViewChildren, Input, ChangeDetectionStrategy } from '@angular/core';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoggingService } from '@services/logging.service';

export interface AnimalJournalFilter {
  start?: Date;
  end?: Date;
}

@Component({
  selector: 'climb-animal-journal-filter',
  templateUrl: './animal-journal-filter.component.html',
  styleUrls: ['./animal-journal-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnimalJournalFilterComponent {
  @ViewChildren('dateControl') dateControls: NgModel[];

  @Input() filter: AnimalJournalFilter = {};

  constructor(
      private activeModal: NgbActiveModal,
      private loggingService: LoggingService,
  ) {}

  filterClicked(event: Event): void {
      const errMessage = dateControlValidator(this.dateControls);
      if (errMessage) {
          this.loggingService.logError(errMessage, null, '', true);
          return;
      }

      event.preventDefault();
      this.closeClicked({ ...this.filter });
  }

  closeClicked(filter?: AnimalJournalFilter): void {
      this.activeModal.close(filter);
  }

  clearClicked(event: Event): void {
      this.filter = {};
      this.filterClicked(event);
  }
}
