import type { Lot } from './lot.interface';
import type { Order } from './order.interface';
import type { cv_SampleCondition } from './cv-sample-condition.interface';
import type { cv_SampleType } from './cv-sample-type.interface';
import { Entity } from '..';

export interface SampleOrder {
    AccessionID: string;
    C_Order_key: number;
    C_SampleCondition_key: number | null;
    C_SampleOrder_key: number;
    C_SampleType_key: number;
    C_Workgroup_key: number;
    Comments: string;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Lot: Lot[];
    LotNumber: string;
    ModifiedBy: string;
    Order: Entity<Order>;
    SampleCount: number | null;
    Version: number;
    cv_SampleCondition: cv_SampleCondition;
    cv_SampleType: cv_SampleType;
}
