import { Component } from '@angular/core';
import { cv_Diet, cv_Sex } from '@common/types';
import { OrderDetailService } from '../../services/order-detail.service';

@Component({
    selector: 'order-detail-animal-form',
    templateUrl: './order-detail-animal-form.component.html',
    styleUrls: ['./order-detail-animal-form.component.scss']
})
export class OrderDetailAnimalFormComponent {
    constructor(
        public orderDetailService: OrderDetailService,
    ) { }

    sexKeyFormatter = (value: cv_Sex): number => {
        return value.C_Sex_key;
    }
    sexFormatter = (value: cv_Sex): string => {
        return value.Sex;
    }

    dietKeyFormatter = (value: cv_Diet): number => {
        return value.C_Diet_key;
    }
    dietFormatter = (value: cv_Diet): string => {
        return value.Diet;
    }
}
