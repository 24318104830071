export const ORDER_DETAIL_FIELD = {
    summary: {
        type: {
            name: 'Type',
            fieldName: 'C_MaterialType_key',
        },
        status: { name: 'Status', fieldName: 'C_OrderStatus_key' },
        orderedBy: { name: 'Ordered By', fieldName: 'C_OrderedBy_key' },
        animalSampleOrigin: { name: 'Animal/Sample Origin', fieldName: 'C_MaterialOrigin_key' },
        institutions: { name: 'Institutions', fieldName: 'OrderInstitution[0]' },
        institutionSite: { name: 'Institutions: Site', fieldName: 'OrderInstitution[0].Site' },
    },
    permitAndJobInformation: {
        permit: { name: 'Permit', fieldName: 'C_Permit_key', isAnimalsOnly: true },
        jobName: { name: 'Job Name', fieldName: 'JobOrder[0].C_Job_key' },
        expectedJobEndDate: {
            name: 'Expected Job End Date',
            fieldName: 'ExpectedJobEndDate',
            isAnimalsOnly: true,
        },
    },
    animalInformation: {
        line: { name: 'Line', fieldName: 'C_Line_key', isAnimalsOnly: true },
        species: { name: 'Species', isAnimalsOnly: true },
        numberOfAnimalOrdered: { name: 'Number of Animals Ordered', fieldName: 'AnimalCount' },
        desiredReceiptDate: { name: 'Desired Receipt Date', fieldName: 'DesiredReceiptDate', isAnimalsOnly: true },
        sex: { name: 'Sex', fieldName: 'C_Sex_key', isAnimalsOnly: true },
        requestedAgeRange: { name: 'Requested Age Range', fieldName: 'RequestedAgeRange', isAnimalsOnly: true },
        requestedWeightRange: {
            name: 'Requested Weight Range',
            fieldName: 'RequestedWeightRange',
            isAnimalsOnly: true,
        },
        procurementInstructions: {
            name: 'Procurement Instructions',
            fieldName: 'ProcurementInstructions',
            isAnimalsOnly: true,
        },
    },
    housingAndLocationInformation: {
        housingRequirements: { name: 'Housing Requirements', fieldName: 'HousingRequirements', isAnimalsOnly: true },
        quarantineServicesFacility: { name: 'Quarantine Services Facility', fieldName: 'C_QuarantineFacility_key' },
        locations: { name: 'Locations', fieldName: 'OrderLocation[0]' },
    },
    receiptInstructions: {
        diet: { name: 'Diet', fieldName: 'C_Diet_key', isAnimalsOnly: true },
        instructions: { name: 'Instructions', fieldName: 'Comments' },
        foodEnrichmentAllowed: {
            name: 'Food Enrichment Allowed',
            isAnimalsOnly: true,
        },
    },
    procurementInformation: {
        poNumber: { name: 'PO Number', fieldName: 'InvoiceNumber' },
        vendorOrderNumber: { name: 'Vendor Order Number', fieldName: 'VendorOrderNumber' },
        vendorRoomId: { name: 'Vendor Room ID', fieldName: 'VendorRoomId' },
        dateOrdered: { name: 'Date Ordered', fieldName: 'DateReceived' },
        projectedReceiptDate: { name: 'Projected Receipt Date', fieldName: 'DateProjectedReceipt' },
        cites: { name: 'CITES', fieldName: 'Cites' },
    },
} as const;

export function getAnimalsOnlyRequiredFields(objectToExtract: any = ORDER_DETAIL_FIELD): string[] {
    let resultValues: string[] = [];

    for (const objectKey in objectToExtract) {
        if (objectKey === 'isAnimalsOnly' && objectToExtract[objectKey] === true && objectToExtract.fieldName != null) {
            resultValues.push(objectToExtract.fieldName);
            continue;
        }

        if (typeof objectToExtract[objectKey] === 'object' && objectToExtract[objectKey] !== null) {
            resultValues = [...resultValues, ...getAnimalsOnlyRequiredFields(objectToExtract[objectKey])];
        }
    }

    return resultValues;
}

export const ORDER_ANIMALS_ONLY_REQUIRED_FIELDS_SET = new Set(getAnimalsOnlyRequiredFields());

type ExtractFieldsByKey<T, K extends string> = T extends { [key in K]: infer F }
    ? F
    : T extends { [key: string]: infer U }
    ? ExtractFieldsByKey<U, K>
    : never;

export type OrderActiveField = ExtractFieldsByKey<typeof ORDER_DETAIL_FIELD, 'name'>;
export type OrderRequiredField = ExtractFieldsByKey<typeof ORDER_DETAIL_FIELD, 'fieldName'>;
