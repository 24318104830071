import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClimbServicesModule } from '../services/climb-services.module';

import { ScheduleService } from './schedule.service';
import { ScheduleVocabService } from './schedule-vocab.service';

@NgModule({
    imports: [
        CommonModule,
        ClimbServicesModule
    ]
})
export class ScheduleSharedModule {
    static forRoot(): ModuleWithProviders<ScheduleSharedModule> {
        return {
          ngModule: ScheduleSharedModule,
          providers: [
            ScheduleService,
            ScheduleVocabService
          ]
        };
    }
}
