import {
    Component,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
    EventEmitter,
    Output,
    Injector,
    SimpleChanges
} from '@angular/core';

import { DragulaOptions, DragulaService } from 'ng2-dragula';

import {
    IFacet,
} from '@common/facet';
import {
    randomId
} from '@common/util';

import { JobPharmaDetailService } from '../../services/job-pharma-detail.service';
import { LoggingService } from '../../../../services/logging.service';
import { SaveChangesService } from '../../../../services/save-changes.service';
import { TableSort } from '../../../../common/models';
import { Entity, ExtendedJob, JobMaterial, Job } from '@common/types';
import { DataContextService } from '@services/data-context.service';
import { JobPharmaAnimalsIndividualTableService } from './job-pharma-animals-individual-table.service';
import { JobPharmaCoreService } from '../../services';
import escapeHtml from '@common/util/escape-html';
import { Base, CanUnsubscribeMixin, applyMixins, mixinUnsubscribe } from '@common/mixins';
import { ISelectable } from '@common/types/selectable.interface';
import { AnimalsIndividualTableColumnsHelper } from './animals-individual-table-columns-helper';
import { VisibleColumns } from '@common/util/table-columns/base-table-columns-helper';
import { CanLoadMixin, mixinLoadable } from '@common/mixins/loadable.mixin';

const Mixins: CanUnsubscribeMixin & CanLoadMixin & typeof Base = applyMixins(mixinUnsubscribe, mixinLoadable);
type JobMaterialExtended = JobMaterial & ISelectable;

@Component({
    selector: 'job-pharma-animals-individual-table',
    templateUrl: './job-pharma-animals-individual-table.component.html',
    styles: [`
        table.job-pharma-animals-individual .fa-sort.draggable {
            cursor: grab;
        }

        table.job-pharma-animals-individual .fa-sort:not(.draggable) {
            opacity: 0.5;
            cursor: not-allowed;
        }

        .bulk-name .form-control {
            width: 50px;
        }

        .ui-draggable-dragging {
            padding: 4px;
            border-radius: 2px;
            font-size: 12px;
            margin-left: 20px;
        }
    `],
    providers: [
        JobPharmaAnimalsIndividualTableService,
        AnimalsIndividualTableColumnsHelper
    ]
})
export class JobPharmaAnimalsIndividualTableComponent extends Mixins implements OnChanges, OnDestroy, OnInit {
    readonly tabset = 'animals';
    readonly tab = 'individual';
    readonly DRAG_MAX_NAMES = 5;
    readonly COMPONENT_LOG_TAG = 'job-pharma-animals-individual-table';

    @Input() readonly: boolean;
    @Input() job: Entity<Job & ExtendedJob>;
    @Input() facet: IFacet;
    @Input() primaryCohortsExpanded = true;
    @Input() isGLP: boolean;
    @Output() primaryCohortsExpandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    tableSort: TableSort = new TableSort();
    loadingMessage = "Loading";
    page = 1;
    animalJobMaterials: JobMaterialExtended[];
    animalCount: number;
    visibleColumns: VisibleColumns;
    dragulaBagName: string;
    bulkNamePrefix: string;
    bulkNameCounter = 1;
    bulkNameSuffix: string;

    constructor(
        injector: Injector,
        private dragulaService: DragulaService,
        private jobPharmaDetailService: JobPharmaDetailService,
        private loggingService: LoggingService,
        private dataContext: DataContextService,
        private jobPharmaAnimalsIndividualTableService: JobPharmaAnimalsIndividualTableService,
        private saveChangesService: SaveChangesService,
        private animalsIndividualTableColumnsHelper: AnimalsIndividualTableColumnsHelper,
        private jobPharmaCoreService: JobPharmaCoreService
    ) {
        super(injector);
    }

    async ngOnInit(): Promise<void> {
        this.setLoading(true);
        try {
            this.initColumnSelect();
            this.initDrag();
            this.initChangeDetection();
            this.tableSort = this.jobPharmaAnimalsIndividualTableService.setDefaultTableSort(this.tableSort, this.facet);
            await this.jobPharmaAnimalsIndividualTableService.loadVocabularies();
            await this.jobPharmaAnimalsIndividualTableService.updateAnimalSequence(this.job.C_Job_key, this.tableSort.sortConfig);
            await this.loadAnimalJobMaterials();
            this.jobPharmaAnimalsIndividualTableService.clearAnimalSelections(this.job, this.animalJobMaterials, true);
            if(!this.jobPharmaAnimalsIndividualTableService.checkPageSequence(this.animalJobMaterials, this.page)){
                this.jobPharmaAnimalsIndividualTableService.fixPageSequence(this.animalJobMaterials, this.page);
            }
        }
        catch(err) {
            this.loggingService.logError("Error loading the animals individual table", err, this.COMPONENT_LOG_TAG, false)
        }
        finally{
            this.setLoading(false);
        }
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.job && !changes.job.firstChange) {
            await this.loadAnimalJobMaterials();
            if(!this.jobPharmaAnimalsIndividualTableService.checkPageSequence(this.animalJobMaterials, this.page)){
                this.jobPharmaAnimalsIndividualTableService.fixPageSequence(this.animalJobMaterials, this.page);
            }
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.dragOnDestroy();
        this.jobPharmaAnimalsIndividualTableService.clearAnimalSelections(this.job, this.animalJobMaterials, true);
    }

    initChangeDetection(): void {

        this.subscription = this.jobPharmaDetailService.jobCohortsChanged$.subscribe(async () => {
            try {
                this.setLoading(true);
                await this.jobPharmaAnimalsIndividualTableService.updateAnimalSequence(this.job.C_Job_key, this.tableSort.sortConfig);
                await this.loadAnimalJobMaterials();
            } catch(err) {
                this.loggingService.logError("Error initializing animal data", err, this.COMPONENT_LOG_TAG, true);
            } finally {
                this.setLoading(false);
            }
        });

        this.subscription = this.jobPharmaDetailService.jobMaterialsChanged$.subscribe(async () => {
            try {
                this.setLoading(true);
                await this.jobPharmaAnimalsIndividualTableService.updateAnimalSequence(this.job.C_Job_key, this.tableSort.sortConfig);
                await this.loadAnimalJobMaterials();
            } catch(err) {
                this.loggingService.logError("Error initializing animal data", err, this.COMPONENT_LOG_TAG, true);
            } finally {
                this.setLoading(false);
            }
        });

        this.subscription = this.dataContext.onRejectEntityChange$.subscribe(async (entity: Entity<unknown>) => {
            if (entity.entityType.shortName !== 'JobMaterial') {
                return;
            }
            
            if (!this.jobPharmaAnimalsIndividualTableService.checkPageSequence(this.animalJobMaterials, this.page)){
                this.jobPharmaAnimalsIndividualTableService.fixPageSequence(this.animalJobMaterials, this.page);
            }

            const jobMaterial = entity as unknown as Entity<JobMaterial>;
            this.jobPharmaAnimalsIndividualTableService.isSelectedChanged(this.job, this.animalJobMaterials, jobMaterial);
        });

        this.subscription = this.saveChangesService.saveSuccessful$.subscribe(async () => {
            await this.jobPharmaAnimalsIndividualTableService.updateAnimalSequence(this.job.C_Job_key, this.tableSort.sortConfig);
            await this.loadAnimalJobMaterials();

            this.jobPharmaAnimalsIndividualTableService.handleUIUpdatesIfLoadingMaterialData(this.animalJobMaterials);
            if (this.jobPharmaAnimalsIndividualTableService.loadingMaterialDataPromise) {
                await this.jobPharmaAnimalsIndividualTableService.loadingMaterialDataPromise;
            }
            this.jobPharmaAnimalsIndividualTableService.updateSelections(this.animalJobMaterials);
            this.jobPharmaCoreService.cleanupIgnoredAnimalsOnJob(this.job);
            this.jobPharmaAnimalsIndividualTableService.trueAnimalCount = await this.jobPharmaCoreService.getAnimalCountMinusPendingDeletions(this.job);
        });
    }

    initColumnSelect(): void {
        const {
            columnSelect,
            visibleColumns,
            updateVisibleColumns
        } = this.animalsIndividualTableColumnsHelper.create();
        this.visibleColumns = visibleColumns;
        
        this.subscribe(
            this.jobPharmaDetailService.registerColumnSelect(
                this.tabset, 
                this.tab, 
                columnSelect,
                () => { updateVisibleColumns(); }
            )
        );

        updateVisibleColumns();
    }

    initDrag(): void {
        this.dragulaBagName = randomId() + '-bag';

        // Register a callback to handle the row drop
        this.subscription = this.dragulaService.dropModel(this.dragulaBagName).subscribe((event) => {
            this.animalJobMaterials = this.jobPharmaAnimalsIndividualTableService.reorderOnDrag(event, this.animalJobMaterials, this.dragulaBagName);
            this.animalJobMaterials = this.jobPharmaAnimalsIndividualTableService.fixPageSequence(this.animalJobMaterials, this.page);
            this.jobPharmaAnimalsIndividualTableService.clearAnimalSelections(this.job, this.animalJobMaterials, true);
            
            this.tableSort = new TableSort();
            this.jobPharmaAnimalsIndividualTableService.saveSortConfig(this.tableSort, this.facet);
        });

        // Register a callback to update the drag helper element
        this.subscription = this.dragulaService.cloned(this.dragulaBagName).subscribe(({clone, cloneType}) => {
            if (cloneType !== 'mirror') {
                // Something else was cloned
                return;
            }

            // Update the mirror (aka drag helper);
            this.dragUpdateHelper(clone as HTMLElement);
        });

        // Dragula options
        const opts: DragulaOptions<any> = {
            moves: (el: Element, source: Element, handle: Element, sibling: Element) => {
                return this.readonly
                    ? false // Disable dragging if can't edit
                    : handle.classList.contains('draggable'); // Only allow dragging by the drag handle
            },
        };

        this.dragulaService.createGroup(this.dragulaBagName, opts);
    }

    /**
     * Remove Dragula configuration and listeners
     */
    dragOnDestroy(): void {
        if (this.dragulaService.find(this.dragulaBagName)) {
            this.dragulaService.destroy(this.dragulaBagName);
        }
    }

    /**
     * Update the Dragula mirror element (aka drag helper) that is displayed
     * under the cursor while dragging rows.
     *
     * By default, the dragged element is cloned. Instead, we want to display
     * some information about the selected rows.
     *
     * @param mirror DOM element displayed under the cursor
     */
    private dragUpdateHelper(mirror: HTMLElement) {
        // Figure out which row was dragged
        const draggedKey: number = parseInt(mirror.dataset.key, 10);

        //  Get the names of all the animals to be dragged
        const names = this.animalJobMaterials.filter((jm) => {
            return jm.isSelected || (jm.C_Material_key === draggedKey);
        }).filter((jm) => {
            return jm.Material && jm.Material.Animal;
        }).map((jm) => {
            return jm.Material.Animal.AnimalName;
        });

        let text = '';
        if (names.length <= this.DRAG_MAX_NAMES) {
            // Join all the names
            text = names.join(', ');
        } else {
            // Join the first names, and note how many were left off
            text = names.slice(0, this.DRAG_MAX_NAMES).join(', ');
            text += ` (plus ${names.length - this.DRAG_MAX_NAMES} more)`;
        }
        text = escapeHtml(text);
        mirror.innerHTML = `<div class="dragula-helper">${text}</div>`;
    }

    async loadAnimalJobMaterials(): Promise<void> {
        this.setLoading(true);
        try {
            this.animalJobMaterials = await this.jobPharmaAnimalsIndividualTableService.getAnimalJobMaterials(this.job.C_Job_key, this.page, this.tableSort.stringifiedSortConfig);
            this.animalCount = await this.jobPharmaAnimalsIndividualTableService.getAnimalCount(this.job);
            this.jobPharmaAnimalsIndividualTableService.clearAnimalSelections(this.job, this.animalJobMaterials, true);
        }
        catch(err){
            this.loggingService.logError("Error initializing animal data", err, this.COMPONENT_LOG_TAG, true);
        }
        finally{
            this.setLoading(false);
        }
    }

    /**
     * Handle dropping Animals into the Job
     */
    async onDrop(): Promise<void> {
        this.setLoading(true);
        try {
            await this.jobPharmaCoreService.onDropAnimals(this.job);
            this.animalCount = await this.jobPharmaAnimalsIndividualTableService.getAnimalCount(this.job);
        } catch (err) {
            this.loggingService.logError("Error dropping animal(s)", err, this.COMPONENT_LOG_TAG, true);
        } finally {
            this.setLoading(false);
        }
    }

    /**
     * Handle pasting Animals into the Job
     */
    async onPaste(): Promise<void> {
        this.setLoading(true);
        try {
            await this.jobPharmaCoreService.onPasteAnimals(this.job);
            this.animalCount = await this.jobPharmaAnimalsIndividualTableService.getAnimalCount(this.job);
        } catch (err) {
            this.loggingService.logError("Error pasting animal(s)", err, this.COMPONENT_LOG_TAG, true);
        } finally {
            this.setLoading(false);
        }
    }

    async removeAnimalJobMaterial(jobMaterial: JobMaterial): Promise<void> {
        await this.jobPharmaAnimalsIndividualTableService.removeAnimalJobMaterial(jobMaterial, this.job, this.isGLP);
        this.jobPharmaAnimalsIndividualTableService.selectionChanged(this.job, this.animalJobMaterials);
        if(!this.jobPharmaAnimalsIndividualTableService.checkPageSequence(this.animalJobMaterials, this.page)){
            this.jobPharmaAnimalsIndividualTableService.fixPageSequence(this.animalJobMaterials, this.page);
        }
    }
    
    async changePage(newPage: number): Promise<void> {
        this.setLoading(true);
        try {
            this.animalJobMaterials = await this.jobPharmaAnimalsIndividualTableService.getAnimalJobMaterials(this.job.C_Job_key, newPage, this.tableSort.stringifiedSortConfig);
            this.page = newPage;

            this.jobPharmaAnimalsIndividualTableService.handleUIUpdatesIfLoadingMaterialData(this.animalJobMaterials);
            if (this.jobPharmaAnimalsIndividualTableService.loadingMaterialDataPromise) {
                await this.jobPharmaAnimalsIndividualTableService.loadingMaterialDataPromise;
            }
            this.jobPharmaAnimalsIndividualTableService.updateSelections(this.animalJobMaterials);
        } catch (err){
            this.loggingService.logError("Error changing pages", err, this.COMPONENT_LOG_TAG, true)
        } finally {
            this.setLoading(false);
        }
    }

    viewPrimaryCohorts(): void {
        this.primaryCohortsExpanded = true;
        this.primaryCohortsExpandedChange.emit(this.primaryCohortsExpanded);
    }

    viewAllCohorts(): void {
        this.primaryCohortsExpanded = false;
        this.primaryCohortsExpandedChange.emit(this.primaryCohortsExpanded);
    }

    async sortColumn(column: string, event: KeyboardEvent): Promise<void> {
        this.setLoading(true);
        try {
            if (event.shiftKey) {
                this.tableSort.nested = true;
            } else {
                this.tableSort.nested = false;
            }
            this.tableSort.toggleSort(column);
            this.jobPharmaAnimalsIndividualTableService.saveSortConfig(this.tableSort, this.facet);
            await this.jobPharmaAnimalsIndividualTableService.updateAnimalSequence(this.job.C_Job_key, this.tableSort.sortConfig);
            this.animalJobMaterials = await this.jobPharmaAnimalsIndividualTableService.getAnimalJobMaterials(this.job.C_Job_key, this.page, this.tableSort.stringifiedSortConfig);
        } catch(err) {
            this.loggingService.logError("Error sorting animals.", err, this.COMPONENT_LOG_TAG, true);

        } 
        finally {
            this.setLoading(false);
        }
    }

    async onBulkAnimalNameUpdated(bulkNamePrefix: string, bulkNameSuffix: string, bulkNameCounter: number): Promise<void> {
        this.setLoading(true);
        try {
            await this.jobPharmaAnimalsIndividualTableService.bulkNameUpdated(bulkNamePrefix, bulkNameSuffix, bulkNameCounter, this.job.C_Job_key);
            this.loadAnimalJobMaterials();
            this.jobPharmaDetailService.tabRefresh('tasks', 'list');
            this.jobPharmaDetailService.tabRefresh('tasks', 'outline');
        } catch(err) {
            this.loggingService.logError("Error bulk updating animal name.", err, this.COMPONENT_LOG_TAG, true);
        } finally {
            this.setLoading(false);
        }
    }
}
