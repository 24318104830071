import {
    Component,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
    Output,
    EventEmitter,
    SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';

import {
    ColumnSelect,
    ColumnSelectLabel
} from '@common/facet';

import {
    JobPharmaDetailService,
} from '../../services/job-pharma-detail.service';
import { Entity, Job, JobMaterial } from '@common/types';
import { JobPharmaCoreService } from '../../services/job-pharma-core.service';
import { DEFAULT_VISIBLE_COLUMNS } from './constants/table-column-options';
import { LoggingService } from '@services/logging.service';
import { VocabularyService } from 'src/app/vocabularies/vocabulary.service';
import { SaveChangesService } from '@services/save-changes.service';
import { PartialJobMaterial, PartialMaterial } from '../../models/partial-material';
import { JobMaterialExtended } from '../../models/extended-job-material';
import { DataContextService } from '@services/data-context.service';
import { toPartialDTO } from '../../util/to-partial-dto';

@Component({
    selector: 'job-pharma-samples-individual-table',
    templateUrl: './job-pharma-samples-individual-table.component.html',
    styles: [`
        .ui-draggable-dragging {
            padding: 4px;
            border-radius: 2px;
            font-size: 12px;
            margin-left: 20px;
        }`
    ]
})
export class JobPharmaSamplesIndividualTableComponent implements OnChanges, OnDestroy, OnInit {
    readonly tabset = 'samples';
    readonly tab = 'individual';
    readonly COMPONENT_LOG_TAG = 'job-pharma-samples-individual-table';

    private dragId: number = null;
    private dragTimeoutId: ReturnType<typeof setTimeout>;

    @Input() readonly: boolean;
    @Input() job: Entity<Job>;
    @Input() activeFields: string[] = [];
    @Output() selectedRowsChange: EventEmitter<PartialMaterial[]> = new EventEmitter();

    loading = false;
    loadingMessage = "Loading";
    page = 1;
    sampleJobMaterials: JobMaterialExtended[] = [];
    sampleCount: number;
    columnSelect: ColumnSelect = {
        model: [],
        labels: [],
    };
    visibleColumns: { [key: string]: boolean } = {...DEFAULT_VISIBLE_COLUMNS};
    allRowsSelected = false;
    
    subs = new Subscription();
    shouldRefreshData: boolean;
    selectedRows: Map<number, (PartialMaterial & PartialJobMaterial)> = new Map();
    
    // Used to keep track of unselections while select all request (/api/jobdata/Sample) is in transit
    trackedUnselections: Set<number> = new Set();
    // Used to determine if data is loaded in after hitting select all button
    loadingMaterialDataPromise?: Promise<void>;
    // Used to keep track of select all button clicks while select all request (/api/jobdata/Sample) is in transit
    applyAllSelected = false;
    // Used to keep track of sample count minus any pending deletions
    trueSampleCount: number;

    
    constructor(
        private jobPharmaDetailService: JobPharmaDetailService,
        private jobPharmaCoreService: JobPharmaCoreService,
        private loggingService: LoggingService,
        private vocabularyService: VocabularyService,
        private saveChangesService: SaveChangesService,
        private dataContext: DataContextService
    ) { }

    async ngOnInit() {
        this.initColumnSelect();
        this.initTabActions();
        this.initChangeDetection();
        await this.initSampleCVs();
        await this.initSampleData();
        this.clearSampleSelections(true);
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.job && !changes.job.firstChange) {
            await this.initSampleData();
            this.clearSampleSelections(true);
        }
    }

    ngOnDestroy() {
        this.clearSampleSelections(true);
        this.subs.unsubscribe();
    }

    /**
     * Watch for external changes
     */
    initChangeDetection() {
        this.subs.add(this.jobPharmaDetailService.jobMaterialsChanged$.subscribe(async () => {
            await this.initSampleData();
            this.clearSampleSelections(true);
        }));

        this.subs.add(this.saveChangesService.saveSuccessful$.subscribe(async () => {
            if (this.shouldRefreshData){
                await this.initSampleData();
                this.shouldRefreshData = false;

                this.handleUIUpdatesIfLoadingMaterialData();
                if (this.loadingMaterialDataPromise) {
                    await this.loadingMaterialDataPromise;
                }
                this.updateSelections();
            }
            this.jobPharmaCoreService.cleanupIgnoredSamplesOnJob(this.job);
            this.trueSampleCount = await this.jobPharmaCoreService.getSampleCountMinusPendingDeletions(this.job);
        }));

        this.subs.add(this.dataContext.onRejectEntityChange$.subscribe(async (entity: Entity<unknown>) => {
            if (entity.entityType.shortName !== 'JobMaterial') {
                return;
            }

            const jobMaterial = entity as unknown as Entity<JobMaterial>;
            this.isSelectedChanged(jobMaterial);
        }));
    }
    // TODO: Move this vocab data access somewhere else
    initSampleCVs() {
        return Promise.all([
            this.vocabularyService.ensureCVLoaded('cv_TimeUnits'),
            this.vocabularyService.ensureCVLoaded('cv_SampleTypes'),
            this.vocabularyService.ensureCVLoaded('cv_SampleStatuses'),
            this.vocabularyService.ensureCVLoaded('cv_PreservationMethods'),
            this.vocabularyService.ensureCVLoaded('cv_ContainerTypes'),
            this.vocabularyService.ensureCVLoaded('cv_SampleSubtypes'),
            this.vocabularyService.ensureCVLoaded('cv_SampleProcessingMethods'),
            this.vocabularyService.ensureCVLoaded('cv_SampleAnalysisMethods'),
        ]);
    }

    initColumnSelect(){
        this.columnSelect.labels = [
            new ColumnSelectLabel('name', 'Name'),
            new ColumnSelectLabel('type', 'Type'),
            this.activeFields.includes("TimePoint") ? new ColumnSelectLabel('timePoint', 'Time Point') : null,
            new ColumnSelectLabel('status', 'Status'),
            this.activeFields.includes("DateHarvest") ? new ColumnSelectLabel('harvestDate', 'Harvest Date') : null,
            this.activeFields.includes("DateExpiration") ? new ColumnSelectLabel('expirationDate', 'Expiration Date') : null,
            this.activeFields.includes("C_PreservationMethod_key") ? new ColumnSelectLabel('preservation', 'Preservation') : null,
            this.activeFields.includes("Material.C_ContainerType_key") ? new ColumnSelectLabel('container', 'Container') : null,
            this.activeFields.includes("Material.MaterialSourceMaterial") ? new ColumnSelectLabel('source', 'Source') : null,
            this.activeFields.includes("Location") ? new ColumnSelectLabel('location', 'Location') : null,
            this.activeFields.includes("C_SampleSubtype_key") ? new ColumnSelectLabel('subtype', 'Subtype') : null,
            this.activeFields.includes("C_SampleProcessingMethod_key") ? new ColumnSelectLabel('processing', 'Processing') : null,
            this.activeFields.includes("SendTo") ? new ColumnSelectLabel('sendTo', 'Send To') : null,
            this.activeFields.includes("C_SampleAnalysisMethod_key") ? new ColumnSelectLabel('analysis', 'Analysis') : null,
            this.activeFields.includes("SpecialInstructions") ? new ColumnSelectLabel('specialInstructions', 'Special Instructions') : null
        ];

        this.columnSelect.labels = this.columnSelect.labels
            .filter((label: ColumnSelectLabel) => label !== null);

        this.columnSelect.model = this.columnSelect.labels
            .filter((item) => this.visibleColumns[item.key])
            .map((item) => item.key);

        this.subs.add(
            this.jobPharmaDetailService.registerColumnSelect(
                this.tabset, 
                this.tab, 
                this.columnSelect,
                () => { this.updateVisibleColumns(); }
            )
        );

        this.updateVisibleColumns();
    }

    updateVisibleColumns() {
        // Make a lookup table
        const selected: {[key: string]: boolean} = {};
        this.columnSelect.model.forEach((key) => {
            selected[key] = true;
        });

        // Update the visibilty based on the column selections
        this.columnSelect.labels.forEach((column) => {
            const key = column.key;
            this.visibleColumns[key] = (selected[key] === true);
        });
    }

    /**
     * Watch for event between the tabs
     */
    initTabActions() {
        // Listen for calls to refresh view
        this.subs.add(
            this.jobPharmaDetailService.tabRefresh$.subscribe(async (event) => {
                if (event.tabset === 'samples' && event.tab === 'individual') {
                    await this.initSampleData();
                    this.clearSampleSelections(true);
                }
            })
        );
    }

    private async initSampleData(): Promise<void> {
        this.loading = true;
        try {
            this.sampleCount = await this.jobPharmaCoreService.getSampleCount(this.job);
            this.trueSampleCount = await this.jobPharmaCoreService.getSampleCountMinusPendingDeletions(this.job);
            this.sampleJobMaterials = await this.jobPharmaCoreService.getSampleJobMaterials(this.job.C_Job_key, this.page);
        } catch (err) {
            this.loggingService.logError("Error initializing sample data", err, this.COMPONENT_LOG_TAG, true);
        } finally {
            this.loading = false;
        }
    }

    private async clearSampleSelections(reload?: boolean) {  
        this.selectedRows.clear();
        this.updateSelections();  
        await this.selectionChanged();

        if (reload) {
            this.jobPharmaCoreService.cleanupIgnoredSamplesOnJob(this.job);
        }
    }

    allSelectedChanged() {
        /**
         * Clear tracked unselections when select all button is clicked.
         * This is responsible for keeping tracking of user unselecting rows
         * while get all samples request is in transit so when data is loaded in,
         * we know to unselect some of them.
         */
        this.trackedUnselections.clear();

        if (this.allRowsSelected) {
            // Select all
            this.applyAllSelected = true;
            
            // Update current page of non-deleted sample job materials for UI purposes
            this.sampleJobMaterials.forEach((jm) => {
                if (!(<Entity<JobMaterial>>jm).entityAspect?.entityState?.isDeleted()) {
                    jm.isSelected = true;
                }
            });

            if (this.loadingMaterialDataPromise) {
                // Do not make additional calls to get all samples if request is in transit
                return;
            }

            this.loadingMaterialDataPromise = this.handleGetAllSampleMaterialsOnJob();
        } else {
            // Unselect all
            if (this.loadingMaterialDataPromise) {
                // Keeps track of select all action while isLoadingMaterialData is still not resolved
                this.applyAllSelected = false;
            }

            this.clearSampleSelections();
        }
    }

    private async handleGetAllSampleMaterialsOnJob() {
        try {
            const jobMaterials = await this.jobPharmaCoreService.getAllSampleJobMaterialsOnJob(this.job);
            if (!this.applyAllSelected) {
                // Do nothing if user unselects all while request is in transit
                return;
            }

            this.selectedRows.clear();
            // Add materials to selected rows only for rows that were not unselected during get all samples request
            jobMaterials.forEach(jm => !this.trackedUnselections.has(jm.C_JobMaterial_key) && this.selectedRows.set(jm.C_JobMaterial_key, jm));
        } catch(err) {
            this.loggingService.logError("Something went wrong getting all samples on job", err, this.COMPONENT_LOG_TAG, false);
            this.sampleJobMaterials.forEach((jm) => this.selectedRows.set(jm.C_JobMaterial_key, toPartialDTO(jm)));
        } finally {
            this.loadingMaterialDataPromise = null;
            this.trackedUnselections.clear();
            this.updateSelections();
            this.selectionChanged();
        }
    }

    isSelectedChanged(jobMaterial: JobMaterialExtended) {
        if (jobMaterial.isSelected) {
            this.selectedRows.set(jobMaterial.C_JobMaterial_key, toPartialDTO(jobMaterial));

            if (this.loadingMaterialDataPromise) {
                this.trackedUnselections.delete(jobMaterial.C_JobMaterial_key);
            }
        } else {
            this.selectedRows.delete(jobMaterial.C_JobMaterial_key);

            if (this.loadingMaterialDataPromise) {
                this.trackedUnselections.add(jobMaterial.C_JobMaterial_key);
            }
        }


        if (this.loadingMaterialDataPromise) {
            // Updates all rows selected while get all samples request is in transit for UI purposes
            if ((this.allRowsSelected && this.trackedUnselections.size) || !this.applyAllSelected) {
                this.allRowsSelected = false;
            } else if (!this.allRowsSelected && !this.trackedUnselections.size) {
                this.allRowsSelected = true;
            }
        }

        this.selectionChanged();
    }

    async selectionChanged() {
        this.trueSampleCount = await this.jobPharmaCoreService.getSampleCountMinusPendingDeletions(this.job);

        if (this.loadingMaterialDataPromise) {
            await this.loadingMaterialDataPromise;
        }

        const rows = this.getSelectedRows();
        if (rows.length === this.trueSampleCount && (this.sampleJobMaterials.filter((jm: Entity<JobMaterial>) => !jm.entityAspect.entityState.isDeleted()).length > 0 || this.applyAllSelected)) {
            this.allRowsSelected = true;
        } else {
            this.allRowsSelected = false;
        }
        
        this.selectedRowsChange.emit(rows);
    }

    getSelectedRows() {
        const ignoredSamplesOnJob = this.jobPharmaCoreService.getIgnoredSampleJobMaterialKeysOnJob(this.job, true);
        return Array.from(this.selectedRows.values()).filter(jm => !ignoredSamplesOnJob.has(jm.C_JobMaterial_key));
    }

    updateSelections() {
        this.sampleJobMaterials.forEach((jm) => jm.isSelected = this.selectedRows.has(jm.C_JobMaterial_key));
    }

    handleUIUpdatesIfLoadingMaterialData() {
        if (this.loadingMaterialDataPromise) {
            // Need to track if user clicks select all/ unselect all while promise is still unresolved
            this.sampleJobMaterials.forEach(jm => {
                if (this.applyAllSelected && !this.trackedUnselections.has(jm.C_JobMaterial_key)) {
                    jm.isSelected = true;
                }
            });
        }
    }

    async onDropSamples(): Promise<void> {
        this.loading = true;
        try {
            await this.jobPharmaCoreService.onDropSamples(this.job);
        } catch (err) {
            this.loggingService.logError("Error dropping sample", err, this.COMPONENT_LOG_TAG, true);
        } finally {
            this.loading = false;
        }
    }

    async onPasteSamples(): Promise<void> {
        this.loading = true;
        try {
            await this.jobPharmaCoreService.onPasteSamples(this.job);
        } catch (err) {
            this.loggingService.logError("Error pasting sample", err, this.COMPONENT_LOG_TAG, true);
        } finally {
            this.loading = false
        }
        
    }

    async removeSampleJobMaterial(jobMaterial: JobMaterial): Promise<void> {
        this.loading = true;
        try {
            await this.jobPharmaCoreService.removeSampleJobMaterial(jobMaterial);
            this.selectionChanged();
            this.shouldRefreshData = true;
        } catch (err) { 
            this.loggingService.logError("Error removing sample", err, this.COMPONENT_LOG_TAG, true);
        } finally {
            this.loading = false;
        }
    }

    async dragStart() {
        if (this.dragTimeoutId) {
            clearTimeout(this.dragTimeoutId);
            this.dragTimeoutId = null;
        }

        this.dragId = this.jobPharmaDetailService.startDragAsync('Sample', async () => {
            if (this.loadingMaterialDataPromise) {
                await this.loadingMaterialDataPromise;
            }

            return this.getSelectedRows();
        });

        let count = 0;
        if (this.loadingMaterialDataPromise && this.applyAllSelected) {
            count = this.trueSampleCount - this.trackedUnselections.size;
        } else {
            count = this.getSelectedRows().length;
        }

        if (count > 0) {
            jQuery('.ui-draggable-dragging')[0].textContent = `${count} Samples Selected`;
        }
    }

    dragStop() {
        this.dragTimeoutId = setTimeout(() => {
            this.jobPharmaDetailService.stopDrag(this.dragId);
            this.dragTimeoutId = null;
        }, 500);
    }

    async changePage(newPage: number) {
        this.loading = true;
        try {
            this.sampleJobMaterials = await this.jobPharmaCoreService.getSampleJobMaterials(this.job.C_Job_key, newPage);
            this.page = newPage;

            this.handleUIUpdatesIfLoadingMaterialData();
            if (this.loadingMaterialDataPromise) {
                await this.loadingMaterialDataPromise;
            }
            this.updateSelections();
        } catch (err) {
            this.loggingService.logError("Error changing pages", err, this.COMPONENT_LOG_TAG, true);
        } finally {
            this.loading = false;
        }
        
    }
}
