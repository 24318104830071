import {
    CellFormatterService,
    TableOptions,
    FormatterOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditUserDetailsTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
        private isGLP: boolean
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'user-details-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortable: false,
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'User Name',
                    field: 'UserName',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'UserName', 'UserName');
                    },
                    cellClass: AuditClassFactory.createCellClass('username'),                },
                {
                    displayName: 'Role',
                    field: 'Role',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'Role', 'Role');
                    },
                    cellClass: AuditClassFactory.createCellClass('role'),                },
                {
                    displayName: 'Is ' + this.translationService.translate(this.isGLP ? 'Job' : 'Study') + ' Administrator',
                    field: 'IsStudyAdministrator',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'IsStudyAdministrator', 'IsStudyAdministrator');
                    },
                    cellClass: AuditClassFactory.createCellClass('isstudyadministrator'),
                },
                {
                    displayName: 'Is Order Administrator',
                    field: 'IsOrderAdministrator',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'IsOrderAdministrator', 'IsOrderAdministrator');
                    },
                    cellClass: AuditClassFactory.createCellClass('isorderadministrator'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}
