import { APIQueryDef } from '@services/api-query-def';
import { WebApiService } from '@services/web-api.service';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
    QueryResult,
} from 'breeze-client';
import { DATA_TYPES_INHERITED, DATA_TYPES_NUMERIC, DataType } from '../../data-type/data-type.type';

import {
    notEmpty,
    softCompare,
    uniqueArrayFromPropertyPath,
    getSafeProp,
    formatDecimal,
} from '@common/util';
import {
    getDateRangePredicates,
    getBooleanPredicate,
} from '@services/queries';
import { DataManagerService } from '@services/data-manager.service';
import { QueryDef } from '@services/query-def';
import { BaseEntityService } from '@services/base-entity.service';
import { VocabularyService } from '../../vocabularies/vocabulary.service';

import { calculateExpressionResult } from '../../tasks/calculated-output';

import { Subject } from 'rxjs';
import { Entity, Output, TaskOutput, WorkflowTask } from "@common/types";
import { TaskType } from 'src/app/tasks/models';

export class TaskStatusChangedEventArgs {
    public taskKeys: number[];
    public taskTypes: string[];
    public taskStatusKey?: number;
}

@Injectable()
export class WorkflowService extends BaseEntityService {

    private workflowImport = new Subject<void>();
    private animalsSync = new Subject<any>();
    private syncOutputValues = new Subject();
    private syncTaskStatusChange = new Subject<TaskStatusChangedEventArgs>();
    private clinicalSync = new Subject<any>();
    public animalSyncEnabled: boolean;
    public refreshWorkflowFacet = new Subject<any>();

    workflowImport$ = this.workflowImport.asObservable();
    animalsSync$ = this.animalsSync.asObservable();
    syncOutputValues$ = this.syncOutputValues.asObservable();
    clinicalSync$ = this.clinicalSync.asObservable();
    syncTaskStateChange$ = this.syncTaskStatusChange.asObservable();

    constructor(
        private dataManager: DataManagerService,
        private vocabularyService: VocabularyService,
        private webApiService: WebApiService,
    ) {
        super();
    }

    workflowImportCompleted() {
        this.workflowImport.next();
    }

    getTasks(queryDef: QueryDef, visibleColumns?: string[]): Promise<QueryResult> {
        let query = this.buildDefaultQuery('TaskInstances', queryDef);

        if (notEmpty(queryDef.expands)) {
            query = query.expand(queryDef.expands.join(','));
        }

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getAPITasks(queryDef: APIQueryDef): Promise<any> {
        const paramsObject = { ... queryDef };
        
        let params: HttpParams = this.webApiService.buildURLSearchParams(paramsObject);

        // Flatten the filter for the API Controller
        params = this.webApiService.flattenParam(params, 'filter');

        const requestUrl = 'api/workflowtaskdata/taskinstances';
        return this.webApiService.callApi(requestUrl, params).then((response) => {
            return response.data;
        });
    }
    
    getTaskInstance(taskInstanceKey: number): Promise<any> {
        const expands = [
            'WorkflowTask',
            'WorkflowTask.cv_TaskType'
        ];
        const query = EntityQuery.from('TaskInstances')
            .where('C_TaskInstance_key', '==', taskInstanceKey)
            .expand(expands.join(','));
        return this.dataManager.returnSingleQueryResult(query);
    }

    getTaskInstances(taskInstanceKeys: number[]): Promise<any[]> {
        const query = EntityQuery.from('TaskInstances')
            .where('C_TaskInstance_key', 'in', taskInstanceKeys)
            .expand('TaskJob.Job');

        return this.dataManager.returnQueryResults(query);
    }

    buildPredicates(filter: any): Predicate[] {
        let predicates: Predicate[] = [];

        if (!filter) {
            return predicates;
        }

        if (filter.JobID) {
            predicates.push(
                Predicate.create('TaskJob', FilterQueryOp.Any, 'Job.JobID', FilterQueryOp.Contains, { value: filter.JobID })
            );
        }
        if (notEmpty(filter.jobs)) {
            const jobKeys = filter.jobs.map((job: any) => {
                return job.JobKey;
            });

            predicates.push(Predicate.create(
                'TaskJob', 'any',
                'C_Job_key', 'in', jobKeys
            ));
        }
        if (filter.C_WorkflowTask_key) {
            predicates.push(
                Predicate.create('C_WorkflowTask_key', 'eq', filter.C_WorkflowTask_key)
            );
        }
        if (notEmpty(filter.C_WorkflowTask_keys)) {
            predicates.push(
                Predicate.create('C_WorkflowTask_key', 'in', filter.C_WorkflowTask_keys)
            );
        }
        if (filter.C_TaskStatus_key) {
            predicates.push(
                Predicate.create('C_TaskStatus_key', 'eq', filter.C_TaskStatus_key)
            );
        }
        if (notEmpty(filter.C_TaskStatus_keys)) {
            predicates.push(
                Predicate.create('C_TaskStatus_key', 'in', filter.C_TaskStatus_keys)
            );
        }
        if (notEmpty(filter.C_JobStatus_keys)) {
            predicates.push(Predicate.create(
                'TaskJob', 'any',
                'Job.C_JobStatus_key', 'in', filter.C_JobStatus_keys
            ));
        }
        if (filter.C_TaskType_key) {
            predicates.push(
                Predicate.create('WorkflowTask.C_TaskType_key', 'eq', filter.C_TaskType_key)
            );
        }
        if (notEmpty(filter.C_TaskType_keys)) {
            predicates.push(
                Predicate.create('WorkflowTask.C_TaskType_key', 'in', filter.C_TaskType_keys)
            );
        }
        if (notEmpty(filter.JobCreatedBy)) {
            predicates.push(Predicate.create(
                'TaskJob', 'any', 'Job.CreatedBy', 'eq', filter.JobCreatedBy
            ));
        }
        if (filter.C_Resource_key) {
            predicates.push(
                Predicate.create('C_AssignedTo_key', 'eq', filter.C_Resource_key)
            );
        }
        if (notEmpty(filter.C_Resource_keys)) {
            predicates.push(
                Predicate.or([
                    Predicate.create('C_AssignedTo_key', 'in', filter.C_Resource_keys),
                    Predicate.create(
                        'AssignedToResource.ParentResourceGroupMember', 'any', 
                        'C_Resource_key', 'in', filter.C_Resource_keys
                    )
                ])
            );
        }
        if (notEmpty(filter.C_ResourceGroup_keys)) {
            predicates.push(
                Predicate.create('AssignedToResource.C_ResourceGroup_key', 'in', filter.C_ResourceGroup_keys)
            );
        }
        if (filter.BirthID) {
            predicates.push(
                Predicate.create(
                    'TaskBirth', FilterQueryOp.Any,
                    'Birth.BirthID', FilterQueryOp.Contains, { value: filter.BirthID },
                )
            );
        }
        if (filter.MatingID) {
            predicates.push(
                Predicate.create(
                    'TaskMaterialPool', FilterQueryOp.Any,
                    'MaterialPool.Mating.MatingID', FilterQueryOp.Contains, { value: filter.MatingID },
                )
            );
        }

        if (filter.DateDueStart || filter.DateDueEnd) {
            const datePredicates: Predicate[] = getDateRangePredicates(
                'DateDue',
                filter.DateDueStart,
                filter.DateDueEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (filter.IsLocked) {
            const isLockedPredicate: Predicate = getBooleanPredicate(filter.IsLocked, 'IsLocked');
            predicates.push(isLockedPredicate);
        }

        if (filter.MaterialLocation) {
            predicates.push(Predicate.create(
                'TaskMaterial', FilterQueryOp.Any,
                'Material.CurrentLocationPath', FilterQueryOp.Contains, { value: filter.MaterialLocation },
            ));
        }

        if (filter.IsNotMemberTask === true) {
            predicates.push(Predicate.create('C_GroupTaskInstance_key', 'eq', null));
        }

        if (filter.TaskLocation) {
            predicates.push(Predicate.create(
                'CurrentLocationPath', FilterQueryOp.Contains, { value: filter.TaskLocation },
            ));
        }

        if (filter.Animals && filter.Animals.length) {
            const animalKeys = filter.Animals.map((animal: any) => animal.C_Material_key);
            const orPredicate = Predicate.or([
                Predicate.create(
                    'TaskMaterial', 'any', 'C_Material_key', 'in', animalKeys
                ),
                Predicate.create(
                    'TaskAnimalHealthRecord', 'any',
                    'C_Material_key', 'in', animalKeys
                )
            ]);
            predicates.push(orPredicate);
        }

        if (filter.C_AnimalStatus_keys && filter.C_AnimalStatus_keys.length > 0) {
            predicates.push(
                Predicate.create('Animal.C_AnimalStatus_key', 'in', filter.C_AnimalStatus_keys)
            );
        }

        if (notEmpty(filter.Lines)) {
            const lineKeys = filter.Lines.map((line: any) => {
                return line.LineKey;
            });

            predicates.push(Predicate.create(
                'TaskTopLine.C_Line_key', 'in', lineKeys
            ));
        }

        if (notEmpty(filter.MaterialPools)) {
            const materialPoolKeys = filter.MaterialPools.map((pool: any) => {
                return pool.C_MaterialPool_key;
            });
            predicates.push(Predicate.create(
                'TaskTopHousing.C_MaterialPool_key', 'in', materialPoolKeys
            ));
        }

        // handle workspace filters
        if ('job-filter' in filter) {
            predicates.push(Predicate.create(
                'TaskJob', 'any',
                'Job.C_Job_key', 'in', filter['job-filter']
            ));
        }

        return predicates;
    }

    ensureTaskRelationshipsExpanded(tasks: any[]): Promise<any> {
        const expands = [
            'TaskBirth.Birth',
            'TaskJob.Job',
            'TaskLine.Line',
            'TaskMaterialPool.MaterialPool.Mating',
            'TaskMaterial.Material.Animal',
            'TaskAnimalHealthRecord.AnimalHealthRecord',
            'TaskCohort'
        ];
        return this.dataManager.ensureRelationships(tasks, expands);
    }

    ensureGroupTaskChildData(tasks: any[]): Promise<any> {
        const expands = [
            'MemberTaskInstance.TaskMaterial.Material.Animal'
        ];
        return this.dataManager.ensureRelationships(tasks, expands);
    }

    async ensureRelationshipsLoaded(items: any, expands: string[]): Promise<any> {
        await this.dataManager.ensureRelationships(items, expands);
    }

    async ensureVisibleColumnsDataLoaded(items: any[], visibleColumns: string[]): Promise<void> {
        const expands = this.generateExpandsFromVisibleColumns(items[0], visibleColumns);
        return this.dataManager.ensureRelationships(items, expands);
    }

    getWorkflowTaskList(preferLocal?: boolean): Promise<any[]> {
        const query = EntityQuery.from('WorkflowTasks')
            .where('IsHidden', '!=', 'true')
            .orderBy('TaskName');

        return this.dataManager.returnQueryResults(query, preferLocal);
    }

    getWorkflowTaskListForOutputPools(preferLocal?: boolean): Promise<Entity<WorkflowTask>[]> {
        // not hidden
        const hidden = Predicate.create('IsHidden', '!=', 'true')
        // not No Materials
        const noMaterials = Predicate.create('NoMaterials', '!=', 'true')
        // task type only animal or job
        const taskType = Predicate.create('cv_TaskType.TaskType', 'in', [TaskType.Animal, TaskType.Job]);
        // task has numeric outputs
        const numeric = Predicate.create('Output', 'any', 'cv_DataType.DataType', 'in', DATA_TYPES_NUMERIC);
        // task has inherited from numeric outputs
        const inhFromNumOutputs = Predicate.and(
            Predicate.create('Output', 'any', 'cv_DataType.DataType', 'in', DATA_TYPES_INHERITED),
            Predicate.create('Output', 'any', 'InheritedFromOutput.cv_DataType.DataType', 'in', DATA_TYPES_NUMERIC),
        );

        const predicate = Predicate.and(
            hidden,
            noMaterials,
            taskType,
            Predicate.or(numeric, inhFromNumOutputs),
        );

        const query = EntityQuery.from('WorkflowTasks')
            .where(predicate)
            .orderBy('TaskName');

        return this.dataManager.returnQueryResults(query, preferLocal);
    }

    getNumericOutputs(preferLocal?: boolean): Promise<Entity<Output>[]> {
        // only active outputs
        const active = Predicate.create('IsActive', '==', true);
        // only numeric outputs
        const numeric = Predicate.create('cv_DataType.DataType', 'in', DATA_TYPES_NUMERIC);
        // task has inherited from numeric outputs
        const inhFromNumOutputs = Predicate.and(
          Predicate.create('cv_DataType.DataType', 'in', DATA_TYPES_INHERITED),
          Predicate.create('InheritedFromOutput.cv_DataType.DataType', 'in', DATA_TYPES_NUMERIC),
        );

        const predicate = Predicate.and(
          active,
          Predicate.or(numeric, inhFromNumOutputs),
        )
        const query = EntityQuery.from('Outputs')
            .where(predicate)
            .orderBy('OutputName');

        return this.dataManager.returnQueryResults(query, preferLocal);
    }

    getWorkflowTaskListByType(type: string, preferLocal?: boolean): Promise<any[]> {
        const predicate = new Predicate('cv_TaskType.TaskType', 'eq', type)
                                 .and('IsHidden', '!=', 'true');
        const query = EntityQuery.from('WorkflowTasks')
            .where(predicate)
            .orderBy('TaskName');

        return this.dataManager.returnQueryResults(query, preferLocal);
    }

    getCohortsNamesList(preferLocal?: boolean): Promise<any[]> {
        const query = EntityQuery.from('Cohorts')
            .orderBy('CohortName');

        return this.dataManager.returnQueryResults(query, preferLocal);
    }
  
    getInputs(taskKey: number): Promise<any[]> {
        const query = EntityQuery.from('TaskInputs')
            .expand('Input.cv_DataType')
            .orderBy('Input.SortOrder')
            .where('C_TaskInstance_key', '==', taskKey);

        return this.dataManager.returnQueryResults(query);
    }

    getWorkflowTaskOutputs(workflowTaskKey: number): Promise<any[]> {
        const query = EntityQuery.from('Outputs')
            .where('C_WorkflowTask_key', '==', workflowTaskKey)
            .orderBy('SortOrder');

        return this.dataManager.getQueryResults(query);
    }

    getReferenceSetTaskOutputs(taskOutputSetKey: number): Promise<any[]> {

        const query = EntityQuery.from('Outputs')
            .expand(this._getOutputExpands().join(','))
            .where('TaskOutput', 'any', 'C_TaskOutputSet_key', 'eq', taskOutputSetKey)
            .orderBy('SortOrder');

        return this.dataManager.getQueryResults(query);
    }

    getActiveWorkflowTaskOutputs(workflowTaskKey: number): Promise<any[]> {
        const query = EntityQuery.from('Outputs')
            .expand(this._getOutputExpands().join(','))
            .where('C_WorkflowTask_key', '==', workflowTaskKey)
            .orderBy('SortOrder')
            .where('IsActive', '==', true);

        return this.dataManager.getQueryResults(query);
    }

    private _getOutputExpands(): string[] {
        return [
            'InheritedFromOutput',
            'CalculatedOutputExpression.ExpressionInputMapping',
            'CalculatedOutputExpression.ExpressionOutputMapping'
        ];
    }

    getOutputSets(taskKey: number): Promise<any[]> {
        const expandClauses = [
            'TaskOutput.Output',
            'TaskOutput.Output.OutputFlag',
            'TaskOutputSetMaterial.Material.Animal'
        ];

        const query = EntityQuery.from('TaskOutputSets')
            .expand(expandClauses.join(', '))
            .orderBy('CollectionDateTime')
            .where('C_TaskInstance_key', '==', taskKey);
        return this.dataManager.getQueryResults(query);
    }

    ensureMaterialPoolsLoaded(materials: any): Promise<void> {
        return this.dataManager.ensureRelationships(materials, 
            ['MaterialPoolMaterial.MaterialPool']
        );
    }

    ensureBulkEditAssociatedDataLoaded(tasks: any[]): Promise<void> {
        const expands = [
            'TaskMaterial.Material.Sample',
            'TaskMaterial.Material.MaterialSourceMaterial'
        ];
        return this.dataManager.ensureRelationships(tasks, expands);
    }

    /**
     * Recalculate any calculated expressions for the given task Inputs
     *   and taskOutputSet Outputs
     * @param task 
     * @param taskOutputSet 
     */
    recalculateValues(task: any, taskOutputSet: any) {
        const taskOutputs: any[] = taskOutputSet.TaskOutput;
        const calculatedTaskOutputs = taskOutputs.filter((taskOutput) => {
            return taskOutput.Output.cv_DataType.DataType === DataType.CALCULATED;
        });
        let numericTaskOutputs = taskOutputs.filter((taskOutput) => {
            return taskOutput.Output.cv_DataType.DataType === DataType.NUMBER || 
            taskOutput.Output.cv_DataType.DataType === DataType.CALCULATED ||
            taskOutput.Output.cv_DataType.DataType === DataType.DOSING_TABLE;
        });
        const inheritedTaskOutputs = taskOutputs.filter((taskOutput) => {
            return taskOutput.Output.cv_DataType.DataType === DataType.INHERITED_MOST_RECENT ||
                taskOutput.Output.cv_DataType.DataType === DataType.INHERITED_FIRST_OCCURRENCE ||
                taskOutput.Output.cv_DataType.DataType === DataType.INHERITED_SECOND_MOST_RECENT ||
                taskOutput.Output.cv_DataType.DataType === DataType.INHERITED_THIRD_MOST_RECENT;
        });
        if (inheritedTaskOutputs && inheritedTaskOutputs.length) {
            const inheritedNumeric = inheritedTaskOutputs.filter((taskOutput) => {
                const inheritedDataType = getSafeProp(
                    taskOutput, 'Output.InheritedFromOutput.cv_DataType.DataType'
                );
                return inheritedDataType === DataType.NUMBER || 
                inheritedDataType === DataType.CALCULATED ||
                inheritedDataType === DataType.DOSING_TABLE;
            });
            numericTaskOutputs = numericTaskOutputs.concat(inheritedNumeric);
        }

        const taskInputs: any[] = task.TaskInput;
        const numericTaskInputs = taskInputs.filter((taskInput) => {
            return taskInput.Input.cv_DataType.DataType === DataType.NUMBER || taskInput.Input.cv_DataType.DataType === DataType.DOSING_TABLE;
        });

        for (const calculatedTaskOutput of calculatedTaskOutputs) {
            const output = calculatedTaskOutput.Output;
            const calcOutExpressionArr = output.CalculatedOutputExpression;
            if (calcOutExpressionArr && calcOutExpressionArr.length) {
                const calcOutExpression = calcOutExpressionArr[0];
                const inputMappings = calcOutExpression.ExpressionInputMapping;
                const outputMappings = calcOutExpression.ExpressionOutputMapping;
                const expression = JSON.parse(calcOutExpression.OutputExpression);
                const calculatedValue = calculateExpressionResult(
                    expression,
                    inputMappings,
                    numericTaskInputs,
                    outputMappings,
                    numericTaskOutputs
                );
                const formattedValue = formatDecimal(calculatedValue, output.DecimalPlaces);
                // Newly created task outputs have output value as NULL.
                // If the calculated value is NaN, the formatted value is an empty string.
                // This prevents NULL values from being replaced by empty string values.
                if (formattedValue !== (calculatedTaskOutput.OutputValue ?? '')) {
                    calculatedTaskOutput.OutputValue = formattedValue;

                    if (this.animalSyncEnabled) {
                        this.syncOutput(calculatedTaskOutput);
                    }
                }
            }
        }
    }

    getTaskMaterials(taskInstanceKey: number): Promise<any[]> {
        const predicate = new Predicate('C_TaskInstance_key', '==', taskInstanceKey);

        const expandClauses = [
            'Material.Animal.cv_AnimalStatus',
            'Material.Animal.cv_Sex',
            'Material.Line',
            'Material.Sample',
            'Material.MaterialSourceMaterial.SourceMaterial.Animal',
            'Material.MaterialSourceMaterial.SourceMaterial.Sample',
            'Material.MaterialExternalSync'
        ];

        const query = EntityQuery.from('TaskMaterials')
            .orderBy('Material.Identifier')
            .where(predicate);

        const p1 = this.dataManager.getQueryResults(query);

        const p2 = p1.then((taskMaterials) => {
            return this.dataManager.ensureRelationships(taskMaterials, expandClauses);
        });


        const p3 = Promise.all([
            this.vocabularyService.ensureCVLoaded('cv_MaterialTypes'),
            this.vocabularyService.ensureCVLoaded('cv_Sexes'),
            this.vocabularyService.ensureCVLoaded('cv_SampleTypes'),
            this.vocabularyService.ensureCVLoaded('cv_SampleStatuses')
        ]);

        return Promise.all([p1, p2, p3]).then((responses) => {
            return responses[0];
        });
    }

    getTasksInProtocolInstance(protocolInstanceKey: number): Promise<any[]> {
        const predicate = new Predicate('C_ProtocolInstance_key', '==', protocolInstanceKey);

        const expands = [
            'TaskMaterial',
            'ProtocolInstance.TaskInstance.ProtocolTask.cv_ScheduleType',
            'ProtocolTask.cv_TimeUnit',
            'ProtocolTask.cv_TimeRelation'
        ];
        const query = EntityQuery.from('TaskInstances')
            .expand(expands.join(','))
            .where(predicate);

        return this.dataManager.returnQueryResults(query);
    }

    loadTaskCohorts(taskInstancekey: number): Promise<any[]> {
        const query = EntityQuery.from('TaskCohorts')
            .expand('TaskCohortInput')
            .where('C_TaskInstance_key', '==', taskInstancekey);
        return this.dataManager.returnQueryResults(query);
    }

    loadAllTaskCohorts(taskKeys: any[]): Promise<any[]> {
        if (notEmpty(taskKeys)) {
            const query = EntityQuery.from('TaskCohorts')
                .expand('TaskCohortInput')
                .where('C_TaskInstance_key', 'in', taskKeys);
            return this.dataManager.returnQueryResults(query);
        }
        return Promise.resolve([]);
    }

    createTaskOutputSetMaterial(initialValues: any): any {
        const manager = this.dataManager.getManager();
        const entityType = 'TaskOutputSetMaterial';

        const initialOutputSetKey = initialValues.C_TaskOutputSet_key;
        const initialMaterialKey = initialValues.C_Material_key;

        // Check local entities for duplicates
        const taskOutputSetMaterials: any[] = this.getNonDeletedLocalEntities(manager, entityType);
        const duplicates = taskOutputSetMaterials.filter((taskOutputSetMaterial) => {
            const outputSetKey = taskOutputSetMaterial.C_TaskOutputSet_key;
            const materialKey = taskOutputSetMaterial.C_Material_key;

            return softCompare(outputSetKey, initialOutputSetKey) &&
                softCompare(materialKey, initialMaterialKey);
        });

        // Not a duplicate
        if (duplicates.length === 0) {
            return this.dataManager.createEntity(entityType, initialValues);
        }
        return null;
    }

    createOutputSet(initialValues: any,
                    workflowTaskKey: number,
                    taskInstanceKey: number): Promise<any> {
        const newOutputSet = this.dataManager.createEntity('TaskOutputSet', initialValues);
        return this.getOutputSets(taskInstanceKey).then((data) => {
            let query: any;
            const referenceSet = data[0];
            
            if (data.length > 0) {
                query = EntityQuery.from('Outputs')
                    .where('TaskOutput', 
                           'any', 
                           'C_TaskOutputSet_key',
                           'eq',
                           referenceSet.C_TaskOutputSet_key
                    );
            } else {
                query = EntityQuery.from('Outputs')
                    .where('C_WorkflowTask_key', '==', workflowTaskKey)
                    .where('IsActive', '==', true);
            }
            return query;
        }).then((query) => { 
            return this.dataManager.getQueryResults(query);
        }).then((data: any[]) => {
            data.forEach((output: any) => {
                this.createOutput(output.C_Output_key, newOutputSet.C_TaskOutputSet_key);
            });

            return newOutputSet;
        });
    }

    createOutput(outputKey: number, outputSetKey: number): any {
        const initialValues: Partial<TaskOutput> = {
            C_Output_key: outputKey,
            C_TaskOutputSet_key: outputSetKey,
            DateCreated: new Date(),
            HasFlag: false,
        };

        return this.dataManager.createEntity('TaskOutput', initialValues);
    }

    /**
     * Return last reported OutputValue for this material
     *    with the given Output,
     *    excluding the currentTaskOutputSet
     * @param output 
     * @param material 
     * @param currentTaskOutputSet 
     */
    getMostRecentInheritedOuputValue(
        output: any, 
        material: any, 
        currentTaskOutputSet: any
    ): Promise<any> {
        return this._getFirstOrLastTaskOutput(
            output,
            material,
            currentTaskOutputSet,
            'last'
        );
    }

    /**
     * Return first reported OutputValue for this material
     *    with the given Output,
     *    excluding the currentTaskOutputSet
     * @param output 
     * @param material 
     * @param currentTaskOutputSet 
     */
    getFirstOccurrenceInheritedOutputValue(
        output: any, 
        material: any, 
        currentTaskOutputSet: any
    ): Promise<any> {
        return this._getFirstOrLastTaskOutput(
            output,
            material,
            currentTaskOutputSet,
            'first'
        );
    }

    private _getFirstOrLastTaskOutput(
        output: any, 
        material: any, 
        currentTaskOutputSet: any,
        whichOutput: 'first' | 'last'
    ) {
        const inheritedFromOutputKey = output.Output.C_InheritedFromOutput_key;
        const materialKey = material.C_Material_key;

        const predicates = [
            // Get the same output ...
            new Predicate('C_Output_key', 'eq', inheritedFromOutputKey),
            // for the same material ...
            new Predicate('TaskOutputSet.TaskOutputSetMaterial',
                          'any', 'C_Material_key', 'eq', materialKey),
            // that has actually been collected.
            new Predicate('TaskOutputSet.CollectionDateTime', '!=', null),
        ];

        if (currentTaskOutputSet) {
            // Never select the current TaskOutputSet (i.e. the one the values will be used in)
            predicates.push(
                new Predicate(
                    'TaskOutputSet.C_TaskOutputSet_key', '!=', 
                    currentTaskOutputSet.C_TaskOutputSet_key
                )
            );
            if (currentTaskOutputSet.CollectionDateTime) {
                // Make sure the inherited values are not after the current set was collected
                predicates.push(
                    new Predicate(
                        'TaskOutputSet.CollectionDateTime', '<=', 
                        currentTaskOutputSet.CollectionDateTime
                    )
                );
            }
        }

        let query = EntityQuery.from('TaskOutputs')
            .select('OutputValue, Output.cv_DataType.DataType')
            .expand('Output.cv_DataType')
            .take(1)
            .where(Predicate.and(predicates));

        if (whichOutput === 'first') {
            query = query.orderBy('TaskOutputSet.CollectionDateTime asc');
        } else if (whichOutput === 'last') {
            query = query.orderBy('TaskOutputSet.CollectionDateTime desc');
        }
            
        return this.dataManager.returnSingleQueryResult(query);
    }

    getNthMostRecentInheritedOutputValue(output: any, material: any, currentTaskOutputSet: any, nthMostRecent: number) {
        const inheritedFromOutputKey = output.Output.C_InheritedFromOutput_key;
        const materialKey = material.C_Material_key;

        const predicates = [
            // Get the same output ...
            new Predicate('C_Output_key', 'eq', inheritedFromOutputKey),
            // for the same material ...
            new Predicate('TaskOutputSet.TaskOutputSetMaterial',
                'any', 'C_Material_key', 'eq', materialKey),
            // that has actually been collected.
            new Predicate('TaskOutputSet.CollectionDateTime', '!=', null),
        ];

        if (currentTaskOutputSet) {
            // Never select the current TaskOutputSet (i.e. the one the values will be used in)
            predicates.push(
                new Predicate(
                    'TaskOutputSet.C_TaskOutputSet_key', '!=',
                    currentTaskOutputSet.C_TaskOutputSet_key
                )
            );
            if (currentTaskOutputSet.CollectionDateTime) {
                // Make sure the inherited values are not after the current set was collected
                predicates.push(
                    new Predicate(
                        'TaskOutputSet.CollectionDateTime', '<=',
                        currentTaskOutputSet.CollectionDateTime
                    )
                );
            }
        }

        const query = EntityQuery.from('TaskOutputs')
            .select('OutputValue, Output.cv_DataType.DataType')
            .expand('Output.cv_DataType')
            .where(Predicate.and(predicates))
            .take(nthMostRecent)
            .orderBy("TaskOutputSet.CollectionDateTime desc");

        return this.dataManager.returnQueryResults(query);
    }

    detachTaskOutputSet(taskOutputSet: any) {
        if (taskOutputSet.TaskOutput) {
            while (taskOutputSet.TaskOutput.length > 0) {
                this.dataManager.detachEntity(taskOutputSet.TaskOutput[0]);
            }
        }

        if (taskOutputSet.TaskOutputSetMaterial) {
            while (taskOutputSet.TaskOutputSetMaterial.length > 0) {
                this.dataManager.detachEntity(taskOutputSet.TaskOutputSetMaterial[0]);
            }
        }

        this.dataManager.detachEntity(taskOutputSet);
    }

    deleteTaskOutputSet(taskOutputSet: any) {
        if (taskOutputSet.TaskOutput) {
            while (taskOutputSet.TaskOutput.length > 0) {
                this.deleteTaskOutput(taskOutputSet.TaskOutput[0]);
            }
        }

        if (taskOutputSet.TaskOutputSetMaterial) {
            while (taskOutputSet.TaskOutputSetMaterial.length > 0) {
                this.deleteTaskOutputSetMaterial(taskOutputSet.TaskOutputSetMaterial[0]);
            }
        }

        this.dataManager.deleteEntity(taskOutputSet);
    }

    deleteTaskOutputSetMaterial(taskOutputSetMaterial: any) {
        this.dataManager.deleteEntity(taskOutputSetMaterial);
    }

    deleteTaskOutput(taskOutput: any) {
        this.dataManager.deleteEntity(taskOutput);
    }

    bulkDeleteOutputSets(outputSets: any[]): Promise<any> {
        return this.webApiService.postApi('api/bulkdata/deleteoutputs', {
            outputSetKeys: uniqueArrayFromPropertyPath(outputSets, 'C_TaskOutputSet_key')
        });
    }

   /**
    * Deletes a TaskInstance.
    * @param taskInstance TaskInstance
    */
    deleteTask(taskInstance: any) {
        while (taskInstance.TaskPlaceholder.length > 0) {
            const taskPlaceholder = taskInstance.TaskPlaceholder[0];

            while (taskPlaceholder.TaskPlaceholderInput.length > 0) {
                this.dataManager.deleteEntity(taskPlaceholder.TaskPlaceholderInput[0]);
            }
            this.dataManager.deleteEntity(taskInstance.TaskPlaceholder[0]);
        }

        while (taskInstance.TaskJob.length > 0) {
            this.dataManager.deleteEntity(taskInstance.TaskJob[0]);
        }

        while (taskInstance.TaskBirth.length > 0) {
            this.dataManager.deleteEntity(taskInstance.TaskBirth[0]);
        }

        while (taskInstance.TaskLine.length > 0) {
            this.dataManager.deleteEntity(taskInstance.TaskLine[0]);
        }

        while (taskInstance.TaskMaterialPool.length > 0) {
            this.dataManager.deleteEntity(taskInstance.TaskMaterialPool[0]);
        }

        while (taskInstance.TaskAnimalHealthRecord.length > 0) {
            this.dataManager.deleteEntity(taskInstance.TaskAnimalHealthRecord[0]);
        }

        while (taskInstance.TaskMaterial.length > 0) {
            this.dataManager.deleteEntity(taskInstance.TaskMaterial[0]);
        }

        while (taskInstance.StoredFileMap.length > 0) {
            this.dataManager.deleteEntity(taskInstance.StoredFileMap[0]);
        }

        while (taskInstance.TaskInput.length > 0) {
            this.dataManager.deleteEntity(taskInstance.TaskInput[0]);
        }

        while (taskInstance.Note.length > 0) {
            this.dataManager.deleteEntity(taskInstance.Note[0]);
        }

        while (taskInstance.TaskOutputSet.length > 0) {
            const jobTaskOutputSet = taskInstance.TaskOutputSet[0];

            while (jobTaskOutputSet.TaskOutputSetMaterial.length > 0) {
                this.dataManager.deleteEntity(jobTaskOutputSet.TaskOutputSetMaterial[0]);
            }

            while (jobTaskOutputSet.TaskOutput.length > 0) {
                this.dataManager.deleteEntity(jobTaskOutputSet.TaskOutput[0]);
            }

            this.dataManager.deleteEntity(jobTaskOutputSet);
        }

        if (taskInstance.TaskCohort) {
            while (taskInstance.TaskCohort.length > 0) {
                const taskCohort = taskInstance.TaskCohort[0];

                if (taskCohort.TaskCohortInput) {
                    while (taskCohort.TaskCohortInput.length > 0) {
                        this.dataManager.deleteEntity(taskCohort.TaskCohortInput[0]);
                    }
                }

                this.dataManager.deleteEntity(taskCohort);
            }
        }

        if (taskInstance.SampleGroup) {
            while (taskInstance.SampleGroup.length > 0) {
                this.dataManager.deleteEntity(taskInstance.SampleGroup[0]);
            }
        }

        this.dataManager.deleteEntity(taskInstance);
    }

    cancelTaskInstance(taskInstance: any) {
        if (!taskInstance) {
            return;
        }

        if (taskInstance.C_TaskInstance_key > 0) {
            this._cancelTaskInstanceEdits(taskInstance);
        } else {
            this._cancelNewTaskInstance(taskInstance);
        }
    }

    private _cancelNewTaskInstance(taskInstance: any) {
        try {
            this.deleteTask(taskInstance);
        } catch (error) {
            console.error('Error cancelling new taskInstance: ' + error);
        }
    }

    private _cancelTaskInstanceEdits(taskInstance: any) {
        this.dataManager.rejectEntityAndRelatedPropertyChanges(taskInstance);

        // Animal properties may have been changed as part of task output entry
        if (notEmpty(taskInstance.TaskMaterial)) {
            for (const taskMaterial of taskInstance.TaskMaterial) {
                this.dataManager.rejectChangesToEntityByFilter(
                    'Animal', (item: any) => {
                        return item.C_Material_key === taskMaterial.C_Material_key;
                    }
                );
                this.dataManager.rejectChangesToEntityByFilter(
                    'Material', (item: any) => {
                        return item.C_Material_key === taskMaterial.C_Material_key;
                    }
                );
            }
        }
        this.dataManager.rejectChangesToEntityByFilter(
            'TaskMaterial', (item: any) => {
                return item.C_TaskInstance_key === taskInstance.C_TaskInstance_key;
            }
        );

        if (notEmpty(taskInstance.TaskOutputSet)) {
            for (const outputSet of taskInstance.TaskOutputSet) {
                this.dataManager.rejectChangesToEntityByFilter(
                    'TaskOutputSetMaterial', (item: any) => {
                        return item.C_TaskOutputSet_key === outputSet.C_TaskOutputSet_key;
                    }
                );
                this.dataManager.rejectChangesToEntityByFilter(
                    'TaskOutput', (item: any) => {
                        return item.C_TaskOutputSet_key === outputSet.C_TaskOutputSet_key;
                    }
                );
            }
            // do TaskOutputSets after their children
            this.dataManager.rejectChangesToEntityByFilter(
                'TaskOutputSet', (item: any) => {
                    return item.C_TaskInstance_key === taskInstance.C_TaskInstance_key;
                }
            );
        }

        this.dataManager.rejectChangesToEntityByFilter(
            'Note', (item: any) => {
                return item.C_TaskInstance_key === taskInstance.C_TaskInstance_key;
            }
        );

        const fileMaps = this.dataManager.rejectChangesToEntityByFilter(
            'StoredFileMap', (item: any) => {
                return item.C_TaskInstance_key === taskInstance.C_TaskInstance_key;
            }
        );
        // also reject files associated with each fileMap
        for (const fileMap of fileMaps) {
            this.dataManager.rejectChangesToEntityByFilter(
                'StoredFile', (item: any) => {
                    return item.C_StoredFile_key === fileMap.C_StoredFile_key;
                }
            );
        }
    }

    completeTasks(taskKeys: any[], userName: any, userKey: any, statusKey: any, completedDate: any, dateDueChanged: any,
                  updateOutputSet: any, completeTask = true, updateGroupTasks = false)
        : Promise<any> {
        const params = {
            CompleteByKey: userKey,
            UserName: userName,
            keys: taskKeys,
            StatusKey: statusKey,
            CompleteDate: completedDate,
            DateDueChanged: dateDueChanged,
            UpdateOutputSet: updateOutputSet,
            CompleteTaskInstance: completeTask,
            UpdateGroupTaskInstances: updateGroupTasks
        };

        const requestUrl = 'api/workflowtaskdata/completetasks';
        return this.webApiService.postApi(requestUrl, params).then((response) => {
            return response.data;
        });
    }

    assignResources(taskInstanceKeys: any, resourceKeys: any[]) {
        const params = {
            taskInstanceKeys,
            resourceKeys,
        };

        const requestUrl = 'api/jobpharma/bulkAssignTo';
        return this.webApiService.postApi(requestUrl, params).then((response) => {
            return response.data;
        });
    }

    taskStatusChanged(taskKeys: any[], userName: any, statusKey: any, userKey: any, completedDate: any, dateDueChanged: any, updateOutputSet: any, completeTask: any): Promise<any> {
        const params = {
            CompleteByKey: userKey,
            UserName: userName,
            keys: taskKeys,
            StatusKey: statusKey,
            CompleteDate: completedDate,
            DateDueChanged: dateDueChanged,
            UpdateOutputSet: updateOutputSet,
            CompleteTaskInstance: completeTask
        };
        const requestUrl = 'api/workflowtaskdata/completetasks';
        return this.webApiService.postApi(requestUrl, params).then((response) => {
            return response.data;
        }); 
    }

    async createOutputSets(taskKeys: any[], userName: any, workgroupKey: any): Promise<any> {
        const paramsObject = {
            Username: userName,
            WorkgroupKey: workgroupKey,
            TasksInstancesKeys: taskKeys.join(",")
        };
        await this.webApiService.postApi('api/workflowtaskdata/spGenerateStudyOutputs', paramsObject, null, false);
    }

    syncWithAnimalsFacet(task: any): void {
        this.animalsSync.next(task);
    }

    syncOutput(output: any) {
        this.syncOutputValues.next(output);
    }

    syncWithClinicalFacet(task: any): void {
        this.clinicalSync.next(task);
    }

    syncTaskStateChanged(taskKeys: any[], taskTypes: string[], taskStatusKey?: number) {
        const numTaskKeys = taskKeys?.filter((item) => typeof item === 'number').map((item) => Number(item));

        if (numTaskKeys && numTaskKeys.length) {
            const args: TaskStatusChangedEventArgs = {
                taskKeys: numTaskKeys,
                taskTypes: taskTypes ?? [],
                taskStatusKey: taskStatusKey
            };
            this.syncTaskStatusChange.next(args);
        }
    }
}
