<div class="lock-control"
     [ngClass]="{'large-text' : largeText}">

    <!-- Administrators -->
    <ng-container *ngIf="administrator && !readonly; else readonlyBlock">

        <ng-container *ngIf="model === true; else unlockedBlock">
            <a (click)="toggleLock()" class="unlock-button">
                <i class="fa fa-fw fa-lock"
                   title="Locked; click to unlock"></i>
            </a>
        </ng-container>

        <ng-template #unlockedBlock>
            <a (click)="toggleLock()" class="lock-button">
                <i class="fa fa-fw fa-unlock-alt unlocked-indicator"
                   title="Unlocked; click to lock"></i>
            </a>
        </ng-template>

    </ng-container>


    <!-- Non-administrators or readonly -->
    <ng-template #readonlyBlock>

        <ng-container *ngIf="model === true; else unlockedBlock">
            <i class="fa fa-fw fa-lock" title={{title}}></i>
        </ng-container>

        <ng-template #unlockedBlock>
            <i class="fa fa-fw fa-unlock-alt"
               title="Unlocked"></i>
        </ng-template>

    </ng-template>

</div>
