import { JobMaterial } from "@common/types";
import { PartialJobMaterial, PartialMaterial } from "../models/partial-material";

export const toPartialDTO = (jobMaterial: JobMaterial): (PartialMaterial & PartialJobMaterial) => {
    return {
        C_JobMaterial_key: jobMaterial.C_JobMaterial_key,
        C_Material_key: jobMaterial.C_Material_key,
        C_Line_key: jobMaterial.Material?.C_Line_key,
        C_Taxon_key: jobMaterial.Material?.C_Taxon_key
    }
}