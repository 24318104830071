import { Component, Input, OnDestroy, OnInit} from "@angular/core"
import { JobReportReference } from "@common/types/models/job-report-reference.interface"
import { JobPharmaReportService } from "./job-pharma-report.service";
import { FileDownloader } from "@common/download";
import { Subscription } from "rxjs";
import { LoggingService } from "@services/logging.service";
import { Job } from "@common/types";
import { DateTime } from "luxon";

@Component({
    templateUrl: "job-pharma-report-table.component.html",
    selector: "job-pharma-report-table",
    styles: [
        `
            .download-link {
                color: var(--text-accent-primary);
            }
            .download-link:hover{
                color: var(--text-accent-hover);
            }
        `
    ]
})
export class JobPharmaReportTableComponent implements OnInit, OnDestroy {
    @Input()
    job: Job;

    reports: JobReportReference[];

    subscriptions: Subscription[] = [];

    loading: boolean;

    constructor(
        private _jobPharmaReportService: JobPharmaReportService,
        private _fileDownloader: FileDownloader,
        private _loggingService: LoggingService) { 
    }

    ngOnInit(): void {
        this.getReportReferences();    
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub: Subscription) => {
            sub.unsubscribe();
        });    
    }

    async getReportReferences(): Promise<void> {
        this.loading = true;
        this.reports = await this._jobPharmaReportService.getReportReferences(this.job.C_Job_key);
        this.loading = false;
    }

    requestReport(): void {  
        this.loading = true;
        const requestReportSubscription = this._jobPharmaReportService.requestReport(this.job.C_Job_key).subscribe(async (response: string) => {
            await this.getReportReferences();
            this.loading = false;
            // since the reports are sorted by created date, the first one is always the most recent
            this._downloadFile(response, this.reports[0])
        }, 
        (error: Error) => {
            this._loggingService.logError(error.message, error, "job-pharma-detail", true);
            this.loading = false;
        });

        this.subscriptions.push(requestReportSubscription);
    }

    downloadReport(reportReference: JobReportReference): void {
        this.loading = true;
        this._jobPharmaReportService.downloadReport(this.job.C_Job_key, reportReference.FileGUID).subscribe((reportContent: string) => {
            this.loading = false;
            this._downloadFile(reportContent, reportReference);
        });
    }

    private _downloadFile(reportContent: string, reportReference: JobReportReference): void {
        const fileName = this._createFriendlyFileName(reportReference)
        this._fileDownloader.triggerFileDownload(reportContent, fileName + ".csv", "csv");
    }

    private _createFriendlyFileName(jobReportReference: JobReportReference) {
        const dateStampFormatted: string = DateTime.fromJSDate(jobReportReference.DateCreated).toFormat("ddMMyyyy-hhmmss")
        return `${this.job.JobID}-${dateStampFormatted}`
    }
}