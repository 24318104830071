import { Injectable } from '@angular/core';
import { Entity } from '@common/types';
import { PermitInstitution } from '@common/types/models/permit-institution.interface';
import { PermitSpeciesLine } from '@common/types/models/permit-species-line.interface';
import { PermitSpeciesOrigin } from '@common/types/models/permit-species-origin.interface';
import { Permit } from '@common/types/models/permit.interface';
import { BaseEntityService } from '@services/base-entity.service';
import { DataManagerService } from '@services/data-manager.service';
import { QueryDef } from '@services/query-def';
import { SaveChangesService } from '@services/save-changes.service';
import { PermitSpecies } from '@common/types/models/permit-species.interface';
import { WebApiService } from '@services/web-api.service';
import { getDateRangePredicates } from '@services/queries';
import { EntityQuery, Predicate, QueryResult } from 'breeze-client';
import { IPermitForm, PermitSpeciesLineForm, PermitSpeciesOriginForm } from '../models/permit-form.interfaces';
import { notEmpty } from '@common/util';
import { IPermitFilter } from '../models/permit-filter.interface';
import { LoggingService } from '@services/logging.service';

@Injectable()
export class PermitService extends BaseEntityService {
    draggedPermits: Permit[] = [];

    constructor(
        private dataManager: DataManagerService,
        private saveChangesService: SaveChangesService,
        private webApiService: WebApiService,
        private loggingService: LoggingService,
    ) {
        super();
    }

    public async getPermits(queryDef: QueryDef): Promise<QueryResult> {
        await this.calculatePermitsAnimalCounts();
        let query = this.buildDefaultQuery('Permits', queryDef);

        this.ensureDefExpanded(queryDef, 'Resource');
        this.ensureDefExpanded(queryDef, 'PermitInstitution');
        this.ensureDefExpanded(queryDef, 'PermitSpecies');
        query = query.expand(queryDef.expands.join(','));

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        try {
            const response = await this.dataManager.executeQuery(query);
            return response;
        } catch (error) {
            return this.dataManager.queryFailed(error);
        }
    }

    public async calculatePermitSpeciesAnimalsCounts(permit: Entity<Permit>): Promise<Entity<Permit>> {
        if (permit.C_Permit_key < 0) {
            return permit;
        }
        try {
            const url = `api/permitcalculations/${permit.C_Permit_key}`;
            const response = await this.webApiService.callApi(url);
            const results = response.data;
                for (const permitSpecies of permit.PermitSpecies) {
                    permitSpecies.AnimalsAllowedOnPermit = results[permitSpecies.C_PermitSpecies_key].AnimalsAllowedOnPermit;
                    permitSpecies.AnimalsRemaining = results[permitSpecies.C_PermitSpecies_key].AnimalsRemaining;
                    permitSpecies.EndStateAnimals = results[permitSpecies.C_PermitSpecies_key].EndStateAnimals;
                    permitSpecies.LiveAnimals = results[permitSpecies.C_PermitSpecies_key].LiveAnimals;
                    permitSpecies.TotalAnimals = results[permitSpecies.C_PermitSpecies_key].TotalAnimals;
                }
        } catch (error) {
            this.loggingService.logError('Error: calculated Permit Species animal counts', error, 'permit-service', true);
        } finally {
            return permit;
        }
    }

    public async calculatePermitsAnimalCounts(): Promise<void> {
        const url = `api/permitcalculations/bulk`;
        await this.webApiService.postApi(url);
    }

    public getAllPermits(): Promise<Entity<Permit>[]> {
        const query = EntityQuery.from('Permits')
            .expand('Resource')
            .orderBy('PermitNumber');

        return this.dataManager.getQueryResults(query);
    }

    public getPermit(permitKey: number, expands: string[] = []): Promise<Entity<Permit>> {
        const query = EntityQuery.from('Permits')
            .where('C_Permit_key', '==', permitKey)
            .expand(expands.join(','));

        return this.dataManager.returnSingleQueryResult(query);
    }


    public createPermit(): Entity<Permit> {
        return this.dataManager.createEntity('Permit', {});
    }

    public buildPredicates(filter: IPermitFilter): Predicate[] {
        const predicates: Predicate[] = [];

        if (!filter) {
            return;
        }

        if (notEmpty(filter.permitNumber)) {
            const permitKeys = filter.permitNumber.map((item: { C_Permit_key: number, PermitNumber: string }) => item.C_Permit_key);
            predicates.push(Predicate.create('C_Permit_key', 'in', permitKeys));
        }
        if (notEmpty(filter.permitOwnerKeys)) {
            predicates.push(Predicate.create('C_Resource_key', 'in', filter.permitOwnerKeys));
        }
        if (filter.title) {
            predicates.push(Predicate.create('Title', 'substringof', filter.title));
        }
        if (notEmpty(filter.permitStatusKeys)) {
            predicates.push(Predicate.create('C_PermitStatus_key', 'in', filter.permitStatusKeys));
        }
        if (notEmpty(filter.permitCategoryKeys)) {
            predicates.push(Predicate.create('C_PermitCategory_key', 'in', filter.permitCategoryKeys));
        }
        if (filter.startDateFrom || filter.startDateTo) {
            const datePredicates: Predicate[] = getDateRangePredicates(
                'StartDate',
                filter.startDateFrom,
                filter.startDateTo
            );
            if (notEmpty(datePredicates)) {
                predicates.push(...datePredicates);
            }
        }
        if (filter.expiryDateFrom || filter.expiryDateTo) {
            const datePredicates: Predicate[] = getDateRangePredicates(
                'ExpiryDate',
                filter.expiryDateFrom,
                filter.expiryDateTo
            );
            if (notEmpty(datePredicates)) {
                predicates.push(...datePredicates);
            }
        }
        if (notEmpty(filter.permitExternalPurposeKeys)) {
            predicates.push(Predicate.create('C_PermitExternalPurpose_key', 'in', filter.permitExternalPurposeKeys));
        }
        if (filter.otherPurpose) {
            predicates.push(Predicate.create('OtherPurpose', 'substringof', filter.otherPurpose));
        }
        if (notEmpty(filter.institutions)) {
            const institutionKeys = filter.institutions.map(institution => {
                return institution.InstitutionKey;
            });

            predicates.push(Predicate.create(
                'PermitInstitution', 'any', 'C_Institution_key', 'in', institutionKeys
            ));
        }
        if (notEmpty(filter.permitSpeciesKeys)) {
            predicates.push(
                Predicate.create('PermitSpecies', 'any', 'C_Taxon_key', 'in', filter.permitSpeciesKeys
            ));
        }
        if (notEmpty(filter.lines)) {
            const lineKeys = filter.lines.map(line => line.LineKey);
            predicates.push(Predicate.create('PermitSpecies', 'any', Predicate.create(
                'PermitSpeciesLine', 'any', Predicate.create(
                    'Line.C_Line_key', 'in', lineKeys
                )
            )));
        }
        return predicates;
    }

    public copyPermit(fromPermit: Entity<Permit>, toPermit: Entity<Permit>): void {
        toPermit.C_PermitCategory_key = fromPermit.C_PermitCategory_key;
        toPermit.C_PermitStatus_key = fromPermit.C_PermitStatus_key;
        toPermit.C_PermitExternalPurpose_key = fromPermit.C_PermitExternalPurpose_key;
        toPermit.C_Resource_key = fromPermit.C_Resource_key;
        toPermit.PermitNumber = `${fromPermit.PermitNumber} (Copy)`;
        toPermit.Title = fromPermit.Title;
        toPermit.GMOAnimalsAllowed = fromPermit.GMOAnimalsAllowed;
        toPermit.OtherPurpose = fromPermit.OtherPurpose;
        toPermit.Summary = fromPermit.Summary;
        toPermit.StartDate = fromPermit.StartDate;
        toPermit.ExpiryDate = fromPermit.ExpiryDate;
        toPermit.Resource = fromPermit.Resource;
        toPermit.InstitutionsSortable = fromPermit.InstitutionsSortable;
        toPermit.SpeciesSortable = fromPermit.InstitutionsSortable;
        toPermit.LinesSortable = fromPermit.InstitutionsSortable;
        toPermit.HighestSeveritiesSortable = fromPermit.InstitutionsSortable;
        toPermit.NonRecoveriesSortable = fromPermit.InstitutionsSortable;
        toPermit.OriginsSortable = fromPermit.InstitutionsSortable;
        toPermit.ReuseAllowedSortable = fromPermit.InstitutionsSortable;
        
        const permitInstitutionKeyMap: {[key: number]: number} = {};
        const permitSpeciesKeyMap: {[key: number]: number} = {};

        for (const fromPermitInstitution of fromPermit.PermitInstitution) {
            const toInstitution: PermitInstitution = this.dataManager.createEntity('PermitInstitution', {
                C_Permit_key: toPermit.C_Permit_key,
                C_Institution_key: fromPermitInstitution.C_Institution_key,
                DateCreated: new Date(),
            });
            permitInstitutionKeyMap[fromPermitInstitution.C_PermitInstitution_key] = toInstitution.C_PermitInstitution_key;
        }

        for (const fromPermitSpecies of fromPermit.PermitSpecies) {
            const toPermitSpecies: PermitSpecies = this.dataManager.createEntity('PermitSpecies', {
                C_Permit_key: toPermit.C_Permit_key,
                C_Severity_key: fromPermitSpecies.C_Severity_key,
                C_Taxon_key: fromPermitSpecies.C_Taxon_key,
                AnimalsOnPermit: fromPermitSpecies.AnimalsOnPermit,
                DateCreated: new Date(),
                NoRecoveryAllowed: fromPermitSpecies.NoRecoveryAllowed,
                ReuseAllowed: fromPermitSpecies.ReuseAllowed,
                Version: 0,
                PermitSpeciesLine: [],
                PermitSpeciesOrigin: [],
            });
            permitSpeciesKeyMap[fromPermitSpecies.C_PermitSpecies_key] = toPermitSpecies.C_PermitSpecies_key;

            for (const permitSpeciesLine of fromPermitSpecies.PermitSpeciesLine) {
                const toPermitSpeciesLine: PermitSpeciesLine = this.dataManager.createEntity('PermitSpeciesLine', {
                    C_PermitSpecies_key: toPermitSpecies.C_PermitSpecies_key,
                    C_Line_key: permitSpeciesLine.C_Line_key,
                    DateCreated: new Date(),
                });
                toPermitSpecies.PermitSpeciesLine.push(toPermitSpeciesLine);
            }

            for (const permitSpeciesOrigin of fromPermitSpecies.PermitSpeciesOrigin) {
                const toPermitSpeciesOrigin: PermitSpeciesOrigin = this.dataManager.createEntity('PermitSpeciesOrigin', {
                    C_PermitSpecies_key: toPermitSpecies.C_PermitSpecies_key,
                    C_MaterialOrigin_key: permitSpeciesOrigin.C_MaterialOrigin_key,
                    DateCreated: new Date(),
                });
                toPermitSpecies.PermitSpeciesOrigin.push(toPermitSpeciesOrigin);
            }
        }
    }
    public onPermitFormValuesChanged(permit: Entity<Permit>, formValue: IPermitForm): void {
        permit.C_PermitCategory_key = formValue.PermitCategoryKey;
        permit.C_PermitExternalPurpose_key = formValue.PermitExternalPurposeKey;
        permit.C_PermitStatus_key = formValue.PermitStatusKey;
        permit.ExpiryDate = formValue.ExpiryDate;
        permit.GMOAnimalsAllowed = formValue.IsGMOAnimalsAllowed;
        permit.OtherPurpose = formValue.OtherPurpose;
        permit.C_Resource_key = formValue.Owner || null;
        permit.PermitNumber = formValue.PermitNumber;
        permit.StartDate = formValue.StartDate;
        permit.Title = formValue.Title;
        permit.Summary = formValue.Summary
        this.setPermitInstitutions(permit, formValue.Institutions);
        this.setPermitSpecies(permit, formValue);
    }

    private setPermitInstitutions(permit: Entity<Permit>, formValue: {InstitutionKey: number; Name: string; }[] = []): void {
        const formInstutionKeysMap = new Map(formValue.map(item => [item.InstitutionKey, item]));
        const alreadyExistMap = new Map(permit.PermitInstitution.map(item => [item.C_Institution_key,  item]));

        for (const [key, entity] of alreadyExistMap.entries()) {
            if (!formInstutionKeysMap.has(key)) {
                this.dataManager.deleteEntity(entity);
            }
        }

        for (const item of formInstutionKeysMap.values()) {
          if (!alreadyExistMap.has(item.InstitutionKey)) {
                this.dataManager.createEntity('PermitInstitution', {
                    C_Institution_key: item.InstitutionKey,
                    C_Permit_key: permit.C_Permit_key,
                });
            }
        }
    }

    private setPermitSpecies(permit: Entity<Permit>, formValue: IPermitForm) {
        permit.PermitSpecies.forEach((permitSpecies, index) => {
            if (formValue.PermitSpecies[index]) {
                permitSpecies.C_Severity_key = formValue.PermitSpecies[index].SeverityKey;
                permitSpecies.C_Taxon_key = formValue.PermitSpecies[index].SpeciesKey;
                permitSpecies.AnimalsOnPermit = formValue.PermitSpecies[index].AnimalsOnPermit;
                permitSpecies.NoRecoveryAllowed = formValue.PermitSpecies[index].NoRecoveryAllowed;
                permitSpecies.ReuseAllowed = formValue.PermitSpecies[index].ReuseAllowed;

                const permitSpeciesLine = formValue.PermitSpecies[index].PermitSpeciesLine;
                if (permitSpeciesLine) {
                    this.setPermitSpeciesLine(permitSpecies as Entity<PermitSpecies>, permitSpeciesLine);
                }

                const permitSpeciesOrigin = formValue.PermitSpecies[index].PermitSpeciesOrigin;
                if (permitSpeciesOrigin) {
                    this.setPermitSpeciesOrigin(permitSpecies as Entity<PermitSpecies>, permitSpeciesOrigin);
                }
            }
        });
    }

    private setPermitSpeciesLine(permitSpecies: Entity<PermitSpecies>, formValue: PermitSpeciesLineForm[] = []): void {
        const formPermitSpeciesLineKeysMap = new Map(formValue.map(item => [item.LineKey, item]));
        const alreadyExistMap = new Map(permitSpecies.PermitSpeciesLine.map(item => [item.C_Line_key,  item]));

        for (const [key, entity] of alreadyExistMap.entries()) {
            if (!formPermitSpeciesLineKeysMap.has(key)) {
                this.dataManager.deleteEntity(entity);
            }
        }

        for (const item of formPermitSpeciesLineKeysMap.values()) {
            if (!alreadyExistMap.has(item.LineKey)) {
                  this.dataManager.createEntity('PermitSpeciesLine', {
                      C_Line_key: item.LineKey,
                      C_PermitSpecies_key: permitSpecies.C_PermitSpecies_key,
                });
            }
        }
    }

    private setPermitSpeciesOrigin(permitSpecies: Entity<PermitSpecies>, formValue: PermitSpeciesOriginForm[] = []): void {
        const formPermitSpeciesOriginKeysMap = new Map(formValue.map(item => [item.MaterialOriginKey, item]));
        const alreadyExistMap = new Map(permitSpecies.PermitSpeciesOrigin.map(item => [item.C_MaterialOrigin_key,  item]));

        for (const [key, entity] of alreadyExistMap.entries()) {
            if (!formPermitSpeciesOriginKeysMap.has(key)) {
                this.dataManager.deleteEntity(entity);
            }
        }

        for (const item of formPermitSpeciesOriginKeysMap.values()) {
          if (!alreadyExistMap.has(item.MaterialOriginKey)) {
                this.dataManager.createEntity('PermitSpeciesOrigin', {
                    C_MaterialOrigin_key: item.MaterialOriginKey,
                    C_PermitSpecies_key: permitSpecies.C_PermitSpecies_key,
                });
            }
        }
    }

    public async savePermit(logTag: string): Promise<void> {
        await this.saveChangesService.saveChanges(logTag);
    }

    public cancelPermit(permit: Entity<Permit>): void {
        if (!permit) {
            return;
        }
        if (permit.C_Permit_key > 0) {
            this.cancelPermitEdits(permit);
        } else {
            this.cancelNewPermit(permit);
        }
    }

    private cancelPermitEdits(permit: Entity<Permit>): void {
        this.dataManager.rejectEntityAndRelatedPropertyChanges(permit);
    }

    private cancelNewPermit(permit: Entity<Permit>): void {
        if (permit.PermitInstitution) {
            while (permit.PermitInstitution.length) {
                this.dataManager.deleteEntity(permit.PermitInstitution[0]);
            }
        }
        this.dataManager.deleteEntity(permit);
    }

    public createPermitSpecies(permitKey: number): Entity<PermitSpecies> {
        return this.dataManager.createEntity('PermitSpecies', { C_Permit_key: permitKey });
    }

    public deletePermitSpecies(permitSpecies: Entity<PermitSpecies>): void {
        // Delete related records in PermitSpeciesOrigin & PermitSpeciesLine to avoid foreign key constraint violations in backend request
        while (permitSpecies.PermitSpeciesLine.length > 0) {
            this.dataManager.deleteEntity(permitSpecies.PermitSpeciesLine[0]);
        }
        while (permitSpecies.PermitSpeciesOrigin.length > 0) {
            this.dataManager.deleteEntity(permitSpecies.PermitSpeciesOrigin[0]);
        }
        this.dataManager.deleteEntity(permitSpecies);
    }

    async ensureVisibleColumnsDataLoaded(permits: Entity<Permit>[], visibleColumns: string[]): Promise<void> {
        const expands = this.generateExpandsFromVisibleColumns(permits[0], visibleColumns);
        return this.dataManager.ensureRelationships(permits, expands);
    }
}
