import { ScheduleService } from './../schedule.service';
import { SaveChangesService } from './../../services/save-changes.service';
import { WorkflowVocabService } from '../../workflow/services/workflow-vocab.service';
import {
    Component,
    Input,
    OnInit,
    ViewChildren,
} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';
import { randomId, focusElementByQuery } from '../../common/util';
import { map } from 'rxjs/operators';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';
import { LoggingService } from '@services/logging.service';

@Component({
    selector: 'reserve-time-modal',
    templateUrl: './reserve-time-modal.component.html',
})
export class ReserveTimeModalComponent implements OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() scheduleNonTask: any;
    @Input() facet: any;

    domIdAddition = '';
    saving = false;
    readonly LOG_TAG = 'reserveTimeModal';

    // Vocabularies
    resources: any[] = [];

    constructor(
        private activeModal: NgbActiveModal,
        private saveChangesService: SaveChangesService,
        private scheduleService: ScheduleService,
        private workflowVocabService: WorkflowVocabService,
        private loggingService: LoggingService,
    ) {
        //
    }

    ngOnInit() {
        this.domIdAddition = randomId() + '_rtm';

        focusElementByQuery('[data-auto-focus="appointmentTitle"]');
        this.getCVs();
    }

    getCVs(): Promise<any> {
        const p1 = this.workflowVocabService.resources$.pipe(map((data) => {
            this.resources = <any[]> data;
        })).toPromise();

        return Promise.all([p1]);
    }

    onSubmit(): void {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }

        if (this.saveChangesService.hasChanges) {
            this.saving = true;
            this.saveChangesService.saveChanges(this.LOG_TAG).then(() => {
                this.saving = false;
                this.activeModal.close(this.scheduleNonTask);
            }).catch((err) => {
                this.saving = false;
                throw err;
            });
        } else {
            this.activeModal.close(this.scheduleNonTask);
        }
    }

    onClose(): void {
        this.scheduleService.cancelScheduleNonTask(this.scheduleNonTask);
        this.activeModal.close();
    }

    resourceKeyFormatter = (value: any) => {
        return value.C_Resource_key;
    }
    resourceNameFormatter = (value: any) => {
        return value.ResourceName;
    }
}
