export interface cv_PermitStatus {
    C_PermitStatus_key: number;
    PermitStatus: string;
    Abbreviation: string;
    IsActive: boolean;
    IsDefault: boolean;
    SortOrder: number;
    C_VocabularySource_key: number;
    ElementID: string;
    IsDeprecated: boolean;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    ModifiedBy: string;
    DateModified: Date;
    Version: number;
}
