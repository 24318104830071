export interface cv_Severity {
    C_Severity_key: number;
    Severity: string;
    Abbreviation: string;
    IsActive: boolean;
    IsDefault: boolean;
    SortOrder: number;
    C_VocabularySource_key: number | null;
    ElementID: string;
    IsDeprecated: boolean;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    ModifiedBy: string;
    DateModified: Date;
    Version: number;
}
