export enum AuditGridView {
    ChooseSection = 'Choose Section',
    AnimalCharacteristics = 'Animal Characteristics',
    AnimalComments = 'Animal Comments',
    Animals = 'Animals',
    AnimalTasks = 'Animal Tasks',
    Births = 'Births',
    CharacteristicsAnimals = 'Characteristics: Animals',
    CharacteristicsJobs = 'Characteristics: Jobs',
    CharacteristicsSamples = 'Characteristics: Samples',
    CharacteristicsStudies = 'Characteristics: Studies',
    Clinical = 'Clinical',
    ClinicalObservationDetails = 'Clinical Observation Details',
    ClinicalObservations = 'Clinical Observations',
    ClinicalTasks = 'Clinical Tasks',
    CohortAnimals = 'Cohort Animals',
    Cohorts = 'Cohorts',
    EnumerationValues = 'Enumeration Values',
    Enumerations = 'Enumerations',
    Files = 'Files',
    Housing = 'Housing',
    HousingAnimals = 'Housing Animals',
    HousingLocations = 'Housing Locations',
    HousingSocialStatutes = 'Housing Social Statutes',
    HousingTasks = 'Housing Tasks',
    JobAnimals = 'Job Animals',
    JobCharacteristics = 'Job Characteristics',
    JobGroupPlaceholders = 'Job Group Placeholders',
    JobGroups = 'Job Groups',
    JobIndividualPlaceholders = 'Job Individual Placeholders',
    JobLineDetails = 'Job Line Details',
    JobLines = 'Job Lines',
    JobLocations = 'Job Locations',
    JobOrders = 'Job Orders',
    JobSampleGroups = 'Job Sample Groups',
    JobSamples = 'Job Samples',
    JobStandardPhrases = 'Job Standard Phrases',
    JobVariablePhrases = 'Job Variable Phrases',
    JobTasks = 'Job Tasks',
    JobTestArticles = 'Job Test Articles',
    Jobs = 'Jobs',
    JobsInstitutions = 'Jobs Institutions',
    Lines = 'Lines',
    Locations = 'Locations',
    MatingMaterials = 'Mating Materials',
    Matings = 'Matings',
    OrderAnimals = 'Order Animals',
    OrderInstitutions = 'Order Institutions',
    OrderLocations = 'Order Locations',
    Orders = 'Orders',
    PermitInstitutions = 'Permit Institutions',
    Permits = 'Permits',
    PermitSpecies = 'Permit Species',
    PermitSpeciesLines = 'Permit Species Lines',
    PermitSpeciesOrigins = 'Permit Species Origins',
    ProtocolDefaultInputs = 'Protocol Default Inputs',
    ProtocolSampleGroups = 'Protocol Sample Groups',
    ProtocolTasks = 'Protocol Tasks',
    Protocols = 'Protocols',
    Resources = 'Resources',
    RoleFacets = 'Role Facets',
    SampleCharacteristics = 'Sample Characteristics',
    Samples = 'Samples',
    SettingsFacets = 'Settings: Facets',
    SettingsNaming = 'Settings: Naming',
    SettingsOther = 'Settings: Other',
    SettingsTaskOutputPoolWorkflowTasks = 'Settings: Task Output Pool Tasks',
    SettingsTaskOutputPools = 'Settings: Task Output Pools',
    SettingsTaskOutputPoolOutputs = 'Settings: Task Output Pool Outputs',
    Studies = 'Studies',
    StudyAdministratorStudies = 'Study Administrator Studies',
    StudyCharacteristics = 'Study Characteristics',
    StudyLines = 'Study Lines',
    TaskAssignments = 'Task Assignments',
    TaskInputs = 'Task Inputs',
    TaskOutputFlags = 'Task Output Flags',
    TaskOutputs = 'Task Outputs',
    Tasks = 'Tasks',
    UserDetails = 'User Details',
    Roles = 'Roles',
    Users = 'Users',
    VocabulariesAnimalClassifications = 'Vocabularies: Animal Classifications',
    VocabulariesAnimalCommentStatuses = 'Vocabularies: Animal Comment Statuses',
    VocabulariesAnimalSampleOrigins = 'Vocabularies: Animal/Sample Origins',
    VocabulariesAnimalStatuses = 'Vocabularies: Animal Statuses',
    VocabulariesAnimalUses = 'Vocabularies: Animal Uses',
    VocabulariesBirthStatuses = 'Vocabularies: Birth Statuses',
    VocabulariesBodyConditionScores = 'Vocabularies: Body Condition Scores',
    VocabulariesBreedingStatuses = 'Vocabularies: Breeding Statuses',
    VocabulariesClinicalObservationBodySystems = 'Vocabularies: Clinical Observation Body Systems',
    VocabulariesClinicalObservationStatuses = 'Vocabularies: Clinical Observation Statuses',
    VocabulariesClinicalObservations = 'Vocabularies: Clinical Observations',
    VocabulariesCompatibilityAccessLevels = 'Vocabularies: Compatibility Access Levels',
    VocabulariesCompliances = 'Vocabularies: Compliances',
    VocabulariesContactTypes = 'Vocabularies: Contact Types',
    VocabulariesContainerTypes = 'Vocabularies: Container Types',
    VocabulariesCouriers = 'Vocabularies: Couriers',
    VocabulariesDiets = 'Vocabularies: Diets',
    VocabulariesExitReasons = 'Vocabularies: Exit Reasons',
    VocabulariesGenerations = 'Vocabularies: Generations',
    VocabulariesGenotypeAssays = 'Vocabularies: Genotype Assays',
    VocabulariesGenotypes = 'Vocabularies: Genotypes',
    VocabulariesHousingStatuses = 'Vocabularies: Housing Statuses',
    VocabulariesHousingTypes = 'Vocabularies: Housing Types',
    VocabulariesIACUCProtocols = 'Vocabularies: IACUC Protocols',
    VocabulariesInstitutionTypes = 'Vocabularies: Institution Types',
    VocabulariesJobReports = 'Vocabularies: Job Reports',
    VocabulariesJobStatuses = 'Vocabularies: Job Statuses',
    VocabulariesJobSubtypes = 'Vocabularies: Job Subtypes',
    VocabulariesJobTypes = 'Vocabularies: Job Types',
    VocabulariesLineStatuses = 'Vocabularies: Line Statuses',
    VocabulariesLineTypes = 'Vocabularies: Line Types',
    VocabulariesLocationTypes = 'Vocabularies: Location Types',
    VocabulariesMarkerTypes = 'Vocabularies: Marker Types',
    VocabulariesMatingPurposes = 'Vocabularies: Mating Purposes',
    VocabulariesMatingStatuses = 'Vocabularies: Mating Statuses',
    VocabulariesMatingTypes = 'Vocabularies: Mating Types',
    VocabulariesModifiers1 = 'Vocabularies: Modifiers 1',
    VocabulariesModifiers2 = 'Vocabularies: Modifiers 2',
    VocabulariesModifiers3 = 'Vocabularies: Modifiers 3',
    VocabulariesModifiers4 = 'Vocabularies: Modifiers 4',
    VocabulariesOrderStatuses = 'Vocabularies: Order Statuses',
    VocabulariesPaymentTerms = 'Vocabularies: Payment Terms',
    VocabulariesPermitCategories = 'Vocabularies: Permit Categories',
    VocabulariesPermitExternalPurposes = 'Vocabularies: Permit External Purposes',
    VocabulariesPermitStatuses = 'Vocabularies: Permit Statuses',
    VocabulariesPlateStatuses = 'Vocabularies: Plate Statuses',
    VocabulariesQuarantineFacilities = 'Vocabularies: Quarantine Facilities',
    VocabulariesResourceGroups = 'Vocabularies: Resource Groups',
    VocabulariesResourceTypes = 'Vocabularies: Resource Types',
    VocabulariesRoutesOfAdministration = 'Vocabularies: Routes of Administration',
    VocabulariesSampleAnalyses = 'Vocabularies: Sample Analyses',
    VocabulariesSampleConditions = 'Vocabularies: Sample Conditions',
    VocabulariesSeverities = 'Vocabularies: Severities',
    VocabulariesShipmentAnimalConditions = 'Vocabularies: Shipment Animal Conditions',
    VocabulariesShipmentContainerConditions = 'Vocabularies: Shipment Container Conditions',
    VocabulariesSiteTypes = 'Vocabularies: Site Types',
    VocabulariesSocialGroupAccessLevels = 'Vocabularies: Social Group Access Levels',
    VocabulariesStandardPhrases = 'Vocabularies: Standard Phrases',
    VocabulariesTaskFlagMessages = 'Vocabularies: Task Flag Messages',
    VocabulariesTaskOutcomes = 'Vocabularies: Task Outcomes',
    VocabulariesTaskStatuses = 'Vocabularies: Task Statuses',
    VocabulariesTestArticles = 'Vocabularies: Test Articles',
    VocabulariesUnits = 'Vocabularies: Units',
    VocabulariesVeterinaryRequestStatuses = 'Vocabularies: Veterinary Request Statuses',
    WorkflowInputs = 'Workflow Inputs',
    WorkflowOutputs = 'Workflow Outputs',
    WorkflowTaskDetails = 'Workflow Task Details',
}
