import {
    Component,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';

import { OrderService } from 'src/app/orders/order.service';

/**
 * Table for adding institutions to orders
 */
@Component({
    selector: 'order-location-table',
    templateUrl: './order-location-table.component.html',
})
export class OrderLocationTableComponent {
    @Input() order: any;
    @Input() readonly: boolean;
    @Input() required: boolean;
    @Output() firstLineChanged: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private orderService: OrderService
    ) {
        //
    }

    addLocation(order: any) {
        if (this.readonly) {
            return;
        }
        const initialValues = {
            C_Order_key: order.C_Order_key,
        };
        this.orderService.createOrderLocation(initialValues);
    }

    removeLocation(orderLocation: any) {
        if (this.readonly) {
            return;
        }

        // If change was made to the first OrderLocation record
        if (this.order.OrderLocation[0].C_OrderLocation_key === orderLocation.C_OrderLocation_key) {
            this.firstLineChanged.emit();
        }

        this.orderService.deleteOrderLocation(orderLocation);
    }

    locationChanged(orderLocation: any) {
        // If change was made to the first OrderLocation record
        if (this.order.OrderLocation[0].C_OrderLocation_key === orderLocation.C_OrderLocation_key) {
            this.firstLineChanged.emit();
        }
    }
}
