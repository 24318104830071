import { CellFormatterService, TableOptions } from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditPermitsTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'permits-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Permit Number',
                    field: 'PermitNumber',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('PermitNumber', 'PermitNumber'),
                    cellClass: AuditClassFactory.createCellClass('permitnumber'),
                },
                {
                    displayName: 'Permit Owner',
                    field: 'PermitOwner',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('PermitOwner', 'PermitOwner'),
                    cellClass: AuditClassFactory.createCellClass('permitowner'),
                },
                {
                    displayName: 'Permit Status',
                    field: 'PermitStatus',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('PermitStatus', 'PermitStatus'),
                    cellClass: AuditClassFactory.createCellClass('permitstatus'),
                },
                {
                    displayName: 'Permit Category',
                    field: 'PermitCategory',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('PermitCategory', 'PermitCategory'),
                    cellClass: AuditClassFactory.createCellClass('permitcategory'),
                },
                {
                    displayName: 'Permit External Purpose',
                    field: 'PermitExternalPurpose',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('PermitExternalPurpose', 'PermitExternalPurpose'),
                    cellClass: AuditClassFactory.createCellClass('permitexternalpurpose'),
                },
                {
                    displayName: 'Permit Title',
                    field: 'Title',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Title', 'Title'),
                    cellClass: AuditClassFactory.createCellClass('title'),
                },
                {
                    displayName: 'GMO Animals Allowed',
                    field: 'GMOAnimalsAllowed',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('GMOAnimalsAllowed', 'GMOAnimalsAllowed'),
                    cellClass: AuditClassFactory.createCellClass('gmoanimalsallowed'),
                },
                {
                    displayName: 'Permit Other Purpose',
                    field: 'OtherPurpose',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('OtherPurpose', 'OtherPurpose'),
                    cellClass: AuditClassFactory.createCellClass('otherpurpose'),
                },
                {
                    displayName: 'Permit Summary',
                    field: 'Summary',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Summary', 'Summary'),
                    cellClass: AuditClassFactory.createCellClass('summary'),
                },
                {
                    displayName: 'Start Date',
                    field: 'StartDate',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('StartDate', 'StartDate', this.cellFormatterService.dateTimeUTCFormatter),
                    cellClass: AuditClassFactory.createCellClass('startdate'),
                },
                {
                    displayName: 'Expiry Date',
                    field: 'ExpiryDate',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ExpiryDate', 'ExpiryDate', this.cellFormatterService.dateTimeUTCFormatter),
                    cellClass: AuditClassFactory.createCellClass('expirydate'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
            ]
        };
    }
}
