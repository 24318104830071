import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';

import { Entity, Order } from '@common/types';

@Component({
    selector: 'order-animal-receipt-check-table',
    templateUrl: './order-animal-receipt-check-table.component.html',
})
export class OrderAnimalReceiptCheckTableComponent implements OnChanges {
    @Input() order: Entity<Order>;
    @Input() activeFields: Set<string>;

    isShowTable = false
    fieldPrefix = 'Animal Receipt Check:'

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.activeFields) {
            this.isShowTable = this.activeFields.size > 0
        }
    }

    isFieldActive(fieldName: string): boolean {
        return this.activeFields.has(`${this.fieldPrefix} ${fieldName}`)
    }

    onAnimalSectionClick(type: Order[keyof Order], property: string): void {
        this.order[property] = this.order[property] === type ? null : type;
    }
}
