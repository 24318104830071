import { Injectable } from '@angular/core';
import { defer, merge, Observable } from 'rxjs';

import { ResourceService } from '../../resources/resource.service';
import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { WorkflowService } from './workflow.service';
import { LocationService } from '../../locations/location.service';
import { take } from 'rxjs/operators';
import { Entity, Output, WorkflowTask } from '@common/types';

@Injectable()
export class WorkflowVocabService {

    // vocab Observables
    public get dataTypes$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_DataTypes')).pipe(take(1));
    }
    public get resources$(): Observable<any[]> {
        return defer(() => this.resourceService.getAllResources()).pipe(take(1));
    }
    public get taskStatuses$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_TaskStatuses')).pipe(take(1));
    }
    public get jobStatuses$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_JobStatuses')).pipe(take(1));
    }
    public get taskTypes$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_TaskTypes')).pipe(take(1));
    }
    public get jobTypes$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_JobTypes')).pipe(take(1));
    }
    public get workflowTasks$(): Observable<any[]> {
        return defer(() => this.workflowService.getWorkflowTaskList()).pipe(take(1));
    }
    public get workflowTasksForOutputPools$(): Observable<Entity<WorkflowTask>[]> {
        return defer(() => this.workflowService.getWorkflowTaskListForOutputPools()).pipe(take(1));
    }
    public get numericOutputs$(): Observable<Entity<Output>[]> {
        return defer(() => this.workflowService.getNumericOutputs()).pipe(take(1));
    }
    public get workflowAnimalTasks$(): Observable<any[]> {
        return defer(() => this.workflowService.getWorkflowTaskListByType('Animal')).pipe(take(1));
    }
    public get workflowJobTasks$(): Observable<any[]> {
        return defer(() => this.workflowService.getWorkflowTaskListByType('Job')).pipe(take(1));
    }
    public get cohorts$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('Cohorts', 'CohortName')).pipe(take(1));
    }
    public get animalStatuses$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_AnimalStatuses')).pipe(take(1));
    }
    public get animalClassifications$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_AnimalClassifications')).pipe(take(1));
    }
    public get sampleStatuses$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_SampleStatuses')).pipe(take(1));
    }
    public get sampleTypes$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_SampleTypes')).pipe(take(1));
    }
    public get preservationMethods$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_PreservationMethods')).pipe(take(1));
    }
    public get containerTypes$(): Observable<any[]> {
        return defer(() => this.locationService.getContainerTypes('Sample')).pipe(take(1));
    }
    public get animalCommentStatuses$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_AnimalCommentStatuses')).pipe(take(1));
    }

    constructor(
        private resourceService: ResourceService,
        private vocabularyService: VocabularyService,
        private workflowService: WorkflowService,
        private locationService: LocationService,
    ) {}


    /**
     * They are used to load data into the Workflow details component.
     * It needs to remove this method and load only the necessary data
     * directly subscribing to the particular observable.
     */
    preloadCVs() {
        merge(
            this.dataTypes$,
            this.resources$,
            this.taskStatuses$,
            this.jobStatuses$,
            this.taskTypes$,
            this.jobTypes$,
            this.workflowTasks$,
            this.workflowAnimalTasks$,
            this.workflowJobTasks$,
            this.cohorts$,
            this.animalStatuses$,
            this.sampleStatuses$,
            this.sampleTypes$,
            this.preservationMethods$,
            this.containerTypes$,
            this.animalCommentStatuses$,
        ).pipe(take(1)).subscribe();
    }
}
