import { WebApiService } from './../services/web-api.service';
import { Injectable } from '@angular/core';
import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
    QueryResult
} from 'breeze-client';

import { 
    notEmpty, 
    listContainsString
} from '../common/util';
import {
    getDateRangePredicates,
    getIsActivePredicate,
} from '../services/queries';

import { DataManagerService } from '../services/data-manager.service';
import { QueryDef } from '../services/query-def';
import { BaseEntityService } from '../services/base-entity.service';
import { Study } from '@common/types';

@Injectable()
export class StudyService extends BaseEntityService {

    constructor(
        private dataManager: DataManagerService,
        private webApiService: WebApiService
    ) {
        super();
    }

    getAllStudies(): Promise<any[]> {
        const query = EntityQuery.from('Studies');
        return this.dataManager.getQueryResults(query);
    }
    
    getStudies(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery('Studies', queryDef);
        
        this.ensureDefExpanded(queryDef, 'cv_StudyType');
        this.ensureDefExpanded(queryDef, 'cv_StudyStatus');
        query = query.expand(queryDef.expands.join(','));
        
        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }
        
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    ensureListViewAssociatedDataLoaded(studies: any[], visibleColumns?: string[]): Promise<void> {

        const promises: Promise<any>[] = [];

        if (listContainsString(visibleColumns, 'AnimalCount')) {
            promises.push(this.getStudyAnimalCounts(studies));
        }

        return Promise.all(promises).then(() => { /* void return result */ });
    }

    async ensureVisibleColumnsDataLoaded(studies: any[], visibleColumns: string[]): Promise<void> {
        const expands = this.generateExpandsFromVisibleColumns(studies[0], visibleColumns);
        return this.dataManager.ensureRelationships(studies, expands);
    }

    getStudyAnimalCounts(studies: any[]): Promise<void> {
        if (!studies) {
            return Promise.resolve();
        }

        const url = 'api/listview/studyanimalcounts';
        const filter: any = {
            studyKeys: studies.map((study) => {
                return study.C_Study_key;
            })
        };
        return this.webApiService.postApi(url, filter).then((response) => {
            const results = response.data;
            const studyMap: any = {};
            for (const study of studies) {
                studyMap[study.C_Study_key] = study;
            }
            for (const result of results) {
                const study = studyMap[result.key];
                if (!study) {
                    continue;
                }

                study.totalAnimalCount = result.totalAnimalCount;
                study.availableAnimalCount = result.availableAnimalCount;
            }
        });
    }
    
    buildPredicates(filter: any): Predicate[] {
        let predicates: Predicate[] = [];
        if (!filter) {
            return predicates;
        }

        if (filter.StudyName) {
            predicates.push(Predicate.create('StudyName', FilterQueryOp.Contains, { value: filter.StudyName }));
        }
        if (notEmpty(filter.C_StudyStatus_keys)) {
            predicates.push(Predicate.create(
                'C_StudyStatus_key', 'in', filter.C_StudyStatus_keys
            ));
        }
        if (notEmpty(filter.C_StudyType_keys)) {
            predicates.push(Predicate.create(
                'C_StudyType_key', 'in', filter.C_StudyType_keys
            ));
        }
        if (filter.ApprovalNumber) {
            predicates.push(Predicate.create('ApprovalNumber', FilterQueryOp.Contains, { value: filter.ApprovalNumber }));
        }
        if (filter.IsActive) {
            const isActivePredicate: Predicate = getIsActivePredicate(filter.IsActive);
            predicates.push(isActivePredicate);
        }
        if (filter.CreatedBy) {
            predicates.push(Predicate.create('CreatedBy', 'eq', filter.CreatedBy));
        }

        if (filter.DateCreatedStart || filter.DateCreatedEnd) {
            const datePredicates: Predicate[] = getDateRangePredicates(
                'DateCreated',
                filter.DateCreatedStart,
                filter.DateCreatedEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

         /* Deprecated - Sprint 79 - replaced by filter.lines */
        if (filter.LineName) {
            predicates.push(Predicate.create(
                'StudyLine', 'any',
                'Line.LineName', 'eq', filter.LineName
            ));
        }
        if (notEmpty(filter.lines)) {
            const lineKeys = filter.lines.map((line: any) => {
                return line.LineKey;
            });

            predicates.push(Predicate.create(
                'StudyLine', 'any',
                'Line.C_Line_key', 'in', lineKeys
            ));
        }

        return predicates;
    }

    getStudy(studyKey: number, expands?: string[]): Promise<Study> {

        if (!expands) {
            expands = [];
        }

        this.ensureExpanded(expands, 'cv_StudyType');
        this.ensureExpanded(expands, 'cv_StudyStatus');

        const query = EntityQuery.from('Studies')
            .expand(expands.join(','))
            .where('C_Study_key', '==', studyKey);

        return this.dataManager.returnSingleQueryResult(query);
    }

    getStudyLines(studyKey: number): Promise<any[]> {
        const query = EntityQuery.from('StudyLines')
            .expand('Line')
            .where('C_Study_key', '==', studyKey);

        return this.dataManager.returnQueryResults(query);
    }

    getStudyCharacteristics(studyKey: number): Promise<any[]> {
        const query = EntityQuery.from('StudyCharacteristicInstances')
            .expand('StudyCharacteristic.cv_DataType')
            .where('C_Study_key', '==', studyKey)
            .orderBy('StudyCharacteristic.SortOrder');

        return this.dataManager.returnQueryResults(query);
    }

    getStudiesApprovalNumbers(): Promise<string[]> {
        const query = EntityQuery.from('Studies')
            .select('ApprovalNumber')
            .orderBy('ApprovalNumber');

        return this.dataManager.returnQueryResults(query);
    }

    ensureLineDataLoaded(studies: any[]): Promise<void> {
        const expands = [
            'StudyLine.Line',
        ];
        return this.dataManager.ensureRelationships(studies, expands);
    }

    createStudy(): any {
        const initialValues: any = {
            DateCreated: new Date(),
            IsActive: true,
            IsLocked: false
        };
        return this.dataManager.createEntity('Study', initialValues);
    }

    createStudyMaterial(initialValues: any): any {
        return this.dataManager.createEntity('StudyMaterial', initialValues);
    }

    createStudyLine(initialValues: any): any {
        return this.dataManager.createEntity('StudyLine', initialValues);
    }

    createStudyCharacteristics(study: any): Promise<any[]> {
        const predicates: Predicate[] = [];
        predicates.push(Predicate.create('IsActive', '==', true));
        // predicates.push(Predicate.create('C_StudyType_key', '==', study.C_StudyType_key));
        predicates.push(Predicate.create("StudyCharacteristicStudyType", "any", "C_StudyType_key", "in", [study.C_StudyType_key]));
        const query = EntityQuery.from('StudyCharacteristics')
            .expand('cv_DataType')
            .where(Predicate.and(predicates))
            .orderBy('SortOrder');

        return this.dataManager.returnQueryResults(query).then((results: any) => {
            const newCharacteristics: any[] = [];

            for (const characteristic of results) {
                const newCharacteristic = this.dataManager.createEntity(
                    'StudyCharacteristicInstance',
                    {
                        C_StudyCharacteristic_key: characteristic.C_StudyCharacteristic_key,
                        C_Study_key: study.C_Study_key,
                        CharacteristicName: characteristic.CharacteristicName,
                        Description: characteristic.Description
                    }
                );
                newCharacteristics.push(newCharacteristic);
            }
            return newCharacteristics;
        });
    }

    createStudyAdministratorStudies(study: any): Promise<any[]> {
        // Add the given study to the list of adminStudies for users already assigned to all existing studies
        return this.webApiService.callApi('api/studyinfo/GetUniversalStudyAdministrators').then((response: any) => {
            const newStudyAdministratorStudies: any[] = [];

            if (response && response.data && response.data.length > 0) {
                const userKeys = response.data;

                for (const userKey of userKeys) {
                    const newStudyAdminStudy = this.dataManager.createEntity(
                        'StudyAdministratorStudy',
                        {
                            C_Study_key: study.C_Study_key,
                            C_User_key: userKey
                        }
                    );
                    newStudyAdministratorStudies.push(newStudyAdminStudy);
                }
            }
            return newStudyAdministratorStudies;
        });
    }

    deleteStudy(study: any) {
        while (study.StudyLine.length > 0) {
            this.dataManager.deleteEntity(study.StudyLine[0]);
        }
        while (study.StudyCharacteristicInstance.length > 0) {
            this.dataManager.deleteEntity(study.StudyCharacteristicInstance[0]);
        }
        this.dataManager.deleteEntity(study);
    }

    deleteStudyCharacteristic(studyCharacteristic: any) {
        this.dataManager.deleteEntity(studyCharacteristic);
    }

    deleteStudyMaterial(studyMaterial: any) {
        this.dataManager.deleteEntity(studyMaterial);
    }

    deleteStudyLine(studyLine: any) {
        this.dataManager.deleteEntity(studyLine);
    }

    cancelStudy(study: any) {
        if (!study) {
            return;
        }

        if (study.C_Study_key > 0) {
            this._cancelStudyEdits(study);
        } else {
            this._cancelNewStudy(study);
        }
    }

    private _cancelNewStudy(study: any) {
        try {
            this.deleteStudy(study);
        } catch (error) {
            console.error('Error cancelling new study: ' + error);
        }
    }

    private _cancelStudyEdits(study: any) {
        this.dataManager.rejectEntityAndRelatedPropertyChanges(study);

        this.dataManager.rejectChangesToEntityByFilter(
            'StudyCharacteristicInstance', (item: any) => {
                return item.C_Study_key === study.C_Study_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'StudyLine', (item: any) => {
                return item.C_Study_key === study.C_Study_key;
            }
        );

        const fileMaps = this.dataManager.rejectChangesToEntityByFilter(
            'StoredFileMap', (item: any) => {
                return item.C_Study_key === study.C_Study_key;
            }
        );
        // also reject files associated with each fileMap
        for (const fileMap of fileMaps) {
            this.dataManager.rejectChangesToEntityByFilter(
                'StoredFile', (item: any) => {
                    return item.C_StoredFile_key === fileMap.C_StoredFile_key;
                }
            );
        }
    }
}
