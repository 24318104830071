<audit-filter-templates #templates [(filter)]="filter"></audit-filter-templates>

<form role="form" class="form-label-right">
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Audit Filter</h4>
    </div>

    <div class="modal-body">
        <ng-container *ngFor="let filter of templates.filters">
            <ng-template [ngTemplateOutlet]="filter.template" [ngTemplateOutletContext]="filter.context || {}">
            </ng-template>
        </ng-container>
    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <span class="btn-set">
            <button type="submit"
                    class="btn btn-fw-wide btn-lg btn-primary"
                    (click)="filterClicked($event)">
                Filter
            </button>
            <button data-automation-id="clear-filter-button"
                    type="button"
                    class="btn btn-fw-wide btn-lg btn-secondary"
                    (click)="clearClicked()">
                Clear Filter
            </button>
        </span>
        <span class="btn-set">
            <button type="button"
                    class="btn btn-lg btn-secondary"
                    (click)="closeClicked()">
                Close
            </button>
        </span>
    </div>
</form>
