import { LocalStorageKey } from '@config';
import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { EntityQuery, Predicate } from 'breeze-client';
import { CurrentWorkgroupService } from './current-workgroup.service';
import { AdminManagerService } from './admin-manager.service';
import { FEATURE_FLAG_STORE_TOKEN, FeatureFlagStore } from '../mf-store/feature-flag';

export const LARGE_ANIMAL = "LargeAnimal";
export const IS_GLP = "IsGLP";
export const IS_DOTMATICS = "IsDotmatics";
@Injectable()
export class FeatureFlagService {
    flags: Record<string, unknown>;

    constructor(
        private _localStorageService: LocalStorageService,
        private workgroupService: CurrentWorkgroupService,
        private adminManager: AdminManagerService,
        @Inject(FEATURE_FLAG_STORE_TOKEN)
        private featureFlagStore: FeatureFlagStore,
    ) { }

    async getFeatureFlags(): Promise<void> {
        const predicates: Predicate[] = [];
        const workgroupKey = await this.workgroupService.getCurrentWorkgroupKey();
        predicates.push(Predicate.create('C_Workgroup_key', '==', workgroupKey));

        const query = EntityQuery.from('WorkgroupFeatureFlags').where(Predicate.and(predicates));
        try {
            const result = await this.adminManager.executeQuery(query);
            this.setFlagsData(result.results);
        } catch (error) {
            this.adminManager.queryFailed(error);
            throw error;
        }
    }


    setFlagsData(data: any[]): void {
        const flagsData = data.reduce((acc, flag) => {
          acc[flag.key] = {
              C_WorkgroupFeatureFlagType_key: flag.C_WorkgroupFeatureFlagType_key,
              C_WorkgroupFeatureFlag_key: flag.C_WorkgroupFeatureFlag_key,
              C_Workgroup_key: flag.C_Workgroup_key,
              IsActive: flag.IsActive,
              Value: flag.Value,
              key: flag.key,
          };
          return acc;
        }, {});
        this.featureFlagStore.events.setData(flagsData);
        this._localStorageService.set(LocalStorageKey.FEATURE_FLAGS, flagsData);
        this.flags = flagsData;
    }

    fillFeatureFlagData(): void {
        const flags = this._localStorageService.get(LocalStorageKey.FEATURE_FLAGS);
        if (flags) {
            this.flags = flags;
        }
    }

    getFlag(flagKey: string): any {
        if (!this.flags) {
            this.fillFeatureFlagData();
        }

        if (this.flags) {
            return this.flags[flagKey];
        }

        return undefined;
    }

    isFlagOn(flagKey: string): boolean {
        const flag = this.getFlag(flagKey);
        return flag?.IsActive && flag.Value.toLowerCase() === 'true';
    }

    getIsGLP(): boolean {
        return this.isFlagOn('IsGLP');
    }

    getIsCRO(): boolean {
        return this.isFlagOn('IsCRO');
    }

    getIsCRL(): boolean {
        return this.isFlagOn('IsCRL');
    }

    getIsDotmatics(): boolean {
        return this.isFlagOn('IsDotmatics');
    }
}
