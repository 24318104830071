<div class="form-group" *ngIf="isShowTable">
    <div class="col-md-12">
        <table class="table">
            <thead>
                <tr>
                    <th *ngIf="isFieldActive('Housing')">Housing</th>
                    <th *ngIf="isFieldActive('Water Available')">Water Available</th>
                    <th *ngIf="isFieldActive('Food Available')">Food Available</th>
                    <th *ngIf="isFieldActive('Bedding Available')">Bedding Available</th>
                    <th *ngIf="isFieldActive('All Shipment Containers Confirmed Empty')">
                        All Shipment Containers Confirmed Empty
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td *ngIf="isFieldActive('Housing')">
                        <input
                            type="checkbox"
                            (click)="onAnimalSectionClick('Single', 'AnimalHousing')"
                            [checked]="order.AnimalHousing === 'Single'"
                            id="single"
                            name="housing"
                        />
                        <label for="single">Single</label>
                    </td>
                    <td *ngIf="isFieldActive('Water Available')">
                        <input
                            type="checkbox"
                            (click)="onAnimalSectionClick(true, 'IsWaterAvailable')"
                            [checked]="order.IsWaterAvailable === true"
                            id="water_yes"
                            name="water"
                        />
                        <label for="water_yes">Yes</label>
                    </td>
                    <td *ngIf="isFieldActive('Food Available')">
                        <input
                            type="checkbox"
                            (click)="onAnimalSectionClick(true, 'IsFoodAvailable')"
                            [checked]="order.IsFoodAvailable === true"
                            id="food_yes"
                            name="food"
                        />
                        <label for="food_yes">Yes</label>
                    </td>
                    <td *ngIf="isFieldActive('Bedding Available')">
                        <input
                            type="checkbox"
                            (click)="onAnimalSectionClick(true, 'IsBeddingAvailable')"
                            [checked]="order.IsBeddingAvailable === true"
                            id="bedding_yes"
                            name="bedding"
                        />
                        <label for="bedding_yes">Yes</label>
                    </td>
                    <td *ngIf="isFieldActive('All Shipment Containers Confirmed Empty')">
                        <input
                            type="checkbox"
                            (click)="onAnimalSectionClick(true, 'IsAllShipmentContainersEmpty')"
                            [checked]="order.IsAllShipmentContainersEmpty === true"
                            id="shipping_yes"
                            name="shipping"
                        />
                        <label for="shipping_yes">Yes</label>
                    </td>
                </tr>
                <tr>
                    <td *ngIf="isFieldActive('Housing')">
                        <input
                            type="checkbox"
                            (click)="onAnimalSectionClick('Social', 'AnimalHousing')"
                            [checked]="order.AnimalHousing === 'Social'"
                            id="social"
                            name="housing"
                        />
                        <label for="social">Social</label>
                    </td>
                    <td *ngIf="isFieldActive('Water Available')">
                        <input
                            type="checkbox"
                            (click)="onAnimalSectionClick(false, 'IsWaterAvailable')"
                            [checked]="order.IsWaterAvailable === false"
                            id="water_no"
                            name="water"
                        />
                        <label for="water_no">No</label>
                    </td>
                    <td *ngIf="isFieldActive('Food Available')">
                        <input
                            type="checkbox"
                            (click)="onAnimalSectionClick(false, 'IsFoodAvailable')"
                            [checked]="order.IsFoodAvailable === false"
                            id="food_no"
                            name="food"
                        />
                        <label for="food_no">No</label>
                    </td>
                    <td *ngIf="isFieldActive('Bedding Available')">
                        <input
                            type="checkbox"
                            (click)="onAnimalSectionClick(false, 'IsBeddingAvailable')"
                            [checked]="order.IsBeddingAvailable === false"
                            id="bedding_no"
                            name="bedding"
                        />
                        <label for="bedding_no">No</label>
                    </td>
                    <td *ngIf="isFieldActive('All Shipment Containers Confirmed Empty')">
                        <input
                            type="checkbox"
                            (click)="onAnimalSectionClick(false, 'IsAllShipmentContainersEmpty')"
                            [checked]="order.IsAllShipmentContainersEmpty === false"
                            id="shipping_no"
                            name="shipping"
                        />
                        <label for="shipping_no">No</label>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
