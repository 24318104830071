<div class="icon-wrapper" [ngSwitch]="status">
    <svg *ngSwitchCase="'success'" [climbIcon]="icons.checkmarkCircleFilled"></svg>
    <svg *ngSwitchCase="'error'" [climbIcon]="icons.errorCircleFilled"></svg>
    <svg *ngSwitchCase="'warning'" [climbIcon]="icons.errorCircleFilled"></svg>
    <svg *ngSwitchCase="'info'" [climbIcon]="icons.errorCircleFilled"></svg>
</div>
<div class="message-wrapper">
    {{ message }}
    <ng-container *ngTemplateOutlet="template"></ng-container>
    <ng-content></ng-content>
</div>
